import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/WorkStatusGroup`;


export const getAllWorkStatusGroupPaged = (tableModel) => {
	let url = `${endpoint}/GetAllPaged/`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};


export const getAllWorkStatusGroup = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getWorkStatusGroup = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveWorkStatusGroup = (workStatusGroup) => {
	let url = endpoint;
	let method = 'POST';
	
	if(workStatusGroup.Id && workStatusGroup.Id > 0)
	{
		url += `/${workStatusGroup.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(workStatusGroup)
	}, getFetchOpts()));
};

export const deleteWorkStatusGroup = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
