import React, { Component } from 'react';

import moment from 'moment';
import swal from 'sweetalert'

import Auth from '../../../util/Auth';
import { getPrize } from '../../../service/Prize';
import { getCommunityMemberToken } from '../../../service/CommunityMember';
import { saveCommunityMemberPrize } from '../../../service/CommunityMemberPrize';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class RewardConfirmation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prizeList: null,
            profileList: {},
        }
        this.handlePrizeConfirmation = this.handlePrizeConfirmation.bind(this);
        this.handlePrizeConfirmationDownload = this.handlePrizeConfirmationDownload.bind(this);
        this.handleBackToRewards = this.handleBackToRewards.bind(this);
    }

    componentDidMount() {
        if (Auth.isUserAuthenticated()) {
            getCommunityMemberToken()
                .then(res => res.json()
                    .then(json => ({ res, json })
                    ))
                .then(({ res, json }) => {
                    if (json.Status) {
                        this.setState({ profileList: json });
                    }
                })
                .catch(() =>
                    swal({
                        title: "Sorry, this is a member only page",
                        text: "Please login to access this page",
                        icon: '/assets/img/SadFaceResize.png',
                        // buttons: true,
                        dangerMode: true,
                        closeOnClickOutside: false,
                    }).then((result) => {
                        if (result) {
                            //TODO: change when go to production
                            var indexOfSlash = window.location.href.indexOf('.com');
                            var redirectTo = window.location.href.substring(indexOfSlash + 4);
                            // var indexOfSlash = window.location.href.indexOf(':3000'); // for test ind development
                            // var redirectTo = window.location.href.substring(indexOfSlash + 5);
                            window.location.href = ('/Dashboard/CommunityMember/Login/?redirect=' + redirectTo);
                        }
                    })
                );;

            getPrize(this.props.location.state.prizeId)
                .then(res => res.json().then(json => ({ res, json })))
                .then(({ res, json }) => {
                    this.setState({ prizeList: json });
                })
                .catch(console.log);
        }
    }

    handlePrizeConfirmation() {
        swal({
            title: "Please confirm",
            text: "By selecting ok, you are agreeing to use " + this.state.prizeList.HowManyPoint.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + ' rewards for \'' + this.state.prizeList.Title + '\'',
            buttons: true,
            dangerMode: true,
        })
            .then((result) => {
                if (result) {
                    this.props.history.push(
                        {
                            pathname: '/Dashboard/CommunityMember/RewardCongratulation',
                            state: { prizeId: this.state.prizeList.Id }
                        })
                }
            });
    }

    handlePrizeConfirmationDownload() {
        var data = {}
        data.PrizeId = this.state.prizeList.Id

        swal({
            title: "Please confirm",
            text: "By selecting ok, you are agreeing to use " + this.state.prizeList.HowManyPoint.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + ' rewards for \'' + this.state.prizeList.Title + '\'',
            buttons: true,
            dangerMode: true,
        })
            .then((result) => {
                if (result)
                    saveCommunityMemberPrize(data).then(res => res.json()
                        .then(json => ({ res, json })))
                        .then(({ res, json }) => {
                            if (json.success) {
                                this.props.history.push(
                                    {
                                        pathname: '/Dashboard/CommunityMember/RewardCongratulation',
                                        state: { prizeId: this.state.prizeList.Id }
                                    })
                            }
                        })
            })
    }

    handleBackToRewards() {
        this.props.history.push('/Dashboard/Overview')
    }


    render() {
        const now = new Date();
        const time = moment(now).format('YYYYMMDDHHmmss');

        const image = this.state.prizeList ? this.state.prizeList.PrizeImage !== null && this.state.prizeList.PrizeImage !== '' ? `${STORAGE_URL}prize/${this.state.prizeList.PrizeImage}?${time}` : '' : '';


        return (
            <div className="style-container">

                {this.state.prizeList && <div className="row mb-5">
                    <div className="col-sm-12 col-lg-4 text-center p-4">
                        <img src={image} style={{ width: '400px' }} /><br />
                    </div>
                    <div className={"col"}>
                        <h1>
                            {this.state.profileList.FirstName + ","}
                        </h1>
                        <h2 className="mb-5">{'you are going to use ' + this.state.prizeList.HowManyPoint.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + ' rewards for \'' + this.state.prizeList.Title + '\''}</h2>

                        <div className="row justify-content-center">
                            <div className="col-3">
                                <button className="btn btn-cons explore-dashboard text-white bg-danger"
                                    onClick={this.handleBackToRewards}
                                >BACK TO REWARDS</button>
                            </div>

                            {this.state.prizeList.Link == '' && <div className="col-3">
                                <button className="btn btn-cons explore-dashboard text-white" style={{ backgroundColor: '#8dc53e' }}
                                    onClick={this.handlePrizeConfirmation}
                                >CONFIRM SELECTION</button>
                            </div>}

                            {this.state.prizeList.Link != '' && <div className="col-3">
                                <button className="btn btn-cons explore-dashboard text-white" style={{ backgroundColor: '#8dc53e' }}
                                    onClick={this.handlePrizeConfirmationDownload}
                                >CONFIRM SELECTION</button>
                            </div>}
                        </div>

                    </div>
                </div>}

            </div>
        );
    }
}

export default RewardConfirmation;