import React, { Component } from 'react';
import { withFormik } from 'formik';
import 'react-select/dist/react-select.css';

import { getByCommunityMemberIdToken, inviteAcceptCommunityMemberProduct, editCommunityMemberProduct } from '../../../service/CommunityMemberProduct';
import { saveRangeInviteFriend } from '../../../service/InviteFriend';

import moment from 'moment';
import scrollToComponent from 'react-scroll-to-component';
import swal from 'sweetalert'

import ResponsiveMenu from 'react-responsive-navbar';
import Auth from '../../../util/Auth';
import { getCommunityMemberToken, getPointByToken } from '../../../service/CommunityMember';
import { getAllSubscribe } from '../../../service/Subscribe';
import { getPrizeByToken } from '../../../service/Prize';
import { saveCommunityMemberSubscribeManufacturer } from '../../../service/CommunityMemberSubscribeManufacturer';
import InviteFriendModal from './InviteFriendModal';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class UserOverview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            subscribeList: [],
            prizeList: [],
            profileList: {},
            haveTestingOpportunity: false,
            haveEvaluation: false,
            haveSubscribe: false,
            havePrize: false,
            displayTestingOpportunity: true,
            displayEvaluation: true,
            displaySubscribe: true,
            displayPrize: true,
            displayPTPAClub: true,
            displayProductReview: true,
            selectedSubscribe: [],
            communityMemberPoints: 0,
        }
        this.handleAccept = this.handleAccept.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleProductReceived = this.handleProductReceived.bind(this);
        this.handleSubscribe = this.handleSubscribe.bind(this);
        this.handlePrize = this.handlePrize.bind(this);
        this.handleEarnMorePoints = this.handleEarnMorePoints.bind(this);
        this.handleChangeSubscribeItems = this.handleChangeSubscribeItems.bind(this);
        this.becomePTPAClub = this.becomePTPAClub.bind(this);
    }

    componentDidMount() {
        if (Auth.isUserAuthenticated()) {
            getCommunityMemberToken()
                .then(res => res.json()
                    .then(json => ({ res, json })
                    ))
                .then(({ res, json }) => {
                    if (json.Status) {
                        this.setState({ profileList: json });
                    }
                })
                .catch(() =>
                    swal({
                        title: "Sorry, this is a member only page",
                        text: "Please login to access this page",
                        icon: '/assets/img/SadFaceResize.png',
                        // buttons: true,
                        dangerMode: true,
                        closeOnClickOutside: false,
                    }).then((result) => {
                        if (result) {
                            //TODO: change when go to production
                            var indexOfSlash = window.location.href.indexOf('.com');
                            var redirectTo = window.location.href.substring(indexOfSlash + 4);
                            // var indexOfSlash = window.location.href.indexOf(':3000'); // for test ind development
                            // var redirectTo = window.location.href.substring(indexOfSlash + 5);
                            window.location.href = ('/Dashboard/CommunityMember/Login/?redirect=' + redirectTo);
                        }
                    })
                );
            getByCommunityMemberIdToken()
                .then(res => res.json()
                    .then(json => ({ res, json })))
                .then(({ res, json }) => {
                    this.setState({ list: json });
                })
                .catch(console.log);
            getAllSubscribe()
                .then(res => res.json().then(json => ({ res, json })))
                .then(({ res, json }) => {
                    this.setState({ subscribeList: json });
                })
                .catch(console.log);
            getPrizeByToken()
                .then(res => res.json().then(json => ({ res, json })))
                .then(({ res, json }) => {
                    this.setState({ prizeList: json });
                })
                .catch(console.log);
            getPointByToken()
                .then(res => res.json().then(json => ({ res, json })))
                .then(({ res, json }) => {
                    this.setState({ communityMemberPoints: json });
                })
                .catch(console.log);
        }
    }

    handleAccept(event) {

        var data = this.state.list[event.target.id];
        data.Accepted = event.target.innerHTML === 'ACCEPT' ? 1 : 0;
        if (data.Accepted == 1)
            data.DateAccepted = moment().format('YYYY-MM-DD');

        const dataProfile = this.state.profileList;

        var span = document.createElement("span");

        try {
            span.innerHTML = data.Accepted == 1 ? dataProfile.PickUp == true ? "By clicking \"OK\", you confirm that you would like to test this product <b>(" + data.Product.ProductName + ")</b> <br/><br/>"
                + "And you are able to pickup at our office location: <b>606 Rivermede Rd Unit 1, Concord, ON L4K 2H6</b>"
                : "By clicking \"OK\", you confirm that you would like to test this product <b>(" + data.Product.ProductName + ")</b> <br/><br/>"
                + "You also confirm that your shipping address below is correct.<br/><b>" + dataProfile.Street + ", " + dataProfile.PostalCode
                + " " + dataProfile.City + " - " + dataProfile.State.StateName + "/" + dataProfile.Country.CountryName + "</b><br/>If incorrect, please click \"cancel\" and scroll up to \"edit profile\"."
                : "Are you sure you want to " + event.target.innerHTML + " the testing opportunity?";

            swal({
                title: "Please confirm",
                content: span,
                // icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((result) => {
                    if (result) {
                        return inviteAcceptCommunityMemberProduct(data).then(res => {
                            if (res.ok) {
                                this.setState({ haveTestingOpportunity: false })
                            }
                        });
                    }
                });

        } catch (e) {
            swal ( "Oops" ,  "Please complete your profile" ,  "error" )
        }
    }

    handleClick() {
        Auth.deauthenticateUser();
        window.location.href = "/Dashboard/CommunityMember/Login";
    }

    handleProductReceived(event) {

        var data = this.state.list[event.target.id];
        data.Received = 1;

        swal({
            title: "Please confirm",
            text: "Did you receive the " + data.Product.ProductName + "?",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((result) => {
                if (result) {
                    return editCommunityMemberProduct(data).then(res => {
                        if (res.ok) {
                            this.setState({ haveTestingOpportunity: false })
                        }
                    });
                }
            });
    }

    handleSubscribe(event) {
        var temp = this.state.subscribeList[event.target.id];
        var data = {}
        data.SubscribeManufacturerId = temp.Id

        // if (temp.Redirect) {
        //     window.open(temp.Redirect, '_blank').focus()
        // }

        swal({
            title: "Confirmation",
            text: "You have agreed to receive notifications and emails from " + temp.Manufacturer.CompanyName,
            icon: "success",
            buttons: "Ok",
            closeOnClickOutside: false,
            closeOnEsc: false,
        })
            .then((result) => {
                if (result) {
                    return saveCommunityMemberSubscribeManufacturer(data).then(res => {
                        if (res.ok) {
                            getPointByToken()
                                .then(res => res.json().then(json => ({ res, json })))
                                .then(({ res, json }) => {
                                    this.setState({ communityMemberPoints: json });
                                })
                                .then(() => {
                                    this.setState(prevState => (
                                        { subscribeList: prevState.subscribeList.filter(item => item.Id !== data.SubscribeManufacturerId) }
                                    ))
                                })
                                .catch(console.log);
                        }
                    });
                }
            });
    }

    handlePrize(event) {
        var temp = this.state.prizeList[event.target.id];

        this.props.history.push(
            {
                pathname: '/Dashboard/CommunityMember/RewardConfirmation',
                state: { prizeId: temp.Id }
            })
    }

    handleEarnMorePoints(event) {
        var temp = this.state.prizeList[event.target.id];

        swal({
            title: "You are only " + temp.HowManyPointLeft.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + " Points away!",
            text: "Earn more rewards:",
            buttons: {
                inviteFriend: "Invite a friend",
                review: "Leave a review",
                subscribe: "Subscribe",
            },
        })
            .then((result) => {
                switch (result) {
                    case "inviteFriend":
                        scrollToComponent(this.InviteFriend, { offset: 0, align: 'top', duration: 1500 })
                        break;
                    case "review":
                        scrollToComponent(this.ProductReview, { offset: 0, align: 'top', duration: 1500 })
                        break;
                    case "subscribe":
                        scrollToComponent(this.Subscribe, { offset: 0, align: 'top', duration: 1500 })
                        break;
                }
            });
    }


    handleChangeSubscribeItems(event) {
        var allSubscribeItems = this.state.selectedSubscribe;
        var index = allSubscribeItems.indexOf(parseInt(event.target.id));
        if (index > -1) {
            allSubscribeItems.splice(index, 1);
            this.state.selectedSubscribe = allSubscribeItems;
        } else {
            this.state.selectedSubscribe.push(parseInt(event.target.id));
        }

        this.setState({
            selectedSubscribe: this.state.selectedSubscribe,
        });
    }

    becomePTPAClub() {
        if (this.state.profileList.Country)
            window.open('https://ptpa.com/parent-rewards-program-ptpa-club/', '_self')
        else
            swal({
                title: "PTPA.Club",
                text: "In order to become part of the PTPA.Club. Please, complete your profile.",
            })
                .then((result) => {
                    if (result) {
                        this.props.history.push(`/Dashboard/CommunityMember/CompleteProfile/`)
                    }
                });
    }

    render() {
        const {
            values,
            handleSubmit,
            isSubmitting,
        } = this.props;

        const now = new Date();
        const time = moment(now).format('YYYYMMDDHHmmss');

        var image;
        var userName;
        if (Object.keys(this.state.profileList).length > 0) {
            image = (this.state.profileList.ProfileImage !== null && this.state.profileList.ProfileImage !== '' ?
                `${STORAGE_URL}member/${this.state.profileList.ProfileImage}?${time}`
                : this.state.profileList.CommunityMemberGenderTargetId == 1 ? '/assets/img/FemaleProfile.png'
                    : this.state.profileList.CommunityMemberGenderTargetId == 2 ? '/assets/img/MaleProfile.png'
                        : '/assets/img/OtherProfile.png'
            );

            userName = this.state.profileList.FirstName;
        }

        const itemsTestingOpportunity = this.state.list.map((item, index) => (
            <div className={item.Accepted != null ? 'noneDisplay' : 'col-sm-6 col-md-4 mt-3 mb-2'}>
                <div style={{
                    boxShadow: '0 0 2px 2px rgba(0,0,0,0.05), 0 0 5px 5px rgba(0,0,0,0.05)', borderRadius: '10px'
                }}>
                    <div className="style-product-container text-center">
                        <a>
                            <img src={(item.Product !== null && item.Product !== '' ? `${STORAGE_URL}product/${item.Product.LargeImage}?${time}` : '')}
                                className={item.TestOpportunityFull ? "noneDisplay" : "attachment-shop_catalog size-shop_catalog wp-post-image"} alt={item.Product.ProductName}
                                style={{ minHeight: "230px", maxHeight: "230px" }} />

                            <img src={(item.Product !== null && item.Product !== '' ? `${STORAGE_URL}product/${item.Product.LargeImage}?${time}` : '')}
                                className={item.TestOpportunityFull ? "attachment-shop_catalog size-shop_catalog wp-post-image" : "noneDisplay"} alt={item.Product.ProductName}
                                style={{ minHeight: "230px", maxHeight: "230px", opacity: "0.3" }} />

                            <div className={item.TestOpportunityFull ? "text-center" : "noneDisplay"}
                                style={{ position: "absolute", top: "75px", maxWidth: "250px", left: '21%' }}>
                                <h3 id={index} className="explore-dashboard"
                                    onClick={this.handleProductReceived}
                                ><strong>OH NO!<br />TESTING OPPORTUNITY IS NOW FULL</strong></h3>
                            </div>
                        </a>
                        <div className="product-text-field">
                            <a><span className="product_item--title"><b className="ng-binding">{item.Product.ProductName}</b></span></a><br />
                            <a><span className="sku ng-binding">MFG: {item.Product.Manufacturer.CompanyName}</span></a><br />
                            <a><span className="sku ng-binding">Price: {item.Product.CurrencyId == 1 ? 'CAD ' : '$'}
                                {item.Product.PriceRetail ? item.Product.PriceRetail : (item.Product.PriceStarts + " - " + (item.Product.CurrencyId == 1 ? 'CAD ' : '$') + item.Product.PriceEnds)}</span></a>
                        </div>

                    </div>
                </div>
                <div className={item.TestOpportunityFull ? "noneDisplay" : "row justify-content-center mt-3 mb-5"}>
                    <div className="text-center mr-1">
                        <button id={index} className="btn btn-cons explore-dashboard text-white" onClick={this.handleAccept}
                            style={{ backgroundColor: '#8dc53e' }}>ACCEPT</button>
                    </div>

                    <div className="text-center ml-1">
                        <button id={index} className="btn btn-cons explore-dashboard text-white bg-danger" onClick={this.handleAccept}>DECLINE</button>
                    </div>
                </div>
            </div>
        ));
        for (var i = 0; i < itemsTestingOpportunity.length; i++) {
            if (itemsTestingOpportunity[i].props.className != 'noneDisplay')
                this.state.haveTestingOpportunity = true;
        }

        const itemsEvaluation = this.state.list.map((item, index) => (
            <div className={item.Accepted != 1 ? 'noneDisplay' : 'col-sm-6 col-md-4 mt-3 mb-2'}>
                <h6><span className="style-product-status" style={{ color: "green" }}>{"Evaluation Due Date: " + moment(item.Product.ProductConfiguration.EvaluationDeadline).format("MM/DD/YYYY")}</span></h6>
                <div style={{
                    boxShadow: '0 0 2px 2px rgba(0,0,0,0.05), 0 0 5px 5px rgba(0,0,0,0.05)', borderRadius: '10px'
                }}>
                    <div className="style-product-container text-center">
                        <a>
                            <img src={(item.Product !== null && item.Product !== '' ? `${STORAGE_URL}product/${item.Product.LargeImage}?${time}` : '')}
                                className="attachment-shop_catalog size-shop_catalog wp-post-image" alt={item.Product.ProductName}
                                style={{ minHeight: "230px", maxHeight: "230px" }} />
                        </a>
                        <div className="product-text-field">
                            <a><span className="product_item--title"><b className="ng-binding">{item.Product.ProductName}</b></span></a><br />
                            <a><span className="sku ng-binding">MFG: {item.Product.Manufacturer.CompanyName}</span></a><br />
                            <a><span className="sku ng-binding">Price: {item.Product.CurrencyId == 1 ? 'CAD ' : '$'}
                                {item.Product.PriceRetail ? item.Product.PriceRetail : (item.Product.PriceStarts + " - " + (item.Product.CurrencyId == 1 ? 'CAD ' : '$') + item.Product.PriceEnds)}</span></a>
                        </div>

                    </div>
                </div>
                <div className="row justify-content-center mt-3 mb-5">
                    <div className="text-center mr-1">
                        <div className={item.Received == 1 ? "noneDisplay" : ""}>
                            <button id={index} className="btn btn-cons explore-dashboard text-white"
                                style={{ backgroundColor: '#8dc53e' }}
                                onClick={this.handleProductReceived}
                            >PRODUCT RECEIVED</button>
                        </div>

                        <div className={item.Received == 1 ? "" : "noneDisplay"}>
                            <button id={index} className="btn btn-cons explore-dashboard text-white"
                                style={{ backgroundColor: '#8dc53e' }}
                                onClick={() => window.open(item.Product.ProductConfiguration.SurveyMonkeyLink +
                                    `?product=${item.Product.Id}&id=${this.state.profileList.Id}`, "_self")}
                            >COMPLETE EVALUATION</button>
                        </div>
                    </div>
                </div>
            </div>
        ));

        for (var i = 0; i < itemsEvaluation.length; i++) {
            if (itemsEvaluation[i].props.className != 'noneDisplay')
                this.state.haveEvaluation = true;
        }

        const itemsSubscribe = this.state.subscribeList.map((item, index) => (
            <div className='col-sm-6 col-md-4 mt-3 mb-2'>
                <h6><span className="style-product-status">{item.Title}</span></h6>
                <h6><span className="style-product-status">{item.Description}</span></h6>
                <div style={{
                    boxShadow: '0 0 2px 2px rgba(0,0,0,0.05), 0 0 5px 5px rgba(0,0,0,0.05)', borderRadius: '10px'
                }}>
                    <div className="style-product-container text-center">
                        <a>
                            <img src={(item.ManufacturerImage !== null && item.ManufacturerImage !== '' ? `${STORAGE_URL}subscribemanufacturer/${item.ManufacturerImage}?${time}` : '')}
                                className="attachment-shop_catalog size-shop_catalog wp-post-image"
                                alt={item.Manufacturer.CompanyName}
                                style={{ minHeight: "170px", maxHeight: "170px" }} />
                        </a>

                        <div className="row justify-content-center mt-3">
                            <div className="text-center mr-1">
                                <div>

                                    <div className="row mb-2" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                                        <input type="checkbox" id={item.Id}
                                            checked={this.state.selectedSubscribe != null ? this.state.selectedSubscribe.indexOf(item.Id) > -1 : false}
                                            onChange={this.handleChangeSubscribeItems} />
                                        <label className="col text-left">Please agree to <a href="https://ptpa.com/terms-of-use/" target="_blank" style={{ color: 'blue' }}>PTPA's terms and conditions</a> prior to signing up.</label>
                                    </div>

                                    <h6 className="mt-4 text-left"><span className="style-product-status" style={{ fontSize: '25px' }}>{"Earn 300 reward points"}</span></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-3 mb-5">
                    <button id={index} className="btn btn-cons explore-dashboard text-white"
                        style={{ backgroundColor: '#8dc53e' }}
                        onClick={this.handleSubscribe}
                        disabled={this.state.selectedSubscribe != null ? !(this.state.selectedSubscribe.indexOf(item.Id) > -1) : true}
                    >SUBSCRIBE</button>
                </div>
            </div>
        ));

        for (var i = 0; i < itemsSubscribe.length; i++) {
            if (itemsSubscribe[i].props.className != 'noneDisplay')
                this.state.haveSubscribe = true;
        }

        const itemsPrize = this.state.prizeList.map((item, index) => (
            <div className='col-sm-6 col-md-4 mt-3 mb-2'>
                <h6><span className="style-product-status">{item.HowManyPoint.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + " Reward Points"}</span></h6>
                <h6><span className="style-product-status">{"You are only " + item.HowManyPointLeft.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + " Points away!"}</span></h6>
                <div style={{
                    boxShadow: '0 0 2px 2px rgba(0,0,0,0.05), 0 0 5px 5px rgba(0,0,0,0.05)', borderRadius: '10px'
                }}>
                    <div className="border style-product-container text-center">
                        <a>
                            <img src={(item.PrizeImage !== null && item.PrizeImage !== '' ? `${STORAGE_URL}prize/${item.PrizeImage}?${time}` : '')}
                                className="attachment-shop_catalog size-shop_catalog wp-post-image"
                                alt={item.Title}
                                style={{ minHeight: "230px", maxHeight: "230px" }} />
                        </a>
                        <div className="product-text-field">
                            <a><span className="sku ng-binding">{item.Title}</span></a><br />
                            <a><span className="sku ng-binding">{'1 of ' + item.HowManyAvailable + ' rewards to be claimed'}</span></a><br />
                        </div>
                    </div>
                </div>

                {item.HowManyPointLeft == 0 && <div className="row justify-content-center mt-3 mb-5">
                    <button id={index} className="btn btn-cons explore-dashboard text-white"
                        style={{ backgroundColor: '#8dc53e' }}
                        onClick={this.handlePrize}
                    >CLAIM REWARD</button>
                </div>}

                {item.HowManyPointLeft > 0 && <div className="row justify-content-center mt-3 mb-5">
                    <button id={index} className="btn btn-cons explore-dashboard text-white"
                        style={{ backgroundColor: '#bbbbbb' }}
                        onClick={this.handleEarnMorePoints}
                    >EARN MORE POINTS</button>
                </div>}
            </div>
        ));

        for (var i = 0; i < itemsPrize.length; i++) {
            if (itemsPrize[i].props.className != 'noneDisplay')
                this.state.havePrize = true;
        }

        // const itemsPTPAClub = PTPAClub.map((item, index) => (
        //     <div className='col-sm-6 col-md-4 mt-3 mb-2'>
        //         <h6><span className="style-product-status">{item.HowManyPoint.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + " Reward Points"}</span></h6>
        //         <h6><span className="style-product-status">{"You are only " + item.HowManyPointLeft.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + " Points away!"}</span></h6>
        //         <div style={{
        //             boxShadow: '0 0 2px 2px rgba(0,0,0,0.05), 0 0 5px 5px rgba(0,0,0,0.05)', borderRadius: '10px'
        //         }}>
        //             <div className="border style-product-container text-center">
        //                 <a>
        //                     <img src={(item.PrizeImage !== null && item.PrizeImage !== '' ? `${STORAGE_URL}prize/${item.PrizeImage}?${time}` : '')}
        //                         className="attachment-shop_catalog size-shop_catalog wp-post-image"
        //                         alt={item.Title}
        //                         style={{ minHeight: "230px", maxHeight: "230px" }} />
        //                 </a>
        //                 <div className="product-text-field">
        //                     <a><span className="sku ng-binding">{item.Title}</span></a><br />
        //                     <a><span className="sku ng-binding">{'1 of ' + item.HowManyAvailable + ' rewards to be claimed'}</span></a><br />
        //                 </div>
        //             </div>
        //         </div>

        //         {item.HowManyPointLeft == 0 && <div className="row justify-content-center mt-3 mb-5">
        //             <button id={index} className="btn btn-cons explore-dashboard text-white"
        //                 style={{ backgroundColor: '#8dc53e' }}
        //                 onClick={this.handlePrize}
        //             >CLAIM REWARD</button>
        //         </div>}

        //         {item.HowManyPointLeft > 0 && <div className="row justify-content-center mt-3 mb-5">
        //             <button id={index} className="btn btn-cons explore-dashboard text-white"
        //                 style={{ backgroundColor: '#bbbbbb' }}
        //                 onClick={this.handleEarnMorePoints}
        //             >EARN MORE POINTS</button>
        //         </div>}
        //     </div>
        // ));

        return (
            <div className="style-container">

                <div className={Object.keys(this.state.profileList).length > 0 ? "row mb-2" : "noneDisplay"}>
                    <div className="col-sm-12 col-lg-3 text-center p-4">
                        <img src={image} style={{ width: '200px', borderRadius: '50%' }} /><br />
                        {this.state.profileList.PaidMember && <div><img src="/assets/img/VIP_Club.png" style={{ width: '200px' }} /><br /> </div>}
                        <div className="style-header bg-white">
                            <div className="style-site-header">
                                <div className="style-container">
                                    <div className="style-header-component-inner border-top">
                                        <div className="style-site-main-nav style-clearfix">
                                            <ResponsiveMenu
                                                menuOpenButton={<div className="fa fa-bars" />}
                                                menuCloseButton={<div className="fa fa-bars" />}
                                                changeMenuOn="200px"
                                                largeMenuClassName="style-main-menu style-mega-menu"
                                                smallMenuClassName="style-mega-menu text-right"
                                                menu={
                                                    <ul className="p-0">
                                                        <li><a style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/Dashboard/CommunityMember/CompleteProfile/`)}>
                                                            <span class="mm-text"><i class="mm-icon far fa-user-circle" aria-hidden="true"></i> MY PROFILE</span></a></li>
                                                        {/* <li><a href="https://ptpa.com/winners/"><span class="mm-text">MY REWARDS</span></a></li> */}
                                                        <li><a href="https://ptpa.com/giveaways/"><span class="mm-text"><i class="mm-icon fas fa-medal"></i> CONTESTS</span></a></li>
                                                        <li><a onClick={() => scrollToComponent(this.Evaluations, { offset: 0, align: 'top', duration: 1500 })} style={{ cursor: 'pointer' }}><span className="mm-text"><i className="mm-icon fas fa-file-signature"></i> MY EVALUATIONS</span></a></li>
                                                        <li><a onClick={() => scrollToComponent(this.Subscribe, { offset: 0, align: 'top', duration: 1500 })} style={{ cursor: 'pointer' }}><span className="mm-text"><i className="mm-icon fas fa-envelope"></i> Offers</span></a></li><br />
                                                        <li><a onClick={() => scrollToComponent(this.Prize, { offset: 0, align: 'top', duration: 1500 })} style={{ cursor: 'pointer' }}><span className="mm-text"><i className="mm-icon fas fa-award"></i> Rewards</span></a></li>
                                                        <li><a onClick={() => window.open('https://www.ptpa.com/support/', '_blank')} style={{ cursor: 'pointer' }}><span className="mm-text"><i className="mm-icon far fa-question-circle"></i> Support</span></a></li>
                                                        <li><a onClick={this.handleClick} style={{ cursor: 'pointer' }}><span className="mm-text"><i className="mm-icon fa fa-lock"></i> Log out</span>  </a></li>
                                                    </ul>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"offset-1 col"}>
                        <h1>
                            {userName + ", you have " + this.state.communityMemberPoints.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + " reward points!"}
                        </h1>
                        <h6 className="mb-5">Thank you for being a part of our ever-growing community. Make sure to fully complete your profile in order to qualify as a PTPA product tester. </h6>


                        <div className={this.state.profileList.CompletedProfile ? "noneDisplay" : "p-2"} style={{ backgroundColor: '#f4f4f6', marginBottom: '10px' }}>
                            <h4 className="bg-white p-2 m-1">
                                <a style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/Dashboard/CommunityMember/CompleteProfile/?utm_source=internal_Site&utm_medium=banner&utm_campaign=banner_Dashboard`)}>
                                    <img src="/assets/img/completeprofile.png" />
                                </a>
                            </h4>
                        </div>



                        <div className={this.state.profileList.CompletedProfile ? "noneDisplay" : "p-2"} style={{ backgroundColor: '#f4f4f6', marginBottom: '10px' }}>
                            <h4 className="bg-white p-2 m-1">
                                <a style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/Dashboard/CommunityMember/CompleteProfile/`)}>
                                    <i class="mm-icon far fa-user-circle" aria-hidden="true" style={{ fontSize: "21px", color: "#CCC" }} ></i><span style={{ color: '#FF0000' }}> {"Complete your full profile to earn 5,000 reward points!"}</span>
                                </a>
                            </h4>
                        </div>

                        {!this.state.profileList.PaidMember && <div className="p-2" style={{ backgroundColor: '#7543a9', marginBottom: '10px' }}>
                            <h4 className="bg-white p-2 m-1">
                                <a style={{ cursor: 'pointer' }} onClick={() => this.becomePTPAClub()} >
                                    <i class="mm-icon fas fa-award" aria-hidden="true" style={{ fontSize: "21px", color: "#CCC" }} ></i><span> {"Join the PTPA.Club and earn 2x the rewards! Click to learn more."}</span>
                                </a>
                            </h4>
                        </div>}

                        <div className="p-2" style={{ backgroundColor: '#f4f4f6', marginBottom: '10px' }}>
                            <h4 className="bg-white p-2 m-1">
                                <form onSubmit={handleSubmit}>
                                    <button className="text-left custom-color-invite-friend" style={{ background: 'none', color: 'inherit', border: 'none', padding: 0, font: 'inherit', cursor: 'pointer', outline: 'inherit' }}>
                                        <i class="fas fa-user-friends" aria-hidden="true" style={{ fontSize: "21px", color: "#CCC" }} ></i><span ref={(section) => { this.InviteFriend = section }}> {"Invite a friend and earn reward points!"}</span>
                                    </button>
                                </form>
                            </h4>
                        </div>
                        <div className="p-2" style={{ backgroundColor: '#f4f4f6', marginBottom: '10px' }}>
                            <h4 className="bg-white p-2 m-1">
                                <a style={{ cursor: 'pointer' }} href="https://www.ptpa.com/Categories" target="_self">
                                    <i class="mm-icon far fa-thumbs-up" aria-hidden="true" style={{ fontSize: "21px", color: "#CCC" }} ></i><span ref={(section) => { this.ProductReview = section }}> {"Leave a product review!"}</span>
                                </a>
                            </h4>
                        </div>
                        {/* <div className="p-2" style={{ backgroundColor: '#f4f4f6' }}>
                            <h4 className="bg-white p-2 m-1">
                                <a style={{ cursor: 'pointer' }} href="https://ptpa.com/contact-ptpa/" target="_blank">
                                    <i class="mm-icon far fa-envelope" aria-hidden="true" style={{ fontSize: "21px", color: "#CCC" }} ></i><span> {"Looking for help?"}</span>
                                </a>
                            </h4>
                        </div> */}

                    </div>
                </div>

                <h1 ref={(section) => { this.Subscribe = section; }} className="border-bottom mt-5">
                    <span>
                        Offers and promotions just for you!
                        <a
                            className="float-right" style={{ cursor: 'pointer' }}
                            onClick={() =>
                                this.setState({ displaySubscribe: !this.state.displaySubscribe })
                            }>
                            <i className={this.state.displaySubscribe ? "mm-icon fas fa-caret-up" : "mm-icon fas fa-caret-down"}
                                style={{ fontSize: '30px' }}>
                            </i>
                        </a>
                        <h5>Sign up for exclusive emails, offers and promotions from these great brands</h5>
                    </span>
                </h1>

                {this.state.displaySubscribe && <div>
                    <div className="row">
                        {this.state.haveSubscribe ? itemsSubscribe : ''}
                        <h3 className={this.state.haveSubscribe ? 'noneDisplay' : 'mt-4 ml-5'}>There are no email promotions at this time</h3>
                    </div>
                </div>}

                <h1 ref={(section) => { this.Evaluations = section; }} className="border-bottom mt-5">
                    <span>
                        My Current Evaluations
                        <a
                            className="float-right" style={{ cursor: 'pointer' }}
                            onClick={() =>
                                this.setState({ displayEvaluation: !this.state.displayEvaluation })
                            }>
                            <i className={this.state.displayEvaluation ? "mm-icon fas fa-caret-up" : "mm-icon fas fa-caret-down"}
                                style={{ fontSize: '30px' }}>
                            </i>
                        </a>
                    </span>
                </h1>

                {this.state.displayEvaluation && <div>
                    <div className="row">
                        {this.state.haveEvaluation ? itemsEvaluation : ''}
                        <h3 className={this.state.haveEvaluation ? 'noneDisplay' : 'mt-4 ml-5'}>There are no evaluations due at the moment</h3>
                    </div>
                </div>}

                <h1 className="border-bottom mt-5">
                    <span>
                        My Testing Opportunities
                        <a
                            className="float-right" style={{ cursor: 'pointer' }}
                            onClick={() =>
                                this.setState({ displayTestingOpportunity: !this.state.displayTestingOpportunity })
                            }>
                            <i className={this.state.displayTestingOpportunity ? "mm-icon fas fa-caret-up" : "mm-icon fas fa-caret-down"}
                                style={{ fontSize: '30px' }}>
                            </i>
                        </a>
                    </span>
                </h1>

                {this.state.displayTestingOpportunity && <div>
                    <div className="row">
                        {this.state.haveTestingOpportunity ? itemsTestingOpportunity : ''}
                        <h3 className={this.state.haveTestingOpportunity ? 'noneDisplay' : 'mt-4 ml-5'}>There are no Testing Opportunities available at the moment</h3>
                    </div>
                </div>}

                <h1 ref={(section) => { this.Prize = section; }} className="border-bottom mt-5">
                    <span>
                        Rewards Available Exclusively For You
                        <a
                            className="float-right" style={{ cursor: 'pointer' }}
                            onClick={() =>
                                this.setState({ displayPrize: !this.state.displayPrize })
                            }>
                            <i className={this.state.displayPrize ? "mm-icon fas fa-caret-up" : "mm-icon fas fa-caret-down"}
                                style={{ fontSize: '30px' }}>
                            </i>
                        </a>
                    </span>
                </h1>

                {this.state.displayPrize && <div>
                    <div className="row">
                        {this.state.havePrize ? itemsPrize : ''}
                        {/* <h3 className={this.state.havePrize ? 'noneDisplay' : 'mt-4 ml-5'}>There are no available rewards at the moment</h3> */}
                    </div>
                </div>}

                {/* <h1 ref={(section) => { this.PTPAClub = section; }} className="border-bottom mt-5">
                    <span>
                        PTPA Club
                        <a
                            className="float-right" style={{ cursor: 'pointer' }}
                            onClick={() =>
                                this.setState({ displayPTPAClub: !this.state.displayPTPAClub })
                            }>
                            <i className={this.state.displayPTPAClub ? "mm-icon fas fa-caret-up" : "mm-icon fas fa-caret-down"}
                                style={{ fontSize: '30px' }}>
                            </i>
                        </a>
                    </span>
                </h1> */}

                {/* {this.state.displayPTPAClub && <div>
                    <div className="row">
                        {this.state.havePTPAClub ? itemsPTPAClub : ''}
                    </div>
                </div>} */}

                {/* <div>
                    <h1 ref={(section) => { this.ProductReview = section }} className="border-bottom mt-5">
                        <span>
                            Product Review
                        <a
                                className="float-right" style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    this.setState({ displayProductReview: !this.state.displayProductReview })
                                }>
                                <i className={this.state.displayProductReview ? "mm-icon fas fa-caret-up" : "mm-icon fas fa-caret-down"}
                                    style={{ fontSize: '30px' }}>
                                </i>
                            </a>
                        </span>
                    </h1>

                    {this.state.displayProductReview && <div className="row justify-content-center reviewDashboard">
                        <div className="col-12 text-center" style={{ top: "130px" }}>
                            <h4 className="col-12">Psst! <br />Have you used any other products that are featured on our site? If you have, leave a review.</h4>
                            <button style={{ marginTop: "60px" }} className="explore-dashboard" onClick={() => { window.open("https://www.ptpa.com/Categories", "_blank") }}>Share my opinion</button>
                        </div>
                    </div>}
                </div> */}

            </div>
        );
    }
}

const formikEnhancer = withFormik({
    mapPropsToValues: props => ({ ...props.model }),
    handleSubmit: (values, { setSubmitting, props }) => {
        props.save();
    },
});

const MyEnhancedForm = formikEnhancer(UserOverview);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = {
    Id: 0, FirstName: '', ProfileImage: '',
};

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: Object.assign(schema, baseSchema),
            popupTrigger: false,
        }

        this.handleReset = this.handleReset.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClearPopupTrigger = this.handleClearPopupTrigger.bind(this);
        this.handleEmailInvitation = this.handleEmailInvitation.bind(this);
    }

    handleReset() {
        this.setState({
            model: Object.assign(schema, baseSchema)
        });
    }

    handleSave() {
        this.setState({ popupTrigger: true })
    }

    handleClearPopupTrigger() {
        this.setState({ popupTrigger: false })
    }

    handleEmailInvitation(emailList, inviteMessage) {

        var data = []

        for (var i = 0; i < emailList.length; i++) {
            data.push({ email: emailList[i], textMessage: inviteMessage })
        }

        saveRangeInviteFriend(data)
            .then(res => {
                if (res.ok)
                    swal({
                        title: "Your invite has been sent!",
                        icon: "success",
                        // buttons: true,
                        dangerMode: true,
                    })
            })
    }

    render() {
        return (
            <div>
                <div className="tab-content">
                    <div className="tab-pane active" id="tab1EditUser">
                        <MyEnhancedForm
                            model={this.state.model}
                            history={this.props.history}
                            save={this.handleSave}
                            reset={this.handleReset} />

                        <InviteFriendModal
                            popupTrigger={this.state.popupTrigger}
                            onClearPopupTrigger={this.handleClearPopupTrigger}
                            handleEmailInvitation={this.handleEmailInvitation}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;