import React, { Component } from 'react';
import { withFormik } from 'formik';
import ResponsiveMenu from 'react-responsive-navbar';

class HeaderForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalStyle: window.innerWidth < 500 ? {width: '90%' } : {width: '30%' },
        };
    }

    render() {
        const {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
        } = this.props;

        return (
            <div className="style-header bg-white">
                <header className="style-site-header">
                    <div>
                        <div className="style-header-component-inner">
                            <div className="style-site-main-nav style-clearfix" style={{ position: 'fixed', top: '0px', zIndex: '999', backgroundColor: '#fff', width: '100%' }}>
                                <div className="small-screen-logo text-center mb-4">
                                    <a href="https://www.ptpa.com/"><span className="mm-text"><img src="/assets/img/Parent-Tested-Parent-Approved.com-Logo.png" /></span></a>
                                </div>
                                <ResponsiveMenu
                                    menuOpenButton={<div className="fa fa-bars" />}
                                    menuCloseButton={<div className="fa fa-bars" />}
                                    changeMenuOn="1225px"
                                    largeMenuClassName="style-main-menu style-mega-menu"
                                    smallMenuClassName="style-mega-menu text-right mr-3 small-menu"
                                    menu={
                                        <ul className="p-3" style={{ fontFamily: 'Poppins', fontSize: '12px' }}>
                                            <li className="p-3"><a href="https://ptpa.com/how-it-works/"><span className="mm-text" style={{ fontFamily: 'Poppins' }}>How it Works</span></a></li>
                                            <li className="p-3"><a href="https://ptpa.com/winners/"><span className="mm-text" style={{ fontFamily: 'Poppins' }}>Recent Winners</span></a></li>
                                            <li className="p-3"><a href="https://ptpa.com/giveaways/"><span className="mm-text" style={{ fontFamily: 'Poppins' }}>OMG Giveaways</span><span className="style-tip" style={{ color: '#ffffff', background: '#dd3333', fontFamily: 'Poppins' }}><span className="style-tip-arrow big-screen-logo" style={{ color: '#dd3333' }}></span>WIN!</span></a></li>
                                            <li className="p-3"><a href="https://product.ptpa.com/categories/"><span className="mm-text" style={{ fontFamily: 'Poppins' }}>Products</span></a></li>
                                            <li className='big-screen-logo'><a href="https://ptpa.com/"><span className="mm-text" style={{ fontFamily: 'Poppins' }}><img src="/assets/img/Parent-Tested-Parent-Approved.com-Logo.png" /></span></a></li>
                                            <li className="p-3"><a href="http://ptpa.com/parent-rewards-program-ptpa-club/"><span className="mm-text" style={{ fontFamily: 'Poppins' }}>Rewards</span><span className="style-tip" style={{ color: '#ffffff', background: '#dd3333', fontFamily: 'Poppins' }}><span className="style-tip-arrow big-screen-logo" style={{ color: '#dd3333' }}></span>PTPA.CLUB</span></a></li>
                                            <li className="p-3"><a target="_blank" href="https://www.ptpamedia.com/services/parent-tested-parent-approved-seal-of-approval/"><span className="mm-text" style={{ fontFamily: 'Poppins' }}><i className="mm-icon fa fa-briefcase"></i>For Brands</span></a></li>
                                            <li className="p-3"><a href="https://dashboard.ptpa.com/Dashboard/CommunityMember/NewMember"><span className="mm-text" style={{ fontFamily: 'Poppins' }}>Join Now</span><span className="style-tip" style={{ color: '#ffffff', background: '#dd3333', fontFamily: 'Poppins' }}><span className="style-tip-arrow big-screen-logo" style={{ color: '#dd3333' }}></span>IT'S FREE!</span></a></li>
                                            {/* <li className="p-3"><a target="_blank" href="https://ptpabreeze.com/dashboard/communitymember/newmember"><span className="mm-text">Join  Now</span><span className="style-tip" style={{ color: '#ffffff', background: '#dd3333' }}><span className="style-tip-arrow big-screen-logo" style={{ color: '#dd3333' }}></span>It's Free!</span></a></li> */}
                                            <li className="p-3"><a href="https://dashboard.ptpa.com/dashboard/overview"><span className="mm-text" style={{ fontFamily: 'Poppins' }}><i className="mm-icon fa fa-lock"></i>My Account</span></a></li>
                                            <li className="p-3"><a data-toggle="modal" data-target="#searchBox" style={{ cursor: 'pointer' }}><span className="mm-text" style={{ fontFamily: 'Poppins' }}><i className="mm-icon fa fa-search"></i>Search</span></a></li>
                                            {/* <li><a href="https://ptpa.com/#"><span className="mm-text"><i className="mm-icon fa fa-search"></i>Search</span></a></li> */}
                                        </ul>
                                    }
                                />

                                <div className="modal fade" id="searchBox" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                                    <div className="modal-dialog" style={this.state.modalStyle}>
                                        <div className="modal-content" style={{ width: '100%' }}>
                                            <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                <div className="wpb_column vc_column_container col-sm-12 m-0 p-0">
                                                    <div className="vc_column-inner ">
                                                        <div className="wpb_wrapper">
                                                            <div className="la-myaccount-page" style={{ margin: '20px 50px !important' }}>
                                                                <div className="la-tabs">
                                                                    <div className="la-tab-panel active" style={{ display: 'block' }}>
                                                                        <form onSubmit={handleSubmit}>
                                                                            <div className="woocomerce-form woocommerce-form-login login">
                                                                                <div className="modal-header pr-0 pl-0">
                                                                                    <h3 className="modal-title bold text-dark" style={{ fontFamily: 'Poppins' }}>Search Product</h3>
                                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                        <span>&times;</span>
                                                                                    </button>
                                                                                </div>
                                                                                <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                                                    <input type="text" className="woocommerce-Input woocommerce-Input--text input-text"
                                                                                        name="TextSearch" id="TextSearch" value={values.TextSearch} onChange={handleChange} onBlur={handleBlur} />
                                                                                </p>
                                                                                <button className="woocommerce-Button button text-center form-actions bold" style={{ cursor: 'pointer', fontFamily: 'Poppins' }} disabled={values.TextSearch == ""}>
                                                                                    SEARCH
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div >
        );
    }
}

const formikEnhancer = withFormik({
    mapPropsToValues: props => ({ ...props.model }),
    handleSubmit: (values, { setSubmitting, props }) => {
        props.save(values).catch((error) => {
            setSubmitting(false);
        });
    },
});

const MyEnhancedForm = formikEnhancer(HeaderForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { TextSearch: '' };

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: Object.assign(schema, baseSchema)
        }

        this.handleReset = this.handleReset.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    handleReset() {
        this.setState({
            model: Object.assign(schema, baseSchema)
        });
    }

    handleSave(values) {
        window.location.href = 'https://ptpa.com/products/search-' + values.TextSearch;
    }

    render() {
        return (
            <MyEnhancedForm model={this.state.model} save={this.handleSave} reset={this.handleReset} />
        );
    }
}

export default Header;