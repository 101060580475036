import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withFormik } from 'formik';
import { login } from '../../../service/CommunityMember';
import { getSystemMessage } from '../../../service/SystemMessage';
import Auth from '../../../util/Auth';
import swal from 'sweetalert'
import * as Yup from 'yup';
import qs from 'qs';

class MyLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			UserName: '',
			Password: ''
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		const {
			values,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			touched,
			errors,
		} = this.props;

		return (
			<div className="row style-login-container">
				<div className="col-sm-6">
					<h1><b>Login to Access Your Account</b></h1>
					<h6>Don’t have a PTPA account yet? <a style={{ cursor: 'pointer', color: 'blue', borderBottom: '1px solid' }}
						onClick={() => {
							this.props.onLoad('/Dashboard/CommunityMember/NewMember');
						}}
					> Sign up</a> and become a member!</h6>
					<div>
						<form onSubmit={handleSubmit}>
							<div className={touched.UserName && errors.UserName ? 'form-group has-error mt-5' : 'form-group mt-5'}>
								<label className="form-label" htmlFor="UserName">Email address *</label>
								<div className="input-width-icon right">
									<input type="UserName" id="UserName" className="form-control" value={values.UserName} onChange={handleChange} onBlur={handleBlur} />
								</div>
								{errors.UserName && touched.UserName && <span className="text-danger">{errors.UserName}</span>}
							</div>

							<div className={touched.Password && errors.Password ? 'form-group has-error' : 'form-group'}>
								<label className="form-label" htmlFor="Password">Password *</label>
								<div className="input-width-icon right">
									<i></i>
									<input type="password" id="Password" className="form-control" value={values.Password} onChange={handleChange} onBlur={handleBlur} />
								</div>
								(Password must contain at least 8 characters and must contain at least one capital letter, one lowercase letter and one numeric character.)
								{errors.Password && touched.Password && <span className="text-danger">{errors.Password}</span>}
							</div>

							<div className="pull-left mr-3">
								<button
									className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}
									disabled={isSubmitting}
								>
									{!isSubmitting ? 'Login' : 'Loading'}</button>
							</div>
							<div className="">
								<a style={{ cursor: 'pointer' }} onClick={() => this.props.onLoad(`/Dashboard/CommunityMember/Forgot-Password`)}>Forgot my password</a>
							</div>
						</form>
					</div>
				</div>
				<div className="style-vc_column_container col-sm-6">
					<img className='img-fluid' src="https://ptpa.com/wp-content/uploads/2018/03/PTPA-Media-happy-mother-and-daughter-laughing-together.jpg" />
				</div>
			</div>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		UserName: Yup.string()
			.required('Email is required!')
			.email('Invalid email address'),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSave(values).then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (res.ok) {
					if (json.token != null) {
						Auth.authenticateUser(json.token);
						props.saveCookie(json.token);
						props.onLoad(json.url);
					} else if (json.firstTime == true) {
						swal({
							title: "Notice",
							text: "Due to recent security upgrades you are required to update your password.",
							// icon: "warning",
							// buttons: true,
							dangerMode: true,
						}).then((result) => {
							if (result) {
								props.onLoad('/Dashboard/CommunityMember/Update-Password-Security');
							}
							setSubmitting(false);
						});
					} else {
						getSystemMessage("loginorpasswordiswrong").then(res => res.json().then(json => ({ res, json })))
							.then(({ res, json }) => {
								swal({
									title: "Notice",
									text: json,
									// icon: "warning",
								})
								setSubmitting(false);
							});
					}
				} else {
					getSystemMessage("error").then(res => res.json().then(json => ({ res, json })))
						.then(({ res, json }) => {
							swal({
								title: "Notice",
								text: json,
								// icon: "warning",
							})
							setSubmitting(false);
						});
				}
				setSubmitting(false);
			})
			.catch((error) => {
			});
	},
});

const MyEnhancedForm = formikEnhancer(MyLogin);

const now = new Date();

const baseSchema = { Created: now, Updated: now };

const schema = { Id: 0, UserName: '', Password: '' };

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: qs.parse(this.props.location.search.slice(1)),
			model: Object.assign(schema, baseSchema),
			isRemoving: this.props.isRemoving
		}

		this.handleSave = this.handleSave.bind(this);
		this.handleLoad = this.handleLoad.bind(this);
		this.handleSetToken = this.handleSetToken.bind(this);
	}

	handleSave(values) {
		return login(values).catch(console.log);
	}

	handleLoad(url) {
		if (typeof this.state.data.redirect != 'undefined')
			window.location.href = url == '/Dashboard/Overview'? this.state.data.redirect : url;
		else
			window.location.href = url;
	}

	handleSetToken(token) {
		this.props.handleSetToken(token);
	}

	render() {
		return (
			<div>
				<MyEnhancedForm model={this.state.model} onSave={this.handleSave} onLoad={this.handleLoad} saveCookie={this.handleSetToken} />
			</div>
		);
	}
}

export default withRouter(Login);