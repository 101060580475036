import React, { Component } from 'react';

import Content from '../Content';

class Container extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="lazyra-page-container row-fluid bg-white" style={{marginTop: '130px'}}>
				<Content/>
			</div>
		);

	}
}

export default Container;