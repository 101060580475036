import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/Level`;

export const getAllLevel = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getLevel = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveLevel = (interest) => {
	let url = endpoint;
	let method = 'POST';
	
	if(interest.Id && interest.Id > 0)
	{
		url += `/${interest.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(interest)
	}, getFetchOpts()));
};

export const deleteLevel = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
