import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getAllManufacturer, deleteManufacturer } from '../../../service/Manufacturer';
import Auth from '../../../util/Auth';

import swal from 'sweetalert'

import ReactTable from "react-table";
import 'react-table/react-table.css'

import matchSorter from 'match-sorter'

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class ManufacturerList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: []
		}
	}

	componentDidMount() {
		getAllManufacturer()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ list: json });
			})
			.catch(console.log);

	}

	// handleClick(event) {
	// 	var id = parseInt(event.currentTarget.id);
	// 	var item = {};
	// 	var index = null;
	// 	for (var i = this.state.list.length - 1; i >= 0; i--) {
	// 		if (this.state.list[i].Id == id) {
	// 			item = this.state.list[i];
	// 			index = i;
	// 			break;
	// 		}
	// 	}
	// 	item.StatusContact = 1;

	// 	swal({
	// 		title: "Please confirm",
	// 		text: "Have you sent e-mail to " + item.Email + "?",
	// 		icon: "warning",
	// 		buttons: true,
	// 		dangerMode: true,
	// 	})
	// 		.then((result) => {
	// 			if (result) {
	// 				return saveLead(item).then(res => {
	// 					if (res.ok) {
	// 						this.state.list[index] = item;
	// 						this.props.history.push('/Lead');
	// 					}
	// 				});
	// 			}
	// 		});

	// 	window.swal({
	// 		title: "Please confirm",
	// 		text: "Are you sure that you want to delete this record? ID: " + id,
	// 		showCancelButton: true,
	// 		cancelButtonText: "Cancel",
	// 		cancelButtonColor: "#ef4836",
	// 		confirmButtonColor: "#E01717",
	// 		showLoaderOnConfirm: true,
	// 		type: "warning"
	// 	}, function (isConfirm) {
	// 		if (isConfirm) {
	// 			deleteManufacturer(id).then(function () {
	// 				window.location.href = "/Client";
	// 			});
	// 		}
	// 	});
	// }

	render() {

		const data = this.state.list;

		const columns = [{
			id: 'id',
			Header: 'ID',
			accessor: 'Id',
			width: 50,
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["id"] }),
			filterAll: true,
		}, {
			id: 'FirstName',
			Header: 'FIRST NAME',
			accessor: 'FirstName',
			width: 90,
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["FirstName"] }),
			filterAll: true,
		}, {
			id: 'LastName',
			Header: 'LAST NAME',
			accessor: 'LastName',
			width: 100,
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["LastName"] }),
			filterAll: true,
		}, {
			id: 'Email',
			Header: 'EMAIL',
			accessor: 'Email',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["Email"] }),
			filterAll: true,
		}, {
			id: 'PhoneNumber',
			Header: 'Phone Number',
			accessor: 'PhoneNumber',
			width: 120,
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["PhoneNumber"] }),
			filterAll: true,
		}, {
			id: 'CompanyName',
			Header: 'CompanyName',
			accessor: 'CompanyName',
			width: 200,
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["CompanyName"] }),
			filterAll: true,
		}, {
			id: 'WebSite',
			Header: 'WebSite',
			accessor: 'WebSite',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["WebSite"] }),
			filterAll: true,
		}, {
			id: 'City',
			Header: 'City',
			accessor: 'City',
			width: 130,
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["City"] }),
			filterAll: true,
		}, {
			id: 'State',
			Header: 'State',
			width: 120,
			accessor: d => d.State ? d.State.StateName : "",
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["State"] }),
			filterAll: true,
		}, {
			id: 'Country',
			Header: 'Country',
			width: 100,
			accessor: d => d.State ? d.State.Country.CountryName : "",
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["Country"] }),
			filterAll: true,
		}, {
			id: 'Action',
			Header: 'ACTIONS',
			Cell: row => (
				<div>
					<Link to={MyConstantsFile.APP_ADMIN_CLIENT_EDIT_CALL_PATH + row.original.Id} title="Edit"><i className="fas fa-pencil-alt"></i></Link>
				</div >
			),
			width: 80,
			filterable: false,
		},]

		return (
			<div>
				<ul className="breadcrumb">
					<li><p>Client</p></li>
					<li><a href="#" className="active">Manage</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<div className="grid simple horizontal purple">
							<div className="grid-title">
								<div className={Auth.getTypeUser() == "Sales" ? 'blockDisplay tools' : 'noneDisplay'}>
									<Link to={MyConstantsFile.APP_ADMIN_CLIENT_ADD_PATH} title="Add"><span className="form-control btn btn-primary btn-cons pull-left">Add Client</span></Link>
								</div>
								<h3>Client</h3>
							</div>
							<div className="grid-body">
								<ReactTable
									data={data}
									columns={columns}
									minRows={1}
									filterable
									defaultFilterMethod={(filter, row) =>
										String(row[filter.id]) === filter.value}
									defaultPageSize={10}
									defaultSorted={[
										{
											id: "id",
											desc: true
										}
									]}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ManufacturerList;
