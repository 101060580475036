import React, { Component } from 'react';
import { withFormik } from 'formik';
import { login } from '../../../service/CommunityMember';
import { getSystemMessage } from '../../../service/SystemMessage';
import Auth from '../../../util/Auth';
import swal from 'sweetalert'
import * as Yup from 'yup';

import { forgotPassword } from '../../../service/CommunityMember';

class MyLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Email: '',
		};
	}

	render() {
		const {
			values,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			touched,
			errors,
		} = this.props;

		return (
			<div className="row style-login-container">
				<div className="col-sm-6">
					<h2>Forgot your password? Don't worry!</h2>
					<h6>We will send to your email the instruction to reset your password.</h6>
					<div>
						<form onSubmit={handleSubmit}>
							<div className={touched.Email && errors.Email ? 'form-group has-error' : 'form-group'}>
								<label className="form-label" htmlFor="Email">Email address *</label>
								<div className="input-width-icon right">
									<input type="Email" id="Email" className="form-control" value={values.Email} onChange={handleChange} onBlur={handleBlur} />
								</div>
								{errors.Email && touched.Email && <span className="text-danger">{errors.Email}</span>}
							</div>

							<div className="pull-left">
								<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Reset password' : 'Resetting'}</button>
							</div>
						</form>
					</div>
				</div>
				<div className="style-vc_column_container col-sm-6">
					<img className='img-fluid' src="https://ptpa.com/wp-content/uploads/2018/03/PTPA-Media-happy-mother-and-daughter-laughing-together.jpg" />
				</div>
			</div>
		);
	}
}


const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		Email: Yup.string()
			.required('Email is required!')
			.email('Invalid email address'),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSave(values).then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (res.ok) {
					getSystemMessage("forgetpasswordsendemail").then(res => res.json().then(json => ({ res, json })))
						.then(({ res, json }) => {
							swal({
								title: "Notice",
								text: json,
								icon: "success",
							})
						});

				} else {
					getSystemMessage("error").then(res => res.json().then(json => ({ res, json })))
						.then(({ res, json }) => {
							swal({
								title: "Notice",
								text: json,
								// icon: "warning",
							})
						});
				}
				setSubmitting(false);
			})
			.catch((error) => {
			});
	},
});

const MyEnhancedForm = formikEnhancer(MyLogin);

const now = new Date();

const baseSchema = { Created: now, Updated: now };

const schema = { Id: 0, Email: '' };

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			model: Object.assign(schema, baseSchema),
		}

		this.handleSave = this.handleSave.bind(this);
	}

	handleSave(values) {
		var data = {};
		data = { Email: values.Email };

		return forgotPassword(data).catch(console.log);
	}

	render() {
		return (
			<div>
				<MyEnhancedForm model={this.state.model} onSave={this.handleSave} />
			</div>
		);
	}
}

export default Login;