import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { getPaymentSummary, getPaymentCapture } from '../../../service/PaymentInformation';
import { getGetByPackageId } from '../../../service/PackageItem';

import { getSystemMessage } from '../../../service/SystemMessage';

import swal from 'sweetalert'
import Stepper from 'react-stepper-horizontal';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class PaymentSummary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: '',
			packageList: [],
		}
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidUpdate(prevProps) {

		if (this.props.match.params.level === prevProps.match.params.level) {
			return false;
		}
	}

	componentDidMount() {
		getPaymentSummary()
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				getGetByPackageId(json.Prospect.PackageId)
					.then(res => res.json().then(json => ({ res, json })))
					.then(({ res, json }) => {
						this.setState({ packageList: json });
					});
				this.setState({ list: json });
			})
			.catch(console.log);
	}

	handleClick() {
		getPaymentCapture()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (json.success) {
					swal({
						title: "Payment Confirmed!",
						text: "Your payment was successful.",
						icon: "success",
						// buttons: true,
						dangerMode: true,
					}).then((result) => {
						if (result) {
							// window.location.href = MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_ADD_PATH;
							this.props.history.push({
								pathname: MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_ADD_PATH,
								state: { levelAccess: "Manufacturer" }
							})
						}
					});

					
					// window.swal({
					// 	title: "Payment Confirmed!",
					// 	text: "Payment made successfully",
					// 	showCancelButton: false,
					// 	cancelButtonText: "Cancel",
					// 	cancelButtonColor: "#ef4836",
					// 	confirmButtonColor: "#E01717",
					// 	showLoaderOnConfirm: true,
					// 	type: "success"
					// }, function (isConfirm) {
					// 	if (isConfirm) {
					// 		window.location.href = '/Product/add';
					// 	}
					// });
				} else {
					getSystemMessage("error").then(res => res.json().then(json => ({ res, json })))
						.then(({ res, json }) => {
							window.swal('Notice', json.msg, 'warning');
						});
				}
			});
	}

	render() {
		const packageListValues = this.state.packageList.map((item) => (
			<div dangerouslySetInnerHTML={{ __html: item.Text }}></div>
		));

		const steps = [{ title: 'Complete Profile' }, { title: 'Payment Information' }, { title: 'Review and Submit Payment' }];

		return (
			<div>
				<div className="row">
					<div className="col-12">
						<div className="grid simple horizontal purple">

							<div className="grid-title text-right">
								<div className="bg-white">
									<Stepper steps={steps} activeStep={2} />
								</div>
								{/* <h3>Payment Summary</h3> */}
							</div>

							<div className="grid-body pt-5 pl-5">
								<div className="text-right mb-5">
									<div className="row">
										<div className="text-left col-9">
											<img src={'/assets/img/PTPAMediaLogomark.png'} className="img-fluid ptpaMediaLogomark" alt="PTPA logo" />
										</div>
										<h6 className="col p-2 m-0 text-left my-auto order-total-box">
											Order Total:
										</h6>
										<h6 className={this.state.list == '' ? 'noneDisplay' : 'col p-2 m-0 mr-3 my-auto order-total-box'}>
											{this.state.list != '' ?
												(this.state.list.Manufacturer.State.Country.Id == 1 ? 'CAD ' : '$ ') : ''}
											{this.state.list != '' ? this.state.list.Manufacturer.State.Country.Id == 1 ?
												(((this.state.list.Prospect.StartAmount - this.state.list.Prospect.Coupon.Amount)
													* (100 + this.state.list.Tax.Percentage) / 100) * this.state.list.Prospect.QuantityProduct)
													.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') :
												((this.state.list.Prospect.StartAmount - this.state.list.Prospect.Coupon.Amount)
													* this.state.list.Prospect.QuantityProduct)
													.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
												: ''}
										</h6>
									</div>
								</div>

								<p className="semi-bold border-light-color pb-3">
									PTPA Seal of Approval
								</p>

								<ul className="semi-bold border-light-color pb-3">
									{packageListValues}
								</ul>

								<div>
									<div className="row">
										<div className="col-8">
										</div>
										<h6 className="col p-2 m-0 text-left bold border-light-color">
											Subtotal:
										</h6>
										<div className="col-1 border-light-color">
										</div>
										<h6 className="col p-2 m-0 mr-3 border-light-color">
											{this.state.list != '' ? parseFloat(this.state.list.Prospect.StartAmount * this.state.list.Prospect.QuantityProduct)
												.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}
										</h6>
									</div>
									<div className="row">
										<div className="col-8">
										</div>
										<h6 className="col p-2 m-0 text-left bold border-light-color">
											Discount:
										</h6>
										<div className="col-1 border-light-color">
										</div>
										<h6 className="col p-2 m-0 mr-3 border-light-color">
											{this.state.list != '' ? parseFloat(this.state.list.Prospect.Coupon.Amount * this.state.list.Prospect.QuantityProduct)
												.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}
										</h6>
									</div>
									<div className={this.state.list != '' ? this.state.list.Manufacturer.State.Country.Id == 1 ? 'row' : 'noneDisplay' : ''}>
										<div className="col-8">
										</div>
										<h6 className="col p-2 m-0 text-left bold border-light-color">
											Total before tax:
										</h6>
										<div className="col-1 border-light-color">
										</div>
										<h6 className="col p-2 m-0 mr-3 border-light-color">
											{this.state.list != '' ?
												parseFloat((this.state.list.Prospect.StartAmount - this.state.list.Prospect.Coupon.Amount)
													* this.state.list.Prospect.QuantityProduct)
													.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}
										</h6>
									</div>
									<div className="row mt-5">
										<div className="col-8">
										</div>
										<h6 className="col p-2 m-0 text-left bold border-light-color">
											{this.state.list != '' ? this.state.list.Manufacturer.State.Country.Id == 1 ?
												('Estimated ' + this.state.list.Tax.TaxName + ':')
												: ''
												: ''
											}

										</h6>
										<div className="col-1 border-light-color">
										</div>
										<h6 className="col p-2 m-0 mr-3 border-light-color">
											{this.state.list != '' ? this.state.list.Manufacturer.State.Country.Id == 1 ?
												(this.state.list.Tax.Percentage + '%') : '' : ''}
										</h6>
									</div>

									<div className="row mt-3">
										<div className="col-8">
										</div>
										<h6 className="col p-2 m-0 text-right order-total-box">
											Order Total:
										</h6>
										<div className="col-1">
										</div>
										<h6 className="col p-2 m-0 mr-3 bold">
											{this.state.list != '' ?
												(this.state.list.Manufacturer.State.Country.Id == 1 ? 'CAD ' : '$ ') : ''}
											{this.state.list != '' ? this.state.list.Manufacturer.State.Country.Id == 1 ?
												(((this.state.list.Prospect.StartAmount - this.state.list.Prospect.Coupon.Amount)
													* (100 + this.state.list.Tax.Percentage) / 100) * this.state.list.Prospect.QuantityProduct)
													.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') :
												((this.state.list.Prospect.StartAmount - this.state.list.Prospect.Coupon.Amount)
													* this.state.list.Prospect.QuantityProduct)
													.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
												: ''}
										</h6>
									</div>
								</div>

								<div className="">
									<div className="pull-right mt-5">
										{/* <button type="button" onClick={reset} className="btn btn-danger btn-cons">Reset</button> */}
										<button className="btn order-total-box btn-cons" onClick={this.handleClick}>Process My Order</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		);
	}
}

export default PaymentSummary;