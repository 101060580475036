import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/PaymentInformation`;

export const getAllPaymentInformation = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getPaymentInformation = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const savePaymentInformation = (paymentInformation) => {
	let url = endpoint;
	let method = 'POST';
	
	if(paymentInformation.Id && paymentInformation.Id > 0)
	{
		url += `/${paymentInformation.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(paymentInformation)
	}, getFetchOpts()));
};

export const deletePaymentInformation = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};

export const getPaymentSummary = () => {
	let url = `${endpoint}/PaymentSumarry`;

	return fetch(url, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};


export const getPaymentSummaryCapture = () => {
	let url = `${endpoint}/PaymentSumarryCapture`;

	return fetch(url, Object.assign({
		method: 'POST',
		//body: JSON.stringify()
	}, getFetchOpts()));
};


export const getPaymentCapture = () => {
	let url = `${endpoint}/PaymentCapture`;

	return fetch(url, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};
