import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import CurrencyInput from 'react-currency-input';

import { getPrize, savePrize } from '../../../service/Prize';

import UploadSingleImagesHelper from '../../../util/UploadSingleImagesHelper';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class MyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imageSelected: '',
		};
		this.handleCategoryImageUpdate = this.handleCategoryImageUpdate.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

	componentDidMount() {
	}

	handleCategoryImageUpdate(imageReceived) {
		this.props.values.imageSelected = imageReceived;
		this.setState({ imageSelected: imageReceived })
	}

	render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
		} = this.props;

		const now = new Date();
		const time = moment(now).format('YYYYMMDDHHmmss');
		const created = values.Created !== '' ? moment(values.Created).format('MM/DD/YYYY HH:mm') : '';
		const updated = values.Updated !== '' ? moment(values.Updated).format('MM/DD/YYYY HH:mm') : '';

		const image = this.state.imageSelected !== '' ? this.state.imageSelected : (values.PrizeImage !== null && values.PrizeImage !== '' ? `${STORAGE_URL}prize/${values.PrizeImage}?${time}` : '');

		return (
			<form onSubmit={handleSubmit}>

				<div className={touched.Title && errors.Title ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Title">Reward Name*</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="Title" className="form-control" value={values.Title} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Title && touched.Title && <span className="text-danger">{errors.Title}</span>}
				</div>

				<div className={touched.Description && errors.Description ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Description">Description*</label>
					<div className="input-width-icon right">
						<i></i>
						<textarea type="text" id="Description" className="form-control" rows="3" value={values.Description} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Description && touched.Description && <span className="text-danger">{errors.Description}</span>}
				</div>

				<div className={touched.Link && errors.Link ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Link">Link for downloadable reward</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="Link" className="form-control" value={values.Link} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Link && touched.Link && <span className="text-danger">{errors.Link}</span>}
				</div>

				<div className={touched.PriceRetail && errors.PriceRetail ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="PriceRetail">Retail Price of the product*</label>
					<div className="input-width-icon right">
						<i></i>
						<CurrencyInput decimalSeparator="." thousandSeparator="," precision="2"
							id="PriceRetail" className="form-control"
							value={parseFloat(values.PriceRetail ? values.PriceRetail.toString().replace(/,/g, "") : 0)} onChangeEvent={handleChange} />
					</div>
					{errors.PriceRetail && touched.PriceRetail && <span className="text-danger">{errors.PriceRetail}</span>}
				</div>

				<div className={touched.HowManyAvailable && errors.HowManyAvailable ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="HowManyAvailable">Quantity available*</label>
					<div className="input-width-icon right">
						<i></i>
						<NumberFormat thousandSeparator={true} id="HowManyAvailable" className="form-control" value={values.HowManyAvailable} allowNegative={false} isNumericString={true}
							onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.HowManyAvailable && touched.HowManyAvailable && <span className="text-danger">{errors.HowManyAvailable}</span>}
				</div>

				<UploadSingleImagesHelper
					labelName={'Reward Image'}
					onHandleImageUpdate={this.handleCategoryImageUpdate}
					imageSize={'fullSize'}
				/>
				<img src={image} style={{ width: '200px' }} /><br />

				<div className="form-group noneDisplay">
					<label className="form-label">Created Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={created} />
						<span className="input-group-addon"></span>
					</div>
				</div>
				<div className="form-group noneDisplay">
					<label className="form-label">Updated Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={updated} />
						<span className="input-group-addon"></span>
					</div>
				</div>

				<div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>
			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		Title: Yup.string()
			.required('Reward name is required!'),
		Description: Yup.string()
			.required('Description is required!'),
		PriceRetail: Yup.string()
			.required('Price required!'),
		HowManyAvailable: Yup.number()
			.required('Quantity available is required!')
			.min(1)
			.nullable(true),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.save(values).catch((error) => {
			setSubmitting(false);
		});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, Title: '', Description: '', ManufacturerImage: '', Link: '', PriceRetail: '', HowManyAvailable: null, };

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema),
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id)
			getPrize(id)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ model: json });
				});
	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {
		var data = values;
		data.HowManyAvailable = values.HowManyAvailable.toString().replace(/,/g, '')
		data.PriceRetail = values.PriceRetail
		var imageSelected = values.imageSelected;

		if (imageSelected && imageSelected !== '')
			data = Object.assign(data,
				{ Image: imageSelected.replace('data:image/jpeg;base64,', '') });

		return savePrize(data).then(res => {
			if (res.ok) {
				this.props.history.push(MyConstantsFile.APP_ADMIN_REWARD_PATH);
			}
		});
	}

	render() {
		return (
			<div>
				<ul className="breadcrumb">
					<li><p>{MyConstantsFile.PAGE_NAME_REWARD}</p></li>
					<li><Link to={MyConstantsFile.APP_ADMIN_REWARD_PATH}>Manage</Link></li>
					<li><a href="#" className="active">{(window.location.href.indexOf("edit") > -1 ? "Edit" : "Add")}</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" role="tabList">
							<li className="active"><a href="#tab1EditUser" role="tab" data-toggle="tab">{(window.location.href.indexOf("edit") > -1 ? "Edit Reward" : "Add Reward")}</a></li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="tab1EditUser">
								<MyEnhancedForm
									model={this.state.model}
									save={this.handleSave}
									reset={this.handleReset} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Form;
