import React, { Component } from 'react';

import qs from 'qs';

import { confirmEmail } from '../../../service/CommunityMember';

class ConfirmEmail extends Component {
	constructor(props) {
		super(props);

		this.state = {
			token: this.props.location.search,
		}
	}

	componentDidMount() {
		const data = qs.parse(this.props.location.search.slice(1));

		var temp = {}
		temp.UserId = parseInt(data.userid)
		temp.Token = this.state.token.substr(this.state.token.indexOf('token=') + 6)

		confirmEmail(temp);
	}

	render() {
		return (
			<div className="style-login-container text-center">
				<h2 className="mb-3">Your email has been confirmed.</h2>
				<h3 className="mb-3">Click <a className="text-primary" href="/Dashboard/CommunityMember/Login">HERE</a> to login</h3>
			</div>
		);
	}
}

export default ConfirmEmail;