import React, { Component } from 'react';
import Auth from '../../../util/Auth';

class CouponSideBar extends Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
	}



	handleClick() {
		Auth.deauthenticateCoupon();
		window.location.href = "/client/login";
	}



	render() {

		return (
			<div className="page-sidebar " id="main-menu">
				<div className="page-sidebar-wrapper scrollbar-dynamic" id="main-menu-wrapper">
					<ul>
						<li><a href="#"> <i className="fa fa-users"></i> <span className="title">My Profile</span> </a></li>
						<li><a onClick={this.handleClick} style={{cursor: 'pointer'}}> <i className="fa fa-power-off"></i> <span className="title">Log out</span>  </a></li>
					</ul>
					<div className="clearfix"></div>
				</div>
			</div>
		);
	}
}

export default CouponSideBar;
