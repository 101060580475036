import React from 'react';
import { Link } from 'react-router-dom';

const FormTemplate = (props) => {
    return (
        <div>
            <ul className="breadcrumb">
                <li><span>{props.pageName}</span></li>
                <li><Link to={props.pageListDisplayPath}>Manage</Link></li>
                <li><span className="active">{props.action}</span> </li>
            </ul>
            <div className="row">
                <div className="col-12">
                    <ul className="nav nav-tabs" role="tabList">
                        <li className="active"><a href="#tab1EditUser" role="tab" data-toggle="tab">{props.action + " " + props.pageName}</a></li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane active" id="tab1EditUser">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormTemplate;