import React, { Component } from 'react';

import Content from '../Content';

class Container extends Component {
	constructor(props) {
		super(props);

		this.handleSetCookies = this.handleSetCookies.bind(this);
	}

	handleSetCookies(token) {
		this.props.handleSetCookies(token);
	}

	render() {
		return (
			<div className="lazyra-page-container row-fluid">
				<Content handleSetCookies={this.handleSetCookies}/>
			</div>
		);

	}
}

export default Container;