import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import * as MyConstantsFile from '../../../util/MyConstantsFile'

import Product from '../Product/index'
import ProductCategory from '../ProductCategory/index'

class Content extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="site-inner">
				<div className="lazyra-page-content bg-white">
					<div id="portlet-config" className="modal hide">
						<div className="modal-header">
							<button data-dismiss="modal" className="close" type="button"></button>
							<h3>Widget Settings</h3>
						</div>
						<div className="modal-body"> Widget settings form goes here </div>
					</div>
					<div className="clearfix"></div>
				</div>
				<div>
					<Route exact path={MyConstantsFile.APP_WEBSITE_PRODUCT_PATH} component={Product} />
					<Route exact path={MyConstantsFile.APP_WEBSITE_CATEGORIES_PATH} component={ProductCategory} />
				</div>
			</div>
		);
	}
}

export default Content;
