import React, { Component } from 'react';

import moment from 'moment';

import { getProductByUrlFriendly, getProductRelatedList } from '../../../service/Product';
import { getAllCategory } from '../../../service/Category';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class ProductCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
        }
    }

    componentDidMount() {
        getAllCategory()
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                this.setState({ list: json });
            })
            .catch(console.log);
    }


    render() {
        const now = new Date();
        const time = moment(now).format('YYYYMMDDHHmmss');

        const categoryList = this.state.list.map((item) => (
            <div className="wpb_column vc_column_container col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                        <div className="wpb_content_element la-banner-box banner-type-7 margin-bottom-15 font-weight-700 text-color-secondary">
                            <div className="box-inner">
                                <div className="banner--image">
                                    <div className="la-lazyload-image pb-0">
                                        <img className="img-fluid" alt={item.CategoryName} src={`${STORAGE_URL}category/${item.CategoryImage}?${time}`} style={{ maxWidth: '100%' }} />
                                    </div>
                                </div>
                                <div className="banner--info">
                                    <div className="js-el b-title b-title1"
                                        style={{ color: '#ffffff' }}> </div>
                                    <div className="titleCategories js-el b-title b-title2 letter-spacing--2"
                                        style={{ color: '#ffffff', minHeight: '80px' }}>
                                        <h1 className="bold" style={{ color: '#ffffff', fontFamily: 'Poppins' }}>{item.CategoryName}</h1>
                                    </div>
                                    <a className="banner--btn" style={{ background: 'transparent', border: '2px solid #fff', color: '#ffffff', fontFamily: 'Poppins' }}
                                        href={"https://ptpa.com" + (MyConstantsFile.APP_WEBSITE_PRODUCT_CATEGORY_CALL_PATH).toLowerCase() + item.UrlFriendly}
                                        title="Explore">Explore</a>
                                </div>
                                <a className="banner--link-overlay item--overlay"
                                    href={"https://ptpa.com" + (MyConstantsFile.APP_WEBSITE_PRODUCT_CATEGORY_CALL_PATH).toLowerCase() + item.UrlFriendly}
                                    title="Explore">
                                    <span className="hidden"><span>Explore</span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))

        return (
            <div className="site-main" style={{ paddingTop: '0px' }}>
                <div className="container"
                    style={{ width: '100%' }}
                >
                    <div className="row m-0 p-0">
                        <main id="site-content" className="col-md-12 col-xs-12 site-content m-0 p-0">
                            <div className="site-content-inner">
                                <div className="lazyra-page-content">
                                    <div className="not-active-fullpage">
                                        <div data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid la_row_gap_80 vc_row-no-padding la_fp_slide la_fp_child_section vc-has-modified"
                                            style={{ position: 'relative', left: '0px', boxSizing: 'border-box' }}
                                        >
                                            <div className="wpb_column vc_column_container col-sm-12 m-0 p-0">
                                                <div className="vc_column-inner ">
                                                    <div className="wpb_wrapper">
                                                        <div className="vc_row wpb_row vc_inner vc_row-fluid la_row_gap_80 vc_column-gap-15">

                                                            {categoryList}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><div className="vc_row-full-width vc_clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductCategory;