import React, { Component } from 'react';
import Header from '../Header';
import Container from '../Container';
import Auth from '../../../util/Auth';
import swal from 'sweetalert'

class App extends Component {
	constructor(props) {
		super(props);

		const imageURL = '/assets/img/SadFaceResize.png';
		// if (window.location.href.toLocaleLowerCase().indexOf("completeinformation") == -1) {
		// 	if (!this.props.handleGetToken()) {
		// 		swal({
		// 			title: "Sorry, this is a member only page",
		// 			text: "Please login to access this page",
		// 			icon: imageURL,
		// 			// buttons: true,
		// 			dangerMode: true,
		// 			closeOnClickOutside: false,
		// 		}).then((result) => {
		// 			if (result) {
		// 				if (document.referrer.indexOf("client") > -1)
		// 					window.location.href = '/client/login';
		// 				else
		// 					window.location.href = '/login';
		// 			}
		// 		});
		// 	}
			if (!Auth.getToken() && this.props.handleGetToken())
				Auth.authenticateUser(this.props.handleGetToken());
		// }
	}

	render() {
		return (
			<React.Fragment>
				<Header />
				<Container />
			</React.Fragment>
		);
	}
}

export default App;