import React, { Component } from 'react';
import swal from 'sweetalert'
import { Tabs, TabList, Tab, PanelList, Panel } from 'react-tabtab';
import * as customStyle from 'react-tabtab/lib/themes/material-design';

import FilterMenuHeader from './FilterMenuHeader';
import TabSearchHelper from './TabSearchHelper';
import SearchCommunityMemberListPaginate from '../SearchCommunityMember/ListPaginate';
import SearchCommunityMemberListInvited from '../SearchCommunityMember/ListInvited';
import { getCommunityMemberPerProduct } from '../../../service/CommunityMember';
import { getSystemMessage } from '../../../service/SystemMessage';

class FilterHelper extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tabs: [],
			activeIndex: 0,
			tabTemplate: [],
			panelTemplate: [],
		}

		this.handleTabChange = this.handleTabChange.bind(this);
		this.handleAddCommunityMemberTab = this.handleAddCommunityMemberTab.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleCurrentCommunityMember = this.handleCurrentCommunityMember.bind(this);
		this.handleHideFilterChild = this.handleHideFilterChild.bind(this);
		this.handleAutoMagic = this.handleAutoMagic.bind(this);
		this.createSearchTab = this.createSearchTab.bind(this);
		this.replaceTab = this.replaceTab.bind(this);
		this.clearTab = this.clearTab.bind(this);
	}

	componentDidMount() {
		this.createSearchTab();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.tabTemplate.length === 0)
			this.createSearchTab();

	}

	createSearchTab() {
		const tabSearch = <TabSearchHelper
			onHandleSearch={this.handleSearch}
		/>;

		this.setState(prevState => ({
			tabTemplate: [...prevState.tabTemplate, <Tab key={0} closable={false}>Search</Tab>],
			panelTemplate: [...prevState.panelTemplate, <Panel key={0}>{tabSearch}</Panel>]
		}))
	}

	handleAddCommunityMemberTab(filterInfo, source) {
		const newTabs =
			<SearchCommunityMemberListPaginate
				refreshData={this.props.refreshData}
				history={this.props.history}
				filterInfo={filterInfo}
				productId={this.props.filterData.Id}
				activeIndex={1}
				onHandleEdit={this.handleEdit}
			/>;

		this.replaceTab(1, source, newTabs);
	}

	replaceTab(id, tabTitle, tabContent) {
		this.setState(prevState => ({
			tabTemplate: [...prevState.tabTemplate.slice(0, 1), <Tab key={id} closable={true}>{tabTitle}</Tab>],
			panelTemplate: [...prevState.panelTemplate.slice(0, 1), <Panel key={id}>{tabContent}</Panel>],
			activeIndex: id,
		}))
	}

	clearTab() {
		this.setState(prevState => ({
			tabTemplate: [...prevState.tabTemplate.slice(0, 1)],
			panelTemplate: [...prevState.panelTemplate.slice(0, 1)],
			activeIndex: 0,
		}))
	}

	handleTabChange(index) {
		this.setState({ activeIndex: index });
	}

	handleEdit({ type, index }) {
		if (type === 'delete')
			this.clearTab();
	}

	handleCurrentCommunityMember() {
		var productId = [];
		productId.push(this.props.filterData.Id);

		var tempProductList = {};
		tempProductList.ProductIds = productId;

		getCommunityMemberPerProduct(tempProductList)
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (res.ok) {
					if (json.length > 0) {
						const newTabs = <SearchCommunityMemberListInvited
							invitedList={json}
							productId={this.props.filterData.Id}
							productName={this.props.filterData.ProductName}
							activeIndex={1}
							onHandleEdit={this.handleEdit}
						/>;

						this.replaceTab(1, 'Invited ' + json.length, newTabs);
					} else {
						getSystemMessage("notestersassigned").then(res => res.json().then(json => ({ res, json })))
							.then(({ res, json }) => {
								swal({
									text: json,
								})
							});
					}
				}
			})
			.catch(console.log);
	}

	handleAutoMagic() {
		var autoMagic = {};
		var children = {};

		autoMagic.CommunityMemberChildren = [];

		var genderTargetIdAutoMagic = this.props.filterData.GenderTargetId;

		if (this.props.filterData.GenderTargetId === 3)
			genderTargetIdAutoMagic = null;

		if (!this.props.filterData.TargetAgeGroupParentChildrenId) {
			children = {
				AgeTargetStarts: this.props.filterData.TargetAgeGroupFromId,
				AgeTargetEnds: this.props.filterData.TargetAgeGroupToId,
				GenderTargetId: genderTargetIdAutoMagic,
			}
			autoMagic.CommunityMemberChildren.push(children);
		} else {
			autoMagic.CommunityMemberGenderTargetId = genderTargetIdAutoMagic;
			autoMagic.AgeTargetStarts = this.props.filterData.TargetAgeGroupFromId;
			autoMagic.AgeTargetEnds = this.props.filterData.TargetAgeGroupToId;
		}

		autoMagic.ProductId = this.props.filterData.Id;
		autoMagic.PickUp = this.props.filterData.PickUp;

		this.handleAddCommunityMemberTab(autoMagic, 'AutoMagic')
	}

	handleHideFilterChild = () => {
		this.props.onHideFilter();
	}

	handleChangePickUp = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.PickUp = selectedOption.value;
			this.setState({
				PickUp: selectedOption.value,
			});
		} else {
			this.props.values.PickUp = null;
			this.setState({
				PickUp: null,
			});
		}
	}

	handleSearch = (childrenProps) => {

		var temp = childrenProps;
		temp.CommunityMemberChildren = [];

		temp.ProductId = this.props.filterData.Id;

		var children = {
			AgeTargetStarts: childrenProps.AgeTargetChildrenStartsId,
			AgeTargetEnds: childrenProps.AgeTargetChildrenEndsId,
			GenderTargetId: childrenProps.ChildrenGenderTargetId,
		}
		temp.CommunityMemberChildren.push(children);

		this.handleAddCommunityMemberTab(temp, 'Result')
	}


	render() {
		const productImage = this.props.filterData.LargeImage

		return (
			<div className="row align-items-center">
				<div className="col-12">
					<div className="grid simple horizontal">
						{this.state.CommunityMember !== '' && <div className="blockDisplay grid-title.no-border-top">
							<div className="product-template-fullscreen border container m-0 mt-3 mb-3">
								<FilterMenuHeader
									productImage={productImage}
									productName={this.props.filterData.ProductName}
									targetAgeFrom={this.props.filterData.TargetAgeGroupFrom}
									targetAgeTo={this.props.filterData.TargetAgeGroupTo}
									genderTargetName={this.props.filterData.GenderTargetName}
									onHandleAutoMagic={this.handleAutoMagic}
									onHandleCurrentCommunityMember={this.handleCurrentCommunityMember}
									onHandleHideFilterChild={this.handleHideFilterChild}
								/>
							</div>

							<div className="row">
								<div className="col-12 product-template border-0 mb-3 container">
									<Tabs
										customStyle={customStyle}
										activeIndex={this.state.activeIndex}
										onTabEdit={this.handleEdit}
										onTabChange={this.handleTabChange}
									>
										<TabList>
											{this.state.tabTemplate}
										</TabList>
										<PanelList>
											{this.state.panelTemplate}
										</PanelList>
									</Tabs>
								</div>
							</div>
						</div>}
					</div>
				</div>
			</div >
		);
	}
}

export default FilterHelper;