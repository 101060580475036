import React, { Component } from 'react';
import Popup from "reactjs-popup";
import MultipleEmails from '../../../util/MultipleEmails';

class InviteFriendModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			emailList: [],
			inviteMessage: "",
		}
		this.getEmailList = this.getEmailList.bind(this)
		this.handleChange = this.handleChange.bind(this)
	}

	getEmailList(emailList) {
		this.setState({ emailList })
	}

	handleChange(event) {
		this.setState({ inviteMessage: event.target.value })
	}

	render() {
		return (
			<div className="align-items-center">
				<div className="col-12">
					<div className="grid simple horizontal">
						<div>
							<Popup
								open={this.props.popupTrigger == true}
								onClose={this.props.onClearPopupTrigger}
								closeOnDocumentClick={false}
								closeOnEscape={false}
								modal>
								{close => (
									<div>
										<div className="style-test style-modal">
											<a className="close" onClick={close}>
												&times;
        									</a>
											<div className="container">
												<div className="style-modal-header">Share the love and Invite a friend</div>
												<p>Invite as many friends as you would like to become a PTPA member and you will earn 250 rewards for each or your friends who become members.</p>

												<MultipleEmails setEmailList={this.getEmailList} />

												<h6 className="mt-3">Send your friend(s) a custom message and let them know why they should sign up.</h6>
												<textarea type="text" id="InviteMessage" className="form-control" rows="3"
													value={this.state.inviteMessage} onChange={this.handleChange} />
											</div>
											<div>
											</div>
										</div>

										<div className="actions mt-2">
											<button
												className="button float-right m-2"
												onClick={() => {
													this.props.handleEmailInvitation(this.state.emailList, this.state.inviteMessage)
													this.setState({emailList: [], inviteMessage: ""}, () => close())
												}}
											>
												Send invitation
											</button>

											<button
												className="button float-right m-2"
												onClick={close}
											>
												Close
											</button>
										</div>
									</div>
								)}
							</Popup>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default InviteFriendModal;