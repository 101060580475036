import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/CommunityMember`;

export const getAllCommunityMember = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getAllCommunityMemberPaged = (tableModel) => {
	let url = `${endpoint}/GetAllPaged/`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};

export const getCommunityMember = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveCommunityMember = (communityMember) => {
	let url = endpoint;
	let method = 'POST';
	
	if(communityMember.Id && communityMember.Id > 0)
	{
		url += `/${communityMember.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMember)
	}, getFetchOpts()));
};

export const deleteCommunityMember = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};

export const filterCommunityMember = (communityMember) => {
	let url = `${endpoint}/Filter/`;
	let method = 'POST';
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMember)
	}, getFetchOpts()));
};

export const filterPaginationCommunityMember = (communityMember) => {
	let url = `${endpoint}/FilterPaginate/`;
	let method = 'POST';
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMember)
	}, getFetchOpts()));
};

export const login = (model) => {
	let url = `${endpoint}/Login`;
	let method = 'POST';
	
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(model)
	}, getFetchOpts()));
};

export const getCommunityMemberToken = () => {
	let url = `${endpoint}/GetToken`;

	return fetch(url, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getPointByToken = () => {
	let url = `${endpoint}/GetPointByToken`;

	return fetch(url, Object.assign({
		method: 'GET',
	}, getFetchOpts()));
};

export const getCommunityMemberPerProduct = (communityMember) => {
	let url = `${endpoint}/CommunityMemberPerProduct`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMember)
	}, getFetchOpts()));
};

export const getCommunityMemberTestOpportunity = (tableModel) => {
	let url = `${endpoint}/CommunityMemberTestOpportunity`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};

export const forgotPassword = (communityMember) => {
	let url = `${endpoint}/ForgotPassword`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMember)
	}, getFetchOpts()));
};

export const resetPassword = (communityMember) => {
	let url = `${endpoint}/ResetPassword`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMember)
	}, getFetchOpts()));
};

export const confirmEmail = (communityMember) => {
	let url = `${endpoint}/ConfirmEmail`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMember)
	}, getFetchOpts()));
};
