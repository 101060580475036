import React, { Component } from 'react';
import SalesSideBar from '../Sidebar/SalesSideBar';
import AdministratorSideBar from '../Sidebar/AdministratorSideBar';
import ManufacturerSideBar from '../Sidebar/ManufacturerSideBar';
import CouponSideBar from '../Sidebar/CouponSideBar';
import Content from '../Content';
import { getAdministratorToken } from '../../../service/Administrator';
import { getManufacturerToken } from '../../../service/Manufacturer';
import Auth from '../../../util/Auth';

class Container extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuStatus: '',
		};
	}

	componentDidMount() {
		if (Auth.getTypeUser() == "manufacturer" && Auth.isUserAuthenticated()) {
			getManufacturerToken()
				.then(res => res.json()
					.then(json => ({ res, json })))
				.then(({ res, json }) => {
					if (json.Status) {
						this.setState({
							menuStatus: 'Manufacturer'
						})
					}
				})
				.catch(console.log);

		} else if (Auth.getTypeUser() == "prospect") {
			this.setState({
				menuStatus: 'Prospect'
			})
		} else if (Auth.isUserAuthenticated() && this.state.menuStatus == '') {
			getAdministratorToken()
				.then(res => res.json()
					.then(json => ({ res, json })))
				.then(({ res, json }) => {
					if (json.Status) {
						if (json.Level.Name == "Administrator") {
							this.setState({
								menuStatus: 'Administrator'
							})
						} else if (json.Level.Name == "Sales") {
							this.setState({
								menuStatus: 'Sales'
							})
						}
					}
				})
				.catch(console.log);
		}
	}

	render() {

		return (
			<div className="page-container row-fluid">
				<span className={this.state.menuStatus == "Administrator" ? 'blockDisplay' : 'noneDisplay'}>
					<AdministratorSideBar
						levelOfAccess={this.state.menuStatus} />
				</span>

				<span className={this.state.menuStatus == "Sales" ? 'blockDisplay' : 'noneDisplay'}>
					<SalesSideBar
						levelOfAccess={this.state.menuStatus} />
				</span>

				<span className={this.state.menuStatus == "Manufacturer" ? 'blockDisplay' : 'noneDisplay'}>
					<ManufacturerSideBar
						levelOfAccess={this.state.menuStatus} />
				</span>

				<span className={this.state.menuStatus == "Prospect" ? 'blockDisplay' : 'noneDisplay'}>
					<CouponSideBar
						levelOfAccess={this.state.menuStatus} />
				</span>
				<a href="#" className="scrollup">Scroll</a>

				{/* <Footer /> */}
				<Content />

			</div>
		);

	}
}

export default Container;