import React, { Component } from 'react';
import moment from 'moment';
import swal from 'sweetalert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class TableEditable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value? props.value : '',
            oldValue: props.value? props.value : '',
            style: props.style,
            index: props.index,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleBlurDate = this.handleBlurDate.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.type ==="number"? parseInt(event.target.value) : event.target.value });
    }

    handleBlur() {
        if (this.state.value !== this.state.oldValue)
            swal({
                title: "Please confirm",
                text: "Are you sure that you want to change " + this.props.changingLabelName + "?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((result) => {
                    if (result) {
                        this.setState({ oldValue: this.state.value },() => this.props.onHandlerSave(this.state.value, this.state.index));                        
                    } else
                        this.setState({ value: this.state.oldValue });
                });
    }
    
    handleChangeDate(date) {
        this.setState({
            value: date
        });
    }
    
    handleBlurDate() {
        if (moment(this.state.value).format('MM/DD/YYYY') !== moment(this.state.oldValue).format('MM/DD/YYYY'))
            swal({
                title: "Please confirm",
                text: "Are you sure that you want to change " + this.props.changingLabelName + "?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((result) => {
                    if (result) {
                        this.setState({ oldValue: this.state.value },() => this.props.onHandlerSave(this.state.value, this.state.index));                        
                    } else
                        this.setState({ value: this.state.oldValue });
                });
    }

    render() {
        return (
            <div className="text-center" >
                {this.props.type !== 'date' && <input type={this.props.type} value={this.state.value} style={this.state.style} onChange={this.handleChange} onBlur={this.handleBlur} />}
                {this.props.type === 'date' && 
                    <DatePicker
                        className={(moment(this.state.value) <= moment() ? 'bg-danger text-white' : 
                            moment(this.state.value) <= moment().add(2, 'days') ?
                            'bg-warning text-white' : 'bg-success text-white')}
                        selected={moment(this.state.value)}
                        onChange={this.handleChangeDate}
                        style={this.state.style}
                        onBlur={this.handleBlurDate}
                    />}
            </div>
        )
    }
}

export default TableEditable