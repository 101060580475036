import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import 'react-select/dist/react-select.css';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

import { getSubscribe, saveSubscribe } from '../../../service/Subscribe';

import UploadSingleImagesHelper from '../../../util/UploadSingleImagesHelper';
import { getAllManufacturer } from '../../../service/Manufacturer';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class MyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listManufacturer: [],
			imageSelected: '',
		};
		this.handleCategoryImageUpdate = this.handleCategoryImageUpdate.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

	componentDidMount() {
		getAllManufacturer()
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listManufacturer: json });
			})
			.catch(console.log);
	}

	handleCategoryImageUpdate(imageReceived) {
		this.props.values.imageSelected = imageReceived;
		this.setState({ imageSelected: imageReceived })
	}

	handleChangeManufacturer = (selectedOption) => {
		this.props.values.ManufacturerId = selectedOption.value;
		this.setState({
			ManufacturerId: selectedOption.value,
		});
	}

	render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
		} = this.props;

		const now = new Date();
		const time = moment(now).format('YYYYMMDDHHmmss');
		const created = values.Created !== '' ? moment(values.Created).format('MM/DD/YYYY HH:mm') : '';
		const updated = values.Updated !== '' ? moment(values.Updated).format('MM/DD/YYYY HH:mm') : '';


		const image = this.state.imageSelected !== '' ? this.state.imageSelected : (values.ManufacturerImage !== null && values.ManufacturerImage !== '' ? `${STORAGE_URL}subscribemanufacturer/${values.ManufacturerImage}?${time}` : '');

		const listManufacturerValue = [];
		const itemsManufacturer = this.state.listManufacturer.map((item) =>
			listManufacturerValue.push({ value: item.Id, label: item.CompanyName + " - " + item.FirstName })
		);


		return (
			<form onSubmit={handleSubmit}>

				<div className={touched.ManufacturerId && errors.ManufacturerId ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="ManufacturerId">Manufacturer</label>
					<div className="input-width-icon right">
						<Select name="form-field-Country" type="text" id="ManufacturerId" value={values.ManufacturerId} onChange={this.handleChangeManufacturer} options={listManufacturerValue} />
					</div>
					{errors.ManufacturerId && touched.ManufacturerId && <span className="text-danger">{errors.ManufacturerId}</span>}
				</div>

				<div className={touched.Title && errors.Title ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Title">Title</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="Title" className="form-control" value={values.Title} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Title && touched.Title && <span className="text-danger">{errors.Title}</span>}
				</div>

				<div className={touched.Description && errors.Description ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Description">Description</label>
					<div className="input-width-icon right">
						<i></i>
						<textarea type="text" id="Description" className="form-control" rows="3" value={values.Description} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Description && touched.Description && <span className="text-danger">{errors.Description}</span>}
				</div>

				<div className={touched.Redirect && errors.Redirect ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Redirect">Redirect (Link)</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="Redirect" className="form-control" value={values.Redirect} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Redirect && touched.Redirect && <span className="text-danger">{errors.Redirect}</span>}
				</div>

				<UploadSingleImagesHelper
					labelName={'Client Image'}
					onHandleImageUpdate={this.handleCategoryImageUpdate}
					imageSize={'fullSize'}
				/>
				<img src={image} style={{ width: '200px' }} /><br />

				<div className="form-group noneDisplay">
					<label className="form-label">Created Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={created} />
						<span className="input-group-addon"></span>
					</div>
				</div>
				<div className="form-group noneDisplay">
					<label className="form-label">Updated Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={updated} />
						<span className="input-group-addon"></span>
					</div>
				</div>

				<div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>
			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		Title: Yup.string()
			.required('Title is required!'),
		Description: Yup.string()
			.required('Description is required!'),
		ManufacturerId: Yup.number()
			.required('Manufacturer is required!')
			.nullable(true),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.save(values).catch((error) => {
			setSubmitting(false);
		});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, Title: '', Description: '', ManufacturerId: null, ManufacturerImage: '', Redirect: '', };

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema),
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id)
			getSubscribe(id)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ model: json });
				});
	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {
		var data = values;
		var imageSelected = values.imageSelected;

		if (imageSelected && imageSelected !== '')
			data = Object.assign(data,
				{ Image: imageSelected.replace('data:image/jpeg;base64,', '') });

		return saveSubscribe(data).then(res => {
			if (res.ok) {
				this.props.history.push(MyConstantsFile.APP_ADMIN_SUBSCRIBER_PATH);
			}
		});
	}

	render() {
		return (
			<div>
				<ul className="breadcrumb">
					<li><p>{MyConstantsFile.PAGE_NAME_SUBSCRIBER}</p></li>
					<li><Link to={MyConstantsFile.APP_ADMIN_SUBSCRIBER_PATH}>Manage</Link></li>
					<li><a href="#" className="active">{(window.location.href.indexOf("edit") > -1 ? "Edit" : "Add")}</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" role="tabList">
							<li className="active"><a href="#tab1EditUser" role="tab" data-toggle="tab">{(window.location.href.indexOf("edit") > -1 ? "Edit Subscriber" : "Add Subscriber")}</a></li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="tab1EditUser">
								<MyEnhancedForm
									model={this.state.model}
									save={this.handleSave}
									reset={this.handleReset} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Form;
