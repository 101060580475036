import React, { Component } from 'react';
import moment from 'moment';

import ReactTable from "react-table";
import 'react-table/react-table.css'

import matchSorter from 'match-sorter'

class EmailTableHelper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadDefaultTable: false,
			disableButton: false,
		}
	}

	componentDidMount() {
		this.props.onRef(this)
	  }

	enableButton(){
		this.setState({disableButton: false});
	}

	render() {

		const data = this.props.listEmail;
		const tabTitle = this.props.listEmail.title;

		const columns = [{
			id: 'Id',
			Header: 'Id',
			accessor: 'Id',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["Id"] }),
			filterAll: true,
			width: 70,
		}, {
			id: 'FirstName',
			Header: 'FIRST NAME',
			accessor: 'FirstName',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["FirstName"] }),
			filterAll: true,
			width: 100,
		}, {
			id: 'LastName',
			Header: 'LAST NAME',
			accessor: 'LastName',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["LastName"] }),
			filterAll: true,
			width: 100,
		}, {
			id: 'Email',
			Header: 'EMAIL',
			accessor: 'Email',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["Email"] }),
			filterAll: true,
			width: 200,
		}, {
			id: 'ProductName',
			Header: 'PRODUCT NAME',
			accessor: d => d.CommunityMemberProduct[0].Product.ProductName, //it is hard code for only one product in the array
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["ProductName"] }),
			filterAll: true,
			width: 200,
		}, {
			id: 'DropShipId',
			Header: 'PICKUP',
			accessor: d => d.CommunityMemberProduct[0].Product.DropShipId == 0 ? "Yes" : "No", //it is hard code for only one product in the array
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["DropShipId"] }),
			filterAll: true,
			width: 200,
		}, {
			id: 'EvaluationDeadline',
			Header: 'EVALUATION DEADLINE',
			accessor: d => moment(d.CommunityMemberProduct[0].Product.ProductConfiguration.EvaluationDeadline).format('YYYY-MM-DD'), //it is hard code for only one product in the array
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["EvaluationDeadline"] }),
			filterAll: true,
			width: 200,
		}]

		return (
			<div className="align-items-center">
				<div className="col-12">
					<div className="grid simple horizontal">
						<div className={this.state.tester == '' ? 'noneDisplay' : "blockDisplay grid-title.no-border-top"}>
							<div className="product-template-fullscreen border-0 mb-3 container">

								<span>{'Quantity of people that will receive email: ' + data.length}</span>

								<ReactTable
									data={data}
									columns={columns}
									minRows={1}
									filterable
									defaultFilterMethod={(filter, row) =>
										String(row[filter.id]) === filter.value}
									defaultPageSize={10}
									className="-striped -highlight mt-2"
								/>

								<div className={"mt-1"}>
									<div className="form-actions">
										<div className="pull-right">
											<button disabled={this.state.disableButton} className={'btn btn-danger btn-cons'}
												onClick={() => {
													this.props.onSendEmail(tabTitle);
													this.setState({ disableButton: true })
												}}>{'Send Emails'}</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default EmailTableHelper;