import React, { Component } from 'react';

import qs from 'qs';

import { getCompletedSurvey } from '../../../service/CommunityMemberProduct';

class CompletedSurvey extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const data = qs.parse(this.props.location.search.slice(1));

		getCompletedSurvey(data.product).then(setTimeout(() => this.props.history.push(`/Dashboard/Overview`), 3000)).catch(console.log);
	}

	render() {
		return (
			<div className="style-login-container text-center">
				<h4 className="mb-3">We are processing your evaluation results and will redirect you back to your dashboard momentarily.</h4>
				<img src="/assets/img/big-loading-gif.gif" style={{ width: '100px' }} /><br />
			</div>
		);
	}
}

export default CompletedSurvey;