import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

import AdministratorList from '../Administrator/List'
import AdministratorForm from '../Administrator/Form'
import CompanyTypesList from '../CompanyTypes/List'
import CompanyTypesForm from '../CompanyTypes/Form'
import CountryList from '../Country/List'
import CountryForm from '../Country/Form'
import CampaignList from '../Campaign/List'
import CampaignForm from '../Campaign/Form'
import CategoryList from '../Category/List'
import CategoryForm from '../Category/Form'
import CouponList from '../Coupon/List'
import GenderTargetList from '../GenderTarget/List'
import GenderTargetForm from '../GenderTarget/Form'
import HeardAboutList from '../HeardAbout/List'
import HeardAboutForm from '../HeardAbout/Form'
import LeadList from '../Lead/List'
import EvaluatorList from '../Evaluator/List'
import EmailList from '../SendEmail/EmailHelper'
import ManufacturerList from '../Manufacturer/List'
import ManufacturerForm from '../Manufacturer/Form'
import ManufacturerCompleteInformation from '../Manufacturer/CompleteInformation'
import ManufacturerEditProfile from '../Manufacturer/EditProfile'
import ManufacturerEditPassword from '../Manufacturer/EditPassword'
import PackageList from '../Package/List'
import PackageForm from '../Package/Form'
import PackageItemList from '../PackageItem/List'
import PackageItemForm from '../PackageItem/Form'
import PaymentInformationCardInfo from '../PaymentInformation/CardInfo'
import ProductListAdmin from '../Product/ListAdmin'
import ProductListManufacturer from '../Product/ListManufacturer'
import UploadSurvey from '../Product/UploadSurvey'
import ProductFormManufacturer from '../Product/FormManufacturer'
import ProspectList from '../Prospect/List'
import ProspectForm from '../Prospect/Form'
import StateList from '../State/List'
import StateForm from '../State/Form'
import CommunityMemberList from '../CommunityMember/List'
import PaymentSummary from '../PaymentInformation/PaymentSummary'
import AllergyList from '../Allergy/List'
import AllergyForm from '../Allergy/Form'
import RewardProductForm from '../RewardProduct/Form'
import RewardProductList from '../RewardProduct/List'
import SubscribeForm from '../Subscribe/Form'
import SubscribeList from '../Subscribe/List'
import RewardForm from '../Reward/Form'
import RewardList from '../Reward/List'
import PointFeatureList from '../PointFeature/List'
import PointFeatureForm from '../PointFeature/Form'
import EducationGroupList from '../EducationGroup/List'
import EducationGroupForm from '../EducationGroup/Form'
import EthnicityList from '../Ethnicity/List'
import EthnicityForm from '../Ethnicity/Form'
import IncomeGroupList from '../IncomeGroup/List'
import IncomeGroupForm from '../IncomeGroup/Form'
import MaritalStatusList from '../MaritalStatus/List'
import MaritalStatusForm from '../MaritalStatus/Form'
import WorkStatusGroupList from '../WorkStatusGroup/List'
import WorkStatusGroupForm from '../WorkStatusGroup/Form'
import AgeTargetList from '../AgeTarget/List'
import AgeTargetForm from '../AgeTarget/Form'
import CallToActionList from '../CallToAction/List'
import CallToActionForm from '../CallToAction/Form'
import IndicateActivityList from '../IndicateActivity/List'
import IndicateActivityForm from '../IndicateActivity/Form'
import NotificationPreferenceList from '../NotificationPreference/List'
import NotificationPreferenceForm from '../NotificationPreference/Form'
import TaxList from '../Tax/List'
import TaxForm from '../Tax/Form'


class Content extends Component {
	// constructor(props) {
	// 	super(props);
	// }

	render() {
		return (
			<div className="page-content">
				<div id="portlet-config" className="modal hide">
					<div className="modal-header">
						<button data-dismiss="modal" className="close" type="button"></button>
						<h3>Widget Settings</h3>
					</div>
					<div className="modal-body"> Widget settings form goes here </div>
				</div>
				<div className="clearfix"></div>
				<div className="content">
					{/* <Route exact path="/" render={(props) => (<h1>Dashboard</h1>)} /> */}
					<Route exact path={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_PATH} component={AdministratorList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_ADD_PATH} component={AdministratorForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_EDIT_PATH} component={AdministratorForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_COMPANYTYPES_PATH} component={CompanyTypesList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_COMPANYTYPES_ADD_PATH} component={CompanyTypesForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_COMPANYTYPES_EDIT_PATH} component={CompanyTypesForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_COUNTRY_PATH} component={CountryList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_COUNTRY_ADD_PATH} component={CountryForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_COUNTRY_EDIT_PATH} component={CountryForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_COUPON_PATH} component={CouponList} />

					<Route exact path={MyConstantsFile.APP_ADMIN_GENDERTARGET_PATH} component={GenderTargetList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_GENDERTARGET_ADD_PATH} component={GenderTargetForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_GENDERTARGET_EDIT_PATH} component={GenderTargetForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_CAMPAIGN_PATH} component={CampaignList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_CAMPAIGN_ADD_PATH} component={CampaignForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_CAMPAIGN_EDIT_PATH} component={CampaignForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_CATEGORY_PATH} component={CategoryList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_CATEGORY_ADD_PATH} component={CategoryForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_CATEGORY_EDIT_PATH} component={CategoryForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_HEARDABOUT_PATH} component={HeardAboutList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_HEARDABOUT_ADD_PATH} component={HeardAboutForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_HEARDABOUT_EDIT_PATH} component={HeardAboutForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_LEAD_PATH} component={LeadList} />

					<Route exact path={MyConstantsFile.APP_ADMIN_EVALUATOR_PATH} component={EvaluatorList} />

					<Route exact path={MyConstantsFile.APP_ADMIN_EMAIL_PATH} component={EmailList} />

					<Route exact path={MyConstantsFile.APP_ADMIN_CLIENT_PATH} component={ManufacturerList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_CLIENT_ADD_PATH} component={ManufacturerForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_CLIENT_EDIT_PATH} component={ManufacturerForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_CLIENT_COMPLETE_INFORMATION_PATH} component={ManufacturerCompleteInformation} />
					<Route exact path={MyConstantsFile.APP_ADMIN_CLIENT_EDIT_PROFILE_PATH} component={ManufacturerEditProfile} />
					<Route exact path={MyConstantsFile.APP_ADMIN_CLIENT_EDIT_PASSWORD_PATH} component={ManufacturerEditPassword} />

					<Route exact path={MyConstantsFile.APP_ADMIN_PACKAGE_PATH} component={PackageList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_PACKAGE_ADD_PATH} component={PackageForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_PACKAGE_EDIT_PATH} component={PackageForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_PACKAGEITEM_PATH} component={PackageItemList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_PACKAGEITEM_ADD_PATH} component={PackageItemForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_PACKAGEITEM_EDIT_PATH} component={PackageItemForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_PAYMENTINFORMATION_PATH} component={PaymentInformationCardInfo} />

					<Route exact path={MyConstantsFile.APP_ADMIN_PAYMENTSUMMARY_PATH} component={PaymentSummary} />

					<Route exact path={MyConstantsFile.APP_ADMIN_PRODUCT_ADMIN_PATH} component={ProductListAdmin} />

					<Route exact path={MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_PATH} component={ProductListManufacturer} />
					<Route exact path={MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_ADD_PATH} component={ProductFormManufacturer} />
					<Route exact path={MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_EDIT_PATH} component={ProductFormManufacturer} />

					<Route exact path={MyConstantsFile.APP_ADMIN_PRODUCT_UPLOAD_SURVEY_PATH} component={UploadSurvey} />

					<Route exact path={MyConstantsFile.APP_ADMIN_PROSPECT_PATH} component={ProspectList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_PROSPECT_ADD_PATH} component={ProspectForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_PROSPECT_EDIT_PATH} component={ProspectForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_STATE_PATH} component={StateList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_STATE_ADD_PATH} component={StateForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_STATE_EDIT_PATH} component={StateForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_ALLERGY_PATH} component={AllergyList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_ALLERGY_ADD_PATH} component={AllergyForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_ALLERGY_EDIT_PATH} component={AllergyForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_COMMUNITYMEMBER_PATH} component={CommunityMemberList} />

					<Route exact path={MyConstantsFile.APP_ADMIN_REWARD_PRODUCT_PATH} component={RewardProductList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_REWARD_PRODUCT_ADD_PATH} component={RewardProductForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_REWARD_PRODUCT_EDIT_PATH} component={RewardProductForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_SUBSCRIBER_PATH} component={SubscribeList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_SUBSCRIBER_ADD_PATH} component={SubscribeForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_SUBSCRIBER_EDIT_PATH} component={SubscribeForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_REWARD_PATH} component={RewardList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_REWARD_ADD_PATH} component={RewardForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_REWARD_EDIT_PATH} component={RewardForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_POINTFEATURE_PATH} component={PointFeatureList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_POINTFEATURE_ADD_PATH} component={PointFeatureForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_POINTFEATURE_EDIT_PATH} component={PointFeatureForm} />
					
					<Route exact path={MyConstantsFile.APP_ADMIN_EDUCATIONGROUP_PATH} component={EducationGroupList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_EDUCATIONGROUP_ADD_PATH} component={EducationGroupForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_EDUCATIONGROUP_EDIT_PATH} component={EducationGroupForm} />
					
					<Route exact path={MyConstantsFile.APP_ADMIN_ETHNICITY_PATH} component={EthnicityList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_ETHNICITY_ADD_PATH} component={EthnicityForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_ETHNICITY_EDIT_PATH} component={EthnicityForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_INCOMEGROUP_PATH} component={IncomeGroupList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_INCOMEGROUP_ADD_PATH} component={IncomeGroupForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_INCOMEGROUP_EDIT_PATH} component={IncomeGroupForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_MARITALSTATUS_PATH} component={MaritalStatusList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_MARITALSTATUS_ADD_PATH} component={MaritalStatusForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_MARITALSTATUS_EDIT_PATH} component={MaritalStatusForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_WORKSTATUSGROUP_PATH} component={WorkStatusGroupList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_WORKSTATUSGROUP_ADD_PATH} component={WorkStatusGroupForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_WORKSTATUSGROUP_EDIT_PATH} component={WorkStatusGroupForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_AGETARGET_PATH} component={AgeTargetList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_AGETARGET_ADD_PATH} component={AgeTargetForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_AGETARGET_EDIT_PATH} component={AgeTargetForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_CALLTOACTION_PATH} component={CallToActionList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_CALLTOACTION_ADD_PATH} component={CallToActionForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_CALLTOACTION_EDIT_PATH} component={CallToActionForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_INDICATEACTIVITY_PATH} component={IndicateActivityList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_INDICATEACTIVITY_ADD_PATH} component={IndicateActivityForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_INDICATEACTIVITY_EDIT_PATH} component={IndicateActivityForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_NOTIFICATIONPREFERENCE_PATH} component={NotificationPreferenceList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_NOTIFICATIONPREFERENCE_ADD_PATH} component={NotificationPreferenceForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_NOTIFICATIONPREFERENCE_EDIT_PATH} component={NotificationPreferenceForm} />

					<Route exact path={MyConstantsFile.APP_ADMIN_TAX_PATH} component={TaxList} />
					<Route exact path={MyConstantsFile.APP_ADMIN_TAX_ADD_PATH} component={TaxForm} />
					<Route exact path={MyConstantsFile.APP_ADMIN_TAX_EDIT_PATH} component={TaxForm} />


					

				</div>
			</div>
		);
	}
}

export default Content;
