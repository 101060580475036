import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/Allergy`;

export const getAllAllergy = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getAllergy = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveAllergy = (allergy) => {
	let url = endpoint;
	let method = 'POST';
	
	if(allergy.Id && allergy.Id > 0)
	{
		url += `/${allergy.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(allergy)
	}, getFetchOpts()));
};

export const deleteAllergy = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
