import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

import { getCategory, saveCategory } from '../../../service/Category';
import { } from '../../../service/Category';
import { getAllAllergy } from '../../../service/Allergy';

import UploadSingleImagesHelper from '../../../util/UploadSingleImagesHelper';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';
class MyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			CategoryId: null,
			listAllergies: [],
			selectedAllergies: [],
			loadFromModel: false,
			imageSelected: '',
			imageSelected2: '',
		};
		this.handleChangeAllergiesItems = this.handleChangeAllergiesItems.bind(this);
		this.handleCategoryImageUpdate = this.handleCategoryImageUpdate.bind(this);
		this.handleCategoryLargeImageUpdate = this.handleCategoryLargeImageUpdate.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

	componentDidMount() {
		getAllAllergy()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listAllergies: json });
			})
			.catch(console.log);
	}

	handleChangeAllergiesItems(event) {
		var allAllergiesItems = this.state.selectedAllergies;
		var index = allAllergiesItems.indexOf(parseInt(event.target.id));
		if (index > -1) {
			allAllergiesItems.splice(index, 1);
			this.state.selectedAllergies = allAllergiesItems;
		} else {
			this.state.selectedAllergies.push(parseInt(event.target.id));
		}

		this.props.values.CategoryAllergy = [];
		for (var i = 0; i < this.state.selectedAllergies.length; i++) {
			this.props.values.CategoryAllergy.push({
				AllergyId: this.state.selectedAllergies[i]
			})
		}

		this.setState({
			selectedAllergies: this.state.selectedAllergies,
		});
	}

	handleCategoryImageUpdate(imageReceived) {
		this.props.values.imageSelected = imageReceived;
		this.setState({ imageSelected: imageReceived })
	}

	handleCategoryLargeImageUpdate(imageReceived) {
		this.props.values.imageSelected2 = imageReceived;
		this.setState({ imageSelected2: imageReceived })
	}

	render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
		} = this.props;

		const now = new Date();
		const time = moment(now).format('YYYYMMDDHHmmss');
		const created = values.Created !== '' ? moment(values.Created).format('MM/DD/YYYY HH:mm') : '';
		const updated = values.Updated !== '' ? moment(values.Updated).format('MM/DD/YYYY HH:mm') : '';

		const image = this.state.imageSelected !== '' ? this.state.imageSelected : (values.CategoryImage !== null && values.CategoryImage !== '' ? `${STORAGE_URL}category/${values.CategoryImage}?${time}` : '');
		const image2 = this.state.imageSelected2 !== '' ? this.state.imageSelected2 : (values.CategoryLargeImage !== null && values.CategoryLargeImage !== '' ? `${STORAGE_URL}category/${values.CategoryLargeImage}?${time}` : '');

		if (this.state.loadFromModel == false && this.props.model.Id != 0) {
			this.state.loadFromModel = true;

			this.state.selectedAllergies = [];
			for (var i = 0; i < this.props.model.CategoryAllergy.length; i++) {
				this.state.selectedAllergies.push(this.props.model.CategoryAllergy[i].AllergyId);
			}
		}


		const itemsAllergies = this.state.listAllergies.map((item) => (
			<div className="col-4">
				<div className="row align-items-center">
					<input type="checkbox" id={item.Id}
						checked={this.state.selectedAllergies != null ? this.state.selectedAllergies.indexOf(item.Id) > -1 : false}
						onChange={this.handleChangeAllergiesItems} />
					<label className="col text-left">{item.Component}</label>
				</div>
			</div>
		));

		return (
			<form onSubmit={handleSubmit}>
				<div className={touched.CategoryName && errors.CategoryName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="CategoryName">Category Name</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="CategoryName" className="form-control" value={values.CategoryName} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.CategoryName && touched.CategoryName && <span className="text-danger">{errors.CategoryName}</span>}
				</div>

				<div className='form-group'>
					<div className="row justify-content-center">
						<div className="col">
							<div className="row align-items-center">
								{itemsAllergies}
							</div>
						</div>
					</div>
				</div>

				<UploadSingleImagesHelper
					labelName={'Category Image'}
					onHandleImageUpdate={this.handleCategoryImageUpdate}
					imageSize={'fullSize'}
				/>
				<img src={image} style={{ width: '200px' }} /><br />

				<UploadSingleImagesHelper
					labelName={'Category Large Image'}
					onHandleImageUpdate={this.handleCategoryLargeImageUpdate}
					imageSize={'fullSize'}
				/>
				<img src={image2} style={{ width: '200px' }} /><br />

				<div className="form-group noneDisplay">
					<label className="form-label">Created Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={created} />
						<span className="input-group-addon"></span>
					</div>
				</div>
				<div className="form-group noneDisplay">
					<label className="form-label">Updated Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={updated} />
						<span className="input-group-addon"></span>
					</div>
				</div>
				<div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>
			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		CategoryName: Yup.string()
			.required('Category is required!'),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.save(values).catch((error) => {
			setSubmitting(false);
		});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, CategoryName: '', };

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema),
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id)
			getCategory(id)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ model: json });
				});

	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {
		var data = values;
		var imageSelected = values.imageSelected;
		var imageSelected2 = values.imageSelected2;

		if (imageSelected && imageSelected !== '')
			data = Object.assign(data,
				{ Image: imageSelected.replace('data:image/jpeg;base64,', '') });

		if (imageSelected2 && imageSelected2 !== '')
			data = Object.assign(data,
				{ ImageLarge: imageSelected2.replace('data:image/jpeg;base64,', '') });

		return saveCategory(data).then(res => {
			if (res.ok) {
				this.props.history.push(MyConstantsFile.APP_ADMIN_CATEGORY_ADD_PATH);
			}
		});
	}

	render() {
		return (
			<div>
				<ul className="breadcrumb">
					<li><p>{MyConstantsFile.PAGE_NAME_CATEGORY}</p></li>
					<li><Link to={MyConstantsFile.APP_ADMIN_CATEGORY_PATH}>Manage</Link></li>
					<li><a href="#" className="active">Edit</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" role="tabList">
							<li className="active"><a href="#tab1EditUser" role="tab" data-toggle="tab">Edit Category</a></li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="tab1EditUser">
								<MyEnhancedForm
									model={this.state.model}
									save={this.handleSave}
									reset={this.handleReset} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Form;
