import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/InviteFriend`;

export const getAllInviteFriend = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getInviteFriend = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const editInviteFriend = (inviteFriend) => {
	let url = endpoint;
	let method = 'PUT';

	if (inviteFriend.Id && inviteFriend.Id > 0) {
		url += `/${inviteFriend.Id}`
	}

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(inviteFriend)
	}, getFetchOpts()));
};

export const saveRangeInviteFriend = (inviteFriend) => {
	let url = `${endpoint}/AddRange/`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(inviteFriend)
	}, getFetchOpts()));
};

export const deleteInviteFriend = (id) => {
	let url = endpoint;
	let method = 'POST';

	if (id && id > 0) {
		url += `/${id}`
		method = 'DELETE';
	}

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};