import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/RewardProduct`;

export const getAllRewardProduct = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getRewardProduct = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveRewardProduct = (rewardProduct) => {
	let url = endpoint;
	let method = 'POST';
	
	if(rewardProduct.Id && rewardProduct.Id > 0)
	{
		url += `/${rewardProduct.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(rewardProduct)
	}, getFetchOpts()));
};

export const deleteRewardProduct = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
