import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Select from 'react-select';

import * as MyConstantsFile from '../../../util/MyConstantsFile'
import FormTemplate from '../_ComponentHelper/FormTemplate'
import { getTax, saveTax } from '../../../service/Tax';

import { getAllState } from '../../../service/State';



class MyForm extends Component {
	constructor(props) {
		super(props);
			this.state = {
			listState: [],
			StateId: null,
			
		};

    }

    componentDidMount() {
			getAllState()
				.then(res => res.json()
					.then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ listState: json });
				})
				.catch(console.log);

			 
		}

    
    handleChangeState = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.StateId = selectedOption.value;
			this.setState({ stateOption: selectedOption });
		} else {
			this.props.values.StateId = null;
			this.setState({
				StateId: null,
			});
		}
	}
	
	

    componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

    render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
		} = this.props;


    const listStateValue = this.state.listState.map((item) => {
		return { value: item.Id, label: item.StateName}
	 })
		
	
        

    return (
			<form onSubmit={handleSubmit}>
				<div className={touched.StateId && errors.StateId ? "form-group has-error" : "form-group" }>
					<label className="form-label" htmlFor="StateId">State</label>
					<div className="input-width-icon right">
						<i></i>
						<Select value={values.StateId} onChange={this.handleChangeState} options={listStateValue} />
					</div>
					{errors.StateId && touched.StateId && <span className="text-danger">{errors.StateId}</span>}
				</div>

				
            
                <div className={touched.TaxName && errors.TaxName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="TaxName">Tax Name</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="TaxName" className="form-control" value={values.TaxName} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.TaxName && touched.TaxName && <span className="text-danger">{errors.TaxName}</span>}
				</div>

				<div className={touched.Percentage && errors.Percentage ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Percentage">Percentage</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="Percentage" className="form-control" value={values.Percentage} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Percentage && touched.Percentage && <span className="text-danger">{errors.Percentage}</span>}
				</div>



            <div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}
						disabled={isSubmitting}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>

			</form>
		);
	}
}







const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({

        StateId: Yup.number()
			.required('State is required!')
			.nullable(true),
		
		

        
}),

	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSave(values)
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (json.success)
					props.onLoadNextPage()
			})
			.catch((error) => {
				setSubmitting(false);
			});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, StateId: 0, TaxName: '', Percentage: 0,  };

class TaxForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema)
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleLoadNextPage = this.handleLoadNextPage.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id) {
		    getTax(id)
			    .then(res => res.json().then(json => ({ res, json })))
			    .then(({ res, json }) => {
		            this.setState({ model: json });
		    });
        }
    }

    handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
        });
    }


    handleSave(values) {
		return saveTax(values);
    }



    handleLoadNextPage() {
		this.props.history.push(MyConstantsFile.APP_ADMIN_TAX_PATH);
	}

		render() {
		return (
			<FormTemplate
				pageName={MyConstantsFile.PAGE_NAME_TAX}
				pageListDisplayPath={MyConstantsFile.APP_ADMIN_TAX_PATH}
				action={typeof this.state.id === 'undefined' ? 'Add' : 'Edit'}
			>
				<MyEnhancedForm model={this.state.model}
					onSave={this.handleSave}
					onReset={this.handleReset}
					onLoadNextPage={this.handleLoadNextPage}
				/>
			</FormTemplate>
		);
	}
}

export default TaxForm;
