import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Select from 'react-select';
import NumberFormat from 'react-number-format';

import { getRewardProduct, saveRewardProduct } from '../../../service/RewardProduct';
import { getAllManufacturer } from '../../../service/Manufacturer';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class MyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: []
		}
		this.handleManufacturer = this.handleManufacturer.bind(this);
	}

	componentDidMount() {
		getAllManufacturer()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ list: json });
			})
			.catch(console.log);

	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

	handleManufacturer = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.ManufacturerId = selectedOption.value;
			this.setState({
				ManufacturerId: selectedOption.value,
			});
		} else {
			this.props.values.ManufacturerId = 0;
			this.setState({
				ManufacturerId: 0,
			});
		}
	}

	render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
			onModalShow,
			onImageLoaded,
			imageSelected,
			imagePreview,
			onImageChange,
			crop,
			onCropChange,
			onCropComplete,
			onCropSave,
		} = this.props;
		const now = new Date();
		const time = moment(now).format('YYYYMMDDHHmmss');
		const created = values.Created !== '' ? moment(values.Created).format('MM/DD/YYYY HH:mm') : '';
		const updated = values.Updated !== '' ? moment(values.Updated).format('MM/DD/YYYY HH:mm') : '';
		const image = imageSelected !== '' ? imageSelected : (values.MainPhoto !== null && values.MainPhoto !== '' ? `${STORAGE_URL}rewards/${values.MainPhoto}?${time}` : '');

		const listManufacturerValue = [];
		const items = this.state.list.map((item) =>
			listManufacturerValue.push({ value: item.Id, label: item.CompanyName })
		);

		return (
			<form onSubmit={handleSubmit}>
				<div className={touched.RewardName && errors.RewardName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="RewardName">Reward Name</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="RewardName" className="form-control" value={values.RewardName} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.RewardName && touched.RewardName && <span className="text-danger">{errors.RewardName}</span>}
				</div>

				<div className={touched.RewardDescription && errors.RewardDescription ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="RewardDescription">Reward Description</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="RewardDescription" className="form-control" value={values.RewardDescription}
							onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.RewardDescription && touched.RewardDescription && <span className="text-danger">{errors.RewardDescription}</span>}
				</div>

				<div className={touched.ManufacturerId && errors.ManufacturerId ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="ManufacturerId">Client Name</label>
					<div className="input-width-icon right">
						<i></i>
						<Select name="form-field-Package" type="text" id="ManufacturerId" value={values.ManufacturerId}
							onChange={this.handleManufacturer} options={listManufacturerValue} />
					</div>
					{errors.ManufacturerId && touched.ManufacturerId && <span className="text-danger">{errors.ManufacturerId}</span>}
				</div>

				<div className={touched.Point && errors.Point ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Point">Required amount of Point</label>
					<div className="input-width-icon right">
						<i></i>
						<NumberFormat thousandSeparator={true} id="Point" className="form-control" value={values.Point}
							onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Point && touched.Point && <span className="text-danger">{errors.Point}</span>}
				</div>

				<div className={touched.Quantity && errors.Quantity ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Quantity">Quantity available</label>
					<div className="input-width-icon right">
						<i></i>
						<NumberFormat thousandSeparator={true} id="Quantity" className="form-control" value={values.Quantity}
							onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Quantity && touched.Quantity && <span className="text-danger">{errors.Quantity}</span>}
				</div>

				<div className="noneDisplay">
					<label className="form-label">Created Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={created} />
						<span className="input-group-addon"></span>
					</div>
				</div>
				<div className="noneDisplay">
					<label className="form-label">Updated Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={updated} />
						<span className="input-group-addon"></span>
					</div>
				</div>

				<div className="form-group">
					<label className="form-label">Photo</label><br />
					<img src={image} style={{ width: '200px' }} /><br />
					<button type="button"
						className="btn btn-small btn-primary"
						data-toggle="modal"
						data-backdrop="false"
						data-target=".bd-example-modal-lg"
						onClick={onModalShow}>Change Image</button>
					<div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-lg" style={{ marginTop: '100px' }}>
							<div className="modal-content">
								<div className="modal-header">
									<h4 className="modal-title">Crop Image</h4>
								</div>
								<div className="modal-body">
									<div className="form-group">
										<input type="file" id="file-picker" className="form-control" accept="image/*" onChange={onImageChange} />
									</div>
									<div className="form-group">
										<ReactCrop
											crop={crop}
											src={imagePreview}
											onImageLoaded={onImageLoaded}
											onComplete={onCropComplete}
											onChange={onCropChange}
										/>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
									<button type="button" disabled={!imagePreview || imagePreview === ''} className="btn btn-primary" data-dismiss="modal" onClick={onCropSave}>Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>
			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	// mapValuesToPayload: x => x,
	validationSchema: Yup.object().shape({
		RewardName: Yup.string()
			.required('Reward Name is required!'),
		Point: Yup.number()
			.required('Point is required!'),
		Quantity: Yup.number()
			.required('Quantity is required!'),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSave(values).catch((error) => {
			setSubmitting(false);
		});
	},
	//displayName: 'BasicForm', // helps with React DevTools
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = {
	Id: 0, RewardName: '', RewardDescription: '', MainPhoto: '', Point: null, Quantity: null, ManufacturerId: null,
};

function getCroppedImg(image, pixelCrop, fileName) {
	const canvas = document.createElement('canvas');
	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;
	const ctx = canvas.getContext('2d');

	ctx.drawImage(
		image,
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height,
		0,
		0,
		pixelCrop.width,
		pixelCrop.height
	);

	// As Base64 string
	const base64Image = canvas.toDataURL('image/jpeg');

	return base64Image;
}

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema),
			imagePreview: '',
			imageSelected: '',
			crop: {
				x: 0,
				y: 0,
				aspect: 1
			},
			tabActive: 1,
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleImageLoaded = this.handleImageLoaded.bind(this)
		this.handleImageChange = this.handleImageChange.bind(this);
		this.handleCropChange = this.handleCropChange.bind(this);
		this.handleCropComplete = this.handleCropComplete.bind(this);
		this.handleModalShow = this.handleModalShow.bind(this);
		this.handleCropSave = this.handleCropSave.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id)
			getRewardProduct(id)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ model: json });
				});
	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {
		var data = values;
		var imageSelected = this.state.imageSelected;

		if (imageSelected !== '') {
			data = Object.assign(data, { Image: imageSelected.replace('data:image/jpeg;base64,', '') });
		}

		data.Point = parseInt(data.Point.replace(/,/g, ''));
		data.Quantity = parseInt(data.Quantity.replace(/,/g, ''));

		return saveRewardProduct(data).then(res => {
			if (res.ok)
				this.props.history.push(MyConstantsFile.APP_ADMIN_REWARD_PRODUCT_PATH);
		});

	}

	handleImageLoaded(image) {
		this.setState({ image });
	}

	handleImageChange(e) {
		const imageType = /^image\//;
		const file = e.target.files.item(0);

		if (!file || !imageType.test(file.type)) {
			return;
		}

		const reader = new FileReader();

		reader.onload = (data) => {
			let dataUrl = data.target.result;
			this.setState({ imagePreview: dataUrl });
			//loadEditView(e2.target.result);
		};

		reader.readAsDataURL(file);
	}

	handleCropChange(crop) {
		this.setState({ crop });
	}

	handleCropComplete(crop, pixelCrop) {
		this.setState({ pixelCrop });
	}

	handleModalShow() {
		this.setState({
			crop: {
				x: 0,
				y: 0,
				aspect: 1
				// aspect: 16 / 9,
			},
			imagePreview: ''
		});
	}

	handleCropSave() {
		var croped = getCroppedImg(this.state.image, this.state.pixelCrop);
		this.setState({ imageSelected: croped });
	}

	render() {
		return (
			<div>
				<ul className="breadcrumb">
					<li><p>Rewards</p></li>
					<li><Link to={MyConstantsFile.APP_ADMIN_REWARD_PRODUCT_PATH}>Manage</Link></li>
					<li><a href="#" className="active">{(window.location.href.indexOf("edit") > -1 ? "Edit" : "Add")}</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" role="tabList">
							<li className="active"><a href="#tab1EditUser" role="tab" data-toggle="tab">{(window.location.href.indexOf("edit") > -1 ? "Edit Rewards" : "Add Rewards")}</a></li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="tab1EditUser">
								<MyEnhancedForm model={this.state.model}
									onSave={this.handleSave}
									onReset={this.handleReset}
									onModalShow={this.handleModalShow}
									onImageLoaded={this.handleImageLoaded}
									imageSelected={this.state.imageSelected}
									imagePreview={this.state.imagePreview}
									onImageChange={this.handleImageChange}
									crop={this.state.crop}
									onCropChange={this.handleCropChange}
									onCropComplete={this.handleCropComplete}
									onCropSave={this.handleCropSave}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Form;