import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Cards from 'react-credit-cards';
import MaskedInput from 'react-text-mask'
import Stepper from 'react-stepper-horizontal';

import 'react-credit-cards/es/styles-compiled.css';

import { getPaymentInformation, savePaymentInformation, getPaymentSummary } from '../../../service/PaymentInformation';
import { getGetByPackageId } from '../../../service/PackageItem';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class MyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: '',
			packageList: [],
			isManagingFocus: '',
		};
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	componentDidMount() {
		getPaymentSummary()
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				getGetByPackageId(json.Prospect.PackageId)
					.then(res => res.json().then(json => ({ res, json })))
					.then(({ res, json }) => {
						this.setState({ packageList: json });
					});
				this.setState({ list: json });
			})
			.catch(console.log);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

	onFocus() {
		this.setState({
			isManagingFocus: 'cvc',
		});
	}

	onBlur() {
		this.setState({
			isManagingFocus: '',
		});
	}

	render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
		} = this.props;
		const created = values.Created !== '' ? moment(values.Created).format('MM/DD/YYYY HH:mm') : '';
		const updated = values.Updated !== '' ? moment(values.Updated).format('MM/DD/YYYY HH:mm') : '';

		const steps = [{ title: 'Complete Profile' }, { title: 'Payment Information' }, { title: 'Review and Submit Payment' }];

		const packageListValues = this.state.packageList.map((item) => (
			<div dangerouslySetInnerHTML={{__html: item.Text}}></div>
		));

		return (
			<form onSubmit={handleSubmit}>
				<div className="mb-3">
					<Stepper steps={steps} activeStep={1} />
				</div>

				<div className="mt-5">
					<p className="semi-bold border-light-color pb-3">
					PTPA Seal of Approval</p>

					<ul className="semi-bold border-light-color pb-3">
						{packageListValues}
					</ul>

					<div className="mb-5">
						<div className="row">
							<div className="col-8">
							</div>
							<h6 className="col p-2 m-0 text-left bold border-light-color">
								Subtotal:
										</h6>
							<div className="col-1 border-light-color">
							</div>
							<h6 className="col p-2 m-0 mr-3 border-light-color">
								{this.state.list != '' ? parseFloat(this.state.list.Prospect.StartAmount * this.state.list.Prospect.QuantityProduct)
									.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}
							</h6>
						</div>
						<div className="row">
							<div className="col-8">
							</div>
							<h6 className="col p-2 m-0 text-left bold border-light-color">
								Discount:
										</h6>
							<div className="col-1 border-light-color">
							</div>
							<h6 className="col p-2 m-0 mr-3 border-light-color">
								{this.state.list != '' ? parseFloat(this.state.list.Prospect.Coupon.Amount * this.state.list.Prospect.QuantityProduct)
									.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}
							</h6>
						</div>
						<div className={this.state.list != '' ? this.state.list.Manufacturer.State.Country.Id == 1 ? 'row' : 'noneDisplay' : ''}>
							<div className="col-8">
							</div>
							<h6 className="col p-2 m-0 text-left bold border-light-color">
								Total before tax:
										</h6>
							<div className="col-1 border-light-color">
							</div>
							<h6 className="col p-2 m-0 mr-3 border-light-color">
								{this.state.list != '' ?
									parseFloat((this.state.list.Prospect.StartAmount - this.state.list.Prospect.Coupon.Amount)
										* this.state.list.Prospect.QuantityProduct)
										.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}
							</h6>
						</div>
						<div className="row mt-5">
							<div className="col-8">
							</div>
							<h6 className="col p-2 m-0 text-left bold border-light-color">
								{this.state.list != '' ? this.state.list.Manufacturer.State.Country.Id == 1 ?
									('Estimated ' + this.state.list.Tax.TaxName + ':')
									: ''
									: ''
								}

							</h6>
							<div className="col-1 border-light-color">
							</div>
							<h6 className="col p-2 m-0 mr-3 border-light-color">
								{this.state.list != '' ? this.state.list.Manufacturer.State.Country.Id == 1 ?
									(this.state.list.Tax.Percentage + '%') : '' : ''}
							</h6>
						</div>

						<div className="row mt-3">
							<div className="col-8">
							</div>
							<h6 className="col p-2 m-0 text-left bold border-light-color">
								Order Total:
										</h6>
							<div className="col-1">
							</div>
							<h6 className="col p-2 m-0 mr-3 bold">
								{this.state.list != '' ?
									(this.state.list.Manufacturer.State.Country.Id == 1 ? 'CAD ' : '$ ') : ''}
								{this.state.list != '' ? this.state.list.Manufacturer.State.Country.Id == 1 ?
									(((this.state.list.Prospect.StartAmount - this.state.list.Prospect.Coupon.Amount)
										* (100 + this.state.list.Tax.Percentage) / 100) * this.state.list.Prospect.QuantityProduct)
										.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') :
									((this.state.list.Prospect.StartAmount - this.state.list.Prospect.Coupon.Amount)
										* this.state.list.Prospect.QuantityProduct)
										.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
									: ''}
							</h6>
						</div>
					</div>
				</div>

				<div>
					<Cards number={values.CardNumber} name={values.FullName} expiry={values.Expires}
						cvc={values.CVC} focused={this.state.isManagingFocus} />
				</div>

				<div className={touched.CardNumber && errors.CardNumber ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="CardNumber">Card Number</label>
					<div className="input-width-icon right">
						<i></i>
						<MaskedInput mask={[/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ',
							/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]} id="CardNumber"
							className="form-control" guide={false} value={values.CardNumber} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.CardNumber && touched.CardNumber && <span className="text-danger">{errors.CardNumber}</span>}
				</div>

				<div className={touched.FullName && errors.FullName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="FullName">Card Holder Name</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="FullName" className="form-control" value={values.FullName} onChange={handleChange}
							onBlur={handleBlur} />
					</div>
					{errors.FullName && touched.FullName && <span className="text-danger">{errors.FullName}</span>}
				</div>

				<div className={touched.Expires && errors.Expires ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Expires">MM/YY</label>
					<div className="input-width-icon right">
						<i></i>
						<MaskedInput mask={[/\d/, /\d/, '/', /\d/, /\d/]} guide={true} id="Expires" className="form-control"
							value={values.Expires} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Expires && touched.Expires && <span className="text-danger">{errors.Expires}</span>}
				</div>

				<div className={touched.CVC && errors.CVC ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="CVC">CVC</label>
					<div className="input-width-icon right">
						<i></i>
						<MaskedInput mask={[/\d/, /\d/, /\d/]} guide={false} id="CVC" className="form-control" value={values.CVC}
							onChange={handleChange} onBlur={this.onBlur} onFocus={this.onFocus} />
					</div>
					{errors.CVC && touched.CVC && <span className="text-danger">{errors.CVC}</span>}
				</div>

				<div className="form-group noneDisplay">
					<label className="form-label">Created Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={created} />
						<span className="input-group-addon"></span>
					</div>
				</div>
				<div className="form-group noneDisplay">
					<label className="form-label">Updated Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={updated} />
						<span className="input-group-addon"></span>
					</div>
				</div>
				<div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>
			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		CardNumber: Yup.number()
			.required('Card Number is required!'),
		FullName: Yup.string()
			.required('Full Name is required!'),
		Expires: Yup.number()
			.required('Expiration date is required!'),
		CVC: Yup.number()
			.required('CVC is required!'),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.save(values).catch((error) => {
			setSubmitting(false);
		});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, CardNumber: '', FullName: '', Expires: '', CVC: '' };

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema)
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id)
			getPaymentInformation(id)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ model: json });
				});
	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {
		return savePaymentInformation(values).then(res => {
			if (res.ok) {
				this.props.history.push(MyConstantsFile.APP_ADMIN_PAYMENTSUMMARY_PATH);
			}
		});
	}

	render() {
		return (
			<div>
				<ul className="breadcrumb">
					<li><p>Payment Information</p></li>
					<li><a href="#" className="active">Add</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						{/* <ul className="nav nav-tabs" role="tabList">
							<li className="active"><a href="#tab1EditUser" role="tab" data-toggle="tab">Payment Information</a></li>
						</ul> */}
						<div className="tab-content">
							<div className="tab-pane active" id="tab1EditUser">
								<MyEnhancedForm model={this.state.model} save={this.handleSave} reset={this.handleReset} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Form;