import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getAllProspect, deleteProspect } from '../../../service/Prospect';

import ReactTable from "react-table";
import 'react-table/react-table.css'

import matchSorter from 'match-sorter'
import swal from 'sweetalert'
import NumberFormat from 'react-number-format';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class ProspectList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
		}
	}

	componentDidMount() {
		let list;

		//TODO need to get if the user is sales just show up his prospects, if admin show up everyting

		getAllProspect()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ list: json });
			})
			.catch(console.log);

	}

	handleClick(id) {
		swal({
			title: "Please confirm",
			text: "Are you sure that you want to delete this record? ID: " + id,
			// icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((result) => {
				if (result) {
					deleteProspect(id).then(function () {
						window.location.href = MyConstantsFile.APP_ADMIN_PROSPECT_PATH;
					});
				}
			});
	}

	render() {
		const data = this.state.list;

		const columns = [{
			id: 'id',
			Header: 'ID',
			accessor: 'Id',
			width: 50,
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["id"] }),
			filterAll: true,
		}, {
			id: 'Package',
			Header: 'PACKAGE',
			accessor: d => d.Package.PackageName,
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["Package"] }),
			filterAll: true,
		}, {
			id: 'CompanyName',
			Header: 'COMPANY NAME',
			accessor: 'CompanyName',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["CompanyName"] }),
			filterAll: true,
		}, {
			id: 'FirstName',
			Header: 'FIRST NAME',
			accessor: 'FirstName',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["FirstName"] }),
			filterAll: true,
		}, {
			id: 'LastName',
			Header: 'LAST NAME',
			accessor: 'LastName',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["LastName"] }),
			filterAll: true,
		}, {
			id: 'Email',
			Header: 'EMAIL',
			accessor: 'Email',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["CompanyName"] }),
			filterAll: true,
		}, {
			id: 'QuantityProduct',
			Header: 'QTY PRODUCTS',
			accessor: d => d.QuantityProduct,
			Cell: row => (
				<div>
					<NumberFormat value={row.original.QuantityProduct} displayType={'text'} thousandSeparator={true} />
				</div>
			),
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["QuantityProduct"] }),
			filterAll: true,
		}, {
			id: 'StartAmount',
			Header: 'CHARGE PER PRODUCT',
			accessor: d => d.StartAmount,
			Cell: row => (
				<div>
					<NumberFormat value={row.original.StartAmount} displayType={'text'} thousandSeparator={true} />
				</div>
			),
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["StartAmount"] }),
			filterAll: true,
		}, {
			id: 'DiscountAmount',
			Header: 'DISCOUNT AMOUNT PER PRODUCT',
			accessor: d => d.Coupon.Amount,
			Cell: row => (
				<div>
					<NumberFormat value={row.original.Coupon.Amount} displayType={'text'} thousandSeparator={true} />
				</div>
			),
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["DiscountAmount"] }),
			filterAll: true,
		}, {
			id: 'TotalAmount',
			Header: 'TOTAL CHARGE',
			accessor: d => (d.StartAmount - d.Coupon.Amount) * d.QuantityProduct,
			Cell: row => (
				<div>
					<NumberFormat value={(row.original.StartAmount - row.original.Coupon.Amount) * row.original.QuantityProduct}
						displayType={'text'} thousandSeparator={true} />
				</div>
			),
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["TotalAmount"] }),
			filterAll: true,
		}, {
			id: 'PaymentOption',
			Header: 'PAYMENT OPTION',
			accessor: d => d.Invoice ? 'Invoice' : 'Credit Card',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["PaymentOption"] }),
			filterAll: true,
		}
			// , {
			// 	Header: 'ACTION',
			// 	Cell: row => (
			// 		<div>
			// 			{/* <Link to={`/Prospect/edit/${row.original.Id}`} title="Edit"><i className="fas fa-pencil-alt"></i></Link>
			// 			<br /> */}
			// 			<a onClick={(e) => this.handleClick(row.original.Id)} title="Remove" className="mouseDelete"><i className="fa fa-trash"></i></a>
			// 		</div>
			// 	),
			// 	width: 100,
			// }
		]

		return (
			<div>
				<ul className="breadcrumb">
					<li><p>Prospect</p></li>
					<li><a href="#" className="active">Manage</a> </li>
				</ul>
				<div className="row">
					<div className="col-md-12">
						<div className="grid simple horizontal purple">
							<div className="grid-title"><div className="tools">
								<Link to={MyConstantsFile.APP_ADMIN_PROSPECT_ADD_PATH} title="Add"><span className="form-control btn btn-primary btn-cons pull-left">Add Prospect</span></Link>
							</div>
								<h3>Prospect</h3>
							</div>
							<div className="grid-body">
								<ReactTable
									data={data}
									columns={columns}
									minRows={1}
									filterable
									defaultFilterMethod={(filter, row) =>
										String(row[filter.id]) === filter.value}
									defaultPageSize={10}
									defaultSorted={[
										{
											id: "id",
											desc: true
										}
									]}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ProspectList;
