import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import CompleteProfile from '../CommunityMember/CompleteProfile'
import Login from '../CommunityMember/Login'
import Form from '../CommunityMember/Form'
import UserOverview from '../CommunityMember/UserOverview'
import RewardConfirmation from '../CommunityMember/RewardConfirmation'
import RewardCongratulation from '../CommunityMember/RewardCongratulation'
import ReviewProduct from '../CommunityMember/ReviewProduct'
import ReviewProductSuccess from '../CommunityMember/ReviewProductSuccess'
import ForgotPassword from '../CommunityMember/ForgotPassword'
import UpdatePasswordSecurity from '../CommunityMember/UpdatePasswordSecurity'
import ResetPassword from '../CommunityMember/ResetPassword'
import CompletedSurvey from '../CommunityMember/CompletedSurvey'
import JoinTheClub from '../CommunityMember/JoinTheClub'
import ConfirmEmail from '../CommunityMember/ConfirmEmail'

class Content extends Component {
	constructor(props) {
		super(props);

		this.handleSetCookies = this.handleSetCookies.bind(this);
	}

	handleSetCookies(token) {
		this.props.handleSetCookies(token);
	}

	render() {
		return (
			<div className="lazyra-page-content bg-white">
				<div id="portlet-config" className="modal hide">
					<div className="modal-header">
						<button data-dismiss="modal" className="close" type="button"></button>
						<h3>Widget Settings</h3>
					</div>
					<div className="modal-body"> Widget settings form goes here </div>
				</div>
				<div className="clearfix"></div>
				<div className="content">
					{/* <Route path="/" render={(props) => (<h1>Dashboard</h1>)} /> */}
					<Route path="/Dashboard/CommunityMember/CompleteProfile" component={CompleteProfile} />
					<Route path="/Dashboard/CommunityMember/Login" render={props => <Login handleSetToken={this.handleSetCookies} />} />
					<Route path="/Dashboard/CommunityMember/NewMember" render={props => <Form handleSetToken={this.handleSetCookies} history={this.props.history}/>} />
					<Route path="/Dashboard/Overview" component={UserOverview} />
					<Route path="/Dashboard/CommunityMember/RewardConfirmation" component={RewardConfirmation} />
					<Route path="/Dashboard/CommunityMember/RewardCongratulation" component={RewardCongratulation} />
					<Route path="/Dashboard/CommunityMember/Review/:friendlyURL" component={ReviewProduct} />
					<Route path="/Dashboard/CommunityMember/ReviewSuccess/:friendlyURL" component={ReviewProductSuccess} />
					<Route path="/Dashboard/CommunityMember/Forgot-Password" component={ForgotPassword} />
					<Route path="/Dashboard/CommunityMember/Update-Password-Security" component={UpdatePasswordSecurity} />
					<Route path="/Dashboard/CommunityMember/Reset-Password" component={ResetPassword} />
					<Route path="/Dashboard/CommunityMember/CompletedSurvey" component={CompletedSurvey} />
					<Route path="/Dashboard/CommunityMember/JoinTheClub" component={JoinTheClub} />
					<Route path="/Dashboard/CommunityMember/ConfirmEmail" component={ConfirmEmail} />
				</div>
			</div>
		);
	}
}

export default Content;
