import React, { Component } from 'react';
import { withFormik } from 'formik';
import { login, saveCommunityMember } from '../../../service/CommunityMember';
import { getSystemMessage } from '../../../service/SystemMessage';
import Auth from '../../../util/Auth';
import swal from 'sweetalert'
import * as Yup from 'yup';
import qs from 'qs';

class MyLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			UserName: '',
			Password: '',
			FirstName: '',
			LastName: '',
		};

		this.handleChange = this.handleChange.bind(this);
	}


	handleChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		const {
			values,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			touched,
			errors,
		} = this.props;


		return (
			<div className="row style-login-container">
				<div className="col-sm-6">
					<h1><b>Become a member and automatically be entered for a chance to WIN a $250 Amazon Gift card!</b></h1>
					<h6>Become part of an ever-growing community by trying free products and services while sharing your feedback.</h6>
					<br />
					<div>
						<form onSubmit={handleSubmit}>
							<div className={touched.FirstName && errors.FirstName ? 'form-group has-error' : 'form-group'}>
								<label className="form-label" htmlFor="FirstName">First Name *</label>
								<div className="input-width-icon right">
									<input type="FirstName" id="FirstName" className="form-control" value={values.FirstName} onChange={handleChange} onBlur={handleBlur} />
								</div>
								{errors.FirstName && touched.FirstName && <span className="text-danger">{errors.FirstName}</span>}
							</div>
							<div className={touched.LastName && errors.LastName ? 'form-group has-error' : 'form-group'}>
								<label className="form-label" htmlFor="LastName">Last Name *</label>
								<div className="input-width-icon right">
									<input type="LastName" id="LastName" className="form-control" value={values.LastName} onChange={handleChange} onBlur={handleBlur} />
								</div>
								{errors.LastName && touched.LastName && <span className="text-danger">{errors.LastName}</span>}
							</div>
							<div className={touched.Email && errors.Email ? 'form-group has-error' : 'form-group'}>
								<label className="form-label" htmlFor="Email">Email address *</label>
								<div className="input-width-icon right">
									<input type="Email" id="Email" className="form-control" value={values.Email} onChange={handleChange} onBlur={handleBlur} />
								</div>
								{errors.Email && touched.Email && <span className="text-danger">{errors.Email}</span>}
							</div>

							<div className={touched.Password && errors.Password ? 'form-group has-error' : 'form-group'}>
								<label className="form-label" htmlFor="Password">Password *</label>
								<div className="input-width-icon right">
									<i></i>
									<input type="password" id="Password" className="form-control" value={values.Password} onChange={handleChange} onBlur={handleBlur} />
								</div>
								(Password must contain at least 8 characters and must contain at least one capital letter, one lowercase letter and one numeric character.)<br />
								{errors.Password && touched.Password && <span className="text-danger">{errors.Password}</span>}
							</div>

							<div className={touched.ConfirmPassword && errors.ConfirmPassword ? 'form-group has-error' : 'form-group'}>
								<label className="form-label" htmlFor="ConfirmPassword">Confirm Password</label>
								<div className="input-width-icon right">
									<i></i>
									<input type="password" id="ConfirmPassword" className="form-control" value={values.ConfirmPassword} onChange={handleChange} />
								</div>
								{errors.ConfirmPassword && touched.ConfirmPassword && <span className="text-danger">{errors.ConfirmPassword}</span>}
							</div>

							<div className={touched.Terms && errors.Terms ? 'form-group has-error' : 'form-group'}>
								<div className="row align-items-center" style={{ padding: '0px 15px' }}>
									<input type="checkbox" name="Terms" value='Yes'
										onChange={handleChange}
										checked={values.Terms} />
									<label className="col text-left" htmlFor="Terms">
										By checking this box I agree to <a href="https://ptpa.com/terms-of-use/" style={{ color: 'blue' }}>PTPA's terms and conditions.</a>
									</label>
								</div>
								{errors.Terms && touched.Terms && <span className="text-danger">{errors.Terms}</span>}
							</div>

							<div className="pull-left">
								<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Create my account' : 'Loading'}</button>
							</div>
						</form>
					</div>
				</div>
				<div className="style-vc_column_container col-sm-6">
					<img className='img-fluid' src="https://ptpa.com/wp-content/uploads/2018/03/PTPA-Media-happy-mother-and-daughter-laughing-together.jpg" />
				</div>
			</div>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		FirstName: Yup.string()
			.required('First name is required!'),
		LastName: Yup.string()
			.required('Last name is required!'),
		Email: Yup.string()
			.required('Email is required!')
			.email('Invalid email address'),
		Password: Yup.string()
			.min(8, 'Password has to be longer than 8 characters!')
			.required('Password is required!')
			.matches(/[a-z]/, 'Lowercase character required!')
			.matches(/[A-Z]/, 'Uppercase character required!')
			.matches(/[0-9]/, 'Numeric character required!'),
		ConfirmPassword: Yup.string()
			.oneOf([Yup.ref('Password'), null], "Passwords don't match")
			.required('Password is required!'),
		Terms: Yup.bool()
			.test(
				'consent',
				'You have to agree with our Terms and Conditions!',
				value => value === true
			)
			.required(
				'You have to agree with our Terms and Conditions!'
			),
	}),

	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSave(values).then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (res.ok) {
					if (json.token != null) {
						Auth.authenticateUser(json.token);
						props.saveCookie(json.token);
						props.onLoad();
					} else {
						getSystemMessage("duplicaterecord").then(res => res.json().then(json => ({ res, json })))
							.then(({ res, json }) => {
								swal({
									title: "Notice",
									text: json,
									// icon: "warning",
								})
							});
					}
				} else {
					getSystemMessage("duplicaterecord").then(res => res.json().then(json => ({ res, json })))
						.then(({ res, json }) => {
							swal({
								title: "Notice",
								text: json,
								// icon: "warning",
							})
						});
				}
				setSubmitting(false);
			})
			.catch((error) => {
			});
	},
});

const MyEnhancedForm = formikEnhancer(MyLogin);

const now = new Date();

const baseSchema = { Created: now, Updated: now };

const schema = { Id: 0, UserName: '', Password: '', FirstName: '', LastName: '', Email: '', ConfirmPassword: '', Terms: false };

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			model: Object.assign(schema, baseSchema),
			isRemoving: this.props.isRemoving,
			queryString: qs.parse(window.location.search.slice(1))
		}

		this.handleSave = this.handleSave.bind(this);
		this.handleLoad = this.handleLoad.bind(this);
		this.handleSetToken = this.handleSetToken.bind(this);
	}

	handleSave(values) {
		var communityMemberObj = {
			FirstName: values.FirstName,
			LastName: values.LastName,
			Email: values.Email,
			Register: { Email: values.Email, Password: values.Password, UserName: values.Email },
			Utm: this.state.queryString.utm_source,
			UtmMedium: this.state.queryString.utm_medium,
			UtmCampaign: this.state.queryString.utm_campaign,
		}

		return saveCommunityMember(communityMemberObj).catch(console.log);
	}

	handleLoad() {
		window.location.href = '/Dashboard/CommunityMember/CompleteProfile/'
	}

	handleSetToken(token) {
		this.props.handleSetToken(token);
	}

	render() {
		return (
			<div>
				<MyEnhancedForm model={this.state.model} onSave={this.handleSave} onLoad={this.handleLoad} saveCookie={this.handleSetToken} />
			</div>
		);
	}
}

export default Login;