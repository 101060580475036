import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { getPackageItem, savePackageItem } from '../../../service/PackageItem';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

import { getAllPackage } from '../../../service/Package';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class MyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listPackage: [],
			PackageId: 0
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

	handleChangePackage = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.PackageId = selectedOption.value;
			this.setState({
				PackageId: selectedOption.value,
			});
		} else {
			this.props.values.PackageId = 0;
			this.setState({
				PackageId: 0,
			});
		}
	}

	componentDidMount() {
		getAllPackage()
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listPackage: json });
			})
			.catch(console.log);
	}

	render() {
		const {
			values,
			touched,
			errors,
			dirty,
			isSubmitting,
			handleBlur,
			handleChange,
			handleSubmit,
			reset,
		} = this.props;
		const created = values.Created !== '' ? moment(values.Created).format('MM/DD/YYYY HH:mm') : '';
		const updated = values.Updated !== '' ? moment(values.Updated).format('MM/DD/YYYY HH:mm') : '';

		const listPackageValue = [];
		const itemsPackage = this.state.listPackage.map((item) =>
			listPackageValue.push({ value: item.Id, label: item.PackageName })
		);

		return (
			<form onSubmit={handleSubmit}>
				<div className={touched.PackageId && errors.PackageId ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="PackageId">Package</label>
					<div className="input-width-icon right">
						<i></i>
						<Select name="form-field-Package" type="text" id="PackageId" value={values.PackageId}
							onChange={this.handleChangePackage} options={listPackageValue} />
					</div>
					{errors.PackageId && touched.PackageId && <span className="text-danger">{errors.PackageId}</span>}
				</div>

				<div className={touched.Text && errors.Text ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Text">Text</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="Text" className="form-control" value={values.Text} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Text && touched.Text && <span className="text-danger">{errors.Text}</span>}
				</div>

				<div className={touched.Priority && errors.Priority ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Priority">Priority</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="number" id="Priority" className="form-control" value={values.Priority} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Priority && touched.Priority && <span className="text-danger">{errors.Priority}</span>}
				</div>

				<div className="form-group">
					<label className="form-label">Created Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={created} />
						<span className="input-group-addon"></span>
					</div>
				</div>
				<div className="form-group">
					<label className="form-label">Updated Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={updated} />
						<span className="input-group-addon"></span>
					</div>
				</div>
				<div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>
			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		PackageId: Yup.number()
			.required('Package is required!'),
		Text: Yup.string()
			.required('Text is required!'),
		Priority: Yup.number()
			.required('Priority is required!'),

	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.save(values).catch((error) => {
			setSubmitting(false);
		});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, PackageId: 0, Text: '', Priority: 0, };

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema)
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id)
			getPackageItem(id)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ model: json });
				});

	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {
		return savePackageItem(values).then(res => {
			if (res.ok) {
				this.props.history.push(MyConstantsFile.APP_ADMIN_PACKAGEITEM_PATH);
			}
		});
	}

	render() {
		return (
			<div>
				<ul className="breadcrumb">
					<li><p>PackageItem</p></li>
					<li><Link to={MyConstantsFile.APP_ADMIN_PACKAGEITEM_PATH}>Manage</Link></li>
					<li><a href="#" className="active">Edit</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" role="tabList">
							<li className="active"><a href="#tab1EditUser" role="tab" data-toggle="tab">Edit PackageItem</a></li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="tab1EditUser">
								<MyEnhancedForm model={this.state.model} save={this.handleSave} reset={this.handleReset} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Form;
