import React, { Component } from 'react';
import { withFormik } from 'formik';
import moment from 'moment';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


import { getAllCountry } from '../../../service/Country';
import { getStateByCountry } from '../../../service/State';
import { getAllCommunityMemberGenderTarget } from '../../../service/CommunityMemberGenderTarget';
import { getAllAgeTarget } from '../../../service/AgeTarget';

class TabSearchHelperForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            StartRegistrationDate: null,
            EndRegistrationDate: null,
            listCountry: [],
            listState: [],
            selected: {},
            listGenderTarget: [],
            listAllergy: [],
            listAgeTargetFrom: [],
            listAgeTargetChildrenFrom: [],
        }

        this.handleChangeStartRegistration = this.handleChangeStartRegistration.bind(this);
        this.handleChangeEndRegistration = this.handleChangeEndRegistration.bind(this);
    }

    componentDidMount() {
        getAllCountry()
            .then(res => res.json()
                .then(json => ({ res, json })))
            .then(({ res, json }) => {
                const listCountry = json.map((item) => {
                    return { value: item.Id, label: item.CountryName }
                });
                this.setState({ listCountry: listCountry });
            })
            .catch(console.log);
        getAllCommunityMemberGenderTarget()
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                const listGenderTarget = json.map((item) => {
                    return { value: item.Id, label: item.CommunityMemberGenderName }
                });
                this.setState({ listGenderTarget: listGenderTarget });
            })
            .catch(console.log);
        getAllAgeTarget()
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                const listAgeTargetFromFilter = json.filter(item => item.OrderPriority != null && item.CommunityMemberAgeTarget);
                const listAgeTargetFrom = listAgeTargetFromFilter.map((item) => {
                    return { value: item.Id, label: item.Name, orderPriority: item.OrderPriority }
                });

                const listAgeTargetChildrenFromFilter = json.filter(item => item.OrderPriority != null && !item.CommunityMemberAgeTarget);
                const listAgeTargetChildrenFrom = listAgeTargetChildrenFromFilter.map((item) => {
                    return { value: item.Id, label: item.Name, orderPriority: item.OrderPriority }
                });

                this.setState({
                    listAgeTargetFrom: listAgeTargetFrom,
                    listAgeTargetChildrenFrom: listAgeTargetChildrenFrom,
                });
            })
            .catch(console.log);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.CountryId !== this.state.CountryId && (this.state.CountryId === 1 || this.state.CountryId === 2))
            getStateByCountry(this.state.CountryId)
                .then(res => res.json()
                    .then(json => ({ res, json })))
                .then(({ res, json }) => {
                    const listState = json.map((item) => {
                        return { value: item.Id, label: item.StateName }
                    });
                    this.setState({ listState: listState });
                })
                .catch(console.log);

        else if (this.state.CountryId !== 1 && this.state.CountryId !== 2 && this.state.listState.length > 0)
            this.setState({ listState: [] });
    }


    handleChangeCountry = (selectedOption) => {
        if (selectedOption != null) {
            this.props.values.CountryId = selectedOption.value;
            this.setState({
                CountryId: selectedOption.value,
                StateId: 0,
            });
        } else {
            this.props.values.CountryId = 0;
            this.setState({
                CountryId: 0,
                StateId: 0,
            });
        }
    }

    handleChangeState = (selectedOption) => {
        if (selectedOption != null) {
            this.props.values.StateId = selectedOption.value;
            this.setState({
                StateId: selectedOption.value,
            });
        } else {
            this.props.values.StateId = 0;
            this.setState({
                StateId: 0,
            });
        }
    }

    handleChangeAgeTargetTo = (selectedOption) => {
        if (selectedOption != null) {
            this.props.values.AgeTargetEnds = selectedOption.value;
            this.setState({
                AgeTargetEnds: selectedOption.value,
            });
        } else {
            this.props.values.AgeTargetEnds = null;
            this.setState({
                AgeTargetEnds: null,
            });
        }
    }

    handleChangeAgeTargetFrom = (selectedOption) => {
        if (selectedOption != null) {
            this.props.values.AgeTargetStarts = selectedOption.value;
            this.props.values.OrderPriorityStartsId = selectedOption.orderPriority;
            this.setState({
                AgeTargetStarts: selectedOption.value,
            });
        } else {
            this.props.values.AgeTargetStarts = null;
            this.props.values.AgeTargetEnds = null;
            this.setState({
                AgeTargetStarts: null,
            });
        }
    }

    handleChangeAgeTargetChildrenFrom = (selectedOption) => {
        if (selectedOption != null) {
            this.props.values.AgeTargetChildrenStartsId = selectedOption.value;
            this.props.values.OrderPriorityStartsChildrenId = selectedOption.orderPriority;
            this.setState({
                AgeTargetChildrenStartsId: selectedOption.value,
            });
        } else {
            this.props.values.AgeTargetChildrenStartsId = null;
            this.props.values.AgeTargetChildrenEndsId = null;
            this.setState({
                AgeTargetChildrenStartsId: null,
            });
        }
    }

    handleChangeAgeTargetChildrenTo = (selectedOption) => {
        if (selectedOption != null) {
            this.props.values.AgeTargetChildrenEndsId = selectedOption.value;
            this.setState({
                AgeTargetChildrenEndsId: selectedOption.value,
            });
        } else {
            this.props.values.AgeTargetChildrenEndsId = null;
            this.setState({
                AgeTargetChildrenEndsId: null,
            });
        }
    }

    handleChangeChildrenGenderTarget = (selectedOption) => {
        if (selectedOption != null) {
            this.props.values.ChildrenGenderTargetId = selectedOption.value;
            this.setState({
                ChildrenGenderTargetId: selectedOption.value,
            });
        } else {
            this.props.values.ChildrenGenderTargetId = null;
            this.setState({
                ChildrenGenderTargetId: null,
            });
        }
    }

    handleChangeStartRegistration(date) {
        this.setState({
            StartRegistrationDate: date
        }, () => {
            if (date != null)
                this.props.values.DateOfRegistrationStarts = moment(this.state.StartRegistrationDate).format('YYYY/MM/DD');
            else
                this.props.values.DateOfRegistrationStarts = "";
        });
    }

    handleChangeEndRegistration(date) {
        if (date == "Invalid date")
            date = null;
        this.setState({
            EndRegistrationDate: date
        }, () => {
            if (date != null)
                this.props.values.DateOfRegistrationEnds = moment(this.state.EndRegistrationDate).format('YYYY/MM/DD');
            else
                this.props.values.DateOfRegistrationEnds = "";
        });
    }

    handleChangeGenderTarget = (selectedOption) => {
        if (selectedOption != null) {
            this.props.values.CommunityMemberGenderTargetId = selectedOption.value;
            this.setState({
                GenderTargetId: selectedOption.value,
            });
        } else {
            this.props.values.CommunityMemberGenderTargetId = null;
            this.setState({
                GenderTargetId: null,
            });
        }
    }

    handleChangePickUp = (selectedOption) => {
        if (selectedOption != null) {
            this.props.values.PickUp = selectedOption.value;
            this.setState({
                PickUp: selectedOption.value,
            });
        } else {
            this.props.values.PickUp = null;
            this.setState({
                PickUp: null,
            });
        }
    }

    render() {
        const {
            values,
            handleSubmit,
            handleChange,
            handleBlur,
        } = this.props;


        // //show all age list
        const listAgeTargetTo = this.state.listAgeTargetFrom.filter(item => this.props.values.AgeTargetStarts != null &&
            item.orderPriority > this.props.values.OrderPriorityStartsId);

        // //show all age children list
        const listAgeTargetChildrenTo = this.state.listAgeTargetChildrenFrom.filter(item => this.props.values.AgeTargetChildrenStartsId != null &&
            item.orderPriority > this.props.values.OrderPriorityStartsChildrenId);

        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-2 border">
                        <div className='form-group mt-2'>
                            <label className="form-label" htmlFor="FirstName">First Name</label>
                            <div className="input-width-icon right">
                                <input type="text" id="FirstName" className="form-control" value={values.FirstName} onChange={handleChange} onBlur={handleBlur} />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className="form-label" htmlFor="LastName">Last Name</label>
                            <div className="input-width-icon right">
                                <input type="text" id="LastName" className="form-control" value={values.LastName} onChange={handleChange} onBlur={handleBlur} />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className="form-label" htmlFor="Email">Email</label>
                            <div className="input-width-icon right">
                                <input type="text" id="Email" className="form-control" value={values.Email} onChange={handleChange} onBlur={handleBlur} />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-2 border">
                        <div className='form-group mt-2'>
                            <label className="form-label" htmlFor="Country">Country</label>
                            <div className="input-width-icon right">
                                <Select name="form-field-Country" type="text" id="CountryId" value={values.CountryId}
                                    onChange={this.handleChangeCountry} options={this.state.listCountry} />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className="form-label" htmlFor="State">State/Province</label>
                            <div className="input-width-icon right">
                                <Select name="form-field-State" type="text" id="StateId" value={values.StateId}
                                    onChange={this.handleChangeState} options={this.state.listState} />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className="form-label" htmlFor="City">City</label>
                            <div className="input-width-icon right">
                                <input type="text" id="City" className="form-control" value={values.City} onChange={handleChange} onBlur={handleBlur} />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 border">
                        <div className="row">
                            <div className='col-9'>
                                <div className="row mt-2">
                                    <div className='form-group col-6'>
                                        <label className="form-label" htmlFor="RegistrationStart">Registration Date <span className="help">(Start)</span></label>
                                        <div className="input-width-icon right">
                                            <DatePicker
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                maxDate={moment()}
                                                selected={this.state.StartRegistrationDate} onChange={this.handleChangeStartRegistration}
                                                id="StartRegistrationDate" className="form-control" />
                                        </div>
                                    </div>
                                    <div className='form-group col-6'>
                                        <label className="form-label" htmlFor="RegistrationEnd">Registration Date <span className="help">(End)</span></label>
                                        <div className="input-width-icon right">
                                            <DatePicker
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                minDate={this.state.StartRegistrationDate}
                                                maxDate={moment()}
                                                selected={this.state.EndRegistrationDate} onChange={this.handleChangeEndRegistration}
                                                id="EndRegistrationDate" className="form-control" />
                                        </div>
                                    </div>

                                    <div className='form-group col-6'>
                                        <label className="form-label" htmlFor="AgeTargetStarts">Age Range <span className="help">(Min)</span></label>
                                        <div className="input-width-icon right">
                                            <Select name="form-field-State" type="text" id="AgeTargetStarts"
                                                value={values.AgeTargetStarts}
                                                onChange={this.handleChangeAgeTargetFrom} options={this.state.listAgeTargetFrom} />
                                        </div>
                                    </div>
                                    <div className='form-group col-6'>
                                        <label className="form-label" htmlFor="AgeTargetEnds">Age Range <span className="help">(Max)</span></label>
                                        <div className="input-width-icon right">
                                            <Select name="form-field-State" type="text" id="AgeTargetEnds"
                                                value={values.AgeTargetEnds}
                                                onChange={this.handleChangeAgeTargetTo} options={listAgeTargetTo} />

                                        </div>
                                    </div>

                                    <div className='form-group col-6'>
                                        <label className="form-label" htmlFor="Gender">Gender Target</label>
                                        <div className="input-width-icon right">
                                            <Select name="form-field-State" type="text" id="CommunityMemberGenderTargetId"
                                                value={values.CommunityMemberGenderTargetId}
                                                onChange={this.handleChangeGenderTarget} options={this.state.listGenderTarget} />
                                        </div>
                                    </div>

                                    <div className='form-group col-6'>
                                        <label className="form-label" htmlFor="PickUp">Pick up</label>
                                        <div className="input-width-icon right">
                                            <Select name="form-field-State" type="text" id="PickUp" value={values.PickUp}
                                                onChange={this.handleChangePickUp} options={[
                                                    { value: 0, label: 'No' },
                                                    { value: 1, label: 'Yes' },
                                                ]} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-sm-4 border">
                        <div className="row">
                            <div className='col-9'>
                                <div className="row mt-2">
                                    <div className='form-group col-6'>
                                        <label className="form-label" htmlFor="ChildrenAgeTargetStarts">Children Age Range <span className="help">(Min)</span></label>
                                        <div className="input-width-icon right">
                                            <Select name="form-field-State" type="text" id="ChildrenAgeTargetStarts"
                                                value={values.AgeTargetChildrenStartsId}
                                                onChange={this.handleChangeAgeTargetChildrenFrom} options={this.state.listAgeTargetChildrenFrom} />
                                        </div>
                                    </div>
                                    <div className='form-group col-6'>
                                        <label className="form-label" htmlFor="ChildrenAgeTargetEnds">Children Age Range <span className="help">(Max)</span></label>
                                        <div className="input-width-icon right">
                                            <Select name="form-field-State" type="text" id="ChildrenAgeTargetEnds"
                                                value={values.AgeTargetChildrenEndsId}
                                                onChange={this.handleChangeAgeTargetChildrenTo} options={listAgeTargetChildrenTo} />
                                        </div>
                                    </div>

                                    <div className='form-group col-6'>
                                        <label className="form-label" htmlFor="Gender">Children Gender Target</label>
                                        <div className="input-width-icon right">
                                            <Select name="form-field-State" type="text" id="ChildrenGenderTargetId"
                                                value={values.ChildrenGenderTargetId}
                                                onChange={this.handleChangeChildrenGenderTarget} options={this.state.listGenderTarget} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="form-actions">
                            <div className="pull-right">
                                <button className='btn btn-danger btn-cons'>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const formikEnhancer = withFormik({
    mapPropsToValues: props => ({ ...props.model }),
    handleSubmit: (values, { setSubmitting, props }) => {
        props.onSave(values);
    },
});

const MyEnhancedForm = formikEnhancer(TabSearchHelperForm);

const schema = {
    AgeTargetChildrenEndsId: null, AgeTargetChildrenStartsId: null, AgeTargetEnds: null, AgeTargetStarts: null, ChildrenAgeTargetEnds: '', ChildrenAgeTargetStarts: '',
    ChildrenGenderTargetId: null, City: '', CountryId: null, DateOfRegistrationEnds: '', DateOfRegistrationStarts: '', Email: '', FirstName: '', Id: 0, LastName: '',
    PickUp: null, StateId: null,
};

class TabSearchHelper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: Object.assign(schema),
        }
        // this.handleReset = this.handleReset.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    // componentDidMount() {
    //     let id = this.state.id;
    //     if (id) {
    //         getAdministrator(id)
    //             .then(res => res.json().then(json => ({ res, json })))
    //             .then(({ res, json }) => {
    //                 this.setState({ model: json });
    //             });
    //     }
    // }

    // handleReset() {
    //     this.setState({
    //         model: Object.assign(schema, baseSchema)
    //     });
    // }

    handleSave(values) {
        this.props.onHandleSearch(values);
    }

    render() {
        return (
            <MyEnhancedForm
                model={this.state.model}
                onSave={this.handleSave}
            // onReset={this.handleReset}
            />
        );
    }
}

export default TabSearchHelper;