import React, { Component } from 'react';
import Header from '../Header';
import Container from '../Container';
import Footer from '../Footer';

import Auth from '../../../util/Auth';
import swal from 'sweetalert'
import { debug } from 'util';

class AppDashboard extends Component {
	constructor(props) {
		super(props);

		if (window.location.href.toLocaleLowerCase().indexOf("login") == -1) {
			if (!this.props.handleGetToken() && (window.location.href.toLocaleLowerCase().indexOf("completeprofile") > -1 || window.location.href.toLocaleLowerCase().indexOf("overview") > -1
				|| window.location.href.toLocaleLowerCase().indexOf("review") > -1 || window.location.href.toLocaleLowerCase().indexOf("completedsurvey") > -1 || window.location.href.toLocaleLowerCase().indexOf("jointheclub") > -1)) {
				swal({
					title: "Sorry, this is a member only page",
					text: "Please sign in or login to access this page",
					icon: '/assets/img/SadFaceResize.png',
					// buttons: true,
					dangerMode: true,
					closeOnClickOutside: false,
				}).then((result) => {
					if (result) {
						//TODO: change when go to production
						var searchProduction = '.com'
						var indexOfSlash = window.location.href.indexOf(searchProduction);
						var redirectTo = window.location.href.substring(indexOfSlash + searchProduction.length);

						//TODO: change when go to development
						// var searchDevelopment = ':3000'
						// var indexOfSlash = window.location.href.indexOf(searchDevelopment); // for test ind development
						// var redirectTo = window.location.href.substring(indexOfSlash + searchDevelopment.length);

						window.location.href = ('/Dashboard/CommunityMember/Login/?redirect=' + redirectTo);
					}
				});
			} else if (!this.props.handleGetToken() && window.location.href.toLocaleLowerCase().indexOf("authvalidation") > -1) {
				swal({
					title: "Sorry, this is a member only page",
					text: "Please sign in or login to access this page",
					icon: '/assets/img/SadFaceResize.png',
					// buttons: true,
					dangerMode: true,
					closeOnClickOutside: false,
				}).then((result) => {
					if (result) {
						//TODO: production and development are the same
						var searchDevelopment = 'authvalidation/'
						var indexOfSlash = window.location.href.toLocaleLowerCase().indexOf(searchDevelopment); // for test ind development
						var redirectTo = window.location.href.substring(indexOfSlash + searchDevelopment.length);

						window.location.href = ('/Dashboard/CommunityMember/Login/?redirect=' + redirectTo)
					}
				});
			} else if (this.props.handleGetToken() && window.location.href.toLocaleLowerCase().indexOf("authvalidation") > -1) {
				//TODO: production and development are the same
				var searchDevelopment = 'authvalidation/'
				var indexOfSlash = window.location.href.toLocaleLowerCase().indexOf(searchDevelopment); // for test ind development
				var redirectTo = window.location.href.substring(indexOfSlash + searchDevelopment.length);

				window.location.href = (redirectTo)
			}
		}

		if (!Auth.getToken() && this.props.handleGetToken())
			Auth.authenticateUser(this.props.handleGetToken());

		this.handleSetCookies = this.handleSetCookies.bind(this);
	}

	handleSetCookies(token) {
		this.props.handleSetToken(token);
	}

	render() {
		return (
			<React.Fragment>
				<Header />
				<Container handleSetCookies={this.handleSetCookies} />
				<Footer />
			</React.Fragment>
		);
	}
}

export default AppDashboard;