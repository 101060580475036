import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/NotificationPreference`;

export const getAllNotificationPreference = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getAllNotificationPreferencePaged = (tableModel) => {
	let url = `${endpoint}/GetAllPaged/`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};

export const getNotificationPreference = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveNotificationPreference = (notificationPreference) => {
	let url = endpoint;
	let method = 'POST';
	
	if(notificationPreference.Id && notificationPreference.Id > 0)
	{
		url += `/${notificationPreference.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(notificationPreference)
	}, getFetchOpts()));
};

export const deleteNotificationPreference = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
