import React, { Component } from 'react';
import moment from 'moment';

import Popup from "reactjs-popup";


//TODO: implement Last product tested:

class CommunityMemberInfoModal extends Component {

	getListProductTestedPerCommunityMember(CommunityMemberProduct) {
		if (CommunityMemberProduct != null && CommunityMemberProduct.length > 0) {
			const listOfProductAcceptedCommunityMember = CommunityMemberProduct.filter(x => x.Accepted).map((item) => {
				return (
							<div>
								{item.Product.ProductName}
								{" - "}
								{"("}
								{moment(item.DateAccepted).format("YYYY-MM-DD")}
								{")"}
							</div>
						)
			});
			return listOfProductAcceptedCommunityMember
		}
		return "None"
	}

	getListProductInvitedPerCommunityMember(CommunityMemberProduct) {
		if (CommunityMemberProduct != null && CommunityMemberProduct.length > 0) {
			const listOfProductCommunityMember = CommunityMemberProduct.map((item) => {
				return (
							<div>
								{item.Product.ProductName}
								{" - "}
								{"("}
								{moment(item.DateInvited).format("YYYY-MM-DD")}
								{")"}
							</div>
						)
			});
			return listOfProductCommunityMember
		}
		return "None"
	}

	render() {

		const childrenList = this.props.rowInfo.CommunityMemberChildren.map((item) => {
			return (<div>
				<span>
					{item.GenderTargetId === 1 ? "Female - " :
						item.GenderTargetId === 2 ? "Male - " : "Prefer not to say - "}
					{moment(item.DOB).format('YYYY/MM/DD')}
					{" - ("}
					{moment.duration(moment(new Date()).diff(moment(item.DOB))).asYears() > 1 ?
						Math.floor(moment.duration(moment(new Date()).diff(moment(item.DOB))).asYears()) + " years)" :
						Math.floor(moment.duration(moment(new Date()).diff(moment(item.DOB))).asMonths()) === 0 ?
							1 + " months)" : Math.floor(moment.duration(moment(new Date()).diff(moment(item.DOB))).asMonths()) + " months)"}
				</span>
			</div>)
		});

		return (
			<div className="align-items-center">
				<div className="col-12">
					<div className="grid simple horizontal">
						<div>
							<Popup trigger={<p className="text-primary"
								style={{ cursor: 'pointer' }}
								title="Additional information">
								{this.props.rowInfo.FirstName}
							</p>}
								modal>
								{close => (
									<div className="style-modal">
										<a className="close" onClick={close}>
											&times;
        								</a>
										<div className="style-modal-header"> {this.props.rowInfo.FirstName + " " + this.props.rowInfo.LastName} </div>
										<div className="row p-1 ml-0 text-left border-bottom">
											<div className="col-2">
												DOB:
											</div>
											<div className="col-10">
												{this.props.rowInfo.DOB ? this.props.rowInfo.DOB.substring(0, 10) + " (" + (moment().year() - this.props.rowInfo.DOB.substring(0, 4)) + " years old)" : ""}
											</div>
										</div>

										<div className="row p-1 ml-0 text-left border-bottom">
											<div className="col-2">
												Email:
													</div>
											<div className="col-10">
												{this.props.rowInfo.Email}
											</div>
										</div>

										<div className="row p-1 ml-0 text-left border-bottom">
											<div className="col-2">
												Tested:
												</div>
											<div className="col-10">
												{this.props.rowInfo.QuantityProductTested > 0 ? this.getListProductTestedPerCommunityMember(this.props.rowInfo.CommunityMemberProduct) : "None"}
											</div>
										</div>

										<div className="row p-1 ml-0 text-left border-bottom">
											<div className="col-2">
												Invited:
												</div>
											<div className="col-10">
												{this.props.rowInfo.HasInvited === 'Yes' ? this.getListProductInvitedPerCommunityMember(this.props.rowInfo.CommunityMemberProduct) : "None"}
											</div>
										</div>

										<div className="row p-1 ml-0 text-left border-bottom">
											<div className="col-2">
												Expecting:
												</div>
											<div className="col-10">
												{this.props.rowInfo.ExpectingKid ?
													`${this.props.rowInfo.ExpectingKidDueDate.substring(0, 10) + " ("
													+ moment(this.props.rowInfo.ExpectingKidDueDate).diff(moment(), 'days') + " days)"}`
													: "No"}
											</div>
										</div>

										<div className="row p-1 ml-0 text-left">
											<div className="col-2">
												Children:
												</div>
											<div className="col-10">
												{this.props.rowInfo.CommunityMemberChildren ? this.props.rowInfo.CommunityMemberChildren.length > 0 ? childrenList : "No" : "No"}
											</div>
										</div>

									</div>
								)}
							</Popup>
						</div>

					</div>
				</div>
			</div >
		);
	}
}

export default CommunityMemberInfoModal;