import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getAllCategory, deleteCategory } from '../../../service/Category';

import swal from 'sweetalert'

import ReactTable from "react-table";
import 'react-table/react-table.css'

import matchSorter from 'match-sorter'

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class CategoryList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: []
		}
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		getAllCategory()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ list: json });
			})
			.catch(console.log);

	}

	handleClick(event) {
		var id = parseInt(event.currentTarget.id);
		swal({
			title: "Please confirm",
			text: "Are you sure that you want to delete this record? ID: " + id,
			// icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((result) => {
				if (result) {
					deleteCategory(id).then(() => {
						for (var i = 0; i < this.state.list.length; i++) {
							if (this.state.list[i].Id == id) {
								this.state.list.splice(i, 1);
								break;
							}
						}
						this.props.history.push(MyConstantsFile.APP_ADMIN_CATEGORY_PATH)
					});
				}
			});
	}

	render() {

		const data = this.state.list;

		const columns = [{
			id: 'id',
			Header: 'ID',
			accessor: 'Id',
			width: 50,
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["id"] }),
			filterAll: true,
		}, {
			id: 'Category',
			Header: 'Category',
			accessor: 'CategoryName',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["Category"] }),
			filterAll: true,
			width: 200,
		}, {
			Header: 'ACTION',
			Cell: row => (
				<div>
					<Link to={MyConstantsFile.APP_ADMIN_CATEGORY_EDIT_CALL_PATH + row.original.Id} title="Edit"><i className="fas fa-pencil-alt"></i></Link>
					<br />
					<a id={row.original.Id} onClick={this.handleClick} title="Remove" className="mouseDelete"><i className="fa fa-trash"></i></a>
				</div>
			),
			width: 100,
		}]


		return (
			<div>
				<ul className="breadcrumb">
					<li><p>Category</p></li>
					<li><a href="#" className="active">Manage</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<div className="grid simple horizontal purple">
							<div className="grid-title"><div className="tools">
								<Link to={MyConstantsFile.APP_ADMIN_CATEGORY_ADD_PATH} title="Add"><span className="form-control btn btn-primary btn-cons pull-left">Add Category</span></Link>
							</div>
								<h3>Category</h3>
							</div>
							<div className="grid-body">
								<ReactTable
									data={data}
									columns={columns}
									minRows={1}
									filterable
									defaultFilterMethod={(filter, row) =>
										String(row[filter.id]) === filter.value}
									defaultPageSize={10}
									defaultSorted={[
										{
											id: "id",
											desc: true
										}
									]}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CategoryList;
