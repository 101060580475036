import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/Coupon`;

export const getAllCoupon = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getCoupon = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveCoupon = (coupon) => {
	let url = endpoint;
	let method = 'POST';
	
	if(coupon.Id && coupon.Id > 0)
	{
		url += `/${coupon.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(coupon)
	}, getFetchOpts()));
};

export const deleteCoupon = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};


export const getCouponByCode = (couponCode) => {
	let url = `${endpoint}/CouponCode/${couponCode}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};


export const getRandomCouponCode = () => {
	let url = `${endpoint}/RandomCouponCode/`;

	return fetch(url, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};