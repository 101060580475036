import React from 'react';
import { Link } from 'react-router-dom';

const ListTemplate = (props) => {
    return (
        <div>
            <ul className="breadcrumb">
                <li><span>{props.pageName}</span></li>
                <li><span className="active">Manage</span> </li>
            </ul>
            <div className="row">
                <div className="col-12">
                    <div className="grid simple horizontal purple">
                        <div className="grid-title">
                            { props.redirectTo && <div className="tools">
                                <Link to={props.redirectTo} title="Add"><span className="form-control btn btn-primary btn-cons pull-left">Add {props.pageName}</span></Link>
                            </div>}
                            <h3>{props.pageName}</h3>
                        </div>
                        <div className="grid-body">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListTemplate;