import React, { Component } from 'react';
import moment from 'moment';

import { getProductRelated } from '../../../service/Product';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class ReviewProductSuccess extends Component {

	constructor(props) {
		super(props);
		this.state = {
			productRelatedList: [],
		}

		window.scrollTo(0, 0)
	}

	componentDidMount() {
		if (typeof this.props.location.state !== 'undefined' && this.props.location.state.product)
			getProductRelated(this.props.location.state.product)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					if (json.length > 0)
						this.setState({ productRelatedList: json })
				});
		else
			window.location.href = '/Dashboard/Overview';
	}

	render() {
		const now = new Date();
		const time = moment(now).format('YYYYMMDDHHmmss');

		const productRelated = this.state.productRelatedList.map((item) => (
			<div className="col-12 col-sm-4 text-center p-4" style={{ cursor: 'pointer' }}
				onClick={() => {
					window.location.href = `/Dashboard/CommunityMember/Review/${item.UrlFriendly}`;
				}}>
				<div className="border" style={{
					boxShadow: '0 0 2px 2px rgba(0,0,0,0.05), 0 0 5px 5px rgba(0,0,0,0.05)', borderRadius: '5px'
				}}>
					<div style={{ height: "350px" }} >
						<img className="img-fluid" src={`${STORAGE_URL}product/${item.LargeImage}?${time}`} style={{ height: "350px" }} />
					</div>
					<div className="align-self-end m-3">
						<b>{item.ProductName}</b>
					</div>
				</div>
			</div>
		));

		return (
			<div className="style-container">
				<div className="align-items-center">
					<div className="col-12">
						<div className="grid simple horizontal">
							<div>
								<h1 className="text-center mb-1" style={{ fontFamily: 'Poppins', fontSize: '40px' }}>Congratulations!</h1>
								<h2 className="text-center" style={{ fontFamily: 'Poppins', fontSize: '28px', marginBottom: '100px' }}>
									{typeof this.props.location.state !== 'undefined' ? this.props.location.state.message : ''}
								</h2>
								<div className="style-modal-header" style={{ fontFamily: 'Poppins', fontSize: '25px' }}>Members who reviewed this item also reviewed</div>
								<div className="mt-3 row">
									{productRelated}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ReviewProductSuccess;