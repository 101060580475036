import React, { Component } from 'react';
import { withFormik } from 'formik';
import Auth from '../../../util/Auth';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

import { errorCallback } from '../../../util/Auxiliary';
import { getCouponByCode } from '../../../service/Coupon';
import { getSystemMessage } from '../../../service/SystemMessage';
import { login } from '../../../service/Manufacturer';

document.body.style = 'background: #e5e9ec';

class MyLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			CouponCode: '',
			UserName: '',
			Password: ''
		};
	}

	render() {
		const {
			values,
			handleChange,
			handleBlur,
			handleSubmit,
			touched,
			errors,
			isSubmitting,
		} = this.props;

		return (
			<div className="container">
				<div className="row login-container">
					<div className="col-md-5 offset-md-1">
						<p><img className="img-fluid" src={'/assets/img/ptpaImage.png'} /></p>
						<p className="col no-padding ptpa-image-text">
							NOTE: This area is NOT public. Please ensure that once you login, you confirm your profile with PTPA in order to be able to submit products for review.
               			</p>
						<p>
							© 2019 PTPA Media Inc. All rights reserved.
                		</p>
					</div>
					<div className="col-md-5">
						<div className="row">
							<form id="couponForm" className="animated fadeIn validate" onSubmit={handleSubmit}>
								<div className="form-group col-md-10">
									<h6 className="font-weight-bold">
										<b>
											Welcome to the PTPA Media Business Login
										</b>
									</h6>
									<p>
										This is where you will access your private PTPA Media personal dashboard. Please enter the code provided by your account executive below:
									</p>
									<div className="row cupom-field">
										<input className="form-control col-lg col-md-12 mr-1" id="CouponCode"
											placeholder="Coupon Code" type="text" value={values.CouponCode} onChange={handleChange} onBlur={handleBlur} />
										<div className="checkbox check-success col-lg-5 no-padding mt-1 mt-lg-0">
											<button type="submit" className="btn btn-primary btn-cons float-right"><i className="icon-ok"></i>Confirm</button>
										</div>
									</div>
								</div>
							</form>
						</div>
						<form className="animated fadeIn validate" id="loginPasswordForm" onSubmit={handleSubmit}>
							<div className="row">
								<div className="form-group col-md-10">
									<p>
										If you already have your profile setup login below.
									</p>
									<input className="form-control" id="UserName" placeholder="Email Address" type="text"
										value={values.UserName} onChange={handleChange} onBlur={handleBlur} />
									{errors.UserName && touched.UserName && <span className="text-danger">{errors.UserName}</span>}
								</div>
							</div>
							<div className="row">
								<div className="form-group col-md-10">
									<input className="form-control" id="Password" placeholder="Password" type="password"
										value={values.Password} onChange={handleChange} onBlur={handleBlur} />
									{errors.Password && touched.Password && <span className="text-danger">{errors.Password}</span>}
								</div>
							</div>
							<div className="row">
								<div className="col-md-10">
									<div className="checkbox check-success">
										<button
											className={isSubmitting ? 'btn btn-primary btn-cons disabled' : 'btn btn-primary btn-cons'}
											disabled={isSubmitting}
										><i className="icon-ok"></i>{!isSubmitting ? 'Login' : 'Loading'}</button>
										<div className="float-right">
											<span style={{ cursor: 'pointer' }} onClick={() => window.location.href = `/Client/Forgot-Password`}>Forgot my password</span>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	handleSubmit: (values, { setSubmitting, props }) => {
		var objResult = {};

		//Check if has coupon or not
		if (values.CouponCode !== '') {

			props.coupon(values.CouponCode).catch((error) => {
				errorCallback("invalidcoupon")
			});

		} else if (values.UserName !== '' && values.Password !== '') {
			objResult.Id = values.Id;
			objResult.UserName = values.UserName;
			objResult.Password = values.Password;


			props.loginPassword(objResult).catch((error) => {
				errorCallback("loginorpasswordiswrong")
			});
		} else if (values.CouponCode === '' && values.UserName === '' && values.Password === '') {
			errorCallback("fillallfields")
		}
		setSubmitting(false);
	},

});

const MyEnhancedForm = formikEnhancer(MyLogin);

const schema = { Id: 0, UserName: '', Password: '', CouponCode: '' };

class ManufacturerLogin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			model: Object.assign(schema),
			isRemoving: this.props.isRemoving
		}

		this.handleCoupon = this.handleCoupon.bind(this);
		this.handleLoginPassword = this.handleLoginPassword.bind(this);
		this.handleSaveCookie = this.handleSaveCookie.bind(this);
	}

	handleCoupon(CouponCode) {
		//TODO find a way to show a msg when coupon is invalid
		return getCouponByCode(CouponCode).then(
			res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (res.ok) {
					if (json.CouponCode != null) {
						Auth.authenticateCoupon(json.CouponCode);
						Auth.setTypeUser("prospect")
						window.location.href = MyConstantsFile.APP_ADMIN_CLIENT_COMPLETE_INFORMATION_PATH;
					} else {
						getSystemMessage("invalidcoupon").then(res => res.json().then(json => ({ res, json })))
							.then(({ res, json }) => {
								window.swal('Notice', json, 'warning');
							});
					}
				}
			})
	}

	handleLoginPassword(objResult) {
		return login(objResult).then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (res.ok) {
					if (json.token != null) {
						Auth.authenticateUser(json.token);
						this.handleSaveCookie(json.token);
						Auth.setTypeUser("manufacturer");
						window.location.href = MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_PATH;
					} else {
						getSystemMessage("loginorpasswordiswrong").then(res => res.json().then(json => ({ res, json })))
							.then(({ res, json }) => {
								window.swal('Notice', json, 'warning');
							});
					}
				} else {
					getSystemMessage("error").then(res => res.json().then(json => ({ res, json })))
						.then(({ res, json }) => {
							window.swal('Notice', json, 'warning');
						});
				}
			})
	}

	handleSaveCookie(token) {
		this.props.handleSetToken(token);
	}

	render() {
		return (
			<div>
				<MyEnhancedForm model={this.state.model} coupon={this.handleCoupon} loginPassword={this.handleLoginPassword} />
			</div>
		);
	}
}

export default ManufacturerLogin;