import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/CommunityMemberGenderTarget`;

export const getAllCommunityMemberGenderTarget = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getCommunityMemberGenderTarget = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveCommunityMemberGenderTarget = (communityMemberGenderTarget) => {
	let url = endpoint;
	let method = 'POST';
	
	if(communityMemberGenderTarget.Id && communityMemberGenderTarget.Id > 0)
	{
		url += `/${communityMemberGenderTarget.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMemberGenderTarget)
	}, getFetchOpts()));
};

export const deleteCommunityMemberGenderTarget = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
