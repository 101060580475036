import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/CommunityMemberPrize`;

export const getAllCommunityMemberPrize = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const saveCommunityMemberPrize = (communityMember) => {
	let url = endpoint;
	let method = 'POST';

	if(communityMember.Id && communityMember.Id > 0)
	{
		url += `/${communityMember.Id}`
		method = 'PUT';
	}

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMember)
	}, getFetchOpts()));
};

export const editCommunityMemberPrize = (communityMemberProduct) => {
	let url = endpoint;
	let method = 'PUT';

	if (communityMemberProduct.Id && communityMemberProduct.Id > 0) {
		url += `/${communityMemberProduct.Id}`
	}

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMemberProduct)
	}, getFetchOpts()));
};

export const deleteCommunityMemberPrize = (id) => {
	let url = endpoint;
	let method = 'POST';

	if (id && id > 0) {
		url += `/${id}`
		method = 'DELETE';
	}

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
