import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/ProductSurveyQuestion`;

export const getAllProductSurveyQuestion = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getProductSurveyQuestion = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveProductSurveyQuestion = (productSurveyQuestion) => {
	let url = endpoint;
	let method = 'POST';

	if (productSurveyQuestion.Id && productSurveyQuestion.Id > 0) {
		url += `/${productSurveyQuestion.Id}`
		method = 'PUT';
	}

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(productSurveyQuestion)
	}, getFetchOpts()));
};

export const uploadSurvey = (productSurveyQuestion) => {
	let url = `${endpoint}/UploadSurvey/`;

	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(productSurveyQuestion.File)
	}, getFetchOpts()));
};

export const deleteProductSurveyQuestion = (id) => {
	let url = endpoint;
	let method = 'POST';

	if (id && id > 0) {
		url += `/${id}`
		method = 'DELETE';
	}

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
