import React, { Component } from 'react';

import swal from 'sweetalert'
import MaskedInput from 'react-text-mask'
import { withFormik } from 'formik';
import * as Yup from 'yup';

import Auth from '../../../util/Auth';
import { getCommunityMemberToken } from '../../../service/CommunityMember';
import { saveCommunityMemberSubscribeInformation } from '../../../service/CommunityMemberSubscribeInformation';

var monthlyPlan = { backgroundColor: '#fdfbfb', fontSize: '20px', padding: '30px 20px', height: '215px', border: '2px solid', borderColor: 'grey' }
var monthlyPlanButton = { height: '40px', width: '170px', color: 'white', backgroundColor: 'grey', border: 'none' }
var monthlyPlanHighlight = { backgroundColor: '#fdfbfb', fontSize: '20px', padding: '30px 20px', height: '215px', border: '2px solid', borderColor: 'orange' }
var monthlyPlanHighlightButton = { height: '40px', width: '170px', color: 'white', backgroundColor: 'orange', border: 'none' }
var annualPlan = { backgroundColor: '#fdfbfb', fontSize: '20px', padding: '30px 20px', height: '215px', border: '2px solid', borderColor: 'grey' }
var annualPlanButton = { height: '40px', width: '170px', color: 'white', backgroundColor: 'grey', border: 'none' }
var annualPlanHighlight = { backgroundColor: '#fdfbfb', fontSize: '20px', padding: '30px 20px', height: '215px', border: '2px solid', borderColor: 'orange' }
var annualPlanHighlightButton = { height: '40px', width: '170px', color: 'white', backgroundColor: 'orange', border: 'none' }

class MyForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prizeList: null,
            profileList: {},
            monthlyPlan: monthlyPlan,
            monthlyPlanButton: monthlyPlanButton,
            annualPlan: annualPlanHighlight,
            annualPlanButton: annualPlanHighlightButton,
        }
        this.handleMonthlyPlan = this.handleMonthlyPlan.bind(this);
        this.handleAnnualPlan = this.handleAnnualPlan.bind(this);
        this.handleBackToRewards = this.handleBackToRewards.bind(this);
    }

    componentDidMount() {
        if (Auth.isUserAuthenticated()) {
            getCommunityMemberToken()
                .then(res => res.json()
                    .then(json => ({ res, json })
                    ))
                .then(({ res, json }) => {
                    if (json.Status) {
                        this.setState({ profileList: json });
                    }
                })
                .catch(() =>
                    swal({
                        title: "Sorry, this is a member only page",
                        text: "Please login to access this page",
                        icon: '/assets/img/SadFaceResize.png',
                        // buttons: true,
                        dangerMode: true,
                        closeOnClickOutside: false,
                    }).then((result) => {
                        if (result) {
                            //TODO: change when go to production
                            var indexOfSlash = window.location.href.indexOf('.com');
                            var redirectTo = window.location.href.substring(indexOfSlash + 4);
                            // var indexOfSlash = window.location.href.indexOf(':3000'); // for test ind development
                            // var redirectTo = window.location.href.substring(indexOfSlash + 5);
                            window.location.href = ('/Dashboard/CommunityMember/Login/?redirect=' + redirectTo);
                        }
                    })
                );
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.model !== nextProps.model)
            this.props.resetForm();
    }

    handleBackToRewards() {
        this.props.history.push('/Dashboard/Overview')
    }

    handleMonthlyPlan() {
        this.props.values.PlanSelected = 'Monthly'
        this.setState({
            monthlyPlan: monthlyPlanHighlight,
            monthlyPlanButton: monthlyPlanHighlightButton,
            annualPlan: annualPlan,
            annualPlanButton: annualPlanButton,
        })
    }

    handleAnnualPlan() {
        this.props.values.PlanSelected = 'Annual'
        this.setState({
            monthlyPlan: monthlyPlan,
            monthlyPlanButton: monthlyPlanButton,
            annualPlan: annualPlanHighlight,
            annualPlanButton: annualPlanHighlightButton,
        })
    }

    render() {
        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
        } = this.props;

        return (
            <div className="style-container text-center mb-3">

                {this.state.profileList.FirstName && <div>

                    <h1 style={{ fontSize: '70px' }}>
                        Join the Club {this.state.profileList.FirstName}!
                    </h1>
                    <h3 className="mt-5 mb-5">It's better being part of the club! Redeem exclusive rewards,<br />earn 2x the reward points and so much more!</h3>

                    <form onSubmit={handleSubmit}>
                        <div className="row justify-content-center">
                            <div className="col-lg-7 border" style={{ padding: '40px 30px' }}>

                                <p>
                                    Please select your plan
                                </p>
                                <div className="col-12 mb-5">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-5 align-self-center" style={this.state.monthlyPlan}>
                                            <a className="text-dark">
                                                Monthly Plan<br />
                                                <span style={{ fontSize: '40px' }}><sup>$</sup>10.<sup>00</sup><br /></span>
                                                <span style={{ fontSize: '15px', position: 'relative', top: '-15px' }}>{this.state.profileList.CountryId == 1 ? 'CAD' : 'USD'}/month</span><br />
                                            </a>
                                            <button onClick={this.handleMonthlyPlan} style={this.state.monthlyPlanButton} >
                                                SELECT PLAN
                                            </button>
                                        </div>
                                        <div className="col-lg-5" style={this.state.annualPlan}>
                                            <img className="float-left" src={this.props.values.PlanSelected == 'Annual' ? "/assets/img/2monthsfreeorange.png" : "/assets/img/2monthsfreegray.png"}
                                                style={{ width: '100px', position: 'absolute', top: '0px', left: '0px' }}></img>
                                            <a className="text-dark">
                                                Annual Plan<br />
                                                <span style={{ fontSize: '40px' }}><sup>$</sup>100.<sup>00</sup><br /></span>
                                                <span style={{ fontSize: '15px', position: 'relative', top: '-15px' }}>{this.state.profileList.CountryId == 1 ? 'CAD' : 'USD'}/year</span><br />
                                            </a>
                                            <button onClick={this.handleAnnualPlan} style={this.state.annualPlanButton} >
                                                SELECT PLAN
                                            </button>
                                        </div>
                                    </div>
                                    {errors.PlanSelected && touched.PlanSelected && <span className="text-danger">{errors.PlanSelected}</span>}
                                </div>

                                <div style={{ padding: '0px 30px' }}>

                                    <div className={touched.FullName && errors.FullName ? 'form-group has-error' : 'form-group'}>
                                        <label className="form-label" htmlFor="FullName">Card Holder Name*</label>
                                        <div className="input-width-icon right">
                                            <i></i>
                                            <input type="text" id="FullName" className="form-control" value={values.FullName} onChange={handleChange}
                                                onBlur={handleBlur} />
                                        </div>
                                        {errors.FullName && touched.FullName && <span className="text-danger">{errors.FullName}</span>}
                                    </div>

                                    <div className={touched.CardNumber && errors.CardNumber ? 'form-group has-error' : 'form-group'}>
                                        <label className="form-label" htmlFor="CardNumber">Card Number*</label>
                                        <div className="input-width-icon right">
                                            <i></i>
                                            <MaskedInput mask={[/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ',
                                                /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]} id="CardNumber"
                                                className="form-control" guide={false} value={values.CardNumber} onChange={handleChange} onBlur={handleBlur} />
                                        </div>
                                        {errors.CardNumber && touched.CardNumber && <span className="text-danger">{errors.CardNumber}</span>}
                                    </div>

                                    <div className={touched.Expires && errors.Expires ? 'form-group has-error' : 'form-group'}>
                                        <label className="form-label" htmlFor="Expires">MM/YY*</label>
                                        <div className="input-width-icon right">
                                            <i></i>
                                            <MaskedInput mask={[/\d/, /\d/, '/', /\d/, /\d/]} guide={false} id="Expires" className="form-control"
                                                value={values.Expires} onChange={handleChange} onBlur={handleBlur} />
                                        </div>
                                        {errors.Expires && touched.Expires && <span className="text-danger">{errors.Expires}</span>}
                                    </div>

                                    <div className={touched.CVC && errors.CVC ? 'form-group has-error' : 'form-group'}>
                                        <label className="form-label" htmlFor="CVC">CVC*</label>
                                        <div className="input-width-icon right">
                                            <i></i>
                                            <MaskedInput mask={[/\d/, /\d/, /\d/]} guide={false} id="CVC" className="form-control"
                                                value={values.CVC} onChange={handleChange} onBlur={handleBlur} />
                                        </div>
                                        {errors.CVC && touched.CVC && <span className="text-danger">{errors.CVC}</span>}
                                    </div>

                                    <p>
                                        You agree that you will automatically be charged the subscription fee based on the plan you selected above. Full terms are available <a className="text-primary" href="http://ptpa.com/terms-of-use-ptpa-club/" target="_black">HERE</a>.
                                    </p>

                                    <button
                                        className={isSubmitting ? 'btn btn-cons explore-dashboard text-white disabled' : 'btn btn-cons explore-dashboard text-white'}
                                        disabled={isSubmitting} style={{ backgroundColor: '#8dc53e' }}
                                    >
                                        {!isSubmitting ? 'Start my membership now' : 'CONFIRMING'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>}
            </div>
        );
    }
}

const formikEnhancer = withFormik({
    mapPropsToValues: props => ({ ...props.model }),
    validationSchema: Yup.object().shape({
        CardNumber: Yup.string()
            .required('Card Number is required!')
            .min(16),
        PlanSelected: Yup.string()
            .required('Plan is required!'),
        FullName: Yup.string()
            .required('Card Holder Name is required!'),
        Expires: Yup.string()
            .required('Expiration date is required!')
            .min(5),
        CVC: Yup.string()
            .required('CVC is required!')
            .min(3),
    }),
    handleSubmit: (values, { setSubmitting, props }) => {
        swal({
            title: "Please confirm",
            text: "By selecting ok, you are agreeing to the terms of the " + values.PlanSelected + " plan PTPA.Club membership.",
            buttons: true,
            dangerMode: true,
        })
            .then((result) => {
                if (result) {
                    props.save(values).then(() => setSubmitting(false)).catch((error) => {
                        setSubmitting(false);
                    });
                } else
                    setSubmitting(false);
            });
    },
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, CardNumber: '', FullName: '', Expires: '', CVC: '', PlanSelected: 'Annual' };

class JoinTheClub extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: Object.assign(schema, baseSchema)
        }

        this.handleReset = this.handleReset.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        // let id = this.state.id;
        // if (id)
        // 	getPaymentInformation(id)
        // 		.then(res => res.json().then(json => ({ res, json })))
        // 		.then(({ res, json }) => {
        // 			this.setState({ model: json });
        // 		});
    }

    handleReset() {
        this.setState({
            model: Object.assign(schema, baseSchema)
        });
    }

    handleSave(values) {
        var data = {}
        data.CardNumber = values.CardNumber
        data.Expires = values.Expires
        data.Cvc = values.CVC
        data.FullName = values.FullName

        if (values.PlanSelected == 'Annual')
            data.Annual = 1

        return saveCommunityMemberSubscribeInformation(data)
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                if (json.success) {
                    swal({
                        title: "Payment Completed",
                        text: "Thank you for becoming part of PTPA.Club",
                        icon: "success",
                    })
                        .then((result) => {
                            if (result) {
                                this.props.history.push('/Dashboard/Overview')
                            }
                        });
                } else {
                    swal(json.msg)
                }
            })
    }

    render() {
        return (
            <div>
                <MyEnhancedForm model={this.state.model} save={this.handleSave} reset={this.handleReset} />
            </div>
        );
    }
}

export default JoinTheClub;