import React from 'react';
import { RadioGroup, ReversedRadioButton } from 'react-radio-buttons';

export const RadioGroup3Button = (props) => {
    return (
        <div>
            <div className="row mb-2">
                <label className="form-label col-sm-12">{props.questionLabel}</label>
                <RadioGroup
                    className="col-sm-12"
                    onChange={props.handleQuestion}
                    horizontal={props.horizontal}
                >
                    <ReversedRadioButton
                        value={props.button1}
                        iconSize={20}>
                        {props.button1}
                    </ReversedRadioButton>
                    <ReversedRadioButton
                        value={props.button2}
                        iconSize={20}>
                        {props.button2}
                    </ReversedRadioButton>
                    <ReversedRadioButton
                        value={props.button3}
                        iconSize={20}>
                        {props.button3}
                    </ReversedRadioButton>
                </RadioGroup>
            </div>
            {props.children}
        </div>
    );
};

export const RadioGroup2Button = (props) => {
    return (
        <div>
            <div className="row mb-2">
                <label className="form-label col-sm-12">{props.questionLabel}</label>
                <RadioGroup
                    className="col-sm-8"
                    onChange={props.handleQuestion}
                    horizontal={props.horizontal}
                >
                    <ReversedRadioButton
                        value={props.button1}
                        iconSize={20}>
                        {props.button1}
                    </ReversedRadioButton>
                    <ReversedRadioButton
                        value={props.button2}
                        iconSize={20}>
                        {props.button2}
                    </ReversedRadioButton>
                </RadioGroup>
            </div>
            {props.children}
        </div>
    );
};