import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/PackageItem`;

export const getAllPackageItem = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getPackageItem = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const savePackageItem = (packageItem) => {
	let url = endpoint;
	let method = 'POST';
	
	if(packageItem.Id && packageItem.Id > 0)
	{
		url += `/${packageItem.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(packageItem)
	}, getFetchOpts()));
};

export const deletePackageItem = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};


export const getGetByPackageId = (id) => {
	let url = `${endpoint}/GetByPackageId`;
	url += `/${id}`

	return fetch(url, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

