import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/Product`;

export const getAllProduct = (tableModel) => {
	let url = `${endpoint}/GetAllProduct`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};

export const getProductRelated = (product) => {
	let url = `${endpoint}/GetProductRelated`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(product)
	}, getFetchOpts()));
};

export const getProduct = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveProduct = (product) => {
	let url = endpoint;
	let method = 'POST';
	
	if(product.Id && product.Id > 0)
	{
		url += `/${product.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(product)
	}, getFetchOpts()));
};

export const getProductByToken = (tableModel) => {
	let url = `${endpoint}/GetByToken`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};

export const deleteProduct = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};

export const getProductByUrlFriendly = friendlyUrl => {
	let url = `${endpoint}/ListUrlFriendly/${friendlyUrl}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const getProductRelatedList = (id) => {
	let url = `${endpoint}/ProductRelated/${id}`;
		
	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const productWinner = (product) => {
	let url = `${endpoint}/ProductWinner/`;
	let method = 'PUT';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(product)
	}, getFetchOpts()));
};

export const productPublished = (product) => {
	let url = `${endpoint}/ProductPublished/`;
	let method = 'PUT';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(product)
	}, getFetchOpts()));

};