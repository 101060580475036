import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/Manufacturer`;

export const getAllManufacturer = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getManufacturer = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveManufacturer = (manufacturer) => {
	let url = endpoint;
	let method = 'POST';
	
	if(manufacturer.Id && manufacturer.Id > 0)
	{
		url += `/${manufacturer.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(manufacturer)
	}, getFetchOpts()));
};

export const tokenGenerateManufacturer = (manufacturer) => {
	let url = `${endpoint}/ManufacturerTokenGenerate`;
	let method = 'POST';
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(manufacturer)
	}, getFetchOpts()));
};

export const deleteManufacturer = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};

export const getManufacturerToken = () => {
	let url = `${endpoint}/GetToken`;

	return fetch(url, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const login = (model) => {
	let url = endpoint;
	let method = 'POST';

	url += "/Login"
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(model)
	}, getFetchOpts()));
};

export const forgotPassword = (client) => {
	let url = `${endpoint}/ForgotPassword`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(client)
	}, getFetchOpts()));
};

export const resetPassword = (client) => {
	let url = `${endpoint}/ResetPassword`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(client)
	}, getFetchOpts()));
};

export const editPassword = (client) => {
	let url = `${endpoint}/ChangePassword`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(client)
	}, getFetchOpts()));
};