import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert'
import ReactTable from "react-table";
import 'react-table/react-table.css'
import moment from 'moment';
import NumberFormat from 'react-number-format';

import { getAllPrizePaged, deletePrize } from '../../../service/Prize';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class RewardList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			sorted: [{ id: "Id", desc: true }],
			page: 0,
			pageSize: 10,
			expanded: {},
			resized: [],
			filtered: [],
			loading: true,
			pages: null,
			data: [],
		}
		this.fetchData = this.fetchData.bind(this);
		this.requestData = this.requestData.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this)
	}

	componentDidMount() {
		this.fetchData(this.state);
	}

	fetchData(state, instance) {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		this.setState({ loading: true });
		// Request the data however you want.  Here, we'll use our mocked service we created earlier
		this.requestData(
			this.state,
			state.pageSize,
			state.page,
			state.sorted,
			state.filtered
		).then(res => {
			// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
			this.setState({
				data: res.rows,
				pages: res.pages,
				loading: false
			});
		});
	}

	requestData = (currentState, pageSize, page, sorted, filtered) => {
		return new Promise((resolve, reject) => {
			// You can retrieve your data however you want, in this case, we will just use some local data.
			var tableModal = {
				QuantityPerPage: pageSize,
				Page: page + 1,
			}

			if (filtered.length > 0) {
				for (var i = 0; i < filtered.length; i++) {
					var temp = filtered[i].id

					tableModal[temp] = filtered[i].value
				}
			}

			if (sorted.length > 0) {
				tableModal.sorted = []
				for (var i = 0; i < sorted.length; i++) {
					tableModal.sorted.push(sorted[i])
				}
			}

			getAllPrizePaged(tableModal)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.list = json;

					let filteredData = json;

					// You must return an object containing the rows of the current page, and optionally the total pages number.
					const result = {
						// rows: sortedData.slice(pageSize * page, pageSize * page + pageSize),
						rows: filteredData,
						pages: filteredData.length > 0 ? filteredData[0].QuantityOfPages : 0
					};
					resolve(result);
				})
				.catch(console.log);
		});
	};

	handleDeleteClick(event) {
		var id = parseInt(event.currentTarget.id)
		swal({
			title: "Please confirm",
			text: "Are you sure that you want to delete this record? ID: " + id,
			// icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((result) => {
				if (result) {
					this.setState({ loading: true },
						() => deletePrize(id).then(() => {
							this.fetchData(this.state)
						}))
				}
			})
	}

	render() {
        const now = new Date();
		const time = moment(now).format('YYYYMMDDHHmmss');
		
		const { data, pages, loading } = this.state

		this.state.dataPagination = []
		const dataValue = data.Item2 ? data.Item2.map(item => {
			this.state.dataPagination.push({
				Id: item.Id,
				RewardImage: item.PrizeImage,
				Title: item.Title,
				Description: item.Description,
				HowManyPoint: item.HowManyPoint,
				HowManyAvailable: item.HowManyAvailable,
			})
		}) : []

		const columns = [{
			id: 'Id',
			Header: 'Id',
			accessor: 'Id',
			width: 60,
		}, {
            Header: 'RewardImage',
            accessor: 'RewardImage',
            Cell: row => (
                <img src={(row.value !== null && row.value !== '' ? `${STORAGE_URL}prize/${row.value}?${time}` : '')}
                    style={{ height: '80px' }} alt="Image not available" />
            ),
            width: 90,
            filterable: false,
        }, {
			id: 'Title',
			Header: 'TITLE',
			accessor: 'Title',
			width: 100,
		}, {
			id: 'Description',
			Header: 'DESCRIPTION',
			accessor: 'Description',
			width: 150,
		}, {
			id: 'HowManyPoint',
			Header: 'HOW MANY POINTS',
			accessor: d => d.HowManyPoint,
			Cell: row => (
				<div>
					<NumberFormat value={row.original.HowManyPoint} displayType={'text'} thousandSeparator={true} />
				</div>
			),
			width: 100,
		}, {
			id: 'HowManyAvailable',
			Header: 'HOW MANY AVAILABLE',
			accessor: 'HowManyAvailable',
			accessor: d => d.HowManyAvailable,
			Cell: row => (
				<div>
					<NumberFormat value={row.original.HowManyAvailable} displayType={'text'} thousandSeparator={true} />
				</div>
			),
			width: 100,
		}, {
			id: 'Action',
			Header: 'ACTIONS',
			Cell: row => (
				<div>
					<Link to={MyConstantsFile.APP_ADMIN_REWARD_EDIT_CALL_PATH + row.original.Id} title="Edit"><i className="fas fa-pencil-alt"></i></Link>
					<br />
					<a id={row.original.Id} onClick={this.handleDeleteClick} title="Remove" className="mouseDelete"><i className="fa fa-trash"></i></a>
				</div >
			),
			width: 80,
			filterable: false,
			sortable: false,
		}]


		return (
			<div>
				<ul className="breadcrumb">
					<li><p>{MyConstantsFile.PAGE_NAME_REWARD}</p></li>
					<li><a href="#" className="active">Manage</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<div className="grid simple horizontal purple">
							<div className="grid-title"><div className="tools">
								<Link to={MyConstantsFile.APP_ADMIN_REWARD_ADD_PATH} title="Add"><span className="form-control btn btn-primary btn-cons pull-left">Add Reward</span></Link>
							</div>
								<h3>{MyConstantsFile.PAGE_NAME_REWARD}</h3>
							</div>
							<div className="grid-body">
								<ReactTable
									data={this.state.dataPagination}
									columns={columns}
									pages={pages}
									loading={loading}
									manual
									minRows={1}
									filterable
									defaultPageSize={10}
									className="-striped -highlight"
									// Controlled props
									sorted={this.state.sorted}
									page={this.state.page}
									pageSize={this.state.pageSize}
									expanded={this.state.expanded}
									resized={this.state.resized}
									filtered={this.state.filtered}
									// Callbacks
									onSortedChange={sorted => this.setState({ sorted }, () => { this.fetchData(this.state) })}
									onPageChange={page => this.setState({ page }, () => { this.fetchData(this.state) })}
									onPageSizeChange={(pageSize, page) =>
										this.setState({ page, pageSize }, () => { this.fetchData(this.state) })}
									onExpandedChange={expanded => this.setState({ expanded })}
									onResizedChange={resized => this.setState({ resized })}
									onFilteredChange={filtered => {
										this.setState({ filtered }, () => {
											if (filtered.length == 0 || (filtered.length > 0 && filtered[0].value.length > 2)) {
												this.state.page = 0
												this.fetchData(this.state)
											}
										})
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RewardList;
