import React, { Component } from 'react';
import { getCommunityMemberTestOpportunity } from '../../../service/CommunityMember';

import ReactTable from "react-table";
import 'react-table/react-table.css'

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class EvaluatorList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			sorted: [{id: "FirstName", desc: false}],
			page: 0,
			pageSize: 10,
			expanded: {},
			resized: [],
			filtered: [],
			loading: true,
			pages: null,
			data: [],
		}
		// this.handleClick = this.handleClick.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.requestData = this.requestData.bind(this);
	}

	componentDidMount() {
		this.fetchData(this.state);
	}

	// handleClick(event) {
	// 	var id = parseInt(event.currentTarget.id);
	// 	var item = {};
	// 	var index = null;
	// 	for (var i = this.state.list.length - 1; i >= 0; i--) {
	// 		if (this.state.list[i].Id == id) {
	// 			item = this.state.list[i];
	// 			index = i;
	// 			break;
	// 		}
	// 	}
	// 	item.StatusContact = 1;

	// 	swal({
	// 		title: "Please confirm",
	// 		text: "Have you sent e-mail to " + item.Email + "?",
	// 		icon: "warning",
	// 		buttons: true,
	// 		dangerMode: true,
	// 	})
	// 		.then((result) => {
	// 			if (result) {
	// 				// return saveLead(item).then(res => {
	// 				// 	if (res.ok) {
	// 				// 		this.state.list[index] = item;
	// 				// 		this.props.history.push('/Evaluator');
	// 				// 	}
	// 				// });
	// 			}
	// 		});
	// }

	fetchData(state, instance) {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		this.setState({ loading: true });
		// Request the data however you want.  Here, we'll use our mocked service we created earlier
		this.requestData(
			this.state,
			state.pageSize,
			state.page,
			state.sorted,
			state.filtered
		).then(res => {
			// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
			this.setState({
				data: res.rows,
				pages: res.pages,
				loading: false
			});
		});
	}

	requestData = (currentState, pageSize, page, sorted, filtered) => {
		return new Promise((resolve, reject) => {
			// You can retrieve your data however you want, in this case, we will just use some local data.
			var tableModal = {
				QuantityPerPage: pageSize,
				Page: page + 1,
			}

			if (filtered.length > 0) {
				for (var i = 0; i < filtered.length; i++) {
					var temp = filtered[i].id;
					if (temp != 'ProductName')
						tableModal[temp] = filtered[i].value;
					else {
						var Product = {};
						Product.ProductName = filtered[i].value;
						var CommunityMemberProductValue = [];
						CommunityMemberProductValue.push({Product: Product});
						tableModal.CommunityMemberProduct = CommunityMemberProductValue
					}
				}
			}

			if (sorted.length > 0) {
				var temp = sorted;
				tableModal.sorted = temp;
			}
			
			getCommunityMemberTestOpportunity(tableModal)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.list = json;

					let filteredData = json;

					// You must return an object containing the rows of the current page, and optionally the total pages number.
					const result = {
						// rows: sortedData.slice(pageSize * page, pageSize * page + pageSize),
						rows: filteredData,
						pages: filteredData.length > 0 ? filteredData[0].QuantityOfPages : 0
					};
					resolve(result);
				})
				.catch(console.log);
		});
	};

	render() {
		const { data, pages, loading } = this.state;

		const dataPagination = [];
		const dataValue = data.map(item => {
			item.CommunityMemberProduct.map(item2 => {
				dataPagination.push({
					Id: item2.Id,
					FullName: item.FirstName + " " + item.LastName,
					FirstName: item.FirstName,
					LastName: item.LastName,
					Email: item.Email,
					ProductName: item2.Product.ProductName,
					LargeImage: item2.Product.LargeImage,
					Accepted: item2.Accepted,
					Received: item2.Received,
					SurveyCompleted: item2.SurveyCompleted,
				})
			})
		});

		const columns = [{
			id: 'Id',
			Header: 'Id',
			accessor: 'Id',
			show: false,
		}, {
			id: 'FullName',
			Header: 'FULL NAME',
			accessor: 'FullName',
			filterable: false,
			sortable: false,
			width: 220,
		}, {
			id: 'FirstName',
			Header: 'FIRST NAME',
			accessor: 'FirstName',
			width: 100,
		}, {
			id: 'LastName',
			Header: 'LAST NAME',
			accessor: 'LastName',
			width: 120,
		}, {
			id: 'ProductName',
			Header: 'PRODUCT NAME',
			accessor: 'ProductName',
			sortable: false,
		}, {
			id: 'Email',
			Header: 'EMAIL',
			accessor: 'Email',
		}, {
			id: 'Accepted',
			Header: 'ACCEPTED',
			width: 100,
			sortable: false,
			filterable: false,
			accessor: d => d.Accepted ? d.Accepted == true ? "Accepted" : "Declined" : "Pending",
		}, {
			id: 'Received',
			Header: 'RECEIVED',
			width: 100,
			sortable: false,
			filterable: false,
			accessor: d => d.Received ? "Received" : "Not Received",
		}, {
			id: 'SurveyCompleted',
			Header: 'SURVEY',
			width: 100,
			sortable: false,
			filterable: false,
			accessor: d => d.SurveyCompleted ? "Completed" : "Not Completed",
		}]

		return (
			<div>
				<ul className="breadcrumb">
					<li><p>{MyConstantsFile.PAGE_NAME_EVALUATOR}</p></li>
					<li><a href="#" className="active">Manage</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<div className="grid simple horizontal purple">
							<div className="grid-title"><div className="tools">

							</div>
								<h3>{MyConstantsFile.PAGE_NAME_EVALUATOR}</h3>
							</div>
							<div className="grid-body">
								<ReactTable
									data={dataPagination}
									columns={columns}
									pages={pages}
									loading={loading}
									manual
									pivotBy={["FullName"]}
									minRows={1}
									filterable
									defaultPageSize={10}
									className="-striped -highlight"
									// Controlled props
									sorted={this.state.sorted}
									page={this.state.page}
									pageSize={this.state.pageSize}
									expanded={this.state.expanded}
									resized={this.state.resized}
									filtered={this.state.filtered}
									// Callbacks
									onSortedChange={sorted => this.setState({ sorted }, () => { this.fetchData(this.state) })}
									onPageChange={page => this.setState({ page }, () => { this.fetchData(this.state) })}
									onPageSizeChange={(pageSize, page) =>
										this.setState({ page, pageSize }, () => { this.fetchData(this.state) })}
									onExpandedChange={expanded => this.setState({ expanded })}
									onResizedChange={resized => this.setState({ resized })}
									onFilteredChange={filtered => {
										this.setState({ filtered }, () => {
											if (filtered.length == 0 || (filtered.length > 0 && filtered[0].value.length > 2)) {
												this.state.page = 0
												this.fetchData(this.state)
											}
										})
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default EvaluatorList;
