import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Select from 'react-select';

import * as MyConstantsFile from '../../../util/MyConstantsFile'
import UploadSingleImagesHelper from '../../../util/UploadSingleImagesHelper';
import FormTemplate from '../_ComponentHelper/FormTemplate'
import { getAllLevel } from '../../../service/Level';
import { getAdministrator, saveAdministrator } from '../../../service/Administrator';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class MyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listLevel: [],
			LevelId: null,
			imageSelected: '',
		};
		this.handleAdminImageUpdate = this.handleAdminImageUpdate.bind(this);
	}

	componentDidMount() {
		getAllLevel()
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listLevel: json });
			})
			.catch(console.log);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

	handleChangeLevel = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.LevelId = selectedOption.value;
			this.setState({
				LevelId: selectedOption.value,
			});
		} else {
			this.props.values.LevelId = null;
			this.setState({
				LevelId: null,
			});
		}
	}

	handleAdminImageUpdate(imageReceived) {
		this.props.values.imageSelected = imageReceived;
		this.setState({ imageSelected: imageReceived })
	}

	render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
		} = this.props;
		const now = new Date();
		const time = moment(now).format('YYYYMMDDHHmmss');

		const image = this.state.imageSelected !== '' ? this.state.imageSelected : (values.Photo !== null && values.Photo !== '' ? `${STORAGE_URL}administrator/${values.Photo}?${time}` : '/assets/img/OtherProfile.png');

		const listLevelValue = this.state.listLevel.map((item) => {
			return { value: item.Id, label: item.Name }
		});

		return (
			<form onSubmit={handleSubmit}>

				<div className={touched.LevelId && errors.LevelId ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="LevelId">Level</label>
					<div className="input-width-icon right">
						<i></i>
						<Select value={values.LevelId} onChange={this.handleChangeLevel} options={listLevelValue} />
					</div>
					{errors.LevelId && touched.LevelId && <span className="text-danger">{errors.LevelId}</span>}
				</div>

				<div className={touched.FirstName && errors.FirstName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="FirstName">First Name</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="FirstName" className="form-control" value={values.FirstName} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.FirstName && touched.FirstName && <span className="text-danger">{errors.FirstName}</span>}
				</div>

				<div className={touched.LastName && errors.LastName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="LastName">Last Name</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="LastName" className="form-control" value={values.LastName} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.LastName && touched.LastName && <span className="text-danger">{errors.LastName}</span>}
				</div>

				<div className={touched.Email && errors.Email ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="LastName">Email</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="Email" className="form-control" value={values.Email} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Email && touched.Email && <span className="text-danger">{errors.Email}</span>}
				</div>

				<div className={touched.Password && errors.Password ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Password">Password</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="password" id="Password" className="form-control" value={values.Password} onChange={handleChange} onBlur={handleBlur} />
					</div>
					(Password must contain at least 8 characters and must contain at least one capital letter, one lowercase letter and one numeric character.)<br />
					{errors.Password && touched.Password && <span className="text-danger">{errors.Password}</span>}
				</div>

				<div className={touched.ConfirmPassword && errors.ConfirmPassword ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="ConfirmPassword">Confirm Password</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="password" id="ConfirmPassword" className="form-control" value={values.ConfirmPassword} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.ConfirmPassword && touched.ConfirmPassword && <span className="text-danger">{errors.ConfirmPassword}</span>}
				</div>

				<UploadSingleImagesHelper
					labelName={'Upload Picture'}
					onHandleImageUpdate={this.handleAdminImageUpdate}
					imageSize={'smallSize'}
				/>
				<img src={image} style={{ width: '200px' }} alt="Profile" />

				<div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}
						disabled={isSubmitting}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>

			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		FirstName: Yup.string()
			.required('First Name is required!'),
		LastName: Yup.string()
			.required('Last Name is required!'),
		Email: Yup.string()
			.required('Email is required!')
			.email('Invalid email address'),
		Password: Yup.string()
			.min(8, 'Password has to be longer than 8 characters!')
			.required('Password is required!')
			.matches(/[a-z]/, 'Lowercase character required!')
			.matches(/[A-Z]/, 'Uppercase character required!')
			.matches(/[0-9]/, 'Numeric character required!'),
		ConfirmPassword: Yup.string()
			.oneOf([Yup.ref('Password'), ""], "Passwords don't match")
			.required('Password is required!'),
		LevelId: Yup.number()
			.required('Level is required!')
			.nullable(true),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSave(values)
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (json.success)
					props.onLoadNextPage()
			})
			.catch((error) => {
				setSubmitting(false);
			});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, FirstName: '', LastName: '', Email: '', Password: '', ConfirmPassword: '', Photo: '', LevelId: null, };

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema),
		}
		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleLoadNextPage = this.handleLoadNextPage.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id) {
			getAdministrator(id)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ model: json });
				});
		}
	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {
		var data = values;
		var Image = values.imageSelected;

		if (typeof Image !== 'undefined' && Image !== '')
			data = Object.assign(data, { Image: Image.replace('data:image/jpeg;base64,', '') });

		var administratorObj = {};

		administratorObj = {
			FirstName: data.FirstName,
			LastName: data.LastName,
			Email: data.Email,
			Password: data.Password,
			Image: data.Image,
			LevelId: data.LevelId,
			Register: { Email: data.Email, Password: data.Password, UserName: data.Email }
		}

		return saveAdministrator(administratorObj);
	}

	handleLoadNextPage() {
		this.props.history.push(MyConstantsFile.APP_ADMIN_ADMINISTRATOR_PATH);
	}

	render() {
		return (
			<FormTemplate
				pageName={MyConstantsFile.PAGE_NAME_ADMINISTRATOR}
				pageListDisplayPath={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_PATH}
				action={typeof this.state.id === 'undefined' ? 'Add' : 'Edit'}
			>
				<MyEnhancedForm model={this.state.model}
					onSave={this.handleSave}
					onReset={this.handleReset}
					onLoadNextPage={this.handleLoadNextPage}
				/>
			</FormTemplate>
		);
	}
}

export default Form;