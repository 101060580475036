import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import * as MyConstantsFile from '../../../util/MyConstantsFile'
import { getCampaign, saveCampaign } from '../../../service/Campaign';

class MyForm extends Component {

	constructor(props) {
		super(props)
		this.state = {
			StartDate: moment(),
			EndDate: moment(),
			EvaluationDeadline: moment(),
			AnnouncementDate: moment(),
			StartStatus: false,
			EndStatus: false,
			EvaluationDeadlineStatus: false,
			AnnouncementStatus: false,
		};
		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
		this.handleChangeEvaluationDeadline = this.handleChangeEvaluationDeadline.bind(this);
		this.handleChangeAnnouncementDate = this.handleChangeAnnouncementDate.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.setState({
				StartDate: moment(nextProps.model.StartDate),
				EndDate: moment(nextProps.model.EndDate),
				EvaluationDeadline: moment(nextProps.model.EvaluationDeadline),
				AnnouncementDate: moment(nextProps.model.AnnouncementDate),
			}, () => { this.props.resetForm() });
	}

	handleChangeStart(date) {
		this.props.values.StartDate = moment(date).format('MM/DD/YYYY');
		this.setState({
			StartDate: date,
		});
	}

	handleChangeEnd(date) {
		this.props.values.EndDate = moment(date).format('MM/DD/YYYY');
		this.setState({
			EndDate: date
		});
	}

	handleChangeEvaluationDeadline(date) {
		this.props.values.EvaluationDeadline = moment(date).format('MM/DD/YYYY');
		this.setState({
			EvaluationDeadline: date
		});
	}

	handleChangeAnnouncementDate(date) {
		this.props.values.AnnouncementDate = moment(date).format('MM/DD/YYYY');
		this.setState({
			AnnouncementDate: date
		});
	}

	render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
		} = this.props;

		return (
			<form onSubmit={handleSubmit}>
				<div className={touched.CampaignName && errors.CampaignName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="CampaignName">Campaign Name</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="CampaignName" className="form-control" value={values.CampaignName} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.CampaignName && touched.CampaignName && <span className="text-danger">{errors.CampaignName}</span>}
				</div>
				<div className={touched.StartDate && errors.StartDate ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="StartDate">Start Date</label>
					<div className="input-width-icon right">
						<i></i>
						<DatePicker
							minDate={moment()}
							selected={this.state.StartDate} onChange={this.handleChangeStart} value={values.StartDate ? moment(values.StartDate).format('MM/DD/YYYY') : ''}
							id="StartDate" className="form-control" />
					</div>
					{errors.StartDate && touched.StartDate && <span className="text-danger">{errors.StartDate}</span>}
				</div>
				<div className={touched.EndDate && errors.EndDate ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="EndDate">End Date</label>
					<div className="input-width-icon right">
						<i></i>
						<DatePicker
							minDate={this.state.StartDate}
							selected={this.state.EndDate} onChange={this.handleChangeEnd} value={values.EndDate ? moment(values.EndDate).format('MM/DD/YYYY') : ''}
							id="EndDate" className="form-control" />
					</div>
					{errors.EndDate && touched.EndDate && <span className="text-danger">{errors.EndDate}</span>}
				</div>
				<div className={touched.EvaluationDeadline && errors.EvaluationDeadline ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="EvaluationDeadline">Evaluation Deadline</label>
					<div className="input-width-icon right">
						<i></i>
						<DatePicker
							minDate={this.state.StartDate}
							selected={this.state.EvaluationDeadline} onChange={this.handleChangeEvaluationDeadline}
							value={values.EvaluationDeadline ? moment(values.EvaluationDeadline).format('MM/DD/YYYY') : ''}
							id="EvaluationDeadline" className="form-control" />
					</div>
					{errors.EvaluationDeadline && touched.EvaluationDeadline && <span className="text-danger">{errors.EvaluationDeadline}</span>}
				</div>
				<div className={touched.AnnouncementDate && errors.AnnouncementDate ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="AnnouncementDate">Announcement Date</label>
					<div className="input-width-icon right">
						<i></i>
						<DatePicker
							minDate={this.state.StartDate}
							selected={this.state.AnnouncementDate} onChange={this.handleChangeAnnouncementDate}
							value={values.AnnouncementDate ? moment(values.AnnouncementDate).format('MM/DD/YYYY') : ''}
							id="AnnouncementDate" className="form-control" />
					</div>
					{errors.AnnouncementDate && touched.AnnouncementDate && <span className="text-danger">{errors.AnnouncementDate}</span>}
				</div>

				<div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>
			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		CampaignName: Yup.string()
			.required('Campaign Name is required!'),
		StartDate: Yup.string()
			.required('Start Date is required!'),
		EndDate: Yup.string()
			.required('End Date is required!'),
		EvaluationDeadline: Yup.string()
			.required('Evaluation Date is required!'),
		AnnouncementDate: Yup.string()
			.required('Announcement Date is required!'),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.save(values).catch((error) => {
			setSubmitting(false);
		});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = {
	Id: 0, CampaignName: '', StartDate: '', EndDate: '', EvaluationDeadline: '', AnnouncementDate: ''
};

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema),
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id)
			getCampaign(id)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({
						model: json
					});
				});
	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {
		return saveCampaign(values).then(res => {

			if (res.ok) {
				this.props.history.push(MyConstantsFile.APP_ADMIN_CAMPAIGN_PATH);
			}

		});
	}

	render() {
		return (
			<div>
				<ul className="breadcrumb">
					<li><p>{MyConstantsFile.PAGE_NAME_CAMPAIGN}</p></li>
					<li><Link to={MyConstantsFile.APP_ADMIN_CAMPAIGN_PATH}>Manage</Link></li>
					<li><a href="#" className="active">{(window.location.href.indexOf("edit") > -1 ? "Edit" : "Add")}</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" role="tabList">
							<li className="active"><a href="#tab1EditUser" role="tab"
								data-toggle="tab">{(window.location.href.indexOf("edit") > -1 ? "Edit Campaign" : "Add Campaign")}</a></li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="tab1EditUser">
								<MyEnhancedForm model={this.state.model} save={this.handleSave} reset={this.handleReset} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Form;