import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/CallToAction`;

export const getAllCallToAction = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};


export const getAllCallToActionPaged = (tableModel) => {
	let url = `${endpoint}/GetAllPaged/`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};


export const getCallToAction = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveCallToAction = (callToAction) => {
	let url = endpoint;
	let method = 'POST';
	
	if(callToAction.Id && callToAction.Id > 0)
	{
		url += `/${callToAction.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(callToAction)
	}, getFetchOpts()));
};

export const deleteCallToAction = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
