import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import swal from 'sweetalert'

import Auth from '../../../util/Auth';
import StarRatings from 'react-star-ratings';
import UploadSingleImagesHelper from '../../../util/UploadSingleImagesHelper';
import { getProductByUrlFriendly } from '../../../service/Product';
import { getCommunityMemberToken } from '../../../service/CommunityMember';
import { saveReviewWebSite } from '../../../service/ReviewWebSite';
import { RadioGroup3Button, RadioGroup2Button } from '../_ComponentHelper/ReviewProductComponent';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

const NUMBER_OF_CHARACTERS = 150

class ReviewProduct extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageSelected: "",
            communityMemberRating: 0,
            profileList: {},
        }
        this.handleLogOutClick = this.handleLogOutClick.bind(this);
        this.handleChangeRating = this.handleChangeRating.bind(this);
        this.handleFirstQuestion = this.handleFirstQuestion.bind(this);
        this.handleSecondQuestion = this.handleSecondQuestion.bind(this);
        this.handleThirdQuestion = this.handleThirdQuestion.bind(this);
        this.handleForthQuestion = this.handleForthQuestion.bind(this);
        this.handleFifthQuestion = this.handleFifthQuestion.bind(this);
        this.createResultList = this.createResultList.bind(this);
        this.handleCategoryImageUpdate = this.handleCategoryImageUpdate.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.model !== nextProps.model)
            this.props.resetForm();
    }

    componentDidMount() {
        if (Auth.isUserAuthenticated()) {
            getCommunityMemberToken()
                .then(res => res.json()
                    .then(json => ({ res, json })
                    ))
                .then(({ res, json }) => {
                    if (json.Status) {
                        this.props.values.CommunityMemberId = json.Id;
                        this.setState({ profileList: json });
                    }
                }).catch(() =>
                    swal({
                        title: "Sorry, this is a member only page",
                        text: "Please login to access this page",
                        icon: '/assets/img/SadFaceResize.png',
                        dangerMode: true,
                        closeOnClickOutside: false,
                    }).then((result) => {
                        if (result) {
                            //TODO: change when go to production
                            var indexOfSlash = window.location.href.indexOf('.com');
                            var redirectTo = window.location.href.substring(indexOfSlash + 4);
                            // var indexOfSlash = window.location.href.indexOf(':3000'); // for test ind development
                            // var redirectTo = window.location.href.substring(indexOfSlash + 5);
                            window.location.href = ('/Dashboard/CommunityMember/Login/?redirect=' + redirectTo);
                        }
                    })
                );
        }
    }

    handleLogOutClick() {
        Auth.deauthenticateUser();
        window.location.href = "/Dashboard/CommunityMember/Login";
    }

    createResultList(id, answer) {
        var obj = {};
        obj.ProductId = this.props.values.Id;
        obj.CommunityMemberId = this.state.profileList.Id;
        obj.ReviewQuestionId = id;
        obj.Answer = answer;
        this.props.values.Result.push(obj);
    }

    handleChangeRating(newRating, name) {
        var id = 2;

        if (this.props.values.Result.filter(item => item.ReviewQuestionId === id).length === 0)
            this.createResultList(id, newRating);
        else {
            this.props.values.Result = this.props.values.Result.map((item) => {
                if (item.ReviewQuestionId === id)
                    item.Answer = newRating

                return item;
            });
        }

        this.props.values.Rating = newRating;
        this.setState({
            communityMemberRating: newRating
        });
    }

    handleFirstQuestion(answer) {
        var id = 3;

        if (this.props.values.Result.filter(item => item.ReviewQuestionId === id).length === 0)
            this.createResultList(id, answer);
        else {
            this.props.values.Result = this.props.values.Result.map((item) => {
                if (item.ReviewQuestionId === id)
                    item.Answer = answer

                return item;
            });
        }

        this.props.values.FirstQuestion = answer;
    }

    handleSecondQuestion(answer) {
        var id = 6;

        if (this.props.values.Result.filter(item => item.ReviewQuestionId === id).length === 0)
            this.createResultList(id, answer);
        else {
            this.props.values.Result = this.props.values.Result.map((item) => {
                if (item.ReviewQuestionId === id)
                    item.Answer = answer

                return item;
            });
        }

        this.props.values.SecondQuestion = answer;
    }

    handleThirdQuestion(answer) {
        var id = 4;

        if (this.props.values.Result.filter(item => item.ReviewQuestionId === id).length === 0)
            this.createResultList(id, answer);
        else {
            this.props.values.Result = this.props.values.Result.map((item) => {
                if (item.ReviewQuestionId === id)
                    item.Answer = answer

                return item;
            });
        }

        this.props.values.ThirdQuestion = answer;
    }

    handleForthQuestion(answer) {
        var id = 5;

        if (this.props.values.Result.filter(item => item.ReviewQuestionId === id).length === 0)
            this.createResultList(id, answer);
        else {
            this.props.values.Result = this.props.values.Result.map((item) => {
                if (item.ReviewQuestionId === id)
                    item.Answer = answer

                return item;
            });
        }

        this.props.values.ForthQuestion = answer;
    }

    handleFifthQuestion(answer) {
        var id = 13;

        if (this.props.values.Result.filter(item => item.ReviewQuestionId === id).length === 0)
            this.createResultList(id, answer);
        else {
            this.props.values.Result = this.props.values.Result.map((item) => {
                if (item.ReviewQuestionId === id)
                    item.Answer = answer

                return item;
            });
        }

        this.props.values.FifthQuestion = answer;
    }

    handleCategoryImageUpdate(imageReceived) {
        this.props.values.imageSelected = imageReceived;
        this.setState({ imageSelected: imageReceived })
    }

    render() {
        const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
        } = this.props;
        const now = new Date();
        const time = moment(now).format('YYYYMMDDHHmmss');

        const image = this.props.values.LargeImage ? `${STORAGE_URL}product/${this.props.values.LargeImage}?${time}` : '';
        const imageUser = this.state.imageSelected

        return (
            <form onSubmit={handleSubmit}>
                <div className="style-container">
                    <div className="row mb-5">
                        <div className="col-sm-12 col-lg-3 text-center p-4">
                            <img className="mb-2 img-fluid" src={image} style={{ width: '150px' }} /><br />
                            <h6><strong>{this.props.values.ProductName}</strong></h6>
                        </div>
                        <div className="col">
                            <h3 className="mb-2">
                                Leave your Review - Earn up to <b>400</b> points for leaving your review!
                            </h3>

                            <div className={touched.Rating && errors.Rating ? 'form-group has-error mt-3' : 'form-group mt-3'}>
                                <h6>YOUR OVERALL RATING *</h6>
                                <StarRatings
                                    id="Rating"
                                    className="form-control"
                                    rating={this.state.communityMemberRating}
                                    starRatedColor="blue"
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="25px"
                                    starSpacing="2px"
                                    changeRating={this.handleChangeRating}
                                />
                                <div>
                                    {errors.Rating && touched.Rating && <span className="text-danger">{errors.Rating}</span>}
                                </div>
                            </div>

                            <div className={touched.HeadLine && errors.HeadLine ? 'form-group has-error mt-3' : 'form-group mt-3'}>
                                <label className="form-label" htmlFor="HeadLine">REVIEW HEADLINE *</label>
                                <div className="input-width-icon right">
                                    <i></i>
                                    <input type="text" id="HeadLine" className="form-control" value={values.HeadLine} onChange={handleChange} onBlur={handleBlur} />
                                </div>
                                {errors.HeadLine && touched.HeadLine && <span className="text-danger">{errors.HeadLine}</span>}
                            </div>

                            <div>
                                <RadioGroup3Button questionLabel="What was your first reaction to the product? *"
                                    handleQuestion={this.handleFirstQuestion}
                                    horizontal={window.screen.width > 500 ? true : false}
                                    button1="Loved It"
                                    button2="Liked It"
                                    button3="Hated It">
                                    <div className={touched.FirstQuestion && errors.FirstQuestion ? 'form-group has-error mt-3' : 'form-group mt-3'}>
                                        {errors.FirstQuestion && touched.FirstQuestion && <span className="text-danger">{errors.FirstQuestion}</span>}
                                    </div>
                                </RadioGroup3Button>

                                <RadioGroup3Button questionLabel="How long have you used the product? *"
                                    handleQuestion={this.handleSecondQuestion}
                                    horizontal={window.screen.width > 500 ? true : false}
                                    button1="Less than 1 month"
                                    button2="1-6 months"
                                    button3="1 year +">
                                    <div className={touched.SecondQuestion && errors.SecondQuestion ? 'form-group has-error mt-3' : 'form-group mt-3'}>
                                        {errors.SecondQuestion && touched.SecondQuestion && <span className="text-danger">{errors.SecondQuestion}</span>}
                                    </div>
                                </RadioGroup3Button>

                                <RadioGroup3Button questionLabel="How would you rate the quality of the product? *"
                                    handleQuestion={this.handleThirdQuestion}
                                    horizontal={window.screen.width > 500 ? true : false}
                                    button1="Amazing"
                                    button2="Average"
                                    button3="Poor">
                                    <div className={touched.ThirdQuestion && errors.ThirdQuestion ? 'form-group has-error mt-3' : 'form-group mt-3'}>
                                        {errors.ThirdQuestion && touched.ThirdQuestion && <span className="text-danger">{errors.ThirdQuestion}</span>}
                                    </div>
                                </RadioGroup3Button>

                                <RadioGroup2Button questionLabel="Would you recommend this product to a friend? *"
                                    handleQuestion={this.handleForthQuestion}
                                    horizontal={window.screen.width > 500 ? true : false}
                                    button1="Highly Recommended"
                                    button2="Not Recommended">
                                    <div className={touched.ForthQuestion && errors.ForthQuestion ? 'form-group has-error mt-3' : 'form-group mt-3'}>
                                        {errors.ForthQuestion && touched.ForthQuestion && <span className="text-danger">{errors.ForthQuestion}</span>}
                                    </div>
                                </RadioGroup2Button>

                                <RadioGroup2Button questionLabel="Is this a product you will continue using? *"
                                    handleQuestion={this.handleFifthQuestion}
                                    horizontal={window.screen.width > 500 ? true : false}
                                    button1="Yes"
                                    button2="No">
                                    <div className={touched.FifthQuestion && errors.FifthQuestion ? 'form-group has-error mt-3' : 'form-group mt-3'}>
                                        {errors.FifthQuestion && touched.FifthQuestion && <span className="text-danger">{errors.FifthQuestion}</span>}
                                    </div>
                                </RadioGroup2Button>
                            </div>

                            <div className={touched.Review && errors.Review ? 'row form-group has-error mt-3' : 'row form-group mt-3'}>
                                <label className="col-12 form-label" htmlFor="Review">Review *</label>
                                <div className="col-12 input-width-icon right">
                                    <i></i>
                                    <textarea rows="10" cols="30" id="Review" className="form-control" value={values.Review} onChange={handleChange} onBlur={handleBlur} />
                                    <span className="help">{values.Review.length === 0 ? 'You must write at least ' + NUMBER_OF_CHARACTERS + ' characters for this field.' :
                                        values.Review.length < NUMBER_OF_CHARACTERS ?
                                            `Minimum required characters left ${NUMBER_OF_CHARACTERS - values.Review.length}.` : 'Minimum characters reached.'}
                                    </span>
                                    <br />
                                    {errors.Review && touched.Review && <span className="text-danger">{errors.Review}</span>}
                                </div>
                            </div>

                            <div className='form-group mt-3'>
                                <label className="form-label">Upload Product Image - Earn <b>bonus</b> points for uploading a picture of the product in use.</label>
                            </div>
                            <UploadSingleImagesHelper
                                labelName={''}
                                onHandleImageUpdate={this.handleCategoryImageUpdate}
                                imageSize={'smallSize'}
                            />
                            <img src={imageUser} style={{ width: '200px' }} alt="Product" />

                        </div>
                    </div>
                </div>
                <div className="form-actions" style={{ backgroundColor: 'white' }}>
                    <div className="pull-right">
                        <button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'} disabled={isSubmitting}>
                            {!isSubmitting ? 'Save' : 'Saving'}</button>
                    </div>
                </div>
            </form >
        );
    }
}

const formikEnhancer = withFormik({
    mapPropsToValues: props => ({ ...props.model }),
    validationSchema: Yup.object().shape({
        HeadLine: Yup.string()
            .required('Headline is required!'),
        Rating: Yup.number()
            .moreThan(0, 'Rating is required!')
            .nullable(null),
        Review: Yup.string()
            .required('Review is required!')
            .min(150),
        FirstQuestion: Yup.string()
            .required('Answer is required!'),
        SecondQuestion: Yup.string()
            .required('Answer is required!'),
        ThirdQuestion: Yup.string()
            .required('Answer is required!'),
        ForthQuestion: Yup.string()
            .required('Answer is required!'),
        FifthQuestion: Yup.string()
            .required('Answer is required!'),
    }),
    handleSubmit: (values, { setSubmitting, props }) => {
        props.save(values)
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                if (json.success) {
                    props.onGetProductRelated(values.Id, json.msg);
                } else
                    swal("Oops!", json.msg, "error")
            })
            .then(setSubmitting(false)).catch((error) => {
                setSubmitting(false);
            });
    },
});

const MyEnhancedForm = formikEnhancer(ReviewProduct);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, HeadLine: '', Rating: 0, Review: '', Result: [], };

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            friendlyURL: this.props.match.params.friendlyURL,
            model: Object.assign(schema, baseSchema),
            productId: 0,
        }

        this.handleReset = this.handleReset.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleGetProductRelated = this.handleGetProductRelated.bind(this);
    }

    componentDidMount() {
        let friendlyURL = this.state.friendlyURL;
        if (friendlyURL) {
            getProductByUrlFriendly(friendlyURL)
                .then(res => res.json().then(json => ({ res, json })))
                .then(({ res, json }) => {
                    var temp = json;
                    temp.Result = [];
                    temp.HeadLine = "";
                    temp.Review = "";
                    temp.Rating = 0;
                    temp.FirstQuestion = "";
                    temp.SecondQuestion = "";
                    temp.ThirdQuestion = "";
                    temp.ForthQuestion = "";
                    temp.FifthQuestion = "";
                    temp.CommunityMemberId = 0;
                    this.setState({ model: temp });
                });
        }
    }

    handleReset() {
        this.setState({
            model: Object.assign(schema, baseSchema)
        });
    }

    handleSave(values) {
        var data = values.Result;

        if (data.length < 9) {
            //adding HeadLine
            var obj = {};
            obj.ProductId = values.Id;
            obj.CommunityMemberId = values.CommunityMemberId;
            obj.ReviewQuestionId = 1;
            obj.Answer = values.HeadLine;

            //adding Review
            var obj2 = {};
            obj2.ProductId = values.Id;
            obj2.CommunityMemberId = values.CommunityMemberId;
            obj2.ReviewQuestionId = 9;
            obj2.Answer = values.Review;

            data.push(obj);
            data.push(obj2);

            //adding photo
            if (typeof values.imageSelected !== 'undefined') {
                var obj3 = {};
                obj3.ProductId = values.Id;
                obj3.CommunityMemberId = values.CommunityMemberId;
                obj3.ReviewQuestionId = 14;
                obj3.Image = values.imageSelected.replace('data:image/jpeg;base64,', '');

                data.push(obj3);
            }
        }
        return saveReviewWebSite(data);
    }

    handleGetProductRelated(productId, message) {
        var product = {};
        product.Id = productId;
        product.AgeTargetStartsId = this.state.model.AgeTargetStartsId;
        product.AgeTargetEndsId = this.state.model.AgeTargetEndsId;
        product.GenderTargetId = this.state.model.GenderTargetId;
        product.QuantityPerPage = 6;

        var categoryList = [];
        for (var i = 0; i < this.state.model.ProductCategory.length; i++) {
            categoryList.push({ CategoryId: this.state.model.ProductCategory[i].CategoryId });
        }

        product.ProductCategory = categoryList;

        this.props.history.push(
            {
                pathname: '/Dashboard/CommunityMember/ReviewSuccess/' + this.state.friendlyURL,
                state: { product: product, message: message }
            }
        )
    }

    render() {
        return (
            <div>
                <MyEnhancedForm
                    model={this.state.model}
                    save={this.handleSave}
                    reset={this.handleReset}
                    history={this.props.history}
                    onGetProductRelated={this.handleGetProductRelated} />
            </div>
        );
    }
}

export default Form;