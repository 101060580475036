import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Select from 'react-select';

import * as MyConstantsFile from '../../../util/MyConstantsFile'
import FormTemplate from '../_ComponentHelper/FormTemplate'
import { getState, saveState } from '../../../service/State';

import { getAllCountry } from '../../../service/Country';



class MyForm extends Component {
	constructor(props) {
		super(props);
			this.state = {
			listCountry: [],
			CountryId: null,
			
		};

    }

    componentDidMount() {
			getAllCountry()
				.then(res => res.json()
					.then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ listCountry: json });
				})
				.catch(console.log);

			 
		}

    
    handleChangeCountry = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.CountryId = selectedOption.value;
			this.setState({ countryOption: selectedOption });
		} else {
			this.props.values.CountryId = null;
			this.setState({
				CountryId: null,
			});
		}
	}
	
	

    componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

    render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
		} = this.props;


    const listCountryValue = this.state.listCountry.map((item) => {
		return { value: item.Id, label: item.CountryName}
	 })
		
	
        

    return (
			<form onSubmit={handleSubmit}>
				<div className={touched.CountryId && errors.CountryId ? "form-group has-error" : "form-group" }>
					<label className="form-label" htmlFor="CountryId">Country</label>
					<div className="input-width-icon right">
						<i></i>
						<Select value={values.CountryId} onChange={this.handleChangeCountry} options={listCountryValue} />
					</div>
					{errors.CountryId && touched.CountryId && <span className="text-danger">{errors.CountryId}</span>}
				</div>

				
            
                <div className={touched.StateName && errors.StateName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="StateName">State Name</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="StateName" className="form-control" value={values.StateName} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.StateName && touched.StateName && <span className="text-danger">{errors.StateName}</span>}
				</div>


										<div className={touched.Abbreviation && errors.Abbreviation ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Abbreviation">Abbreviation</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="Abbreviation" className="form-control" value={values.Abbreviation} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Abbreviation && touched.Abbreviation && <span className="text-danger">{errors.Abbreviation}</span>}
				</div>


										

            <div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}
						disabled={isSubmitting}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>

			</form>
		);
	}
}







const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({

        CountryId: Yup.number()
			.required('Country is required!')
			.nullable(true),
		
		

        StateName: Yup.string()
			.trim()
			.required('StateName is required!'),
		Abbreviation: Yup.string()
			.trim()
			.required('Abbreviation is required!'),
		
}),

	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSave(values)
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (json.success)
					props.onLoadNextPage()
			})
			.catch((error) => {
				setSubmitting(false);
			});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, CountryId: 0, StateName: '', Abbreviation: '',  };

class StateForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema)
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleLoadNextPage = this.handleLoadNextPage.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id) {
		    getState(id)
			    .then(res => res.json().then(json => ({ res, json })))
			    .then(({ res, json }) => {
		            this.setState({ model: json });
		    });
        }
    }

    handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
        });
    }


    handleSave(values) {
		return saveState(values);
    }



    handleLoadNextPage() {
		this.props.history.push(MyConstantsFile.APP_ADMIN_STATE_PATH);
	}

		render() {
		return (
			<FormTemplate
				pageName={MyConstantsFile.PAGE_NAME_STATE}
				pageListDisplayPath={MyConstantsFile.APP_ADMIN_STATE_PATH}
				action={typeof this.state.id === 'undefined' ? 'Add' : 'Edit'}
			>
				<MyEnhancedForm model={this.state.model}
					onSave={this.handleSave}
					onReset={this.handleReset}
					onLoadNextPage={this.handleLoadNextPage}
				/>
			</FormTemplate>
		);
	}
}

export default StateForm;
