import React, { Component } from 'react';
import { CookiesProvider } from 'react-cookie';
import AppCookies from '../AppCookies';
import Sentry from '../Sentry';
import { Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const options = {
	timeout: 5000,
	position: "bottom center"
};

class AppRoot extends Component {
	render() {
		return (
			// <Sentry>
			<CookiesProvider>
				<Provider template={AlertTemplate} {...options}>
					<AppCookies />
				</Provider>
			</CookiesProvider>

			// </Sentry>
		);
	}
}

export default AppRoot;