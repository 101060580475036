import React from 'react';

const FilterMenuHeader = props => {
    return (
        <div className="row mt-3 mb-3">
            <img className="col-1" src={props.productImage}
                style={{ height: '80px' }} alt="Not available" />

            <div className='form-group col-3'>
                <label>Product Name</label>
                <h6 className='ml-2'><span className="help">{props.productName}</span></h6>
            </div>

            <div className='form-group col'>
                <label>Target Age Group <span className="help">(From)</span></label>
                <h6 className='ml-2'><span className="help">{props.targetAgeFrom}</span></h6>
            </div>

            <div className='form-group col'>
                <label>Target Age Group <span className="help">(To)</span></label>
                <h6 className='ml-2'><span className="help">{props.targetAgeTo}</span></h6>
            </div>

            <div className='form-group col'>
                <label>Gender Target</label>
                <h6 className='ml-2'><span className="help">{props.genderTargetName}</span></h6>
            </div>

            <div className='form-group col-2'>
                <div className='row'>
                    <p className="text-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={props.onHandleAutoMagic}
                    >Auto Magic</p>
                </div>
                <div className='row mt-3'>
                    <p className="text-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={props.onHandleCurrentCommunityMember}
                    >Invited</p>
                </div>
                <div className='row mt-3'>
                    <p className="text-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={props.onHandleHideFilterChild}
                    >Close Filter</p>
                </div>
            </div>
        </div>
    );
};

export default FilterMenuHeader