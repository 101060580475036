import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { editPassword } from '../../../service/Manufacturer';
import Auth from '../../../util/Auth';
import { getSystemMessage } from '../../../service/SystemMessage';
import swal from 'sweetalert'

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class MyForm extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleBlur,
			handleChange,
			handleSubmit,
		} = this.props;

		return (
			<form onSubmit={handleSubmit}>
				<div className={touched.PasswordCurrent && errors.PasswordCurrent ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="PasswordCurrent">Current Password *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="password" id="PasswordCurrent" className="form-control" value={values.PasswordCurrent} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.PasswordCurrent && touched.PasswordCurrent && <span className="text-danger">{errors.PasswordCurrent}</span>}
				</div>

				<div className={touched.Password && errors.Password ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Password">Create Password *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="password" id="Password" className="form-control" value={values.Password} onChange={handleChange} onBlur={handleBlur} />
					</div>
					(Password must contain at least 8 characters and must contain at least one capital letter and one numeric character.)<br />
					{errors.Password && touched.Password && <span className="text-danger">{errors.Password}</span>}
				</div>

				<div className={touched.ConfirmPassword && errors.ConfirmPassword ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="ConfirmPassword">Confirm your password *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="password" id="ConfirmPassword" className="form-control" value={values.ConfirmPassword} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.ConfirmPassword && touched.ConfirmPassword && <span className="text-danger">{errors.ConfirmPassword}</span>}
				</div>

				<div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>
			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		PasswordCurrent: Yup.string()
			.min(8, 'Password has to be longer than 8 characters!')
			.required('Password is required!')
			.matches(/[a-z]/, 'Lowercase character required!')
			.matches(/[A-Z]/, 'Uppercase character required!'),
		Password: Yup.string()
			.min(8, 'Password has to be longer than 8 characters!')
			.required('Password is required!')
			.matches(/[a-z]/, 'Lowercase character required!')
			.matches(/[A-Z]/, 'Uppercase character required!'),
		ConfirmPassword: Yup.string()
			.oneOf([Yup.ref('Password'), null], "Passwords don't match")
			.required('Password is required!'),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSave(values).catch((error) => {
			setSubmitting(false);
		});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = {
	PasswordCurrent: "", Password: "", ConfirmPassword: "",
};

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			couponCode: Auth.getCoupon(),
			model: Object.assign(schema, baseSchema)
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {
		return editPassword(values).then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (json.success) {
					getSystemMessage("passwordhasbeenchanged").then(res => res.json().then(json => ({ res, json })))
						.then(({ res, json }) => {
							swal({
								title: "Password changed",
								text: json,
								icon: "success",
								dangerMode: true,
							}).then((result) => {
								if (result) {
									this.props.history.push(MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_PATH);
								}
							});
						});
				} else {
					getSystemMessage("faileditpassword").then(res => res.json().then(json => ({ res, json })))
						.then(({ res, json }) => {
							swal({
								title: "Notice",
								text: json,
								// icon: "warning",
							})
						});
				}
			}
			);
	}

	render() {
		return (
			<div>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" role="tabList">
							<li className="active"><a href="#tab1EditUser" role="tab" data-toggle="tab">Edit your Password</a></li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="tab1EditUser">
								<MyEnhancedForm model={this.state.model} onSave={this.handleSave} reset={this.handleReset} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Form;
