import React, { Component } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';

class MultipleEmails extends Component {
  constructor(props){
    super(props)
    this.state = {
      emails: [],
    };
  }

  render() {
    const { emails } = this.state;

    return (
      <div>
        <h6>Invite as many people as you would like. Simply enter their emails below:</h6>
        <ReactMultiEmail
          placeholder='Type or paste as many emails you want!'
          emails={emails}
          onChange={emails => {
            this.props.setEmailList(emails)
            this.setState({ emails: emails })
          }}
          getLabel={(
            email,
            index,
            removeEmail,
          ) => {
            return (
              <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        />
        {/* <br />
        <h4>react-multi-email value</h4>
        <p>{emails.join(', ') || 'empty'}</p> */}
      </div>
    );
  }
}

export default MultipleEmails;