import React, { Component } from 'react';
import swal from 'sweetalert'
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { CSVLink } from "react-csv";

import CommunityMemberInfoModal from './CommunityMemberInfoModal';
import TableEditable from '../_ComponentHelper/TableEditable';
import { deleteCommunityMemberProduct, editCommunityMemberProduct } from '../../../service/CommunityMemberProduct';

class ListInvited extends Component {

	constructor(props) {
		super(props);

		this.state = {
			data: this.props.invitedList,
		}
		this.deleteAssignedTester = this.deleteAssignedTester.bind(this);
		this.saveTrackingNumber = this.saveTrackingNumber.bind(this);
	}

	deleteAssignedTester = (id, firstName, dataId) => {
		swal({
			title: "Please confirm",
			text: "Are you sure that you want to remove " + firstName + "?",
			buttons: true,
			dangerMode: true,
		}).then((result) => {
			if (result) {
				deleteCommunityMemberProduct(id)
					.then(res => {
						if (res.ok)
							this.setState({
								data: this.state.data.filter(item => item.Id !== dataId)
							});
					});
			}
		});
	}

	saveTrackingNumber(childrenValue, index) {
		var updateData = this.state.data[index].CommunityMemberProduct.filter(item => item.ProductId === this.props.productId);
		updateData[0].TrackingNumber = childrenValue;

		editCommunityMemberProduct(updateData[0])
			.then(res => {
				if (res.ok) {
					swal({
						title: "Data Changed!",
						icon: "success",
					});

					this.setState(prevState => {
						const data = prevState.data.map((item, i) => {
							if (index === i) {
								return updateData[0];
							}
							return item;
						});
						return data;
					});
				}
			});
	}

	render() {

		const data = this.state.data.map(item => {
			const matchingProduct = item.CommunityMemberProduct.filter(item2 => item2.ProductId === this.props.productId)[0];
			return {
				Id: item.Id,
				StatusAccepted: matchingProduct.Accepted == null ?
					'Pending' : matchingProduct.Accepted ? "Accepted" : "Declined",
				PickUp: item.PickUp ? 'Yes' : 'No',
				FirstName: item.FirstName,
				LastName: item.LastName,
				Email: item.Email,
				Address: item.Street,
				Address2: item.Apartment,
				City: item.City,
				State: item.State ? item.State.StateName : '',
				PostalCode: item.PostalCode,
				Country: item.Country ? item.Country.CountryName : '',
				PrimaryPhone: item.PrimaryPhone,
				CommunityMemberChildren: item.CommunityMemberChildren,
				DOB: item.DOB,
				QuantityProductTested: item.CommunityMemberProduct.filter(item => item.Accepted).length,
				CommunityMemberProduct: item.CommunityMemberProduct,
				ExpectingKidDueDate: item.ExpectingKidDueDate,
				ExpectingKid: item.ExpectingKid,
				CommunityMemberProductId: matchingProduct.Id,
				TrackingNumber: matchingProduct.TrackingNumber,
				Status: matchingProduct.SurveyCompleted ? 'Completed' : matchingProduct.Received ? 'Received' : matchingProduct.TrackingNumber ? 'Shipped' : 'Pending'
			}
		});

		var headerExport = [
			{ label: "Product Name", key: "productName" },
			{ label: "First Name", key: "firstName" },
			{ label: "Last Name", key: "lastName" },
			{ label: "Email", key: "email" },
			{ label: "Primary Phone", key: "primaryPhone" },
			{ label: "Address1", key: "address1" },
			{ label: "Address2", key: "address2" },
			{ label: "City", key: "city" },
			{ label: "Postal Code", key: "postalCode" },
			{ label: "State", key: "state" },
			{ label: "Country", key: "country" },
			{ label: "Pickup", key: "pickUp" },
			{ label: "Status Accept", key: "statusAccept" },
			{ label: "TrackingNumber", key: "trackingNumber" },
			{ label: "Status", key: "status" },
		]

		const dataExport = data.filter(item => item.StatusAccepted === 'Accepted').map(item => {
			return {
				productName: this.props.productName,
				firstName: item.FirstName,
				lastName: item.LastName,
				email: item.Email,
				primaryPhone: item.PrimaryPhone,
				address1: item.Address,
				address2: item.Address2,
				city: item.City,
				postalCode: item.PostalCode,
				state: item.State,
				country: item.Country,
				pickUp: item.PickUp,
				statusAccept: item.StatusAccepted,
				trackingNumber: item.TrackingNumber,
				status: item.Status,
			}
		})

		const columns = [
			{
				id: 'Id',
				Header: 'ID',
				accessor: 'Id',
				width: 70,
			}, {
				id: 'StatusAccepted',
				Header: 'STATUS ACCEPTED',
				accessor: 'StatusAccepted',
				filterMethod: (filter, row) => {
					if (filter.value === "true") {
						return row.StatusAccepted === "Accepted";
					}
					return true;
				},
				Filter: ({ filter, onChange }) =>
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="all">Show All</option>
						<option value="true">Accepted</option>
					</select>,
				width: 80,
			}, {
				id: 'PickUp',
				Header: 'PICKUP',
				accessor: 'PickUp',
				filterMethod: (filter, row) => {
					if (filter.value === "all") {
						return true;
					}
					if (filter.value === "true") {
						return row.PickUp === "Yes";
					}
					return row.PickUp === "No";
				},
				Filter: ({ filter, onChange }) =>
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="all">Show All</option>
						<option value="true">Yes</option>
						<option value="false">No</option>
					</select>,
				width: 80,
			}, {
				id: 'FirstName',
				Header: 'FIRST NAME',
				accessor: 'FirstName',
				Cell: row => (<CommunityMemberInfoModal rowInfo={row.original} />
				),
				width: 100,
			}, {
				id: 'LastName',
				Header: 'LAST NAME',
				accessor: 'LastName',
				width: 100,
			}, {
				id: 'Email',
				Header: 'EMAIL',
				accessor: 'Email',
				width: 200,
			}, {
				id: 'City',
				Header: 'CITY',
				accessor: 'City',
				width: 110,
			}, {
				id: 'State',
				Header: 'STATE',
				accessor: 'State',
				width: 110,
			}, {
				id: 'Country',
				Header: 'COUNTRY',
				accessor: 'Country',
				width: 100,
			}, {
				id: 'TrackingNumber',
				Header: 'TRACKING NUMBER',
				accessor: 'TrackingNumber',
				Cell: row => (
					<TableEditable
						value={row.value}
						index={row.index}
						changingLabelName={row.original.FirstName}
						type="number"
						style={{ padding: '5px', width: '170px' }}
						onHandlerSave={this.saveTrackingNumber} />
				),
				width: 90,
				filterable: false,
			}, {
				id: 'Status',
				Header: 'STATUS',
				accessor: 'Status',
				width: 90,
				filterable: false,
			}, {
				Header: 'ACTIONS',
				width: 80,
				Cell: row => (
					<div>
						{row.original.StatusAccepted === 'Accepted' && <span
							id={row.original.Id}
							onClick={() => this.deleteAssignedTester(row.original.CommunityMemberProductId, row.original.FirstName, row.original.Id)}
							title="Unassign tester"
							className="mouseDelete"><i className="fa fa-trash"></i></span>}
					</div >
				),
				filterable: false,
			}]

		return (
			<div className="align-items-center">
				<div className="col-12">
					<div className="grid simple horizontal">
						<div className="blockDisplay grid-title.no-border-top">
							<div className="product-template-fullscreen border-0 mb-3 container">

								<ReactTable
									data={data}
									columns={columns}
									minRows={1}
									filterable
									defaultFilterMethod={(filter, row) =>
										String(row[filter.id]) === filter.value}
									defaultPageSize={10}
									className="-striped -highlight"
									defaultSorted={[
										{
											id: "StatusAccepted",
											desc: false
										}
									]}
								/>

								<div className="mt-1">
									<div className="pull-right">
										<CSVLink className="btn btn-danger btn-cons" data={dataExport} headers={headerExport} filename={this.props.productName + ".csv"}>
											Export Table
										</CSVLink>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ListInvited;