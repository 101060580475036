import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Popup from "reactjs-popup";

class UploadSingleImagesHelper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			crop: {
				x: 0,
				y: 0,
				width: 100,
				height: 100,
			},
			loadFromDataBase: false,
			imagePreview: '',
			pixelCrop: null,
		};
		this.handleImageLoaded = this.handleImageLoaded.bind(this)
		this.handleImageChange = this.handleImageChange.bind(this);
		this.handleCropChange = this.handleCropChange.bind(this);
		this.handleCropComplete = this.handleCropComplete.bind(this);
		this.handleCropSave = this.handleCropSave.bind(this);
		// this.handleDeletePhoto = this.handleDeletePhoto.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.state !== nextState;
	}

	handleCropSave() {
		var cropped = this.getCroppedImg(this.state.image, this.state.pixelCrop);

		this.props.onHandleImageUpdate(cropped);
	}

	handleImageLoaded(image) {
		this.setState({ image });
	}

	handleImageChange(e) {
		const imageType = /^image\//;
		const file = e.target.files.item(0);

		if (!file || !imageType.test(file.type)) {
			return;
		}

		const reader = new FileReader();

		reader.onload = (data) => {
			let dataUrl = data.target.result;
			this.setState({ imagePreview: dataUrl });
		};

		reader.readAsDataURL(file);
	}

	handleCropChange(crop) {
		this.setState({ crop });
	}

	handleCropComplete(crop, pixelCrop) {
		this.setState({ pixelCrop });
	}

	getCroppedImg(image, pixelCrop, fileName) {
		const canvas = document.createElement('canvas');

		if (!pixelCrop) {
			pixelCrop = {}
			pixelCrop.x = 0;
			pixelCrop.y = 0;
			pixelCrop.width = image.naturalWidth;
			pixelCrop.height = image.naturalHeight;
		}

		var width;
		var height;

		if (this.props.imageSize === 'fullSize') {
			width = pixelCrop.width;
			height = pixelCrop.height;
		} else {
			var MAX_WIDTH = 700;
			var MAX_HEIGHT = 700;
			width = pixelCrop.width;
			height = pixelCrop.height;

			if (width > height) {
				if (width > MAX_WIDTH) {
					height *= MAX_WIDTH / width;
					width = MAX_WIDTH;
				}
			} else {
				if (height > MAX_HEIGHT) {
					width *= MAX_HEIGHT / height;
					height = MAX_HEIGHT;
				}
			}
		}

		canvas.width = width;
		canvas.height = height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			pixelCrop.x,
			pixelCrop.y,
			pixelCrop.width - 2,
			pixelCrop.height,
			0,
			0,
			width,
			height
		);

		// As Base64 string
		const base64Image = canvas.toDataURL('image/jpeg');

		return base64Image;
	}

	// handleDeletePhoto(event) {
	// 	var id = parseInt(event.currentTarget.id);
	// 	swal({
	// 		title: "Please confirm",
	// 		text: "Are you sure that you want to delete this record?",
	// 		icon: "warning",
	// 		buttons: true,
	// 		dangerMode: true,
	// 	}).then((result) => {
	// 		if (result) {
	// 			// deleteProductPhoto(id).then(() => {
	// 			// 	for (var i = 0; i < this.state.list.length; i++) {
	// 			// 		if (this.state.list[i].Id == id) {
	// 			// 			this.state.list.splice(i, 1);
	// 			// 			this.setState({ list: this.state.list })
	// 			// 			break;
	// 			// 		}
	// 			// 	}
	// 			// });
	// 		}
	// 	});
	// }

	openModal() {
		this.setState({
			crop: {
				x: 0,
				y: 0,
				width: 100,
				height: 100,
			},
			open: true,
			imagePreview: '',
			pixelCrop: null,
		});
	};

	closeModal() {
		this.setState({ open: false });
	};

	render() {
		const labelName = this.props.labelName ? this.props.labelName : 'Upload Image';

		return (
			<div>
				<label className="btn btn-small btn-primary mb-4" onClick={this.openModal}>
					{`${labelName}`}
				</label>

				<Popup
					open={this.state.open}
					closeOnDocumentClick={false}
					closeOnEscape={false}
					onClose={this.closeModal}
					modal>
					{close => (
						<div className="style-test style-modal">
							<span className="close" onClick={this.closeModal}>
								&times;
        								</span>

							<div className="modal-header">
								<h4 className="modal-title">{`${labelName}`}</h4>
							</div>
							<div className="modal-body">
								<div>
									<input type="file" id="file-picker" accept=".jpg" onChange={this.handleImageChange} style={{ height: '40px !important' }} />
								</div>
								<div className="mt-2">
									<ReactCrop
										crop={this.state.crop}
										src={this.state.imagePreview}
										onImageLoaded={this.handleImageLoaded}
										onComplete={this.handleCropComplete}
										onChange={(crop) => this.setState({ crop })}
									/>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" disabled={!this.state.imagePreview || this.state.imagePreview === ''} className="btn btn-primary"
									onClick={(e) => {
										this.handleCropSave(e);
										this.closeModal();
									}}>Save</button>
							</div>

						</div>
					)}
				</Popup>
			</div>
		);
	}
}

export default UploadSingleImagesHelper;