import React from 'react';
import ReactDOM from 'react-dom';
import * as Raven from 'raven-js';
// import './index.css';
import AppRoot from './components/Expresso/AppRoot';
import { unregister } from './registerServiceWorker';

if (process.env.NODE_ENV !== 'production') {
    const {whyDidYouUpdate} = require('why-did-you-update');
    whyDidYouUpdate(React);
  }
  
Raven
    .config('https://acf6b3ec62174af4abd3e73e4be15b8c@sentry.io/1304882')
    .install();

unregister();
ReactDOM.render(<AppRoot />, document.getElementById('root'));
