import React, { Component } from 'react';

import moment from 'moment';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import StarRatings from 'react-star-ratings';
import Progress from 'react-progressbar';
import ShowMoreText from 'react-show-more-text';
import scrollToComponent from 'react-scroll-to-component';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

import { getProductByUrlFriendly, getProductRelatedList } from '../../../service/Product';
import { getListIdProductPaged } from '../../../service/ReviewWebSite';
import { getListIdProduct } from '../../../service/Review';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

var allProductPhotosSlider, thumbItem, categoryImage

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: null,
            allReviewsByProduct: [],
            allTestersReview: [],
            friendlyURL: this.props.match.params.friendlyURL,
            currentIndex: 1,
            responsive: {
                0: { items: 1 },
                600: { items: 1 },
                1024: { items: 1 },
            },
            showContent: 'MemberReviews',
            productRelatedList: [],
            loadProductRelated: false,
            loadTestersReview: false,
            loadProductInfo: false,
            loadCategoryImage: false,
            organizedArrayReviews: [],
            page: 0,
            pageSize: 80,
            filtered: [],
            pages: null,
            countAmazing: 0,
            countAverage: 0,
            countPoor: 0,
            countLovedIt: 0,
            countLikedIt: 0,
            countHatedIt: 0,
            sumStarMemberReview: 0,
            sumStarTesterReview: 0,
            recommended: 0,
            countQualityQuestion: 0,
            countImpressionQuestion: 0,
            styleCategoriImage: window.innerWidth > 900 ? { position: 'absolute', textAlign: 'center', left: '0', right: '0', top: '40px' }
                : { position: 'absolute', textAlign: 'center', left: '0', right: '0', top: '-15px' }
        }
        this.getProductRelated = this.getProductRelated.bind(this);
        this.loadProductInfo = this.loadProductInfo.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.requestData = this.requestData.bind(this);
        this.getListIdProduct = this.getListIdProduct.bind(this);
    }

    componentDidMount() {
        let friendlyURL = this.state.friendlyURL;
        if (friendlyURL) {
            getProductByUrlFriendly(friendlyURL)
                .then(res => res.json().then(json => ({ res, json })))
                .then(({ res, json }) => {
                    this.setState({
                        product: json,
                        filtered: { ProductId: json.Id }
                    }, () =>
                            this.fetchData(this.state)
                    )
                });
        }
    }

    getListIdProduct(id) {
        getListIdProduct(id)
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                this.setState({
                    allTestersReview: json.reviewList,
                    sumStarTesterReview: json.SumStarTesterReview,
                    loadTestersReview: true,
                })
            });
    }

    getProductRelated() {
        getProductRelatedList(this.state.product.Id)
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                this.setState({
                    productRelatedList: json,
                    loadProductRelated: true,
                })
            });
    }

    loadProductInfo(time) {
        this.state.loadProductInfo = true

        var allProductPhotos = []

        allProductPhotos.push(this.state.product.LargeImage)
        this.state.product.ProductPhoto.forEach(element => {
            allProductPhotos.push(element.Photo)
        })

        allProductPhotosSlider = allProductPhotos.map((item, i) => (
            <div key={`key-${i}`} className="row justify-content-center m-0"><img className="img-fluid attachment-shop_single size-shop_single wp-post-image" style={{ maxWidth: '100%', maxHeight: '500px' }} src={`${STORAGE_URL}product/${item}?${time}`} /></div>
        ));

        thumbItem = allProductPhotos.map((item, i) => (
            <li style={{ display: 'inline' }} key={i} onClick={() => this.Carousel._onDotClick(i)}><img className="img-fluid" style={{ width: '13%' }} src={`${STORAGE_URL}product/${item}?${time}`} /></li>
        ))
    }

    fetchData(state, instance) {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        this.requestData(
            this.state,
            state.pageSize,
            state.page,
            state.filtered
        ).then(res => {
            // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
            this.setState({
                allReviewsByProduct: res.rows,
                pages: res.pages,
                countAmazing: res.countAmazing,
                countAverage: res.countAverage,
                countPoor: res.countPoor,
                countLovedIt: res.countLovedIt,
                countLikedIt: res.countLikedIt,
                countHatedIt: res.countHatedIt,
                sumStarMemberReview: res.sumStarMemberReview,
                recommended: res.recommended,
                countQualityQuestion: res.countQualityQuestion,
                countImpressionQuestion: res.countImpressionQuestion,
            });
        });
    }

    requestData = (currentState, pageSize, page, filtered) => {
        return new Promise((resolve, reject) => {
            // You can retrieve your data however you want, in this case, we will just use some local data.
            var tableModal = {
                QuantityPerPage: pageSize,
                Page: page + 1,
                ProductId: filtered.ProductId,
            }

            getListIdProductPaged(tableModal)
                .then(res => res.json().then(json => ({ res, json })))
                .then(({ res, json }) => {
                    let filteredData = json;

                    // You must return an object containing the rows of the current page, and optionally the total pages number.
                    const result = {
                        rows: filteredData.group,
                        pages: filteredData.quantity ? filteredData.Quantity : 0,
                        countAmazing: filteredData.Amazing,
                        countAverage: filteredData.Average,
                        countPoor: filteredData.Poor,
                        countLovedIt: filteredData.LovedIt,
                        countLikedIt: filteredData.LikedIt,
                        countHatedIt: filteredData.HatedIt,
                        sumStarMemberReview: filteredData.SumStarMemberReview,
                        recommended: filteredData.Recommended,
                        countQualityQuestion: filteredData.Amazing + filteredData.Average + filteredData.Poor,
                        countImpressionQuestion: filteredData.LovedIt + filteredData.LikedIt + filteredData.HatedIt,
                    };
                    resolve(result);
                });
        });
    };

    htmlDecode(input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    render() {
        const now = new Date();
        const time = moment(now).format('YYYYMMDDHHmmss');

        if (!this.state.loadCategoryImage && this.state.product) {
            this.state.loadCategoryImage = true

            categoryImage =
                this.state.product.ProductCategory ?
                    `${STORAGE_URL}category/${this.state.product.ProductCategory[0].Category.CategoryLargeImage}?${time}` : ''
        }

        if (!this.state.loadProductInfo && this.state.product)
            this.loadProductInfo(time)

        if (!this.state.loadTestersReview && this.state.product && this.state.product.Id)
            this.getListIdProduct(this.state.product.Id)

        //user review
        const memberReviewsValues = this.state.allReviewsByProduct.map((item) => (
            <div className="wpb_column vc_column_container allReviews col-sm-12 ng-scope">
                <div>
                    <div className="wpb_column vc_column_container col-sm-3 extraMarginBottom">
                        {/* TODO: change later to member and tester review */}
                        <div className="extraMarginBottom" style={{ fontFamily: 'Poppins' }}>PTPA Member Review</div>

                        <img src={item[0].CommunityMember.ProfileImage !== null && item[0].CommunityMember.ProfileImage !== '' ?
                            `${STORAGE_URL}member/${item[0].CommunityMember.ProfileImage}?${time}`
                            : item[0].CommunityMember.CommunityMemberGenderTargetId == 1 ? '/assets/img/FemaleProfile.png'
                                : item[0].CommunityMember.CommunityMemberGenderTargetId == 2 ? '/assets/img/MaleProfile.png'
                                    : '/assets/img/OtherProfile.png'} style={{ width: '100px', borderRadius: '50%' }} /><br />

                        <div><span className="product_item--title"><b style={{ fontFamily: 'Poppins' }}>{item[0].CommunityMember.FirstName}</b></span></div>

                        {item[0].CommunityMember.State && <div style={{ fontFamily: 'Poppins' }}>
                            {item[0].CommunityMember.City}<span>/</span>{item[0].CommunityMember.State.StateName}
                        </div>}

                        <div>
                            <StarRatings
                                id="Rating"
                                rating={item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 2).length > 0 ?
                                    parseInt(item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 2)[0].Answer) : 0}
                                starRatedColor="orange"
                                numberOfStars={5}
                                name='rating'
                                starDimension="25px"
                                starSpacing="2px"
                            />
                        </div>

                        {item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 5).length > 0 && <div style={{ fontFamily: 'Poppins' }}>
                            {item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 5)[0].Answer}
                        </div>}

                        {item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 5).length == 0
                            && item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 2).length > 0
                            && <div style={{ fontFamily: 'Poppins' }}>
                                {(item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 2)[0].Answer == "4"
                                    || item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 2)[0].Answer == "5") ? 'Highly Recommended' : 'Not Recommended'}
                            </div>}

                        {item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 6).length > 0 && <div style={{ fontFamily: 'Poppins' }}>
                            <span>Used for: </span>
                            {item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 6)[0].Answer.toLowerCase()}
                        </div>}

                    </div>

                    <div className="wpb_column vc_column_container col-sm-9">
                        <div>
                            <div className="titleAnwserReview"><span className="product_item--title"><b style={{ fontFamily: 'Poppins' }}>
                                {item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 1)[0] ?
                                    item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 1)[0].Answer : ""}
                            </b></span></div>
                        </div>
                        <div style={{ fontFamily: 'Poppins' }}>
                            {item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 9)[0] ?
                                item.filter(reviewTitle => reviewTitle.ReviewQuestionId == 9)[0].Answer : ""}
                        </div>
                    </div>
                </div>
            </div>
        ));

        //tester review
        const testerReviewsValues = this.state.allTestersReview.map((item) => (
            <div className="wpb_column vc_column_container allReviews col-sm-12 ng-scope">
                <div>
                    <div className="wpb_column vc_column_container col-sm-12">
                        <div style={{ fontFamily: 'Poppins' }}>PTPA Tester Review</div><br />
                        <div>
                            <div className="titleAnwserReview mb-0">
                                <span className="product_item--title">
                                    <b style={{ fontFamily: 'Poppins' }}>
                                        Review
                                    </b>
                                </span>
                            </div>
                        </div>
                        <div style={{ fontFamily: 'Poppins' }}>
                            {item.Testimonial}
                        </div>
                        <br />

                        <div>
                            <div className="titleAnwserReview mb-0">
                                <span className="product_item--title">
                                    <b style={{ fontFamily: 'Poppins' }}>
                                        Rating
                                    </b>
                                </span>
                            </div>
                        </div>
                        <div>
                            <StarRatings
                                id="Rating"
                                rating={item.StarRating}
                                starRatedColor="orange"
                                numberOfStars={5}
                                name='rating'
                                starDimension="25px"
                                starSpacing="2px"
                            />
                        </div>
                    </div>
                </div>
            </div>
        ));

        if (this.state.product && !this.state.loadProductRelated)
            this.getProductRelated();

        const productRelatedValue = this.state.productRelatedList.map((item) =>
            <li className="product_item grid-item product post-35 type-product status-publish has-post-thumbnail product_cat-demo product_cat-demo-01 product_cat-demo-02-top-sale product_cat-women product_tag-fashion first instock shipping-taxable purchasable product-type-simple thumb-has-effect prod-rating-off ng-scope" >
                <div className="product_item--inner">
                    <div className="product_item--thumbnail">
                        <div className="product_item--thumbnail-holder">
                            <a href={MyConstantsFile.APP_WEBSITE_PRODUCT_CALL_PATH + item.UrlFriendly} style={{ cursor: 'pointer' }} className="woocommerce-LoopProduct-link woocommerce-loop-product__link">
                                <img src={item.LargeImage ? `${STORAGE_URL}product/${item.LargeImage}?${time}` : ""} style={{ minHeight: '150px', maxWidth: '186px !important', display: 'block', marginLeft: 'auto', marginRight: 'auto', width: 'auto', height: 'auto', position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }} className="attachment-shop_catalog size-shop_catalog wp-post-image" alt={item.ProductName} />
                                <div className="item--overlay"></div>
                            </a>
                        </div>
                    </div>
                    <div className="product_item--info">
                        <div className="product_item--info-inner">
                            <a href={MyConstantsFile.APP_WEBSITE_PRODUCT_CALL_PATH + item.UrlFriendly} style={{ cursor: 'pointer' }}><span className="product_item--title" style={{ fontFamily: 'Poppins' }}><b>{item.ProductName}</b></span></a><br />
                            <a
                                // href={"https://ptpa.com/products/manufacturer-" + item.Id} 
                                style={{ cursor: 'pointer', fontFamily: 'Poppins' }}><span className="sku">MFG: {item.Manufacturer.CompanyName}</span></a>
                        </div>
                    </div>
                </div>
            </li>
        )

        return (
            <div>
                {this.state.product && <div>
                    <div className="text-center text-white" style={{ marginBottom: '50px' }}>
                        <img className="img-fluid" src={categoryImage} ></img>
                        <div style={this.state.styleCategoriImage}>
                            <h2 className="bold text-white" style={{ fontSize: '3vw', fontFamily: 'Poppins' }}>{this.state.product.ProductCategory[0].Category.CategoryName}</h2>

                            {window.innerWidth > 900 && <div className="la-breadcrumbs hidden-xs">
                                <div className="la-breadcrumb-content">
                                    <div className="la-breadcrumb-wrap">
                                        <div className="la-breadcrumb-item">
                                            <a href="https://ptpa.com/" className="la-breadcrumb-item-link is-home text-white" rel="home" title="Home"
                                                style={{ fontFamily: 'Poppins' }}>Home</a>
                                        </div>
                                        <div className="la-breadcrumb-item">
                                            <div className="la-breadcrumb-item-sep">/</div>
                                        </div>
                                        <div className="la-breadcrumb-item">
                                            <a target="_parent" href="javascript:window.history.back();">
                                                <span className="la-breadcrumb-item-target text-white" style={{ fontFamily: 'Poppins' }}>Products</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div id="main" className="site-main">
                        <div className="container">
                            <div className="row">
                                <main className="col-md-12 col-xs-12 site-content">
                                    <div className="site-content-inner">
                                        <div className="lazyra-page-content">
                                            <div className="la-p-single-wrap la-p-single-1 post-35 product type-product status-publish has-post-thumbnail product_cat-demo product_cat-demo-01 product_cat-demo-02-top-sale product_cat-women product_tag-fashion first instock shipping-taxable purchasable product-type-simple thumb-has-effect prod-rating-off">
                                                <div className="row la-single-product-page vc_row vc-has-modified" >
                                                    <div className="col-xs-12 col-sm-6">
                                                        <AliceCarousel
                                                            items={allProductPhotosSlider}
                                                            dotsDisabled={true}
                                                            buttonsDisabled={true}
                                                            ref={el => this.Carousel = el}
                                                            duration={400}
                                                            autoPlay={true}
                                                            fadeOutAnimation={true}
                                                            autoPlayInterval={3000}
                                                            autoPlayDirection="ltr"
                                                            responsive={this.responsive}
                                                            disableAutoPlayOnAction={true}
                                                        />
                                                        <ul style={{ listStyleType: 'none', cursor: 'pointer' }}>{thumbItem}</ul>
                                                    </div>

                                                    <div className="col-xs-12 col-sm-6 p-right product--summary">
                                                        <div className="la-custom-pright">
                                                            <div className="summary entry-summary">
                                                                <h1 className="product_title entry-title" style={{ fontFamily: 'Poppins' }}>{this.state.product.ProductName}</h1>

                                                                <div className="product_meta-top">
                                                                    <StarRatings
                                                                        id="Rating"
                                                                        className="form-control"
                                                                        rating={this.state.allReviewsByProduct.length + this.state.allTestersReview.length > 0 ?
                                                                            Math.round(((this.state.sumStarMemberReview + this.state.sumStarTesterReview) / (this.state.allReviewsByProduct.length + this.state.allTestersReview.length)) * 100) / 100 : 0}
                                                                        starRatedColor="orange"
                                                                        numberOfStars={5}
                                                                        name='rating'
                                                                        starDimension="25px"
                                                                        starSpacing="2px"
                                                                    />
                                                                </div>

                                                                {((this.state.product.PriceRetail !== null || this.state.product.PriceStarts !== null) &&
                                                                    (parseInt(this.state.product.PriceStarts) > 0 || parseInt(this.state.product.PriceRetail) > 0)
                                                                    ) && <div className="single-price-wrapper">
                                                                        <p className="price">
                                                                            <span className="woocommerce-Price-amount amount">
                                                                                <span className="woocommerce-Price-currencySymbol" style={{ fontFamily: 'Poppins' }}>${parseInt(this.state.product.PriceRetail) > 0 ?
                                                                                    this.state.product.PriceRetail : this.state.product.PriceStarts}</span>
                                                                            </span>
                                                                        </p>
                                                                    </div>}

                                                                <span onClick={() => window.open(this.state.product.Website.indexOf('http') > -1 ? this.state.product.Website : "http://" + this.state.product.Website, "_blank")}
                                                                    style={{ cursor: 'pointer', backgroundColor: '#343538', fontFamily: 'Poppins' }} className="single_add_to_cart_button button alt">
                                                                    Learn More
                                                                </span>

                                                                <div className="clearfix"></div>

                                                                <div className="woocommerce-product-details__short-description">
                                                                    <ShowMoreText
                                                                        lines={4}
                                                                        more='Show more'
                                                                        less='Show less'
                                                                        anchorclassName='text-warning'
                                                                    >
                                                                        <div style={{ fontFamily: 'Poppins' }} dangerouslySetInnerHTML={{ __html: this.htmlDecode(this.state.product.ProductDescription) }}></div>
                                                                    </ShowMoreText>
                                                                </div>

                                                                <div className="clearfix"></div>

                                                                <div className="product_meta">
                                                                    <span className="posted_in" style={{ fontFamily: 'Poppins' }}>
                                                                        Categories:
                                                                            <a style={{ cursor: 'pointer', fontFamily: 'Poppins' }} href={'https://ptpa.com/products/' + this.state.product.ProductCategory[0].Category.UrlFriendly}>
                                                                            <span className="vc_tta-title-text" style={{ letterSpacing: '1px', fontFamily: 'Poppins' }} >{this.state.product.ProductCategory[0].Category.CategoryName}</span>
                                                                        </a>
                                                                    </span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="vc_row-full-width vc_clearfix"></div>

                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <div className="la-wc-tabs-wrapper">
                                                            <div className="wc-tabs-outer">
                                                                <div className="woocommerce-tabs wc-tabs-wrapper">
                                                                    <ul className="tabs wc-tabs">
                                                                        <li className={this.state.showContent == 'Description' ? "description_tab active" : "description_tab"}>
                                                                            <a style={{ cursor: 'pointer', fontFamily: 'Poppins' }} onClick={() => { this.setState({ showContent: 'Description' }) }} name="readMore">Description</a>
                                                                        </li>

                                                                        {/* <li className={this.state.showContent == 'TesterReviews' ? "reviews_tab active" : "reviews_tab"} style={{ width: '270px' }}>
                                                                            <a style={{ cursor: 'pointer', fontFamily: 'Poppins', fontSize: '17px' }} onClick={() => scrollToComponent(this.TesterReview, { offset: 0, align: 'top', duration: 1500 })} name="readMore">Tester Reviews ({this.state.allTestersReview.length})</a>
                                                                        </li> */}

                                                                        <li className={this.state.showContent == 'MemberReviews' ? "reviews_tab active" : "reviews_tab"}>
                                                                            <a style={{ cursor: 'pointer', fontFamily: 'Poppins' }} onClick={() => { this.setState({ showContent: 'MemberReviews' }) }}>Reviews ({this.state.allReviewsByProduct.length + this.state.allTestersReview.length})</a>
                                                                        </li>

                                                                        <li className="description_tab">
                                                                            <a
                                                                                style={{ cursor: 'pointer', backgroundColor: '#ef5619', color: '#FFF', fontFamily: 'Poppins' }}
                                                                                href={'/Dashboard/CommunityMember/Review/' + this.state.friendlyURL} name="writeReview">Write a review</a>
                                                                        </li>
                                                                    </ul>

                                                                    {this.state.showContent == 'Description' && <div className="clearfix woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab active">
                                                                        <div className="tab-content">
                                                                            <div className="vc_row wpb_row vc_row-fluid la_fp_slide la_fp_child_section">
                                                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                                    <div className="vc_column-inner ">
                                                                                        <div className="wpb_wrapper">
                                                                                            <div className="js-el la-divider la-unit-responsive" >
                                                                                            </div>
                                                                                            <div className="wpb_text_column wpb_content_element ">
                                                                                                <div className="wpb_wrapper" style={{ fontSize: '14px', fontFamily: 'Poppins' }} dangerouslySetInnerHTML={{ __html: this.htmlDecode(this.state.product.ProductDescription) }}>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}

                                                                    {this.state.showContent == 'MemberReviews'
                                                                        && (this.state.allReviewsByProduct.length > 0 || this.state.allTestersReview.length > 0)
                                                                        && <div className="clearfix woocommerce-Tabs-panel woocommerce-Tabs-panel--reviews panel entry-content wc-tab active">
                                                                            <div className="woocommerce-Reviews">
                                                                                <div id="comments">
                                                                                    <div className="wpb_column vc_column_container allReviews col-sm-12 ng-scope" style={{ paddingBottom: '20px' }}>
                                                                                        <div className="wpb_column vc_column_container col-sm-4 summaryReview text-left">
                                                                                            <div className="averageReview"><h2></h2></div>
                                                                                            <div><h4 className="bold" style={{ fontFamily: 'Poppins' }}>{this.state.allReviewsByProduct.length + this.state.allTestersReview.length} customer reviews</h4></div>
                                                                                            <div className="row justify-content-start m-0 p-0 inline-item" style={{ lineHeight: '0px' }}>
                                                                                                <StarRatings
                                                                                                    id="Rating"
                                                                                                    rating={this.state.allReviewsByProduct.length + this.state.allTestersReview.length > 0 ?
                                                                                                        Math.round(((this.state.sumStarMemberReview + this.state.sumStarTesterReview) / (this.state.allReviewsByProduct.length + this.state.allTestersReview.length)) * 100) / 100 : 0}
                                                                                                    starRatedColor="orange"
                                                                                                    numberOfStars={5}
                                                                                                    name='rating'
                                                                                                    starDimension="25px"
                                                                                                    starSpacing="2px"
                                                                                                />
                                                                                                <p className="ml-1 mt-3">{this.state.allReviewsByProduct.length + this.state.allTestersReview.length > 0 ?
                                                                                                    Math.round(((this.state.sumStarMemberReview + this.state.sumStarTesterReview) / (this.state.allReviewsByProduct.length + this.state.allTestersReview.length)) * 100) / 100 : 0} out of 5 stars</p>
                                                                                            </div>
                                                                                            <h5 style={{ fontSize: '15px' }}>{this.state.recommended + this.state.allTestersReview.length} of {this.state.allReviewsByProduct.length + this.state.allTestersReview.length} reviewers recommend this</h5>
                                                                                        </div>
                                                                                        <div className="wpb_column vc_column_container col-sm-4">
                                                                                            <div className="titleAnwserReview">
                                                                                                <span className="product_item--title" style={{ fontFamily: 'Poppins' }}><b>Quality Rating</b></span>
                                                                                            </div>
                                                                                            <div className="wpb_column vc_column_container row ng-scope">
                                                                                                <div className="wpb_column vc_column_container col-sm-3" style={{ fontFamily: 'Poppins' }}>Amazing</div>

                                                                                                <Progress className="mt-2" color="orange" completed={100 * (this.state.countAmazing + this.state.allTestersReview.length) / (this.state.countQualityQuestion + this.state.allTestersReview.length)} style={{ backgroundColor: '#cfcfcf', width: '50%', height: '10px' }} />

                                                                                                <div className="wpb_column vc_column_container col-sm-3 textSummaryReview"><span style={{ fontFamily: 'Poppins' }}>{this.state.countAmazing + this.state.allTestersReview.length}/{this.state.countQualityQuestion + this.state.allTestersReview.length}</span></div>
                                                                                            </div>
                                                                                            <div className="wpb_column vc_column_container row ng-scope">
                                                                                                <div className="wpb_column vc_column_container col-sm-3" style={{ fontFamily: 'Poppins' }}>Average</div>

                                                                                                <Progress className="mt-2" color="orange" completed={100 * this.state.countAverage / (this.state.countQualityQuestion + this.state.allTestersReview.length)} style={{ backgroundColor: '#cfcfcf', width: '50%', height: '10px' }} />

                                                                                                <div className="wpb_column vc_column_container col-sm-3 textSummaryReview"><span style={{ fontFamily: 'Poppins' }}>{this.state.countAverage}/{this.state.countQualityQuestion + this.state.allTestersReview.length}</span></div>
                                                                                            </div>
                                                                                            <div className="wpb_column vc_column_container row ng-scope">
                                                                                                <div className="wpb_column vc_column_container col-sm-3" style={{ fontFamily: 'Poppins' }}>Poor</div>

                                                                                                <Progress className="mt-2" color="orange" completed={100 * this.state.countPoor / (this.state.countQualityQuestion + this.state.allTestersReview.length)} style={{ backgroundColor: '#cfcfcf', width: '50%', height: '10px' }} />

                                                                                                <div className="wpb_column vc_column_container col-sm-3 textSummaryReview"><span style={{ fontFamily: 'Poppins' }}>{this.state.countPoor}/{this.state.countQualityQuestion + this.state.allTestersReview.length}</span></div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="wpb_column vc_column_container col-sm-4">
                                                                                            <div className="titleAnwserReview">
                                                                                                <span className="product_item--title"><b style={{ fontFamily: 'Poppins' }}>First Reaction</b></span>
                                                                                            </div>
                                                                                            <div className="wpb_column vc_column_container row ng-scope">
                                                                                                <div className="wpb_column vc_column_container col-sm-3" style={{ fontFamily: 'Poppins' }}>Loved It</div>

                                                                                                <Progress className="mt-2" color="orange" completed={100 * (this.state.countLovedIt + this.state.allTestersReview.length) / (this.state.countImpressionQuestion + this.state.allTestersReview.length)} style={{ backgroundColor: '#cfcfcf', width: '50%', height: '10px' }} />

                                                                                                <div className="wpb_column vc_column_container col-sm-3 textSummaryReview"><span style={{ fontFamily: 'Poppins' }}>{this.state.countLovedIt + this.state.allTestersReview.length}/{this.state.countImpressionQuestion + this.state.allTestersReview.length}</span></div>
                                                                                            </div>
                                                                                            <div className="wpb_column vc_column_container row ng-scope">
                                                                                                <div className="wpb_column vc_column_container col-sm-3" style={{ fontFamily: 'Poppins' }}>Liked It</div>

                                                                                                <Progress className="mt-2" color="orange" completed={100 * this.state.countLikedIt / (this.state.countImpressionQuestion + this.state.allTestersReview.length)} style={{ backgroundColor: '#cfcfcf', width: '50%', height: '10px' }} />

                                                                                                <div className="wpb_column vc_column_container col-sm-3 textSummaryReview"><span style={{ fontFamily: 'Poppins' }}>{this.state.countLikedIt}/{this.state.countImpressionQuestion + this.state.allTestersReview.length}</span></div>
                                                                                            </div>
                                                                                            <div className="wpb_column vc_column_container row ng-scope">
                                                                                                <div className="wpb_column vc_column_container col-sm-3" style={{ fontFamily: 'Poppins' }}>Hated it</div>

                                                                                                <Progress className="mt-2" color="orange" completed={100 * this.state.countHatedIt / (this.state.countImpressionQuestion + this.state.allTestersReview.length)} style={{ backgroundColor: '#cfcfcf', width: '50%', height: '10px' }} />

                                                                                                <div className="wpb_column vc_column_container col-sm-3 textSummaryReview"><span style={{ fontFamily: 'Poppins' }}>{this.state.countHatedIt}/{this.state.countImpressionQuestion + this.state.allTestersReview.length}</span></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div>
                                                                                        {memberReviewsValues}
                                                                                    </div>

                                                                                    {/* <h1 ref={(section) => { this.TesterReview = section; }} className="border-bottom mt-5">
                                                                                        <span>
                                                                                            Rewards Available Exclusively For You
                                                                                        </span>
                                                                                    </h1> */}

                                                                                    {this.state.allTestersReview.length > 0 && <div className="text-center mb-5">
                                                                                        <img src="/assets/img/PTPAClubReviewsBanner.png" />
                                                                                    </div>}

                                                                                    <div>
                                                                                        {testerReviewsValues}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>}

                                                                    {/* {this.state.showContent == 'TesterReviews'
                                                                        && this.state.allReviewsByProduct.length > 0
                                                                        && <div className="clearfix woocommerce-Tabs-panel woocommerce-Tabs-panel--reviews panel entry-content wc-tab active">
                                                                            <div className="woocommerce-Reviews">
                                                                                <div id="comments">
                                                                                    <div className="wpb_column vc_column_container allReviews col-sm-12 ng-scope" style={{ paddingBottom: '20px' }}>
                                                                                        <h3>These reviews are from PTPA members that received products for free in exchange for their opinions.  Become a <a href="/Dashboard/CommunityMember/NewMember" style={{color: 'blue'}}>PTPA Club</a> member today!</h3>
                                                                                    </div>

                                                                                    {testerReviewsValues}

                                                                                </div>
                                                                            </div>
                                                                        </div>} */}
                                                                </div>
                                                            </div>

                                                            <div class="custom-product-wrap related">
                                                                <div className="custom-product-ul">
                                                                    <div className="row block_heading">
                                                                        <div className="col-xs-12 align-content-center">
                                                                            <h3 className="block_heading--title" style={{ fontFamily: 'Poppins' }}>Related Products</h3>
                                                                            <div className="block_heading--subtitle"></div>
                                                                        </div>
                                                                    </div><div className="row">
                                                                        <div className="col-xs-12">
                                                                            <ul className="products grid-items products-grid grid-space-default products-grid-1 xlg-grid-4-items lg-grid-4-items md-grid-3-items sm-grid-2-items xs-grid-1-items mb-grid-1-items">
                                                                                {productRelatedValue}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}

export default Product;