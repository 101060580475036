import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/Ethnicity`;


export const getAllEthnicity = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};



export const getAllEthnicityPaged = (tableModel) => {
	let url = `${endpoint}/GetAllPaged/`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};

export const getEthnicity = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveEthnicity = (ethnicity) => {
	let url = endpoint;
	let method = 'POST';
	
	if(ethnicity.Id && ethnicity.Id > 0)
	{
		url += `/${ethnicity.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(ethnicity)
	}, getFetchOpts()));
};

export const deleteEthnicity = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
