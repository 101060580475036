import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import CurrencyInput from 'react-currency-input';
import { getPackage, savePackage } from '../../../service/Package';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class MyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorState: EditorState.createEmpty(),
		};
		this.onEditorStateChange = this.onEditorStateChange.bind(this);
		this.changeToHTML = this.changeToHTML.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

	onEditorStateChange(editorState) {
		this.props.values.EditorText = editorState;
		this.setState({
			editorState,
		});
	};

	changeToHTML() {
		this.props.values.EditorText = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
		return this.props.values.EditorText;
	}

	render() {
		const {
			values,
			touched,
			errors,
			dirty,
			isSubmitting,
			handleBlur,
			handleChange,
			handleSubmit,
			reset,
		} = this.props;

		const created = values.Created !== '' ? moment(values.Created).format('MM/DD/YYYY HH:mm') : '';
		const updated = values.Updated !== '' ? moment(values.Updated).format('MM/DD/YYYY HH:mm') : '';

		return (
			<form onSubmit={handleSubmit}>

				<div className={touched.PackageName && errors.PackageName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="PackageName">Package Name</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="PackageName" className="form-control" value={values.PackageName} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.PackageName && touched.PackageName && <span className="text-danger">{errors.PackageName}</span>}
				</div>


				<div className={touched.Amount && errors.Amount ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Amount">Amount</label>
					<div className="input-width-icon right">
						<i></i>
						<CurrencyInput decimalSeparator="." thousandSeparator="," precision="2"
							id="Amount" className="form-control"
							value={values.Amount} onChangeEvent={handleChange} />
					</div>
					{errors.Amount && touched.Amount && <span className="text-danger">{errors.Amount}</span>}
				</div>

				<div className='form-group'>
					<label className="form-label">Package Description</label>

					<Editor
						editorState={this.state.editorState}
						toolbarClassName="toolbarClassName"
						wrapperClassName="wrapperClassName border"
						editorClassName="editorClassName"
						onEditorStateChange={this.onEditorStateChange}
					/>
					<textarea
						className="noneDisplay"
						disabled
						value={this.changeToHTML()}
					/>

				</div>
				{/* <div className={touched.Text && errors.Text ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Text">Text</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="Text" className="form-control" value={values.Text} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Text && touched.Text && <span className="text-danger">{errors.Text}</span>}
				</div> */}

				<div className={touched.Priority && errors.Priority ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Priority">Priority</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="number" id="Priority" className="form-control" value={values.Priority} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Priority && touched.Priority && <span className="text-danger">{errors.Priority}</span>}
				</div>



				<div className="form-group">
					<label className="form-label">Created Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={created} />
						<span className="input-group-addon"></span>
					</div>
				</div>
				<div className="form-group">
					<label className="form-label">Updated Date</label>
					<div className="input-group">
						<input type="text" readOnly="readonly" className="form-control" value={updated} />
						<span className="input-group-addon"></span>
					</div>
				</div>
				<div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>
			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		PackageName: Yup.string()
			.required('PackageName is required!'),
		Priority: Yup.number()
			.required('Priority is required!')
			.nullable(true),
		Amount: Yup.string()
			.required('Amount is required!'),
		EditorText: Yup.string()
			.required('Amount is required!'),



	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.save(values).catch((error) => {
			setSubmitting(false);
		});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, PackageName: '', Amount: '', Priority: null, EditorText: '', };

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema)
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id)
			getPackage(id)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ model: json });
				});

	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {
		return savePackage(values).then(res => {
			if (res.ok) {
				this.props.history.push(MyConstantsFile.APP_ADMIN_PACKAGE_PATH);
			}
		});
	}

	render() {
		return (
			<div>
				<ul className="breadcrumb">
					<li><p>Package</p></li>
					<li><Link to={MyConstantsFile.APP_ADMIN_PACKAGE_PATH}>Manage</Link></li>
					<li><a href="#" className="active">Edit</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" role="tabList">
							<li className="active"><a href="#tab1EditUser" role="tab" data-toggle="tab">Edit Package</a></li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="tab1EditUser">
								<MyEnhancedForm model={this.state.model} save={this.handleSave} reset={this.handleReset} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Form;
