import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ReactTable from "react-table";
import 'react-table/react-table.css'
import * as MyConstantsFile from '../../../util/MyConstantsFile'

import swal from 'sweetalert'

import StarRatings from 'react-star-ratings';

import { getAllCommunityMemberPaged, saveCommunityMember, deleteCommunityMember } from '../../../service/CommunityMember';

class CommunityMemberList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			list: [],
			rating: 0,
			sorted: [{ id: "FirstName", desc: false }],
			page: 0,
			pageSize: 10,
			expanded: {},
			resized: [],
			filtered: [],
			loading: true,
			pages: null,
			data: [],
		}
		this.renderEditableRating = this.renderEditableRating.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.requestData = this.requestData.bind(this);
		// this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		this.fetchData(this.state);
	}

	renderEditableRating(cellInfo) {
		return (
			<div>
				<StarRatings
					rating={cellInfo.original.Rating != null ? cellInfo.original.Rating : 0}
					starRatedColor="blue"
					numberOfStars={5}
					name='rating'
					starDimension="25px"
					starSpacing="2px"
					changeRating={newRating => {
						const data = [...this.state.list];
						const oldValue = cellInfo.original.Rating;
						const newValue = newRating;
						if (oldValue != newValue) {
							swal({
								title: "Please confirm",
								text: "Are you sure that you want to change this record?",
								// icon: "warning",
								buttons: true,
								dangerMode: true,
							})
								.then((result) => {
									if (result) {
										swal({
											title: "Data Changed!",
											icon: "success",
										});
										data[cellInfo.index].Rating = newValue;
										var temp = data[cellInfo.index];
										for (var i = 0; i < temp.CommunityMemberChildren.length; i++) {
											temp.CommunityMemberChildren[i].Id = 0;
										}
										saveCommunityMember(temp);
									}
									this.setState({ data });
								});
						}
					}}
				/>
			</div>
		);
	}

	fetchData(state, instance) {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		this.setState({ loading: true });
		// Request the data however you want.  Here, we'll use our mocked service we created earlier
		this.requestData(
			this.state,
			state.pageSize,
			state.page,
			state.sorted,
			state.filtered
		).then(res => {
			// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
			this.setState({
				data: res.rows,
				pages: res.pages,
				loading: false
			});
		});
	}

	requestData = (currentState, pageSize, page, sorted, filtered) => {
		return new Promise((resolve, reject) => {
			// You can retrieve your data however you want, in this case, we will just use some local data.
			var tableModal = {
				QuantityPerPage: pageSize,
				Page: page + 1,
			}

			if (filtered.length > 0) {
				for (var i = 0; i < filtered.length; i++) {
					var temp = filtered[i].id;
					tableModal[temp] = filtered[i].value;
				}
			}

			if (sorted.length > 0) {
				var temp = sorted;
				tableModal.sorted = temp;
			}

			getAllCommunityMemberPaged(tableModal)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.list = json;

					let filteredData = json;

					// You must return an object containing the rows of the current page, and optionally the total pages number.
					const result = {
						// rows: sortedData.slice(pageSize * page, pageSize * page + pageSize),
						rows: filteredData,
						pages: filteredData.length > 0 ? filteredData[0].QuantityOfPages : 0
					};
					resolve(result);
				})
				.catch(console.log);
		});
	};

	// handleClick(event) {
	// 	var id = parseInt(event.currentTarget.id);
	// 	swal({
	// 		title: "Please confirm",
	// 		text: "Are you sure that you want to delete this record? ID: " + id,
	// 		icon: "warning",
	// 		buttons: true,
	// 		dangerMode: true,
	// 	}).then((result) => {
	// 		if (result) {
	// 			deleteCommunityMember(id).then(() => {
	// 				for (var i = 0; i < this.state.list.length; i++) {
	// 					if (this.state.list[i].Id == id) {
	// 						this.state.list.splice(i, 1);
	// 						break;
	// 					}
	// 				}
	// 				this.props.history.push('/CommunityMember')
	// 			});
	// 		}
	// 	});
	// }

	render() {
		const { data, pages, loading } = this.state;

		const columns = [{
			id: 'Id',
			Header: 'Id',
			accessor: 'Id',
			width: 80,
		}, {
			id: 'FirstName',
			Header: 'FIRST NAME',
			accessor: 'FirstName',
		}, {
			id: 'LastName',
			Header: 'LAST NAME',
			accessor: 'LastName',
		}, {
			id: 'Email',
			Header: 'EMAIL',
			accessor: 'Email',
		}, {
			id: 'City',
			Header: 'CITY',
			accessor: 'City',
		}, {
			id: 'State',
			Header: 'STATE',
			accessor: d => d.State ? d.State.StateName : '',
		}, {
			id: 'Country',
			Header: 'COUNTRY',
			accessor: d => d.State ? d.Country.CountryName : '',
		}, {
			id: 'Rating',
			Header: 'Rating',
			Cell: this.renderEditableRating,
			filterable: false,
		}
		// , {
		// 	Header: 'ACTION',
		// 	Cell: row => (
		// 		<div>
		// 			<Link to={`/CommunityMember/edit/${row.original.Id}`} title="Edit"><i className="fas fa-pencil-alt"></i></Link>
		// 			<br />
		// 			<a id={row.original.Id} onClick={this.handleClick} title="Remove" className="mouseDelete"><i className="fas fa-trash"></i></a>
		// 		</div>
		// 	),
		// 	width: 100,
		// }
	]


		return (
			<div>
				<ul className="breadcrumb">
					<li><p>{MyConstantsFile.PAGE_NAME_COMMUNITYMEMBER}</p></li>
					<li><a href="#" className="active">Manage</a> </li>
				</ul>
				<div className="row align-items-center">
					<div className="col-12">
						<div className="grid simple horizontal purple">
							<div className="grid-title">
								<h3>{MyConstantsFile.PAGE_NAME_COMMUNITYMEMBER}</h3>
							</div>
							<div className="grid-body">
								<ReactTable
									data={data}
									columns={columns}
									pages={pages}
									loading={loading}
									manual
									minRows={1}
									filterable
									defaultPageSize={10}
									className="-striped -highlight"
									// Controlled props
									sorted={this.state.sorted}
									page={this.state.page}
									pageSize={this.state.pageSize}
									expanded={this.state.expanded}
									resized={this.state.resized}
									filtered={this.state.filtered}
									// Callbacks
									onSortedChange={sorted => this.setState({ sorted }, () => { this.fetchData(this.state) })}
									onPageChange={page => this.setState({ page }, () => { this.fetchData(this.state) })}
									onPageSizeChange={(pageSize, page) =>
										this.setState({ page, pageSize }, () => { this.fetchData(this.state) })}
									onExpandedChange={expanded => this.setState({ expanded })}
									onResizedChange={resized => this.setState({ resized })}
									onFilteredChange={filtered => {
										this.setState({ filtered }, () => {
											if (filtered.length == 0 || (filtered.length > 0 && filtered[0].value.length > 2)) {
												this.state.page = 0
												this.fetchData(this.state)
											}
										})
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div >
		);
	}
}

export default CommunityMemberList;