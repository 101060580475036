import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/MaritalStatus`;


export const getAllMaritalStatusPaged = (tableModel) => {
	let url = `${endpoint}/GetAllPaged/`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};


export const getAllMaritalStatus = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getMaritalStatus = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveMaritalStatus = (maritalStatus) => {
	let url = endpoint;
	let method = 'POST';
	
	if(maritalStatus.Id && maritalStatus.Id > 0)
	{
		url += `/${maritalStatus.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(maritalStatus)
	}, getFetchOpts()));
};

export const deleteMaritalStatus = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
