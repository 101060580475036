import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/Campaign`;

export const getAllCampaign = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getCampaign = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const getAllCampaignPaged = (tableModel) => {
	let url = `${endpoint}/GetAllPaged/`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};

export const saveCampaign = (campaign) => {
	let url = endpoint;
	let method = 'POST';
	
	if(campaign.Id && campaign.Id > 0)
	{
		url += `/${campaign.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(campaign)
	}, getFetchOpts()));
};

export const deleteCampaign = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
