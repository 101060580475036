import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import CurrencyInput from 'react-currency-input';
import NumberFormat from 'react-number-format';

import { getProspect, saveProspect } from '../../../service/Prospect';
import { getAdministratorToken } from '../../../service/Administrator';
import { getCouponByCode, getRandomCouponCode } from '../../../service/Coupon';
import { getSystemMessage } from '../../../service/SystemMessage';

import { getAllPackage } from '../../../service/Package';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class MyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listPackage: [],
			PackageId: null,
			PaymentOptionId: null,
			AdministratorId: 0,
			DiscountAmountStatus: false,
		};
		this.setValueDiscount = this.setValueDiscount.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

	handleChangePackage = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.PackageId = selectedOption.value;
			this.setState({
				PackageId: selectedOption.value,
			});
		} else {
			this.props.values.PackageId = null;
			this.setState({
				PackageId: null,
			});
		}
	}

	handleChangePaymentOption = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.PaymentOptionId = selectedOption.value;
			this.setState({
				PaymentOptionId: selectedOption.value,
			});
		} else {
			this.props.values.PaymentOptionId = null;
			this.setState({
				PaymentOptionId: null,
			});
		}
	}

	componentDidMount() {
		getAllPackage()
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listPackage: json });
			})
			.catch(console.log);

		getAdministratorToken()
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (json.Status) {
					this.props.values.AdministratorId = json.Id;
				}
			})


		getRandomCouponCode()
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (json.Status) {
					this.props.values.CouponCode = json.CouponCode;
					this.setState({ json });
				}
			})




	}

	setValueDiscount() {
		var result;

		if (window.location.href.indexOf("edit") > -1 && (this.props.values.StartAmount != 0) && (!this.state.DiscountAmountStatus)) {
			this.state.DiscountAmountStatus = true;
			result = this.props.values.Coupon.Amount;
			this.props.values.DiscountAmount = result;
		} else {
			result = this.props.values.DiscountAmount;
		}

		return result;
	}

	render() {
		const {
			values,
			touched,
			errors,
			dirty,
			isSubmitting,
			handleBlur,
			handleChange,
			handleSubmit,
			reset,
		} = this.props;
		const created = values.Created !== '' ? moment(values.Created).format('MM/DD/YYYY HH:mm') : '';
		const updated = values.Updated !== '' ? moment(values.Updated).format('MM/DD/YYYY HH:mm') : '';


		const listPackageValue = [];
		const itemsPackage = this.state.listPackage.map((item) =>
			listPackageValue.push({ value: item.Id, label: item.PackageName })
		);

		const totalAmount = this.props.values.QuantityProduct != null ?
			((parseFloat(this.props.values.StartAmount.toString().replace(/,/g, ""))
				- parseFloat(this.setValueDiscount().toString().replace(/,/g, "")))
				* parseFloat(this.props.values.QuantityProduct.toString().replace(/,/g, ""))) : 0;

		return (
			<form onSubmit={handleSubmit}>
				<div className={touched.PackageId && errors.PackageId ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="PackageId">Package *</label>
					<div className="input-width-icon right">
						<i></i>
						<Select name="form-field-Package" type="text" id="PackageId" value={values.PackageId}
							onChange={this.handleChangePackage} options={listPackageValue} />
					</div>
					{errors.PackageId && touched.PackageId && <span className="text-danger">{errors.PackageId}</span>}
				</div>

				<div className={touched.CompanyName && errors.CompanyName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="CompanyName">Client Company Name *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="CompanyName" className="form-control" value={values.CompanyName} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.CompanyName && touched.CompanyName && <span className="text-danger">{errors.CompanyName}</span>}
				</div>

				<div className="row">
					<div className={touched.FirstName && errors.FirstName ? 'form-group has-error col-12 col-md-6' : 'form-group col-12 col-md-6'}>
						<label className="form-label" htmlFor="FirstName">First Name *</label>
						<div className="input-width-icon right">
							<i></i>
							<input type="text" id="FirstName" className="form-control" value={values.FirstName} onChange={handleChange} onBlur={handleBlur} />
						</div>
						{errors.FirstName && touched.FirstName && <span className="text-danger">{errors.FirstName}</span>}
					</div>

					<div className={touched.LastName && errors.LastName ? 'form-group has-error col-12 col-md-6' : 'form-group col-12 col-md-6'}>
						<label className="form-label" htmlFor="LastName">Last Name *</label>
						<div className="input-width-icon right">
							<i></i>
							<input type="text" id="LastName" className="form-control" value={values.LastName} onChange={handleChange} onBlur={handleBlur} />
						</div>
						{errors.LastName && touched.LastName && <span className="text-danger">{errors.LastName}</span>}
					</div>
				</div>

				<div className={touched.Email && errors.Email ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Email">Email *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="email" id="Email" className="form-control" value={values.Email} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Email && touched.Email && <span className="text-danger">{errors.Email}</span>}
				</div>

				<div className={touched.QuantityProduct && errors.QuantityProduct ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="QuantityProduct">How many products are you selling? *</label>
					<div className="input-width-icon right">
						<NumberFormat thousandSeparator={true} id="QuantityProduct" className="form-control" value={values.QuantityProduct}
							onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.QuantityProduct && touched.QuantityProduct && <span className="text-danger">{errors.QuantityProduct}</span>}
				</div>

				<div className="row">
					<div className={touched.StartAmount && errors.StartAmount ? 'form-group has-error col-12 col-md-4' : 'form-group col-12 col-md-4'}>
						<label className="form-label" htmlFor="StartAmount">Charge per product</label>
						<div className="input-width-icon right">
							<i></i>
							<CurrencyInput decimalSeparator="." thousandSeparator="," precision="2"
								id="StartAmount" className="form-control"
								value={values.StartAmount} onChangeEvent={handleChange} />
						</div>
						{errors.StartAmount && touched.StartAmount && <span className="text-danger">{errors.StartAmount}</span>}
					</div>

					<div className={touched.DiscountAmount && errors.DiscountAmount ? 'form-group has-error col-12 col-md-4' : 'form-group col-12 col-md-4'}>
						<label className="form-label" htmlFor="CouponAmount">Discount amount per product</label>
						<div className="input-width-icon right">
							<i></i>
							<CurrencyInput decimalSeparator="." thousandSeparator="," precision="2"
								id="DiscountAmount" className="form-control"
								value={this.setValueDiscount()} onChangeEvent={handleChange} />
						</div>
						{errors.DiscountAmount && touched.DiscountAmount && <span className="text-danger">{errors.DiscountAmount}</span>}
					</div>

					<div className="form-group col-12 col-md-4">
						<label className="form-label">Total charge</label>
						<div className="input-width-icon right">
							<i></i>
							<CurrencyInput decimalSeparator="." thousandSeparator="," precision="2" className={totalAmount >= 0 ? 'form-control' : 'bg-danger'}
								value={totalAmount > 0 ? totalAmount : 0} readOnly />
						</div>
					</div>
				</div>

				<div className={touched.PaymentOptionId && errors.PaymentOptionId ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="PaymentOptionId">Payment Option *</label>
					<div className="input-width-icon right">
						<i></i>
						<Select name="form-field-Package" type="text" id="PaymentOptionId" value={values.PaymentOptionId}
							onChange={this.handleChangePaymentOption} options={[
								{ value: 0, label: 'Credit Card' },
								{ value: 1, label: 'Invoice' },
							]} />
					</div>
					{errors.PaymentOptionId && touched.PaymentOptionId && <span className="text-danger">{errors.PaymentOptionId}</span>}
				</div>

				<div className={touched.CouponCode && errors.CouponCode ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="CouponCode">Coupon Code (Click to customize)</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="CouponCode" className="form-control" value={values.CouponCode} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.CouponCode && touched.CouponCode && <span className="text-danger">{errors.CouponCode}</span>}
				</div>

				<div className="row noneDisplay">
					<div className="form-group col-6">
						<label className="form-label">Created Date</label>
						<div className="input-group">
							<input type="text" readOnly="readonly" className="form-control" value={created} />
							{/* <span className="input-group-addon"></span> */}
						</div>
					</div>
					<div className="form-group col-6">
						<label className="form-label">Updated Date</label>
						<div className="input-group">
							<input type="text" readOnly="readonly" className="form-control" value={updated} />
							{/* <span className="input-group-addon"></span> */}
						</div>
					</div>
				</div>
				<div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>
			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		PackageId: Yup.number()
			.required('Package is required!')
			.nullable(true),
		CompanyName: Yup.string()
			.required('Company name is required!'),
		FirstName: Yup.string()
			.required('First name is required!'),
		LastName: Yup.string()
			.required('Last name is required!'),
		Email: Yup.string()
			.required('Email is required!')
			.email('Invalid email address'),
		QuantityProduct: Yup.number()
			.required('Product quantity is required!')
			.nullable(true),
		StartAmount: Yup.number()
			.required('Start amount is required!'),
		DiscountAmount: Yup.number()
			.required('Discount amount is required!'),
		PaymentOptionId: Yup.number()
			.required('Payment option is required!')
			.nullable(true),
		CouponCode: Yup.string()
			.required('Coupon code is required!'),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {

		getCouponByCode(values.CouponCode)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (!json.Status) {
					props.save(values).catch((error) => {
						setSubmitting(false);
					});
				} else {
					getSystemMessage("duplicatecoupon").then(res => res.json().then(json => ({ res, json })))
						.then(({ res, json }) => {
							window.swal('Notice', json, 'warning');
							setSubmitting(false);
						});
				}
			})

	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = {
	Id: 0, PackageId: null, CompanyName: '', FirstName: '', LastName: '', Email: '', QuantityProduct: null,
	StartAmount: 0, DiscountAmount: 0, PaymentOptionId: null, CouponCode: '', AdministratorId: null,
};

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema)
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id)
			getProspect(id)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ model: json });
				});

	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {
		var prospectObj = {};

		prospectObj = {
			CompanyName: values.CompanyName,
			FirstName: values.FirstName,
			LastName: values.LastName,
			Email: values.Email,
			Coupon: { AdministratorId: values.AdministratorId, Amount: parseFloat(values.DiscountAmount.toString().replace(/,/g, "")), CouponCode: values.CouponCode },
			AdministratorId: values.AdministratorId,
			StartAmount: parseFloat(values.StartAmount.toString().replace(/,/g, "")),
			Invoice: values.PaymentOptionId,
			QuantityProduct: parseInt(values.QuantityProduct.replace(/,/g, '')),
			PackageId: values.PackageId
		}

		return saveProspect(prospectObj).then(res => {
			if (res.ok) {
				this.props.history.push(MyConstantsFile.APP_ADMIN_PROSPECT_PATH);
			}
		});

	}

	render() {
		return (
			<div>
				<ul className="breadcrumb">
					<li><p>Prospect</p></li>
					<li><Link to={MyConstantsFile.APP_ADMIN_PROSPECT_PATH}>Manage</Link></li>
					<li><a href="#" className="active">{(window.location.href.indexOf("edit") > -1 ? "Edit" : "Add")}</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" role="tabList">
							<li className="active"><a href="#tab1EditUser" role="tab" data-toggle="tab">{(window.location.href.indexOf("edit") > -1 ? "Edit Prospect" : "Add Prospect")}</a></li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="tab1EditUser">
								<MyEnhancedForm model={this.state.model} save={this.handleSave} reset={this.handleReset} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Form;
