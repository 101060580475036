import Auth from '../util/Auth';
import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/Administrator`;

export const getAllAdministrator = (levelId) => {
	let url = `${endpoint}?levelId=${levelId}`;
	return fetch(url, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getAdministrator = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveAdministrator = (model) => {
	let url = endpoint;
	let method = 'POST';
	
	if(model.Id && model.Id > 0)
	{
		url += `/${model.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(model)
	}, getFetchOpts()));
};

export const deleteAdministrator = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'DELETE'
	}, getFetchOpts()));
};


export const login = (model) => {
	let url = `${endpoint}/Login`;
	let method = 'POST';
	
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(model)
	}, getFetchOpts()));
};


export const getAdministratorToken = () => {
	let url = `${endpoint}/GetToken`;

	return fetch(url, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getAllAdministratorPaged = (tableModel) => {
	let url = `${endpoint}/GetAllPaged/`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};