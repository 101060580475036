import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input';

import { getProduct, saveProduct } from '../../../service/Product';
import { getAllAgeTarget } from '../../../service/AgeTarget';
import { getAllCampaign } from '../../../service/Campaign';
import { getAllGenderTarget } from '../../../service/GenderTarget';
import { screenDateFormat } from '../../../util/DateHelper';
import { getAllAllergy } from '../../../service/Allergy';
import { getManufacturerToken } from '../../../service/Manufacturer';
import { getAllCategory } from '../../../service/Category';

import Auth from '../../../util/Auth';

import UploadMultipleImagesHelper from '../../../util/UploadMultipleImagesHelper';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class MyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listAgeTarget: [],
			listCampaign: [],
			listGenderTarget: [],
			listCategory: [],
			AgeTargetStartsId: null,
			AgeTargetEndsId: null,
			CampaignId: null,
			listAllergy: [],
			selectedAllergies: [],
			GenderTargetId: null,
			CurrencyId: null,
			crop: {
				x: 10,
				y: 10,
				width: 20,
				height: 20,
				aspect: 1,
			},
			loadFromDataBase: false,
			loadProductCategory: false,
		};
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
	}

	componentDidMount() {
		getAllAgeTarget()
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listAgeTarget: json });
			})
			.catch(console.log);

		getAllCampaign()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listCampaign: json });
			})
			.catch(console.log);

		getAllGenderTarget()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listGenderTarget: json });
				// window.$('#tableEspresso').DataTable();
			})
			.catch(console.log);

		getAllCategory()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listCategory: json });
				// window.$('#tableEspresso').DataTable();
			})
			.catch(console.log);

		if (Auth.getTypeUser() == "manufacturer" && Auth.isUserAuthenticated()) {
			getManufacturerToken()
				.then(res => res.json()
					.then(json => ({ res, json })))
				.then(({ res, json }) => {
					if (json.Status) {
						this.props.values.ManufacturerId = json.Id;
					}
				})
		}

		getAllAllergy()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listAllergy: json });
			})
			.catch(console.log);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
		//this.props.resetForm(nextProps);
	}

	handleChangeAgeTargetFrom = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.AgeTargetStartsId = selectedOption.value;
			this.props.values.AgeTargetEndsId = null;
			this.props.values.OrderPriority = selectedOption.orderPriority;
			this.setState({
				AgeTargetStartsId: selectedOption.value,
			});
		} else {
			this.props.values.AgeTargetStartsId = null;
			this.props.values.AgeTargetEndsId = null;
			this.setState({
				AgeTargetStartsId: null,
			});
		}
	}

	handleChangeAgeTargetTo = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.AgeTargetEndsId = selectedOption.value;
			this.setState({
				AgeTargetEndsId: selectedOption.value,
			});
		} else {
			this.props.values.AgeTargetEndsId = null;
			this.setState({
				AgeTargetEndsId: null,
			});
		}
	}

	handleChangeCampaign = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.CampaignId = selectedOption.value;
			this.setState({
				CampaignId: selectedOption.value,
			});
		} else {
			this.props.values.CampaignId = null;
			this.setState({
				CampaignId: null,
			});
		}
	}

	handleChangeGenderTarget = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.GenderTargetId = selectedOption.value;
			this.setState({
				GenderTargetId: selectedOption.value,
			});
		} else {
			this.props.values.GenderTargetId = null;
			this.setState({
				GenderTargetId: null,
			});
		}
	}

	handleChangeCategory = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.CategoryId = selectedOption.value;
			this.setState({
				CategoryId: selectedOption.value,
			});
		} else {
			this.props.values.CategoryId = null;
			this.props.values.ProductAllergies = [];
			this.state.selectedAllergies = [];
			this.setState({
				CategoryId: null,
			});
		}
	}

	handleChangeCategory2 = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.CategoryId2 = selectedOption.value;
			this.setState({
				CategoryId2: selectedOption.value,
			});
		} else {
			this.props.values.CategoryId2 = null;
			this.props.values.ProductAllergies = [];
			this.state.selectedAllergies = [];
			this.setState({
				CategoryId2: null,
			});
		}
	}

	handleChangeCurrency = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.CurrencyId = selectedOption.value;
			this.setState({
				CurrencyId: selectedOption.value,
			});
		} else {
			this.props.values.CurrencyId = null;
			this.setState({
				CurrencyId: null,
			});
		}
	}

	setClassNameBody(listCampaignId) {
		var mClassName = 'grid-body';
		if (this.props.values.CampaignId == listCampaignId) {
			mClassName += ' active';
		}
		return mClassName;
	}

	setClassNameTitle(listCampaignId) {
		var mClassName = 'grid-title';
		if (this.props.values.CampaignId == listCampaignId) {
			mClassName += ' active';
		}
		return mClassName;
	}

	handleChangeDropShip = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.DropShipId = selectedOption.value;
			this.setState({
				DropShipId: selectedOption.value,
			});
		} else {
			this.props.values.DropShipId = null;
			this.setState({
				DropShipId: null,
			});
		}
	}

	handleCheckboxChange(event) {
		var allAllergies = this.state.selectedAllergies;
		var index = allAllergies.indexOf(parseInt(event.target.id));
		if (index > -1) {
			allAllergies.splice(index, 1);
			this.state.selectedAllergies = allAllergies;
		} else {
			this.state.selectedAllergies.push(parseInt(event.target.id));
		}

		this.props.values.ProductAllergies = [];
		for (var i = 0; i < this.state.selectedAllergies.length; i++) {
			this.props.values.ProductAllergies.push({
				AllergyId: this.state.selectedAllergies[i]
			})
		}
		this.setState({
			selectedAllergies: this.state.selectedAllergies,
		});
	}

	render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
			onModalShow,
			onImageLoaded,
			imageSelected,
			imagePreview,
			onImageChange,
			crop,
			onCropChange,
			onCropComplete,
			onCropSave,
		} = this.props;
		const now = new Date();
		const time = moment(now).format('YYYYMMDDHHmmss');
		const created = values.Created !== '' ? moment(values.Created).format('MM/DD/YYYY HH:mm') : '';
		const updated = values.Updated !== '' ? moment(values.Updated).format('MM/DD/YYYY HH:mm') : '';
		const image = imageSelected !== '' ? imageSelected : (values.LargeImage !== null && values.LargeImage !== '' ? `${STORAGE_URL}product/${values.LargeImage}?${time}` : '');

		if (this.state.loadProductCategory == false && this.props.values.ProductCategory &&
			this.props.values.ProductCategory.length > 0 && this.props.values.AgeTargetStarts) {

			this.state.loadProductCategory = true;
			//TODO: change to accept more than one product category
			this.props.values.CategoryId = this.props.values.ProductCategory[0].CategoryId;
			if (this.props.values.ProductCategory.length > 1)
				this.props.values.CategoryId2 = this.props.values.ProductCategory[1].CategoryId;

			this.props.values.OrderPriority = this.props.values.AgeTargetStarts.OrderPriority;
		}

		if (window.location.href.indexOf("edit") == -1) {
			this.state.loadFromDataBase = true;
		}

		const listAgeTargetFromValue = [];
		const itemsAgeTargetFrom = this.state.listAgeTarget.map((item) =>
			listAgeTargetFromValue.push({ value: item.Id, label: item.Name, orderPriority: item.OrderPriority })
		);

		const listAgeTargetToValue = [];
		const itemsAgeTargetTo = this.state.listAgeTarget.map((item) => {
			if (this.props.values.AgeTargetStartsId != null && item.OrderPriority > this.props.values.OrderPriority)
				listAgeTargetToValue.push({ value: item.Id, label: item.Name, orderPriority: item.OrderPriority })
		});

		const listCampaignValue = [];
		const itemsCampaign = this.state.listCampaign.map((item) =>
			listCampaignValue.push({ value: item.Id, label: item.CampaignName })
		);

		const listGenderTargetValue = [];
		const itemsGenderTarget = this.state.listGenderTarget.map((item) =>
			listGenderTargetValue.push({ value: item.Id, label: item.GenderTargetName })
		);

		const listCategoryValue = [];
		const itemsCategory = this.state.listCategory.map((item) =>
			listCategoryValue.push({ value: item.Id, label: item.CategoryName })
		);

		const campaignList = this.state.listCampaign.map((item) => (
			<div className="grid simple horizontal purple">
				<div id={`grid-title${item.Id}`} className={this.setClassNameTitle(item.Id)}>
					<h4>{item.CampaignName}</h4>
				</div>
				<div id={`grid-body${item.Id}`} className={this.setClassNameBody(item.Id)}>
					<div>
						<h5><span className="semi-bold"> Status: </span>Open</h5>
						<h5><span className="semi-bold"> Deadline: </span>{screenDateFormat(item.EndDate)}</h5>
						<h5><span className="semi-bold"> Announcement: </span>{screenDateFormat(item.AnnouncementDate)}</h5>
						<h5><span className="semi-bold">Types of Products Accepted:</span></h5>
						<p>{item.ProductAccepted}</p>
					</div>
				</div>
			</div>
		));

		const listAllergy = this.state.listAllergy.map((item) =>
			<div className="col-3">
				<input
					className='float-left mt-1 mr-1'
					type="checkbox"
					id={item.Id}
					checked={this.state.selectedAllergies.indexOf(item.Id) !== -1}
					onChange={this.handleCheckboxChange}
				/>
				<label>{item.Component}</label>
			</div>
		);


		return (
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-6 product-template">
						<h4>
							Please complete this form as accurately as possible as this data will be used on
						<span className="semi-bold"> our website  </span>
							should your product earn our certification.
						</h4>
						<div className={touched.ProductName && errors.ProductName ? 'form-group has-error' : 'form-group'}>
							<label className="form-label mt-5" htmlFor="ProductName">Product Name *</label>
							<div className="input-width-icon right">
								<i></i>
								<input type="text" id="ProductName" className="form-control" value={values.ProductName} onChange={handleChange} onBlur={handleBlur} />
							</div>
							{errors.ProductName && touched.ProductName && <span className="text-danger">{errors.ProductName}</span>}
						</div>
						<div className={touched.Website && errors.Website ? 'form-group has-error' : 'form-group'}>
							<label className="form-label" htmlFor="Website">Website *</label>
							<div className="input-width-icon right">
								<i></i>
								<input type="text" id="Website" className="form-control" value={values.Website} onChange={handleChange} onBlur={handleBlur} />
							</div>
							{errors.Website && touched.Website && <span className="text-danger">{errors.Website}</span>}
						</div>
						<div className={touched.GTIN && errors.GTIN ? 'form-group has-error' : 'form-group'}>
							<label className="form-label" htmlFor="GTIN">GTIN (Global Trade Item Number)</label>
							<div className="input-width-icon right">
								<i></i>
								<input type="text" id="GTIN" className="form-control" value={values.GTIN} onChange={handleChange} onBlur={handleBlur} />
							</div>
							{errors.GTIN && touched.GTIN && <span className="text-danger">{errors.GTIN}</span>}
						</div>
						<div className={touched.ASIN && errors.ASIN ? 'form-group has-error' : 'form-group'}>
							<label className="form-label" htmlFor="ASIN">ASIN (Amazon Standard Identification Number)</label>
							<div className="input-width-icon right">
								<i></i>
								<input type="text" id="ASIN" className="form-control" value={values.ASIN} onChange={handleChange} onBlur={handleBlur} />
							</div>
							{errors.ASIN && touched.ASIN && <span className="text-danger">{errors.ASIN}</span>}
						</div>

						<div className="row">
							<div className={touched.AgeTargetStartsId && errors.AgeTargetStartsId ? 'form-group has-error col-6' : 'form-group col-6'}>
								<label className="form-label" htmlFor="AgeTargetStartsId">Target Age Group *<span className="help">(From)</span></label>
								<div className="input-width-icon right">
									<i></i>
									<Select name="form-field-Package" type="text" id="AgeTargetStartsId" value={values.AgeTargetStartsId}
										onChange={this.handleChangeAgeTargetFrom} options={listAgeTargetFromValue} />
								</div>
								{errors.AgeTargetStartsId && touched.AgeTargetStartsId && <span className="text-danger">{errors.AgeTargetStartsId}</span>}
							</div>

							<div className={touched.AgeTargetEndsId && errors.AgeTargetEndsId ? 'form-group has-error col-6' : 'form-group col-6'}>
								<label className="form-label" htmlFor="AgeTargetEndsId">Target Age Group *<span className="help">(To)</span></label>
								<div className="input-width-icon right">
									<i></i>
									<Select name="form-field-Package" type="text" id="AgeTargetEndsId" value={values.AgeTargetEndsId}
										onChange={this.handleChangeAgeTargetTo} options={listAgeTargetToValue} />
								</div>
								{errors.AgeTargetEndsId && touched.AgeTargetEndsId && <span className="text-danger">{errors.AgeTargetEndsId}</span>}
							</div>
						</div>
						<div className={touched.CategoryId && errors.CategoryId ? 'form-group has-error' : 'form-group'}>
							<label className="form-label" htmlFor="CategoryId">Product Category *</label>
							<div className="input-width-icon right">
								<i></i>
								<Select name="form-field-Package" type="text" id="CategoryId" value={values.CategoryId}
									onChange={this.handleChangeCategory} options={listCategoryValue} />
							</div>
							{errors.CategoryId && touched.CategoryId && <span className="text-danger">{errors.CategoryId}</span>}
						</div>

						<div className={touched.CategoryId2 && errors.CategoryId2 ? 'form-group has-error' : 'form-group'}>
							<label className="form-label" htmlFor="CategoryId2">Product Category 2</label>
							<div className="input-width-icon right">
								<i></i>
								<Select name="form-field-Package" type="text" id="CategoryId2" value={values.CategoryId2}
									onChange={this.handleChangeCategory2} options={listCategoryValue} />
							</div>
							{errors.CategoryId2 && touched.CategoryId2 && <span className="text-danger">{errors.CategoryId2}</span>}
						</div>

						<div className={this.state.CategoryId == 11 ? 'form-group' : 'noneDisplay'}>
							<label className="form-label" htmlFor="Components">It contains: </label>
							<div className="row input-width-icon right">
								<i></i>
								{listAllergy}
							</div>
							{errors.Components && touched.Components && <span className="text-danger">{errors.Components}</span>}
						</div>

						<div className={touched.ProductDescription && errors.ProductDescription ? 'form-group has-error' : 'form-group'}>
							<label className="form-label" htmlFor="ProductDescription">Product Description *</label>
							<div className="input-width-icon right">
								<i></i>
								<textarea type="text" id="ProductDescription" className="form-control" rows="5"
									value={values.ProductDescription} onChange={handleChange} onBlur={handleBlur} />
							</div>
							{errors.ProductDescription && touched.ProductDescription && <span className="text-danger">{errors.ProductDescription}</span>}
						</div>

						<div className={touched.CampaignId && errors.CampaignId ? 'form-group has-error' : 'form-group'}>
							<label className="form-label" htmlFor="CampaignId">Campaign * <span className="help">(Please review campaign dates to the right)</span></label>
							<div className="input-width-icon right">
								<i></i>
								<Select name="form-field-Package" type="text" id="CampaignId" value={values.CampaignId}
									onChange={this.handleChangeCampaign} options={listCampaignValue} />
							</div>
							{errors.CampaignId && touched.CampaignId && <span className="text-danger">{errors.CampaignId}</span>}
						</div>

						<div className={touched.GenderTargetId && errors.GenderTargetId ? 'form-group has-error' : 'form-group'}>
							<label className="form-label" htmlFor="GenderTarget">Gender Target *</label>
							<div className="input-width-icon right">
								<i></i>
								<Select name="form-field-Package" type="text" id="GenderTargetId" value={values.GenderTargetId}
									onChange={this.handleChangeGenderTarget} options={listGenderTargetValue} />
							</div>
							{errors.GenderTargetId && touched.GenderTargetId && <span className="text-danger">{errors.GenderTargetId}</span>}
						</div>

						<div className={touched.DropShipId && errors.DropShipId ? 'form-group has-error' : 'form-group'}>
							<label className="form-label" htmlFor="DropShipId">Are you able to drop-ship products directly to our testers? *</label>
							<div className="input-width-icon right">
								<i></i>
								<Select name="form-field-Package" type="text" id="DropShipId" value={values.DropShipId}
									onChange={this.handleChangeDropShip} options={[
										{ value: 0, label: 'No' },
										{ value: 1, label: 'Yes' },
									]} />
							</div>
							{errors.DropShipId && touched.DropShipId && <span className="text-danger">{errors.DropShipId}</span>}
						</div>

						{/* <div className={touched.CountryAvailable && errors.CountryAvailable ? 'form-group has-error' : 'form-group'}>
							<label className="form-label" htmlFor="CountryAvailable">What country is your product available in?</label>
							<div className="input-width-icon right">
								<i></i>
								<div><input className='float-left mt-1 mr-1' type="checkbox" id="Canada" name="AvailableCountries" value="Canada" />
									<label for="Canada">Canada</label> </div>
								<div><input className='float-left mt-1 mr-1' type="checkbox" id="UnitedStates" name="AvailableCountries" value="UnitedStates" />
									<label for="UnitedStates">United States</label></div>
							
							</div>
							{errors.CountryAvailable && touched.CountryAvailable && <span className="text-danger">{errors.CountryAvailable}</span>}
						</div> */}


						<div className="row">
							<div className={touched.CurrencyId && errors.CurrencyId ? 'form-group has-error col-4' : 'form-group col-4'}>
								<label className="form-label" htmlFor="CurrencyId">Currency*</label>
								<div className="input-width-icon right">
									<i></i>
									<Select name="form-field-Package" type="text" id="CurrencyId" value={values.CurrencyId}
										onChange={this.handleChangeCurrency} options={[
											{ value: 1, label: 'CAD' },
											{ value: 2, label: 'USD' },
										]} />
								</div>
								{errors.CurrencyId && touched.CurrencyId && <span className="text-danger">{errors.CurrencyId}</span>}
							</div>

							<div className={touched.PriceRetail && errors.PriceRetail ? 'form-group has-error col' : 'form-group col'}>
								<label className="form-label" htmlFor="PriceRetail">Retail Price</label>
								<div className="input-width-icon right">
									<i></i>
									<CurrencyInput decimalSeparator="." thousandSeparator="," precision="2"
										id="PriceRetail" className="form-control"
										value={parseFloat(values.PriceRetail ? values.PriceRetail.toString().replace(/,/g, "") : 0)} onChangeEvent={handleChange} />
								</div>
								{errors.PriceRetail && touched.PriceRetail && <span className="text-danger">{errors.PriceRetail}</span>}
							</div>
						</div>

						<div className="form-group">
							<label className="form-label text-danger">OR</label>
						</div>

						<div className="row">
							<div className={touched.PriceStarts && errors.PriceStarts ? 'form-group has-error col' : 'form-group col'}>
								<label className="form-label" htmlFor="PriceStarts">Price Range <span className="help">(Low)</span></label>
								<div className="input-width-icon right">
									<i></i>
									<CurrencyInput decimalSeparator="." thousandSeparator="," precision="2"
										id="PriceStarts" className="form-control"
										value={parseFloat(values.PriceStarts ? values.PriceStarts.toString().replace(/,/g, "") : 0)} onChangeEvent={handleChange} />
								</div>
								{errors.PriceStarts && touched.PriceStarts && <span className="text-danger">{errors.PriceStarts}</span>}
							</div>

							<div className={touched.PriceEnds && errors.PriceEnds ? 'form-group has-error col' : 'form-group col'}>
								<label className="form-label" htmlFor="PriceEnds">Price Range <span className="help">(High)</span></label>
								<div className="input-width-icon right">
									<i></i>
									<CurrencyInput decimalSeparator="." thousandSeparator="," precision="2"
										id="PriceEnds" className="form-control"
										value={parseFloat(values.PriceEnds ? values.PriceEnds.toString().replace(/,/g, "") : 0)} onChangeEvent={handleChange} />
								</div>
								{errors.PriceEnds && touched.PriceEnds && <span className="text-danger">{errors.PriceEnds}</span>}
							</div>

						</div>

						<div className="row noneDisplay">
							<div className="form-group col-6">
								<label className="form-label">Created Date</label>
								<div className="input-group">
									<input type="text" readOnly="readonly" className="form-control" value={created} />
									{/* <span className="input-group-addon"></span> */}
								</div>
							</div>
							<div className="form-group col-6">
								<label className="form-label">Updated Date</label>
								<div className="input-group">
									<input type="text" readOnly="readonly" className="form-control" value={updated} />
									{/* <span className="input-group-addon"></span> */}
								</div>
							</div>
						</div>
						<div className="form-actions">
							<div className="pull-right">
								<button className={this.props.showButton ? 'btn btn-danger btn-cons' : 'noneDisplay'} disabled={isSubmitting}>{!isSubmitting ? 'Save' : 'Saving'}</button>
							</div>
						</div>
					</div> {/* <div className="col-6 product-template"> */}

					{window.innerWidth > 900 && <div className="col-6">
						{campaignList}
					</div>}
				</div>
			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		ProductName: Yup.string()
			//.email('Invalid email address')
			.required('Product Name is required!'),
		Website: Yup.string()
			.required('Website is required!'),
		AgeTargetStartsId: Yup.number()
			.required('Age Target Starts is required!')
			.nullable(true),
		AgeTargetEndsId: Yup.number()
			.required('Age Target Ends is required!')
			.nullable(true),
		ProductDescription: Yup.string()
			.required('Description is required!'),
		CampaignId: Yup.number()
			.required('Campaign is required!')
			.nullable(true),
		GenderTargetId: Yup.number()
			.required('Gender is required!')
			.nullable(true),
		DropShipId: Yup.number()
			.required('Drop-ship is required!')
			.nullable(true),
		CategoryId: Yup.number()
			.required('Product Category is required!')
			.nullable(true),
		CurrencyId: Yup.number()
			.required('Currency is required!')
			.nullable(true),
		PriceRetail: Yup.string()
			.max(9, 'Max value accepted is "99,999.00"'),
		PriceStarts: Yup.string()
			.max(9, 'Max value accepted is "99,999.00"'),
		PriceEnds: Yup.string()
			.max(9, 'Max value accepted is "99,999.00"'),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSave(values).then(() => setSubmitting(false)).catch((error) => {
			setSubmitting(false);
		});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = {
	Id: 0, ProductName: '', Website: '', GTIN: '', ASIN: '', AgeTargetStartsId: null, AgeTargetEndsId: null, ASIN: '',
	ProductDescription: '', CampaignId: null, GenderTargetId: null, CurrencyId: null, PriceRetail: '', PriceStarts: '',
	PriceEnds: '', LargeImage: '', DropShipId: null, CategoryId: null, CategoryId2: null,
};

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema),
			tabActive: 1,
			showButton: true,
			productId: 0,
		}
		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleChangeTabActive = this.handleChangeTabActive.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id)
			getProduct(id)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({
						model: json,
						productId: json.Id,
					});
				});
	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {
		var data = values;
		if (values.imageSelected) {
			var imageSelected = this.state.imageSelected;

			if (imageSelected !== '') {
				data = Object.assign(data, { Image: imageSelected.replace('data:image/jpeg;base64,', '') });
			}
		}

		data.ProductCategory = [];
		data.ProductCategory.push({
			CategoryId: values.CategoryId
		});

		if (values.CategoryId2 != null) {
			data.ProductCategory.push({
				CategoryId: values.CategoryId2
			});
		}

		data.ManufacturerId = values.ManufacturerId;
		return saveProduct(data).then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (json.success) {
					if (this.state.model.Id === 0) {
						this.state.productId = json.productId;
						this.state.model.ProductPhoto = [];
					}
					window.scrollTo(0, 0)
					this.handleChangeTabActive();
					this.setState({
						showButton: false,
					})
				}
			});
	}

	handleChangeTabActive() {
		if (this.state.tabActive == 1)
			this.setState({ tabActive: 2 })
		else {
			this.setState({ tabActive: 1 })
		}
	}

	render() {
		return (
			<div>
				<ul className="breadcrumb mb-2">
					<li><p>Product</p></li>
					<li><Link to={this.props.location.state.levelAccess === "Manufacturer" ? MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_PATH : MyConstantsFile.APP_ADMIN_PRODUCT_ADMIN_PATH}>Manage</Link></li>
					<li><a href="#" className="active">{(window.location.href.indexOf("edit") > -1 ? "Edit" : "Add")}</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" role="tabList">
							<li className={"active grid simple " + (this.state.tabActive == 1 ? 'purple' : 'white')}
								onClick={this.handleChangeTabActive}
							><a href="#tab1EditUser" role="tab" data-toggle="tab">{(window.location.href.indexOf("edit") > -1 ? "Edit Product" : "Add Product")}</a></li>
							<li className={"active grid simple " + (this.state.tabActive == 2 ? 'purple' : 'white')}
								onClick={this.handleChangeTabActive}
							><a href="#tab2EditUser" role="tab" data-toggle="tab">Add Photos</a></li>
						</ul>
						<div className="tab-content">
							<div className={this.state.tabActive == 1 ? 'tab-pane active' : 'noneDisplay'} id="tab1EditUser">
								<MyEnhancedForm
									showButton={this.state.showButton}
									model={this.state.model}
									onSave={this.handleSave}
									onReset={this.handleReset}
								/>
							</div>

							<div className={this.state.tabActive == 2 ? 'tab-pane active' : 'noneDisplay'} id="tab2EditUser">
								<div>
									<UploadMultipleImagesHelper
										productImages={this.state.model.ProductPhoto}
										productId={this.state.productId}
										history={this.props.history}
										levelAccess={this.props.location.state.levelAccess == "Manufacturer" ? "Client" : "Administrator"} />
									{/* //   window.location.href.toLocaleLowerCase().indexOf("client") > -1 ? "Client" : "Administrator"} /> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Form;