import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getAllLead, saveLead } from '../../../service/Lead';

import swal from 'sweetalert'

import ReactTable from "react-table";
import 'react-table/react-table.css'

import matchSorter from 'match-sorter'

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class LeadList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: []
		}
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		getAllLead()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ list: json });
			})
			.catch(console.log);

	}

	handleClick(event) {
		var id = parseInt(event.currentTarget.id);
		var item = {};
		var index = null;
		for (var i = this.state.list.length - 1; i >= 0; i--) {
			if (this.state.list[i].Id == id) {
				item = this.state.list[i];
				index = i;
				break;
			}
		}
		item.StatusContact = 1;

		swal({
			title: "Please confirm",
			text: "Have you sent e-mail to " + item.Email + "?",
			// icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((result) => {
				if (result) {
					return saveLead(item).then(res => {
						if (res.ok) {
							this.state.list[index] = item;
							this.props.history.push(MyConstantsFile.APP_ADMIN_LEAD_PATH);
						}
					});
				}
			});
	}

	render() {

		const data = this.state.list;

		const columns = [{
			id: 'id',
			Header: 'ID',
			accessor: 'Id',
			width: 50,
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["id"] }),
			filterAll: true,
		}, {
			id: 'FirstName',
			Header: 'FIRST NAME',
			accessor: 'FirstName',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["FirstName"] }),
			filterAll: true,
		}, {
			id: 'LastName',
			Header: 'LAST NAME',
			accessor: 'LastName',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["LastName"] }),
			filterAll: true,
		}, {
			id: 'CompanyName',
			Header: 'COMPANY NAME',
			accessor: 'CompanyName',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["CompanyName"] }),
			filterAll: true,
		}, {
			id: 'Email',
			Header: 'EMAIL',
			accessor: 'Email',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["Email"] }),
			filterAll: true,
		}, {
			Header: 'ACTION',
			Cell: row => (
				<a id={row.original.Id} onClick={row.original.StatusContact ? '' : this.handleClick}
					title={row.original.StatusContact ? 'Contacted' : 'New lead'} className="mouseDelete">
					<i className={row.original.StatusContact ? 'fa fa-check cursor-default' : 'fa fa-certificate'}></i></a>
			),
			width: 100,
		}]

		return (
			<div>
				<ul className="breadcrumb">
					<li><p>Lead</p></li>
					<li><a href="#" className="active">Manage</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<div className="grid simple horizontal purple">
							<div className="grid-title"><div className="tools">

							</div>
								<h3>Lead</h3>
							</div>
							<div className="grid-body">
								<ReactTable
									data={data}
									columns={columns}
									minRows={1}
									filterable
									defaultFilterMethod={(filter, row) =>
										String(row[filter.id]) === filter.value}
									defaultPageSize={10}
									defaultSorted={[
										{
											id: "id",
											desc: true
										}
									]}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default LeadList;
