import React, { Component } from 'react';

class Header extends Component {
	// constructor(props) {
	// 	super(props);
	// }

	render() {
		return (
			<div className="header navbar navbar-inverse">
				<div className="navbar-inner">
					<div className="header-seperation">
						<ul className="nav pull-left notifcation-center visible-xs visible-sm">
							<li className="dropdown">
								<a href="#main-menu" data-webarch="toggle-left-side">
									<i className="material-icons">menu</i>
								</a>
							</li>
						</ul>
						<a href="index.html">
							<img src="/assets/img/PTPA-Media-LogoMark-White-Text.png" className="logo" alt="" data-src="/assets/img/PTPA-Media-LogoMark-White-Text.png" data-src-retina="assets/img/PTPA-Media-LogoMark-White-Text.png" width="81" height="90" />
						</a>
					</div>
					<div className="header-quick-nav">
						<div className="pull-left">
							{/* <ul className="nav quick-section">
								<li className="quicklinks">
									<a href="#" className="" id="layout-condensed-toggle">
										<i className="material-icons">menu</i>
									</a>
								</li>
							</ul> 
							<ul className="nav quick-section">
								<li className="quicklinks  m-r-10">
									<a href="#" className="">
									<i className="material-icons">refresh</i>
									</a>
								</li>
								<li className="quicklinks">
									<a href="#" className="">
									<i className="material-icons">apps</i>
									</a>
								</li>
								<li className="quicklinks"> <span className="h-seperate"></span></li>
								<li className="quicklinks">
									<a href="#" className="" id="my-task-list" data-placement="bottom" data-content='' data-toggle="dropdown" data-original-title="Notifications">
									<i className="material-icons">notifications_none</i>
									<span className="badge badge-important bubble-only"></span>
									</a>
								</li>
								<li className="m-r-10 input-prepend inside search-form no-boarder">
									<span className="add-on"> <i className="material-icons">search</i></span>
									<input name="" type="text" className="no-boarder " placeholder="Search Dashboard" style={{width: '250px'}} />
								</li>
							</ul> */}
						</div>
						<div id="notification-list" style={{ display: 'none' }}>
							<div style={{ width: '300px' }}>
								<div className="notification-messages info">
									<div className="user-profile">
										<img src="assets/img/profiles/d.jpg" alt="" data-src="assets/img/profiles/d.jpg" data-src-retina="assets/img/profiles/d2x.jpg" width="35" height="35" />
									</div>
									<div className="message-wrapper">
										<div className="heading">
											David Nester - Commented on your wall
									</div>
										<div className="description">
											Meeting postponed to tomorrow
									</div>
										<div className="date pull-left">
											A min ago
									</div>
									</div>
									<div className="clearfix"></div>
								</div>
								<div className="notification-messages danger">
									<div className="iconholder">
										<i className="icon-warning-sign"></i>
									</div>
									<div className="message-wrapper">
										<div className="heading">
											Server load limited
									</div>
										<div className="description">
											Database server has reached its daily capicity
									</div>
										<div className="date pull-left">
											2 mins ago
									</div>
									</div>
									<div className="clearfix"></div>
								</div>
								<div className="notification-messages success">
									<div className="user-profile">
										<img src="assets/img/profiles/h.jpg" alt="" data-src="assets/img/profiles/h.jpg" data-src-retina="assets/img/profiles/h2x.jpg" width="35" height="35" />
									</div>
									<div className="message-wrapper">
										<div className="heading">
											You haveve got 150 messages
									</div>
										<div className="description">
											150 newly unread messages in your inbox
									</div>
										<div className="date pull-left">
											An hour ago
									</div>
									</div>
									<div className="clearfix"></div>
								</div>
							</div>
						</div>
						<div className="pull-right">
							{/* <ul className="nav quick-section ">
								<li className="quicklinks">
									<a data-toggle="dropdown" className="dropdown-toggle  pull-right " href="javascript" id="user-options">
										<i className="material-icons">tune</i>
									</a>
									<ul className="dropdown-menu  pull-right" role="menu" aria-labelledby="user-options">
										<li>
											<a href="user-profile.html"> My Account</a>
										</li>
										<li>
											<a href="calender.html">My Calendar</a>
										</li>
										<li>
											<a href="email.html"> My Inbox&nbsp;&nbsp;
												<span className="badge badge-important animated bounceIn">2</span>
											</a>
										</li>
										<li className="divider"></li>
										<li>
											<a href="login.html"><i className="material-icons">power_settings_new</i>&nbsp;&nbsp;Log Out</a>
										</li>
									</ul>
								</li>
							</ul> */}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Header;