import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/CommunityMemberProduct`;

export const getAllCommunityMemberProduct = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getCommunityMemberProduct = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const getByProductId = productId => {
	let url = `${endpoint}/GetByProductId/${productId}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};


export const getByCommunityMemberId = communityMemberId => {
	let url = `${endpoint}/GetByCommunityMemberId/${communityMemberId}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const getByCommunityMemberIdToken = () => {
	let url = `${endpoint}/GetByCommunityMemberIdToken/`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveCommunityMemberProduct = (communityMember) => {
	let url = `${endpoint}/AddRange/`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMember)
	}, getFetchOpts()));
};

export const inviteAcceptCommunityMemberProduct = (communityMemberProduct) => {
	let url = `${endpoint}/TesterAccept`;
	let method = 'PUT';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMemberProduct)
	}, getFetchOpts()));
};

export const editCommunityMemberProduct = (communityMemberProduct) => {
	let url = endpoint;
	let method = 'PUT';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMemberProduct)
	}, getFetchOpts()));
};

export const getCompletedSurvey = productId => {
	let url = `${endpoint}/CompletedSurvey/${productId}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};


export const saveRangeCommunityMemberProduct = (communityMemberProduct) => {
	let url = `${endpoint}/AddRange/`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(communityMemberProduct)
	}, getFetchOpts()));
};

export const deleteCommunityMemberProduct = (id) => {
	let url = endpoint;
	let method = '';

	if (id && id > 0) {
		url += `/${id}`
		method = 'DELETE';
	}

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};

export const getReminderPeople = (communityMemberProduct) => {
	let url = `${endpoint}/ReminderPeople/${communityMemberProduct.accepted}/${communityMemberProduct.pickUp}/${communityMemberProduct.received}/
	${communityMemberProduct.survey}/${communityMemberProduct.daysAccepted}/${communityMemberProduct.daysEvaluationDeadline}/`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const getReminderPeopleOverdue = () => {
	let url = `${endpoint}/ReminderPeopleOverdue/`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const getReminderPeopleSend = (communityMemberProduct) => {
	let url = `${endpoint}/ReminderPeopleSend/${communityMemberProduct.accepted}/${communityMemberProduct.pickUp}/${communityMemberProduct.received}/
	${communityMemberProduct.survey}/${communityMemberProduct.daysAccepted}/${communityMemberProduct.daysEvaluationDeadline}/`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const getReminderPeopleOverdueSend = () => {
	let url = `${endpoint}/ReminderPeopleOverdueSend/`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};