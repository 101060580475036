import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/Prize`;

export const getAllPrize = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getAllPrizePaged = (tableModel) => {
	let url = `${endpoint}/GetAllPaged/`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};

export const getPrize = id => {
	let url = `${endpoint}/${id}`;
	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const savePrize = (prize) => {
	let url = endpoint;
	let method = 'POST';
	
	if(prize.Id && prize.Id > 0)
	{
		url += `/${prize.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(prize)
	}, getFetchOpts()));
};

export const getPrizeByToken = () => {
	let url = `${endpoint}/GetPrizeByToken`;

	return fetch(url, Object.assign({
		method: 'GET',
	}, getFetchOpts()));
};

export const deletePrize = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
