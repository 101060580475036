import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/PointFeature`;

export const getAllPointFeature = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getPointFeature = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const savePointFeature = (pointFeature) => {
	let url = endpoint;
	let method = 'POST';
	
	if(pointFeature.Id && pointFeature.Id > 0)
	{
		url += `/${pointFeature.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(pointFeature)
	}, getFetchOpts()));
};

export const deletePointFeature = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};

export const getAllPointFeaturePaged = (tableModel) => {
	let url = `${endpoint}/GetAllPaged/`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};
