import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import swal from 'sweetalert'

import * as MyConstantsFile from '../../../util/MyConstantsFile'
import ListTemplate from '../_ComponentHelper/ListTemplate'
import { getAllCountryPaged, deleteCountry } from '../../../service/Country';

class CountryList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			sorted: [{ id: "Id", desc: false }],
			page: 0,
			pageSize: 10,
			expanded: {},
			resized: [],
			filtered: [],
			loading: true,
			pages: null,
			data: [],
		}
		this.fetchData = this.fetchData.bind(this);
		this.requestData = this.requestData.bind(this);
		this.handleDeleteStateClick = this.handleDeleteStateClick.bind(this);
	}

	componentDidMount() {
		this.fetchData(this.state);
	}

	fetchData(state, instance) {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		this.setState({ loading: true });
		// Request the data however you want.  Here, we'll use our mocked service we created earlier
		this.requestData(
			this.state,
			state.pageSize,
			state.page,
			state.sorted,
			state.filtered
		).then(res => {
			// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
			this.setState({
				data: res.rows,
				pages: res.pages,
				loading: false
			});
		});
	}

	requestData = (currentState, pageSize, page, sorted, filtered) => {
		return new Promise((resolve, reject) => {
			// You can retrieve your data however you want, in this case, we will just use some local data.
			var tableModal = {
				QuantityPerPage: pageSize,
				Page: page + 1,
			}

			if (filtered.length > 0) {
				for (var i = 0; i < filtered.length; i++) {
					var temp = filtered[i].id;
					tableModal[temp] = filtered[i].value;
				}
			}

			if (sorted.length > 0) {
				tableModal.sorted = []
				for (var i = 0; i < sorted.length; i++) {
					tableModal.sorted.push(sorted[i])
				}
			}

			getAllCountryPaged(tableModal)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					let filteredData = json;

					// You must return an object containing the rows of the current page, and optionally the total pages number.
					const result = {
						// rows: sortedData.slice(pageSize * page, pageSize * page + pageSize),
						rows: filteredData.Item2,
						pages: filteredData.Item1,
					};
					resolve(result);
				})
				.catch(console.log);
		});
	};

	handleDeleteStateClick(event) {
		var id = parseInt(event.currentTarget.id)
		swal({
			title: "Please confirm",
			text: "Are you sure that you want to delete this record? ID: " + id,
			buttons: true,
			dangerMode: true,
		})
			.then((result) => {
				if (result) {
					this.setState({ loading: true },
						() => deleteCountry(id).then(() => {
							this.fetchData(this.state)
						}))
				}
			})
	}

	render() {
		const now = new Date();
		const time = moment(now).format('YYYYMMDDHHmmss');

		const { data, pages, loading } = this.state;

		const dataPagination = data.map(item => {
			return {
				Id: item.Id,
				CountryName: item.CountryName,
				Abbreviation: item.Abbreviation,

			}
		});

		const columns = [{
			id: 'Id',
			Header: 'Id',
			accessor: 'Id',
			width: 80,
		}
			, {
			id: 'CountryName',
			Header: 'Country Name',
			accessor: 'CountryName',
		}
			, {
			id: 'Abbreviation',
			Header: 'Abbreviation',
			accessor: 'Abbreviation',
		}


			, {
			Header: 'ACTION',
			Cell: row => (
				<div>
					<Link to={MyConstantsFile.APP_ADMIN_COUNTRY_EDIT_CALL_PATH + row.original.Id} title="Edit"><i className="fas fa-pencil-alt"></i></Link>
					<br />
					<span id={row.original.Id} onClick={this.handleDeleteStateClick} title="Remove" className="mouseDelete"><i className="fa fa-trash"></i></span>
				</div>
			),
			width: 100,
			filterable: false,
		}]


		return (
			<ListTemplate
				pageName={MyConstantsFile.PAGE_NAME_COUNTRY}
				redirectTo={MyConstantsFile.APP_ADMIN_COUNTRY_ADD_PATH}
			>
				<ReactTable
					data={dataPagination}
					columns={columns}
					pages={pages}
					loading={loading}
					manual
					minRows={1}
					filterable
					defaultPageSize={10}
					className="-striped -highlight"
					// Controlled props
					sorted={this.state.sorted}
					page={this.state.page}
					pageSize={this.state.pageSize}
					expanded={this.state.expanded}
					resized={this.state.resized}
					filtered={this.state.filtered}
					// Callbacks
					onSortedChange={sorted => this.setState({ sorted }, () => { this.fetchData(this.state) })}
					onPageChange={page => this.setState({ page }, () => { this.fetchData(this.state) })}
					onPageSizeChange={(pageSize, page) =>
						this.setState({ page, pageSize }, () => { this.fetchData(this.state) })}
					onExpandedChange={expanded => this.setState({ expanded })}
					onResizedChange={resized => this.setState({ resized })}
					onFilteredChange={filtered => {
						this.setState({ filtered }, () => {
							if (filtered.length === 0 || (filtered.length > 0 && filtered[0].value.length > 2)) {
								this.state.page = 0
								this.fetchData(this.state)
							}
						})
					}}
				/>
			</ListTemplate>
		);
	}
}

export default CountryList;
