import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Select from 'react-select';

import * as MyConstantsFile from '../../../util/MyConstantsFile'
import FormTemplate from '../_ComponentHelper/FormTemplate'
import { getNotificationPreference, saveNotificationPreference } from '../../../service/NotificationPreference';




class MyForm extends Component {
	constructor(props) {
		super(props);
			
    }

    
    
    

    componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

    render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
		} = this.props;


    
        

    return (
			<form onSubmit={handleSubmit}>
				
            
                <div className={touched.NotificationPreferenceLabel && errors.NotificationPreferenceLabel ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="NotificationPreferenceLabel">Notification Preference Label</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="NotificationPreferenceLabel" className="form-control" value={values.NotificationPreferenceLabel} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.NotificationPreferenceLabel && touched.NotificationPreferenceLabel && <span className="text-danger">{errors.NotificationPreferenceLabel}</span>}
				</div>


										

            <div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}
						disabled={isSubmitting}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>

			</form>
		);
	}
}







const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({

        

        NotificationPreferenceLabel: Yup.string()
			.trim()
			.required('NotificationPreferenceLabel is required!'),
		
}),

	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSave(values)
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (json.success)
					props.onLoadNextPage()
			})
			.catch((error) => {
				setSubmitting(false);
			});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, NotificationPreferenceLabel: '',  };

class NotificationPreferenceForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema)
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleLoadNextPage = this.handleLoadNextPage.bind(this);
	}

	componentDidMount() {
		let id = this.state.id;
		if (id) {
		    getNotificationPreference(id)
			    .then(res => res.json().then(json => ({ res, json })))
			    .then(({ res, json }) => {
		            this.setState({ model: json });
		    });
        }
    }

    handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
        });
    }


    handleSave(values) {
		return saveNotificationPreference(values);
    }



    handleLoadNextPage() {
		this.props.history.push(MyConstantsFile.APP_ADMIN_NOTIFICATIONPREFERENCE_PATH);
	}

		render() {
		return (
			<FormTemplate
				pageName={MyConstantsFile.PAGE_NAME_NOTIFICATIONPREFERENCE}
				pageListDisplayPath={MyConstantsFile.APP_ADMIN_NOTIFICATIONPREFERENCE_PATH}
				action={typeof this.state.id === 'undefined' ? 'Add' : 'Edit'}
			>
				<MyEnhancedForm model={this.state.model}
					onSave={this.handleSave}
					onReset={this.handleReset}
					onLoadNextPage={this.handleLoadNextPage}
				/>
			</FormTemplate>
		);
	}
}

export default NotificationPreferenceForm;
