import React, { Component } from 'react';
import moment from 'moment';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import swal from 'sweetalert'
import Popup from "reactjs-popup";
import { withAlert } from "react-alert";

import { saveProductPhoto, deleteProductPhoto, getPhotoPerProduct } from '../service/ProductPhoto';

import * as MyConstantsFile from '../util/MyConstantsFile'

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class UploadMultipleImagesHelper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			crop: {
				x: 0,
				y: 0,
				width: 100,
				height: 100,
			},
			loadFromDataBase: false,
			imagePreview: '',
			tabActive: 1,
			list: [],
		};
		this.handleImageLoaded = this.handleImageLoaded.bind(this)
		this.handleImageChange = this.handleImageChange.bind(this);
		this.handleCropChange = this.handleCropChange.bind(this);
		this.handleCropComplete = this.handleCropComplete.bind(this);
		this.handleCropSave = this.handleCropSave.bind(this);
		this.handleDeletePhoto = this.handleDeletePhoto.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

	handleCropSave() {
		var allPhotos = this.state.list;
		var cropped = this.getCroppedImg(this.state.image, this.state.pixelCrop);

		var data = {};
		data.ProductId = this.props.productId;

		if (cropped !== '') {
			data = Object.assign(data, { Image: cropped.replace('data:image/jpeg;base64,', '') });
		}

		return saveProductPhoto(data).then(res => {
			if (res.ok) {
				getPhotoPerProduct(this.props.productId)
					.then(res => res.json()
						.then(json => ({ res, json })))
					.then(({ res, json }) => {
						this.setState({ list: json });
					})
					.then(this.props.alert.success("You Image has been successfully uploaded"))
					.catch(console.log);
			}
		});
	}

	handleImageLoaded(image) {
		this.setState({ image });
	}

	handleImageChange(e) {
		const imageType = /^image\//;
		const file = e.target.files.item(0);

		if (!file || !imageType.test(file.type)) {
			return;
		}

		const reader = new FileReader();

		reader.onload = (data) => {
			let dataUrl = data.target.result;
			this.setState({ imagePreview: dataUrl });
			//loadEditView(e2.target.result);
		};

		reader.readAsDataURL(file);
	}

	handleCropChange(crop) {
		this.setState({ crop });
	}

	handleCropComplete(crop, pixelCrop) {
		this.setState({ pixelCrop });
	}

	getCroppedImg(image, pixelCrop, fileName) {
		const canvas = document.createElement('canvas');

		if (!pixelCrop) {
			pixelCrop = {}
			pixelCrop.x = 0;
			pixelCrop.y = 0;
			pixelCrop.width = image.naturalWidth;
			pixelCrop.height = image.naturalHeight;
		}

		var MAX_WIDTH = 700;
		var MAX_HEIGHT = 700;
		var width = pixelCrop.width;
		var height = pixelCrop.height;

		if (width > height) {
			if (width > MAX_WIDTH) {
				height *= MAX_WIDTH / width;
				width = MAX_WIDTH;
			}
		} else {
			if (height > MAX_HEIGHT) {
				width *= MAX_HEIGHT / height;
				height = MAX_HEIGHT;
			}
		}


		canvas.width = width;
		canvas.height = height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			pixelCrop.x,
			pixelCrop.y,
			pixelCrop.width,
			pixelCrop.height,
			0,
			0,
			width,
			height
		);

		// As Base64 string
		const base64Image = canvas.toDataURL('image/jpeg');

		return base64Image;
	}

	handleDeletePhoto(event) {
		var id = parseInt(event.currentTarget.id);
		swal({
			title: "Please confirm",
			text: "Are you sure that you want to delete this record?",
			// icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((result) => {
			if (result) {
				deleteProductPhoto(id).then(() => {
					for (var i = 0; i < this.state.list.length; i++) {
						if (this.state.list[i].Id == id) {
							this.state.list.splice(i, 1);
							this.setState({ list: this.state.list })
							break;
						}
					}
				});
			}
		});
	}

	openModal() {
		this.setState({
			crop: {
				x: 0,
				y: 0,
				width: 100,
				height: 100,
			},
			open: true,
			imagePreview: '',
			pixelCrop: null,
		});
	};

	closeModal() {
		this.setState({ open: false });
	};

	render() {
		const {
			isSubmitting,
			handleSubmit,
		} = this.props;
		const now = new Date();
		const time = moment(now).format('YYYYMMDDHHmmss');

		const created = moment().format('MM/DD/YYYY HH:mm');
		const updated = moment().format('MM/DD/YYYY HH:mm');

		if (this.state.loadFromDataBase == false && this.props.productId && this.props.productId > 0) {
			this.state.loadFromDataBase = true;
			this.setState({ list: this.props.productImages })
		}

		const listOfPhotos = this.state.list.map((item) => (
			<div className="col-2 border m-2">
				<a className="float-right" style={{ cursor: 'pointer' }} id={item.Id} onClick={this.handleDeletePhoto}><i className="far fa-times-circle"></i></a>
				<img src={`${STORAGE_URL}product/${item.Photo}?${time}`} style={{ width: '200px' }} /><br />
			</div>
		));

		return (
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col product-template">
						<h4>
							Please upload up to 5 product images.
						{/* <span className="semi-bold"> our website  </span>
							should your product earn our certification. */}
						</h4>


						<div className="form-group">
							<div className="row">
								{listOfPhotos}
							</div>

							<label className="btn btn-small btn-primary mb-4" onClick={this.openModal}>
								Upload Image
       						</label>

							<Popup
								open={this.state.open}
								onClose={this.closeModal}
								closeOnDocumentClick={false}
								closeOnEscape={false}
								modal>
								{close => (
									<div className="style-test style-modal">
										<a className="close" onClick={this.closeModal}>
											&times;
        								</a>

										<div className="modal-header">
											<h4 className="modal-title">Upload Image</h4>
										</div>
										<div className="modal-body">
											<div>
												<input type="file" id="file-picker" className="form-control" accept=".jpg" onChange={this.handleImageChange} />
											</div>
											<div className="form-group">
												<ReactCrop
													crop={this.state.crop}
													src={this.state.imagePreview}
													onImageLoaded={this.handleImageLoaded}
													onComplete={this.handleCropComplete}
													onChange={(crop) => this.setState({ crop })}
												/>
											</div>
										</div>
										<div className="modal-footer">
											<button type="button" disabled={!this.state.imagePreview || this.state.imagePreview === ''} className="btn btn-primary"
												onClick={(e) => {
													this.handleCropSave(e);
													this.closeModal();
												}}>Save</button>
										</div>

									</div>
								)}
							</Popup>
						</div>


						<div className="row noneDisplay">
							<div className="form-group col-6">
								<label className="form-label">Created Date</label>
								<div className="input-group">
									<input type="text" readOnly="readonly" className="form-control" value={created} />
								</div>
							</div>
							<div className="form-group col-6">
								<label className="form-label">Updated Date</label>
								<div className="input-group">
									<input type="text" readOnly="readonly" className="form-control" value={updated} />
								</div>
							</div>
						</div>
						<div className="pull-right">
							<button className="btn btn-small btn-primary"
								onClick={() => {
									this.props.history.push(
										{
											pathname: `${this.props.levelAccess == "Administrator" ? MyConstantsFile.APP_ADMIN_PRODUCT_ADMIN_PATH : MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_PATH}`,
										})
								}}>Submission completed</button>
						</div>
					</div>

				</div> {/* className="row" */}
			</form>
		);
	}
}

export default withAlert(UploadMultipleImagesHelper);