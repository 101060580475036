import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

import { BrowserRouter, Router, Switch, Route, Redirect, NavLink } from 'react-router-dom';
import { createBrowserHistory } from "history";

import Login from '../Login';
import ManufacturerLogin from '../Manufacturer/Login';
import ManufacturerLead from '../Manufacturer/Lead';
import ManufacturerForgotPassword from '../Manufacturer/ForgotPassword'
import ManufacturerResetPassword from '../Manufacturer/ResetPassword'
import Register from '../Register';
import App from '../App';
import AppDashboardPage from '../../Dashboard/AppDashboard/index';
import AppWebsite from '../../Website/AppWebsite/index';
import CompleteProfile from '../../Dashboard/CommunityMember/CompleteProfile';

import * as MyConstantsFile from '../../../util/MyConstantsFile'
// import ResetPassword from '../CommunityMember/ResetPassword'

class AppCookies extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	}

	constructor(props) {
		super(props);

		const { cookies } = props;

		this.handleSetCookies = this.handleSetCookies.bind(this);
		this.handleGetCookies = this.handleGetCookies.bind(this);
	}

	handleSetCookies(token) {
		const { cookies } = this.props;
		cookies.set('token', token, { path: '/' });
	}

	handleGetCookies() {
		const { cookies } = this.props;
		return cookies.get('token');
	}

	render() {
		return (
			<BrowserRouter>
				<Switch>
					<Route path='/Login' render={props => <Login handleSetToken={this.handleSetCookies} />} />
					<Route path='/CommunityMember/CompletProfile' component={CompleteProfile} />
					<Route path='/Client/Login' render={props => <ManufacturerLogin handleSetToken={this.handleSetCookies} />} />
					<Route path="/Client/Forgot-Password" component={ManufacturerForgotPassword} />
					<Route path="/Client/Reset-Password" component={ManufacturerResetPassword} />
					<Route path='/Client/Lead' component={ManufacturerLead} />
					{/* <Route path='/Register' component={Register} /> */}
					<Route path='/Dashboard' render={props => <AppDashboardPage handleGetToken={this.handleGetCookies} handleSetToken={this.handleSetCookies} />} />
					<Route path={MyConstantsFile.APP_ADMIN_PATH} render={props => <App handleGetToken={this.handleGetCookies} />} />
					<Route path="/" component={AppWebsite} />
				</Switch>
			</BrowserRouter>
		);
	}
}

export default withCookies(AppCookies);