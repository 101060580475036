import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/SubscribeManufacturer`;

export const getAllSubscribe = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getAllSubscribePaged = (tableModel) => {
	let url = `${endpoint}/GetAllPaged/`;
	let method = 'POST';
		
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};

export const getSubscribe = id => {
	let url = `${endpoint}/${id}`;
	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveSubscribe = (subscriber) => {
	let url = endpoint;
	let method = 'POST';
	
	if(subscriber.Id && subscriber.Id > 0)
	{
		url += `/${subscriber.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(subscriber)
	}, getFetchOpts()));
};

export const deleteSubscribe = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
