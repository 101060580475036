import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/IncomeGroup`;

export const getAllIncomeGroup = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getAllIncomeGroupPaged = (tableModel) => {
	let url = `${endpoint}/GetAllPaged/`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};


export const getIncomeGroup = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveIncomeGroup = (incomeGroup) => {
	let url = endpoint;
	let method = 'POST';
	
	if(incomeGroup.Id && incomeGroup.Id > 0)
	{
		url += `/${incomeGroup.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(incomeGroup)
	}, getFetchOpts()));
};

export const deleteIncomeGroup = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};
