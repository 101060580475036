import React, { Component } from 'react';
import moment from 'moment';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import swal from 'sweetalert';
import StarRatings from 'react-star-ratings';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

import CommunityMemberInfoModal from './CommunityMemberInfoModal';
import { saveCommunityMemberProduct } from '../../../service/CommunityMemberProduct';
import { filterPaginationCommunityMember } from '../../../service/CommunityMember';

class ListPaginate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedCommunityMemberId: [],
			selectedCommunityMemberEmail: [],
			selectedCommunityMemberFirstName: [],
			selectedCommunityMemberPickUp: [],
			selected: {},
			selectAll: 0,
			isSubmitting: false,
			sorted: [{ id: "Id", desc: true }],
			page: 0,
			pageSize: 10,
			expanded: {},
			resized: [],
			filtered: [],
			loading: true,
			pages: null,
			data: [],
		}

		this.toggleRow = this.toggleRow.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.requestData = this.requestData.bind(this);
	}

	componentDidMount() {
		this.fetchData(this.state)
	}


	toggleRow(Id, Email, FirstName, PickUp) {
		var index = this.state.selectedCommunityMemberId.indexOf(Id);

		if (index > -1)
			this.setState({
				selectedCommunityMemberId: this.state.selectedCommunityMemberId.filter(item => item !== Id),
				selectedCommunityMemberEmail: this.state.selectedCommunityMemberEmail.filter(item => item !== Email),
				selectedCommunityMemberFirstName: this.state.selectedCommunityMemberFirstName.filter(item => item !== FirstName),
				selectedCommunityMemberPickUp: this.state.selectedCommunityMemberPickUp.filter(item => item !== PickUp),
				selectAll: 2,
			});
		else
			this.setState(prevState => ({
				selectedCommunityMemberId: [...prevState.selectedCommunityMemberId, Id],
				selectedCommunityMemberEmail: [...prevState.selectedCommunityMemberEmail, Email],
				selectedCommunityMemberFirstName: [...prevState.selectedCommunityMemberFirstName, FirstName],
				selectedCommunityMemberPickUp: [...prevState.selectedCommunityMemberPickUp, PickUp],
				selectAll: 2,
			}));
	}

	toggleSelectAll(data) {
		if (this.state.selectAll === 0)
			this.setState({
				selectedCommunityMemberId: data.map(item => { return item.Id }),
				selectedCommunityMemberEmail: data.map(item => { return item.Email }),
				selectedCommunityMemberFirstName: data.map(item => { return item.FirstName }),
				selectedCommunityMemberPickUp: data.map(item => { return item.PickUp === 'Yes' }),
				selectAll: 1,
			});
		else this.setState({
			selectedCommunityMemberId: [],
			selectedCommunityMemberEmail: [],
			selectedCommunityMemberFirstName: [],
			selectedCommunityMemberPickUp: [],
			selectAll: 0,
		});
	}

	handleSubmit(event) {
		this.setState({
			isSubmitting: true
		})
		event.preventDefault();
		var CommunityMemberObj = [];

		for (var i = 0; i < this.state.selectedCommunityMemberId.length; i++) {
			var communityMemberProduct = [{
				ProductId: this.props.productId,
				CommunityMemberId: this.state.selectedCommunityMemberId[i],
				DateInvited: moment().format('YYYY-MM-DD'),
			}];

			CommunityMemberObj = [...CommunityMemberObj, {
				Email: this.state.selectedCommunityMemberEmail[i],
				FirstName: this.state.selectedCommunityMemberFirstName[i],
				PickUp: this.state.selectedCommunityMemberPickUp[i],
				CommunityMemberProduct: communityMemberProduct
			}]
		}

		return saveCommunityMemberProduct(CommunityMemberObj)
			.then(res => {
				if (res.ok)
					swal({
						title: "CommunityMember have been assigned and emails have been sent.",
						icon: "success",
					})
						.then((result) => {
							if (result) {
								this.props.onHandleEdit({ type: "delete", index: this.props.activeIndex });
								this.props.refreshData();
								this.props.history.push(MyConstantsFile.APP_ADMIN_PRODUCT_ADMIN_PATH);
							}

							this.setState({
								isSubmitting: false
							})
						});
			});
	}

	fetchData(state, instance) {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		this.state.loading = true
		// Request the data however you want.  Here, we'll use our mocked service we created earlier
		this.requestData(
			this.state,
			state.pageSize,
			state.page,
			state.sorted,
			state.filtered
		).then(res => {
			// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
			this.setState({
				data: res.rows,
				pages: Math.ceil(res.pages / state.pageSize),
				loading: false
			});
		});
	}

	requestData = (currentState, pageSize, page, sorted, filtered) => {
		return new Promise((resolve, reject) => {
			// You can retrieve your data however you want, in this case, we will just use some local data.

			var tableModal = Object.assign({}, this.props.filterInfo)

			tableModal.QuantityPerPage = pageSize
			tableModal.Page = page + 1

			if (filtered.length > 0) {
				for (var i = 0; i < filtered.length; i++) {
					var temp = filtered[i].id

					if (temp !== 'HasTested' && temp !== 'HasInvited' && temp !== 'PickUp')
						tableModal[temp] = filtered[i].value;

					else {
						var temp2 = filtered[i].id;

						switch (temp2) {
							case 'HasTested':
								tableModal[temp2] = filtered[i].value === 'all' ? null : filtered[i].value === 'true' ? 1 : 0;
								break;
							case 'HasInvited':
								tableModal[temp2] = filtered[i].value === 'all' ? null : filtered[i].value === 'true' ? 1 : 0;
								break;
							case 'PickUp':
								tableModal[temp2] = filtered[i].value === 'all' ? null : filtered[i].value === 'true' ? 1 : 0;
								break;
							default:
								break;
						}
					}
				}
			}

			if (sorted.length > 0) {
				tableModal.sorted = []
				for (var j = 0; j < sorted.length; j++) {
					tableModal.sorted.push(sorted[j])
				}
			}

			filterPaginationCommunityMember(tableModal).then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					if (res.ok) {

						// You must return an object containing the rows of the current page, and optionally the total pages number.
						const result = {
							rows: json.Item2,
							pages: json.Item1 > 0 ? json.Item1 : 0
						};
						resolve(result)
					}
				})
				.catch(console.log);
		});
	};

	render() {
		const { data, pages, loading } = this.state

		const dataPagination = data.map(item => {
			return ({
				Id: item.Id,
				PickUp: item.PickUp ? 'Yes' : 'No',
				FirstName: item.FirstName,
				LastName: item.LastName,
				Email: item.Email,
				// Address: item.Street,
				// Address2: item.Apartment,
				City: item.City,
				State: item.State ? item.State.StateName : '',
				// PostalCode: item.PostalCode,
				Country: item.Country ? item.Country.CountryName : '',
				// PrimaryPhone: item.PrimaryPhone,
				// HasTested: item.HasTested ? 'Yes' : 'No',
				HasTested: item.CommunityMemberProduct.filter(item => item.Accepted).length > 0 ?
					`Yes (${item.CommunityMemberProduct.filter(item => item.Accepted)[0].RangePriceProduct})`
					: 'No',
				HasInvited: item.CommunityMemberProduct.filter(item => (item.Accepted === null || item.Accepted) && moment(item.Product.Campaign.AnnouncementDate).isAfter(moment())).length > 0 ?
					`Yes (${item.CommunityMemberProduct.filter(item => (item.Accepted === null || item.Accepted) && moment(item.Product.Campaign.AnnouncementDate).isAfter(moment())).length})`
					: 'No',
				Rating: item.Rating,
				DOB: item.DOB,
				QuantityProductTested: item.CommunityMemberProduct.filter(item => item.Accepted).length,
				CommunityMemberChildren: item.CommunityMemberChildren,
				CommunityMemberProduct: item.CommunityMemberProduct,
				ExpectingKid: item.ExpectingKid,
				ExpectingKidDueDate: item.ExpectingKidDueDate
			})
		})

		const columns = [
			{
				id: 'checkBox',
				Header: x => {
					return (
						<input
							type="checkbox"
							className="checkbox"
							checked={this.state.selectAll === 1}
							ref={input => {
								if (input) {
									input.indeterminate = this.state.selectAll === 2;
								}
							}}
							onChange={() => this.toggleSelectAll(dataPagination)}
						/>
					);
				},
				accessor: "",
				Cell: ({ original }) => {
					return (
						<input
							type="checkbox"
							checked={this.state.selectedCommunityMemberId.filter(item => item === original.Id).length > 0}
							onChange={() => this.toggleRow(original.Id, original.Email,
								original.FirstName, original.PickUp === "Yes")}
						/>
					);
				},
				width: 45,
				sortable: false,
			}, {
				id: 'Id',
				Header: 'ID',
				accessor: 'Id',
				width: 70,
			}, {
				id: 'PickUp',
				Header: 'PICKUP',
				accessor: 'PickUp',
				Filter: ({ filter, onChange }) =>
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="all">Show All</option>
						<option value="true">Yes</option>
						<option value="false">No</option>
					</select>,
				width: 80,
			}, {
				id: 'HasInvited',
				Header: 'INVITED?',
				accessor: 'HasInvited',
				Filter: ({ filter, onChange }) =>
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="all">Show All</option>
						<option value="true">Yes</option>
						<option value="false">No</option>
					</select>,
				width: 80,
				sortable: false,
			}, {
				id: 'FirstName',
				Header: 'FIRST NAME',
				accessor: 'FirstName',
				Cell: row => (<CommunityMemberInfoModal rowInfo={row.original} />
				),
				width: 100,
			}, {
				id: 'LastName',
				Header: 'LAST NAME',
				accessor: 'LastName',
				width: 100,
			}, {
				id: 'Email',
				Header: 'EMAIL',
				accessor: 'Email',
				width: 200,
				// }, {
				// 	id: 'Address',
				// 	Header: 'ADDRESS',
				// 	accessor: 'Address',
				// 	width: 100,
				// }, {
				// 	id: 'Address2',
				// 	Header: 'ADDRESS 2',
				// 	accessor: 'Address2',
				// 	width: 100,
			}, {
				id: 'City',
				Header: 'CITY',
				accessor: 'City',
				width: 110,
			}, {
				id: 'State',
				Header: 'STATE',
				accessor: 'State',
				width: 110,
				// }, {
				// 	id: 'PostalCode',
				// 	Header: 'ZIP CODE/POSTAL CODE',
				// 	accessor: 'PostalCode',
				// 	width: 100,
			}, {
				id: 'Country',
				Header: 'COUNTRY',
				accessor: 'Country',
				width: 100,
				// }, {
				// 	id: 'PrimaryPhone',
				// 	Header: 'PRIMARY PHONE',
				// 	accessor: 'PrimaryPhone',
			}, {
				id: 'HasTested',
				Header: 'COMPLETED EVALUATORS',
				accessor: 'HasTested',
				Filter: ({ filter, onChange }) =>
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value='all'>Show All</option>
						<option value="true">Yes</option>
						<option value="false">No</option>
					</select>,
				width: 80,
				sortable: false,
			}, {
				id: 'Rating',
				Header: 'Rating',
				Cell: row => (
					<div>
						<StarRatings
							rating={row.original.Rating != null ? row.original.Rating : 0}
							starRatedColor="blue"
							numberOfStars={5}
							name='rating'
							starDimension="25px"
							starSpacing="2px"
						/>
					</div>
				),
				filterMethod: (filter, row) => {
					switch (filter.value) {
						case "all":
							return true;
						case "1":
							return row._original.Rating === 1;
						case "2":
							return row._original.Rating === 2;
						case "3":
							return row._original.Rating === 3;
						case "4":
							return row._original.Rating === 4;
						case "5":
							return row._original.Rating === 5;
						default:
							return true;
					}
				},
				Filter: ({ filter, onChange }) =>
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="all">Show All</option>
						<option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
						<option value="5">5</option>
					</select>,
				width: 100,
			}]


		return (
			<div className="align-items-center">
				<div className="col-12">
					<div className="grid simple horizontal">
						<div className={this.state.CommunityMember == '' ? 'noneDisplay' : "blockDisplay grid-title.no-border-top"}>
							<form onSubmit={this.handleSubmit}>
								<div className="product-template-fullscreen border-0 mb-3 container">

									<span>{'Users selected: ' + this.state.selectedCommunityMemberId.length}</span>

									<ReactTable
										data={dataPagination}
										columns={columns}
										pages={pages}
										loading={loading}
										manual
										minRows={1}
										filterable
										defaultPageSize={10}
										className="-striped -highlight col-12"
										// Controlled props
										sorted={this.state.sorted}
										page={this.state.page}
										pageSize={this.state.pageSize}
										expanded={this.state.expanded}
										resized={this.state.resized}
										filtered={this.state.filtered}
										// Callbacks
										onSortedChange={sorted => this.setState({ sorted, loading: true }, () => { this.fetchData(this.state) })}
										onPageChange={page => this.setState({ page, loading: true }, () => { this.fetchData(this.state) })}
										onPageSizeChange={(pageSize, page) =>
											this.setState({ page, pageSize, loading: true }, () => { this.fetchData(this.state) })}
										onExpandedChange={expanded => this.setState({ expanded })}
										onResizedChange={resized => this.setState({ resized })}
										onFilteredChange={filtered => {
											this.setState({ filtered, loading: true, page: 0, }, () => {
												if (filtered.length === 0 || (filtered.length > 0 && filtered[0].value.length > 2)) {
													this.fetchData(this.state)
												}
											})
										}}
									/>

									<div className="mt-1">
										<div className="form-actions">
											<div className="pull-right">
												<button className={this.state.isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}
													disabled={this.state.isSubmitting || this.state.selectedCommunityMemberId.length === 0}
												>{!this.state.isSubmitting ? 'Invite' : 'Inviting'}</button>
											</div>
										</div>
									</div>

								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ListPaginate;