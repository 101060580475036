import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import ReactTable from "react-table";
import 'react-table/react-table.css'
import swal from 'sweetalert'

import { getProductByToken } from '../../../service/Product';
import { getManufacturerToken } from '../../../service/Manufacturer';
import Auth from '../../../util/Auth';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class ProductListManufacturer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            displayTable: 'noneDisplay',
            displayMessageNoProduct: 'noneDisplay',
            sorted: [{ id: "Campaign.AnnouncementDate", desc: true }],
            page: 0,
            pageSize: 10,
            expanded: {},
            resized: [],
            filtered: [],
            loading: true,
            pages: null,
            data: [],
            openCampaign: true,
        }

        this.fetchData = this.fetchData.bind(this);
        this.requestData = this.requestData.bind(this);
        this.renderTableByCampaign = this.renderTableByCampaign.bind(this);
    }

    componentDidMount() {
        this.fetchData(this.state);
    }

    fetchData(state, instance) {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        this.requestData(
            this.state,
            state.pageSize,
            state.page,
            state.sorted,
            state.filtered
        ).then(res => {
            // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
            this.setState({
                data: res.rows,
                pages: Math.ceil(res.pages / state.pageSize),
                loading: false
            });
        });
    }

    requestData = (currentState, pageSize, page, sorted, filtered) => {
        return new Promise((resolve, reject) => {
            // You can retrieve your data however you want, in this case, we will just use some local data.
            var tableModal = {
                QuantityPerPage: pageSize,
                Page: page + 1,
            }

            tableModal.Campaign = {};

            if (filtered.length > 0) {
                for (var i = 0; i < filtered.length; i++) {
                    var temp = filtered[i].id;
                    if (temp != 'CampaignName')
                        tableModal[temp] = filtered[i].value;
                    else {
                        var temp2 = filtered[i].id;

                        switch (temp2) {
                            case 'CampaignName':
                                tableModal.Campaign[temp2] = filtered[i].value;
                                break;
                        }
                    }
                }
            }

            if (sorted.length > 0) {
                tableModal.sorted = []
                for (var i = 0; i < sorted.length; i++) {
                    var temp = sorted[i].id
                    if (temp != 'CampaignName')
                        tableModal.sorted.push(sorted[i])
                    else {
                        switch (sorted[i].id) {
                            case 'CampaignName':
                                tableModal.sorted.push({ id: 'Campaign.CampaignName', desc: sorted[i].desc })
                                break;
                        }
                    }
                }
            }

            getProductByToken(tableModal)
                .then(res => res.json()
                    .then(json => ({ res, json })
                    )).then(({ res, json }) => {
                        this.state.list = json.Item2;

                        let filteredData = json;
                        //display message no products or display table with products
                        if (filteredData.Item2.length > 0)
                            this.state.displayTable = '';
                        else if (this.state.displayTable == 'noneDisplay')
                            this.state.displayMessageNoProduct = '';
                        else if (this.state.displayTable == '')
                            this.state.displayMessageNoProduct = 'noneDisplay';

                        // You must return an object containing the rows of the current page, and optionally the total pages number.
                        const result = {
                            // rows: sortedData.slice(pageSize * page, pageSize * page + pageSize),
                            rows: filteredData.Item2,
                            pages: filteredData.Item1 > 0 ? filteredData.Item1 : 0
                        };
                        resolve(result);
                    })
                .catch(() =>
                    swal({
                        title: "Sorry, this is a member only page",
                        text: "Please login to access this page",
                        icon: '/assets/img/SadFaceResize.png',
                        // buttons: true,
                        dangerMode: true,
                        closeOnClickOutside: false,
                    }).then((result) => {
                        if (result) {
                            if (document.referrer.indexOf("client") > -1)
                                window.location.href = '/client/login';
                            else
                                window.location.href = '/login';
                        }
                    })
                )
                .catch(console.log);
        });
    };

    renderTableByCampaign() {
        this.setState({
            openCampaign: !this.state.openCampaign,
            page: 0
        }, () => this.fetchData(this.state));
    }

    render() {
        const now = new Date();
        const time = moment(now).format('YYYYMMDDHHmmss');

        const { data, pages, loading } = this.state;


        const {
            values,
            handleChange,
            handleBlur,
        } = this.props;

        const columns = [{
            id: 'Id',
            Header: 'ID',
            accessor: 'Id',
            width: 60,
            show: false,
        }, {
            Header: 'IMAGE',
            accessor: 'LargeImage',
            Cell: row => (
                <img src={(row.value !== null && row.value !== '' ? `${STORAGE_URL}product/${row.value}?${time}` : '')}
                    style={{ height: '80px' }} alt="Image not available" />
            ),
            width: 90,
            filterable: false,
        }, {
            id: 'ProductName',
            Header: 'PRODUCT NAME',
            accessor: 'ProductName',
        }, {
            id: 'CampaignName',
            Header: 'CAMPAIGN',
            accessor: d => d.Campaign.CampaignName,
            width: 100,
        }, {
            id: 'SubmissionStatus',
            Header: 'STATUS',
            accessor: d => (d.SubmissionStatus) ? 'Completed' : 'Incomplete',
            Cell: row => (
                <span className={row.value == 'Completed' ? 'bold tracking-status bg-success' : 'bold tracking-status bg-danger'}>{row.value}</span>
            ),
            width: 90,
            filterMethod: (filter, row) => {
                if (filter.value === "null") {
                    return true;
                }
                if (filter.value === "true") {
                    return row.SubmissionStatus == "Completed";
                }
                return row.SubmissionStatus == "Incomplete";
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "null"}
                >
                    <option value="null">Show All</option>
                    <option value="true">Complete</option>
                    <option value="false">Incomplete</option>
                </select>,
            filterable: false,
        }, {
            id: 'PaymentStatus',
            Header: 'PAYMENT',
            accessor: d => (d.PaymentStatus != null) ? 'Paid' : 'Unpaid',
            Cell: row => (
                <span className={row.value == 'Paid' ? 'bold tracking-status bg-success' : 'bold tracking-status bg-danger'}>{row.value}</span>
            ),
            width: 70,
            filterMethod: (filter, row) => {
                if (filter.value === "null") {
                    return true;
                }
                if (filter.value === "true") {
                    return row.PaymentStatus == 'Paid';
                }
                return row.PaymentStatus == 'Unpaid';
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "null"}
                >
                    <option value="null">Show All</option>
                    <option value="true">Paid</option>
                    <option value="false">Unpaid</option>
                </select>,
            filterable: false,
            show: false,
        }, {
            id: 'Action',
            Header: 'ACTIONS',
            Cell: row => (
                <div className={row.original.ProductConfiguration.ClientReport ? "noneDisplay" : ""}>
                    <Link to={{
                        pathname: MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_EDIT_CALL_PATH + row.original.Id,
                        state: { levelAccess: "Manufacturer" }
                    }} title="Edit"><i className="fas fa-pencil-alt"></i></Link>
                    <br />
                </div >
            ),
            width: 80,
            filterable: false,
        }, {
            id: 'ClientReport',
            Header: 'CLIENT REPORT',
            accessor: d => d.ProductConfiguration.ClientReport,
            Cell: row => (
                <div>
                    <a id={row.original.Id} href={row.original.ProductConfiguration.ClientReport}
                        target="_blank" title="Report" className="mouseDelete"><i className={row.original.ProductConfiguration.ClientReport ? "fas fa-chart-pie" : "noneDisplay"}></i></a>
                </div >
            ),
            width: 90,
            filterable: false,
        }]

        return (
            <div>
                <ul className="breadcrumb">
                    <li><p>Product</p></li>
                    <li><a href="#" className="active">Manage</a> </li>
                </ul>
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="grid simple horizontal purple">

                            <div className="grid-title">
                                <div className='tools'>
                                    <Link to={{
                                        pathname: MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_ADD_PATH,
                                        state: { levelAccess: "Manufacturer" }
                                    }} title="Add"><span className="form-control btn btn-primary btn-cons pull-left">Add Product</span></Link>
                                </div>
                                <h3>Product</h3>
                            </div>
                            <div className="grid-body">

                                <div className={this.state.displayTable + " row"}>

                                    <ReactTable
                                        data={data}
                                        columns={columns}
                                        pages={pages}
                                        loading={loading}
                                        manual
                                        minRows={1}
                                        filterable
                                        defaultPageSize={10}
                                        className="-striped -highlight col-12"
                                        // Controlled props
                                        sorted={this.state.sorted}
                                        page={this.state.page}
                                        pageSize={this.state.pageSize}
                                        expanded={this.state.expanded}
                                        resized={this.state.resized}
                                        filtered={this.state.filtered}
                                        // Callbacks
                                        onSortedChange={sorted => this.setState({ sorted }, () => { this.fetchData(this.state) })}
                                        onPageChange={page => this.setState({ page }, () => { this.fetchData(this.state) })}
                                        onPageSizeChange={(pageSize, page) =>
                                            this.setState({ page, pageSize }, () => { this.fetchData(this.state) })}
                                        onExpandedChange={expanded => this.setState({ expanded })}
                                        onResizedChange={resized => this.setState({ resized })}
                                        onFilteredChange={filtered => {
                                            this.setState({ filtered }, () => {
                                                if (filtered.length == 0 || (filtered.length > 0 && filtered[0].value.length > 2)) {
                                                    this.state.page = 0
                                                    this.fetchData(this.state)
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <div className={this.state.displayMessageNoProduct}>
                                    <h4>
                                        Welcome! <br /><br /> Have you made your payment? If not Click
										<span style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(MyConstantsFile.APP_ADMIN_PAYMENTINFORMATION_PATH)}> HERE</span>
                                        . If yes it is time to add your product clicking
										<span style={{ cursor: 'pointer' }} onClick={() => this.props.history.push({
                                            pathname: MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_ADD_PATH,
                                            state: { levelAccess: "Manufacturer" }
                                        })}> HERE</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        );
    }
}

export default ProductListManufacturer;