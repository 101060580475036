import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Auth from '../../../util/Auth';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class AdministratorSideBar extends Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
	}


	handleClick() {
		Auth.deauthenticateCoupon();
		window.location.href = "/login";
	}

	render() {

		return (
			<div className="page-sidebar " id="main-menu">
				<div className="page-sidebar-wrapper scrollbar-dynamic" id="main-menu-wrapper">
				
				{/* <ul>
					<li>
						<Link to="/"><i className="fa fa-home"></i> <span className="title">Dashboard</span></Link>
					</li>
				</ul> */}
				
				<p class="menu-title sm">Daily routine<span class="pull-right"></span></p>
				
				<ul>
					<li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">{MyConstantsFile.PAGE_NAME_COMMUNITYMEMBER}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_COMMUNITYMEMBER_PATH}>Manager</Link></li></ul></li>
					<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_EVALUATOR}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_EVALUATOR_PATH}>Manager</Link></li></ul></li>
					<li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">{MyConstantsFile.PAGE_NAME_PRODUCT}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_PRODUCT_ADMIN_PATH}>Manager</Link></li></ul></li>
					<li><a href="#"> <i className="fa fa-flag-checkered"></i> <span className="title">{MyConstantsFile.PAGE_NAME_CLIENT}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_CLIENT_PATH}>Manager</Link></li></ul></li>
				</ul>
				
				<p class="menu-title sm">WebSite Configuration<span class="pull-right"></span></p>
				
				<ul>
					<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_CAMPAIGN}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_CAMPAIGN_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_CAMPAIGN_PATH}>Manager</Link></li></ul></li>
					<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_REWARD}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_REWARD_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_REWARD_PATH}>Manager</Link></li></ul></li>
					<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_SUBSCRIBER}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_SUBSCRIBER_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_SUBSCRIBER_PATH}>Manager</Link></li></ul></li>
					<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_POINTFEATURE}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_POINTFEATURE_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_POINTFEATURE_PATH}>Manager</Link></li></ul></li>
				</ul>

				<p class="menu-title sm">Demographic Configuration<span class="pull-right"></span></p>

				<ul>
					<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_COUNTRY}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_COUNTRY_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_COUNTRY_PATH}>Manager</Link></li></ul></li>
					<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_STATE}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_STATE_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_STATE_PATH}>Manager</Link></li></ul></li>
					<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_EDUCATIONGROUP}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_EDUCATIONGROUP_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_EDUCATIONGROUP_PATH}>Manager</Link></li></ul></li>
					<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_ETHNICITY}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_ETHNICITY_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_ETHNICITY_PATH}>Manager</Link></li></ul></li>
					<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_INCOMEGROUP}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_INCOMEGROUP_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_INCOMEGROUP_PATH}>Manager</Link></li></ul></li>
					<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_MARITALSTATUS}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_MARITALSTATUS_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_MARITALSTATUS_PATH}>Manager</Link></li></ul></li>
					<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_WORKSTATUSGROUP}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_WORKSTATUSGROUP_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_WORKSTATUSGROUP_PATH}>Manager</Link></li></ul></li>
				</ul>

				<p class="menu-title sm">General Configuration<span class="pull-right"></span></p>

				<ul>


						
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_ADMINISTRATOR}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_ADMINISTRATOR_PATH}>Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_EMAIL}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_EMAIL_PATH}>Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_GENDERTARGET}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_GENDERTARGET_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_GENDERTARGET_PATH}>Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_AGETARGET}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_AGETARGET_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_AGETARGET_PATH}>Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_CALLTOACTION}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_CALLTOACTION_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_CALLTOACTION_PATH}>Manager</Link></li></ul></li>
					

						
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_PACKAGE}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_PACKAGE_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_PACKAGE_PATH}>Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_ALLERGY}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_ALLERGY_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_ALLERGY_PATH}>Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_CATEGORY}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_CATEGORY_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_CATEGORY_PATH}>Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_HEARDABOUT}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_HEARDABOUT_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_HEARDABOUT_PATH}>Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_INDICATEACTIVITY}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_INDICATEACTIVITY_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_INDICATEACTIVITY_PATH}>Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_NOTIFICATIONPREFERENCE}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_NOTIFICATIONPREFERENCE_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_NOTIFICATIONPREFERENCE_PATH}>Manager</Link></li></ul></li>
						<li><a href="#"> <i className="fa fa-rocket"></i> <span className="title">{MyConstantsFile.PAGE_NAME_TAX}</span> <span className=" arrow"></span> </a><ul className="sub-menu"><li><Link to={MyConstantsFile.APP_ADMIN_TAX_ADD_PATH}>Add</Link></li><li><Link to={MyConstantsFile.APP_ADMIN_TAX_PATH}>Manager</Link></li></ul></li>
						

						<li><a onClick={this.handleClick} style={{ cursor: 'pointer' }}> <i className="fa fa-power-off"></i> <span className="title">Log out</span>  </a></li>
						<li> </li>
						<li> </li>
						<li> </li>
						<li> </li>
					</ul>
					<div className="clearfix"></div>
				</div>
			</div>
		);
	}
}

export default AdministratorSideBar;
