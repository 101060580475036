
//mask date
export const screenDateFormat = (date) => {

  var
    str = date,
    parts = str.split('-'),
    year = parseInt(parts[0], 10),
    month = parseInt(parts[1], 10),
    day = parseInt(parts[2], 10);

  if (day < 10) {
    day = String(0 + "" + day);
  }

  if (month < 10) {
    month = String(0 + "" + month);
  }


  return month + "/" + day + "/" + year;

}
