import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getAllCompanyTypes, deleteCompanyTypes } from '../../../service/CompanyTypes';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class CompanyTypesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: []
		}
	}

	componentDidMount() {
		let list;
		getAllCompanyTypes()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ list: json });
				// window.$('#tableEspresso').DataTable();
			})
			.catch(console.log);

	}

	handleClick(id) {
		window.swal({
			title: "Please confirm",
			text: "Are you sure that you want to delete this record? ID: " + id,
			showCancelButton: true,
			cancelButtonText: "Cancel",
			cancelButtonColor: "#ef4836",
			confirmButtonColor: "#E01717",
			showLoaderOnConfirm: true,
			type: "warning"
		}, function (isConfirm) {
			if (isConfirm) {
				deleteCompanyTypes(id).then(function () {
					window.location.href = MyConstantsFile.APP_ADMIN_COMPANYTYPES_PATH;
				});
			}
		});
	}

	render() {
		const items = this.state.list.map((item) => (
			<tr key={item.Id} id={`row${item.Id}`}>
				<td className="v-align-middle">{item.Id}</td>
                    <td>{item.CompanyTypesName}</td>
					
				<td>
					<Link to={MyConstantsFile.APP_ADMIN_COMPANYTYPES_EDIT_CALL_PATH + item.Id} title="Edit"><i className="fas fa-pencil-alt"></i></Link>
					<br />
					<a onClick={(e) => this.handleClick(item.Id)} title="Remove" className="mouseDelete"><i className="fa fa-trash"></i></a>
				</td>
			</tr>
		));

		return (
			<div>
				<ul className="breadcrumb">
					<li><p>Age Target</p></li>
					<li><a href="#" className="active">Manage</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<div className="grid simple horizontal purple">
							<div className="grid-title"><div className="tools">
								<Link to={MyConstantsFile.APP_ADMIN_COMPANYTYPES_ADD_PATH} title="Add"><span className="form-control btn btn-primary btn-cons pull-left">Add CompanyTypes</span></Link>
							</div>
								<h3>CompanyTypes</h3>
							</div>
							<div className="grid-body">
								{/* <table className="table table-hover table-condensed display" id="tableEspresso">
									<thead>
										<tr>
											<th>ID</th>
                                            <th>CompanyTypesName</th>
											
											<th>ACTION</th>
										</tr>
									</thead>
									<tbody>
										{items}
									</tbody>
								</table> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CompanyTypesList;
