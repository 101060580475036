import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import moment from 'moment';
import swal from 'sweetalert'
import Select from 'react-select';
import 'react-select/dist/react-select.css';

import Auth from '../../../util/Auth';
import { getPrize } from '../../../service/Prize';
import { getCommunityMemberToken, saveCommunityMember } from '../../../service/CommunityMember';
import { saveCommunityMemberPrize } from '../../../service/CommunityMemberPrize';
import { getAllCountry } from '../../../service/Country';
import { getStateByCountry } from '../../../service/State';
import { getSystemMessage } from '../../../service/SystemMessage';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class MyForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            listCountry: [],
            listState: [],
            prizeList: null,
        }
        // this.handleUpdateAddress = this.handleUpdateAddress.bind(this);
        this.handlePrizeConfirmationDownload = this.handlePrizeConfirmationDownload.bind(this);
        this.handlePrizeConfirmation = this.handlePrizeConfirmation.bind(this);
    }

    componentDidMount() {
        getAllCountry()
            .then(res => res.json()
                .then(json => ({ res, json })))
            .then(({ res, json }) => {
                this.setState({ listCountry: json });
            })
            .catch(console.log);
        getPrize(this.props.prizeId)
            .then(res => res.json().then(json => ({ res, json })))
            .then(({ res, json }) => {
                this.setState({ prizeList: json });
            })
            .catch(console.log);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.model !== nextProps.model)
            this.props.resetForm();
    }

    // handleUpdateAddress() {
    //     this.props.history.push(
    //         {
    //             pathname: '/Dashboard/CommunityMember/CompleteProfile',
    //             state: { prizeId: this.state.prizeList.Id }
    //         })
    // }

    handlePrizeConfirmation() {
        var data = {}
        data.PrizeId = this.state.prizeList.Id

        swal("We are processing your request...")

        return saveCommunityMemberPrize(data)
            .then(res => res.json()
                .then(json => ({ res, json })))
            .then(({ res, json }) => {
                if (json.success)
                    swal({
                        //change message back end
                        text: "Congratulations! Your reward is being processed. An email has been sent to you with a confirmation.",
                        icon: "success",
                    })
                        .then((result) => {
                            if (result) {
                                this.props.history.push('/Dashboard/Overview')
                            }
                        });
            })
    }

    handlePrizeConfirmationDownload() {
        window.open(this.state.prizeList.Link, "_blank")
    }

    handleChangeCountry = (selectedOption) => {
        this.handleChangeState(null);
        if (selectedOption != null) {
            this.props.values.CountryId = selectedOption.value;
            this.getState(this.props.values.CountryId);
            this.setState({
                CountryId: selectedOption.value,
            });
        } else {
            this.props.values.CountryId = null;
            this.setState({
                CountryId: null,
            });
        }
    }

    getState(countryId) {
        getStateByCountry(countryId)
            .then(res => res.json()
                .then(json => ({ res, json })))
            .then(({ res, json }) => {
                this.setState({ listState: json });
            })
            .catch(console.log);
    }

    handleChangeState = (selectedOption) => {
        if (selectedOption != null) {
            this.props.values.StateId = selectedOption.value;
            this.setState({
                StateId: selectedOption.value,
            });
        } else {
            this.props.values.StateId = null;
            this.setState({
                StateId: null,
            });
        }
    }

    render() {
        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            reset,
        } = this.props;

        const now = new Date();
        const time = moment(now).format('YYYYMMDDHHmmss');

        const image = this.state.prizeList ? this.state.prizeList.PrizeImage !== null && this.state.prizeList.PrizeImage !== '' ? `${STORAGE_URL}prize/${this.state.prizeList.PrizeImage}?${time}` : '' : '';

        const listCountryValue = [];
        const itemsCountry = this.state.listCountry.map((item) =>
            listCountryValue.push({ value: item.Id, label: item.CountryName })
        );

        const listStateValue = [];
        const itemsState = this.state.listState.map((item) =>
            listStateValue.push({ value: item.Id, label: item.StateName })
        );

        if (this.state.StateId == null && this.props.model.StateId > 0) {
            this.getState(this.props.model.CountryId);
            this.setState({ StateId: this.props.model.StateId })
        }

        return (
            <form onSubmit={handleSubmit}>
                <div className="style-container">

                    {this.state.prizeList && this.state.prizeList.Link == '' && <div>
                        <div className="row mb-5">
                            <div className="col-sm-12 col-lg-4 text-center p-4">
                                <img src={image} style={{ width: '400px' }} /><br />
                            </div>
                            <div className="col">
                                <h1>
                                    {"Congratulations " + this.props.values.FirstName + "!"}
                                </h1>
                                <h2 className="mb-5">{'You have claimed \'' + this.state.prizeList.Title + '\'.'}</h2>
                                <h2 className="mb-5">Please confirm your shipping details.</h2>
                            </div>

                        </div>

                        <div className="row justify-content-center mb-5">
                            <div className="text-center">

                                <div className={touched.Street && errors.Street ? 'style-form-group has-error' : 'style-form-group'}>
                                    <div className="row justify-content-center">
                                        <input type="text" className="style-form-control-layout title-layout p-0 m-0" value={"Address Line 1*"} readOnly disabled />
                                        <div>
                                            <input className={this.props.notEdit ? "style-form-control-layout title-layout col-12" : "style-form-control-layout col-12"}
                                                type="text" id="Street" value={values.Street} onChange={handleChange} onBlur={handleBlur}
                                                readOnly={this.props.notEdit} disabled={this.props.notEdit} />
                                            <div className="col-12">
                                                {errors.Street && touched.Street && <span className="text-danger">{errors.Street}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='style-form-group'>
                                    <div className="row justify-content-center">
                                        <input type="text" className="style-form-control-layout title-layout p-0 m-0" value={"Address Line 2"} readOnly disabled />
                                        <div>
                                            <input className={this.props.notEdit ? "style-form-control-layout title-layout col-12" : "style-form-control-layout col-12"}
                                                type="text" id="Apartment" value={values.Apartment} onChange={handleChange} onBlur={handleBlur}
                                                readOnly={this.props.notEdit} disabled={this.props.notEdit} />
                                        </div>
                                    </div>
                                </div>

                                <div className={touched.City && errors.City ? 'style-form-group has-error' : 'style-form-group'}>
                                    <div className="row justify-content-center">
                                        <input type="text" className="style-form-control-layout title-layout p-0 m-0" value={"City*"} readOnly disabled />
                                        <div>
                                            <input className={this.props.notEdit ? "style-form-control-layout title-layout col-12" : "style-form-control-layout col-12"}
                                                type="text" id="City" value={values.City} onChange={handleChange} onBlur={handleBlur}
                                                readOnly={this.props.notEdit} disabled={this.props.notEdit} />
                                            <div className="col-12">
                                                {errors.City && touched.City && <span className="text-danger">{errors.City}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={touched.PostalCode && errors.PostalCode ? 'style-form-group has-error' : 'style-form-group'}>
                                    <div className="row justify-content-center">
                                        <input type="text" className="style-form-control-layout title-layout p-0 m-0" value={"ZIP/Postal Code*"} readOnly disabled />
                                        <div>
                                            <input className={this.props.notEdit ? "style-form-control-layout title-layout col-12" : "style-form-control-layout col-12"}
                                                type="text" id="PostalCode" value={values.PostalCode} onChange={handleChange} onBlur={handleBlur}
                                                readOnly={this.props.notEdit} disabled={this.props.notEdit} />
                                            <div className="col-12">
                                                {errors.PostalCode && touched.PostalCode && <span className="text-danger">{errors.PostalCode}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={touched.CountryId && errors.CountryId ? 'style-form-group has-error' : 'style-form-group'}>
                                    <div className="row justify-content-center">
                                        <input type="text" className="style-form-control-layout title-layout p-0 m-0" value={"Country*"} readOnly disabled />
                                        <div>
                                            <Select className={this.props.notEdit ? "style-form-control-layout title-layout" : "style-form-control-layout"}
                                                name="form-field-Country" type="text" id="CountryId" value={values.CountryId}
                                                onChange={this.handleChangeCountry} options={listCountryValue} readOnly={this.props.notEdit} disabled={this.props.notEdit} />
                                            {errors.CountryId && touched.CountryId && <span className="text-danger">{errors.CountryId}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className={touched.StateId && errors.StateId ? 'style-form-group has-error' : 'style-form-group'}>
                                    <div className="row justify-content-center">
                                        <input type="text" className="style-form-control-layout title-layout p-0 m-0" value={"State/Province*"} readOnly disabled />
                                        <div>
                                            <Select className={this.props.notEdit ? "style-form-control-layout title-layout" : "style-form-control-layout"}
                                                name="form-field-State" type="text" id="StateId" value={values.StateId}
                                                onChange={this.handleChangeState} options={listStateValue} readOnly={this.props.notEdit} disabled={this.props.notEdit} />
                                            {errors.StateId && touched.StateId && <span className="text-danger">{errors.StateId}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >

                        <div className="row justify-content-center mb-5">
                            <div className="col-3 text-center">
                                {this.props.notEdit && <button className="btn btn-cons explore-dashboard text-white bg-danger"
                                    onClick={() => this.props.editing()}
                                >UPDATE INFO</button>}
                                {!this.props.notEdit &&
                                    <button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'SAVE' : 'SAVING'}</button>
                                }
                            </div>

                            {this.props.notEdit && <div className="col-3 text-center">
                                <a className="btn btn-cons explore-dashboard text-white" style={{ backgroundColor: '#8dc53e' }}
                                    onClick={this.handlePrizeConfirmation}
                                >CONFIRM ADDRESS</a>
                            </div>}
                        </div>
                    </div >
                    }

                    {this.state.prizeList && this.state.prizeList.Link != '' && <div>
                        <div className="row mb-5">
                            <div className="col-sm-12 col-lg-4 text-center p-4">
                                <img src={image} style={{ maxWidth: '400px', height: '300px' }} /><br />
                                <a className="btn btn-cons explore-dashboard text-white mt-3" style={{ backgroundColor: '#8dc53e' }}
                                    onClick={this.handlePrizeConfirmationDownload}
                                >CLICK TO DOWNLOAD</a>
                            </div>

                            <div className="col">
                                <h1>
                                    {"Congratulations " + this.props.values.FirstName + "!"}
                                </h1>
                                <h2 className="mb-5">{'You have claimed \'' + this.state.prizeList.Title + '\'.'}</h2>
                            </div>
                        </div>

                    </div >
                    }

                </div>
            </form>
        );
    }
}

const formikEnhancer = withFormik({
    mapPropsToValues: props => ({ ...props.model }),
    validationSchema: Yup.object().shape({
        City: Yup.string()
            .required('City is required!')
            .nullable(true),
        PostalCode: Yup.string()
            .required('Postal code is required!')
            .nullable(true),
        CountryId: Yup.number()
            .required('Country is required!')
            .nullable(true),
        StateId: Yup.number()
            .required('State is required!')
            .nullable(true),
        Street: Yup.string()
            .required('Address is required!')
            .nullable(true),
    }),
    handleSubmit: (values, { setSubmitting, props }) => {
        props.save(values)
            .then(res => {
                if (res.ok) {
                    setSubmitting(false);
                }
                else {
                    getSystemMessage("error").then(res => res.json().then(json => ({ res, json })))
                        .then(({ res, json }) => {
                            swal({
                                title: "Notice",
                                text: json,
                            }).then(setSubmitting(false))
                        });
                }
            })
            .catch((error) => {
                setSubmitting(false);
            });
    },
    //displayName: 'BasicForm', // helps with React DevTools
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = {
    Id: 0, FirstName: '', LastName: '', Email: '', Street: '', Apartment: '', PostalCode: '', CommunityMemberGenderTargetId: null, City: '',
    CountryId: null, StateId: null, DOB: null, MaritalStatusId: null, EducationGroupId: null, WorkStatusGroupId: null,
    IncomeGroupId: null, ExpectingKidDueDate: null, ExpectingKid: null, PickUp: null, ChildrenQtyValue: null, HeardAboutId: null,
    CommunityMemberAllergy: null, CommunityMemberChildren: null, Subscribe: null,
    ProfileImage: '',
};

class RewardCongratulations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: Object.assign(schema, baseSchema),
            notEdit: true,
        }
        this.handleSave = this.handleSave.bind(this);
        this.editing = this.editing.bind(this);
    }

    componentDidMount() {
        if (Auth.isUserAuthenticated())
            getCommunityMemberToken()
                .then(res => res.json()
                    .then(json => ({ res, json })))
                .then(({ res, json }) => {
                    if (json.Status) {
                        this.setState({ model: json });
                    }
                })
                .catch(() =>
                    swal({
                        title: "Sorry, this is a member only page",
                        text: "Please login to access this page",
                        icon: '/assets/img/SadFaceResize.png',
                        // buttons: true,
                        dangerMode: true,
                        closeOnClickOutside: false,
                    }).then((result) => {
                        if (result) {
                            //TODO: change when go to production
                            var indexOfSlash = window.location.href.indexOf('.com');
                            var redirectTo = window.location.href.substring(indexOfSlash + 4);
                            // var indexOfSlash = window.location.href.indexOf(':3000'); // for test ind development
                            // var redirectTo = window.location.href.substring(indexOfSlash + 5);
                            window.location.href = ('/Dashboard/CommunityMember/Login/?redirect=' + redirectTo);
                        }
                    })
                );
    }


    handleSave(values) {
        return saveCommunityMember(values).then(() => this.setState({ notEdit: true }));
    }

    editing() {
        this.setState({ notEdit: false })
    }

    render() {
        return (
            <div>
                <div className="tab-content">
                    <div className="tab-pane active" id="tab1EditUser">
                        <MyEnhancedForm
                            notEdit={this.state.notEdit}
                            editing={this.editing}
                            model={this.state.model}
                            history={this.props.history}
                            save={this.handleSave}
                            prizeId={this.props.location.state.prizeId} />
                    </div>
                </div>
            </div>
        );
    }
}

export default RewardCongratulations;