import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Auth from '../../../util/Auth';
import moment from 'moment';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import swal from 'sweetalert'
import FilterHelper from '../FilterMenu/FilterHelper';
import TableEditable from '../_ComponentHelper/TableEditable';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

import { getAllProduct } from '../../../service/Product';
import { saveProductConfiguration } from '../../../service/ProductConfiguration';
import { saveProduct, deleteProduct, productWinner, productPublished } from '../../../service/Product';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class ProductListAdmin extends Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();

        this.state = {
            showFilter: false,
            filterData: {},
            sorted: [{ id: "Id", desc: true }],
            page: 0,
            pageSize: 10,
            expanded: {},
            resized: [],
            filtered: [],
            loading: true,
            pages: null,
            data: [],
            openCampaign: true,
        }

        this.handleReceivedProductClick = this.handleReceivedProductClick.bind(this);
        this.handleWinnerProductClick = this.handleWinnerProductClick.bind(this);
        this.handlePublishProductClick = this.handlePublishProductClick.bind(this);
        this.hideFilter = this.hideFilter.bind(this);
        this.handleDeleteProductClick = this.handleDeleteProductClick.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.requestData = this.requestData.bind(this);
        this.renderTableByCampaign = this.renderTableByCampaign.bind(this);
        this.saveTesterLimit = this.saveTesterLimit.bind(this);
        this.saveSurveyLink = this.saveSurveyLink.bind(this);
        this.saveClientReport = this.saveClientReport.bind(this);
        this.saveDate = this.saveDate.bind(this);
    }

    componentDidMount() {
        if (Auth.isUserAuthenticated())
            this.fetchData(this.state);
        else
            swal({
                title: "Sorry, this is a member only page",
                text: "Please login to access this page",
                icon: '/assets/img/SadFaceResize.png',
                dangerMode: true,
                closeOnClickOutside: false,
            }).then((result) => {
                if (result) {
                    if (document.referrer.indexOf("client") > -1)
                        window.location.href = '/client/login';
                    else
                        window.location.href = '/login';
                }
            })
    }

    fetchData(state, instance) {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        this.requestData(
            this.state,
            state.pageSize,
            state.page,
            state.sorted,
            state.filtered
        ).then(res => {
            // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
            this.setState({
                data: res.rows,
                pages: res.pages,
                loading: false,
            });
        });
    }

    requestData = (currentState, pageSize, page, sorted, filtered) => {
        return new Promise((resolve, reject) => {
            // You can retrieve your data however you want, in this case, we will just use some local data.
            var tableModal = {
                QuantityPerPage: pageSize,
                Page: page + 1,
            }

            tableModal.Campaign = {};

            if (this.state.openCampaign) {
                tableModal.Campaign = { AnnouncementDate: moment().format("YYYY-MM-DD") }
            }

            if (filtered.length > 0) {
                for (var i = 0; i < filtered.length; i++) {
                    var tempFilter = filtered[i].id;
                    if (tempFilter !== 'CampaignName' && tempFilter !== 'CompanyName')
                        tableModal[tempFilter] = filtered[i].value;
                    else {
                        var tempFilter2 = filtered[i].id;

                        switch (tempFilter2) {
                            case 'CampaignName':
                                tableModal.Campaign[tempFilter2] = filtered[i].value;
                                break;
                            case 'CompanyName':
                                var ManufacturerValue = {};
                                ManufacturerValue.CompanyName = filtered[i].value;
                                tableModal.Manufacturer = ManufacturerValue;
                                break;
                            default:
                                break;
                        }
                    }
                }
            }

            if (sorted.length > 0) {
                tableModal.sorted = []
                for (var j = 0; j < sorted.length; j++) {
                    var tempSort = sorted[j].id
                    if (tempSort !== 'CampaignName' && tempSort !== 'CompanyName')
                        tableModal.sorted.push(sorted[j])
                    else {
                        switch (sorted[j].id) {
                            case 'CampaignName':
                                tableModal.sorted.push({ id: 'Campaign.CampaignName', desc: sorted[j].desc })
                                break;
                            case 'CompanyName':
                                tableModal.sorted.push({ id: 'Manufacturer.CompanyName', desc: sorted[j].desc })
                                break;
                            default:
                                break;
                        }
                    }
                }
            }

            getAllProduct(tableModal)
                .then(res => res.json()
                    .then(json => ({ res, json })
                    )).then(({ res, json }) => {

                        // You must return an object containing the rows of the current page, and optionally the total pages number.
                        const result = {
                            // rows: sortedData.slice(pageSize * page, pageSize * page + pageSize),
                            rows: json.Item2,
                            pages: json.Item1 > 0 ? Math.ceil(json.Item1 / this.state.pageSize) : 0
                        };
                        resolve(result);
                    })
                .catch(() =>
                    swal({
                        title: "Sorry, this is a member only page",
                        text: "Please login to access this page",
                        icon: '/assets/img/SadFaceResize.png',
                        dangerMode: true,
                        closeOnClickOutside: false,
                    }).then((result) => {
                        if (result) {
                            if (document.referrer.indexOf("client") > -1)
                                window.location.href = '/client/login';
                            else
                                window.location.href = '/login';
                        }
                    })
                ).catch(console.log);
        });
    };

    saveTesterLimit(childrenValue, index) {
        var updateData = this.state.data[index];
        updateData.ProductConfiguration.ProductQuantity = childrenValue;

        saveProductConfiguration(updateData.ProductConfiguration)
            .then(res => {
                if (res.ok) {
                    swal({
                        title: "Data Changed!",
                        icon: "success",
                    });

                    this.setState(prevState => {
                        const data = prevState.data.map((item, i) => {
                            if (index === i) {
                                return updateData;
                            }
                            return item;
                        });
                        return data;
                    });
                }
            });
    }

    saveSurveyLink(childrenValue, index) {
        var updateData = this.state.data[index];
        updateData.ProductConfiguration.SurveyMonkeyLink = childrenValue;

        saveProductConfiguration(updateData.ProductConfiguration)
            .then(res => {
                if (res.ok) {
                    swal({
                        title: "Data Changed!",
                        icon: "success",
                    });

                    this.setState(prevState => {
                        const data = prevState.data.map((item, i) => {
                            if (index === i) {
                                return updateData;
                            }
                            return item;
                        });
                        return data;
                    });
                }
            });
    }

    saveClientReport(childrenValue, index) {
        var updateData = this.state.data[index];
        updateData.ProductConfiguration.ClientReport = childrenValue;

        saveProductConfiguration(updateData.ProductConfiguration)
            .then(res => {
                if (res.ok) {
                    swal({
                        title: "Data Changed!",
                        icon: "success",
                    });

                    this.setState(prevState => {
                        const data = prevState.data.map((item, i) => {
                            if (index === i) {
                                return updateData;
                            }
                            return item;
                        });
                        return data;
                    });
                }
            });
    }

    saveDate(childrenValue, index) {
        var updateData = this.state.data[index];
        updateData.ProductConfiguration.EvaluationDeadline = moment(childrenValue).format('MM/DD/YYYY');

        saveProductConfiguration(updateData.ProductConfiguration)
            .then(res => {
                if (res.ok) {
                    swal({
                        title: "Data Changed!",
                        icon: "success",
                    });

                    this.setState(prevState => {
                        const data = prevState.data.map((item, i) => {
                            if (index === i) {
                                return updateData;
                            }
                            return item;
                        });
                        return data;
                    });
                }
            });
    }

    handleReceivedProductClick(event) {
        var id = parseInt(event.currentTarget.id);
        var updateData = this.state.data.filter(item => item.Id === id);
        updateData[0].StatusReceived = true;

        swal({
            title: "Please confirm",
            text: "The product \"" + updateData[0].ProductName + "\" was received?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((result) => {
                if (result) {
                    return saveProduct(updateData[0]).then(res => {
                        if (res.ok) {
                            this.setState(prevState => {
                                const data = prevState.data.map((item) => {
                                    if (item.Id === id) {
                                        return updateData[0];
                                    }
                                    return item;
                                });
                                return data;
                            });
                        }
                    });
                }
            });
    }

    handleWinnerProductClick(event) {
        var id = parseInt(event.currentTarget.id);
        var updateData = this.state.data.filter(item => item.Id === id);
        updateData[0].IsWinner = 1;

        swal({
            title: "Please confirm",
            text: "Please confirm that the product \"" + updateData[0].ProductName + "\" has earned the seal?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((result) => {
                if (result) {

                    return productWinner(updateData[0]).then(res => {
                        if (res.ok) {
                            this.setState(prevState => {
                                const data = prevState.data.map((item) => {
                                    if (item.Id === id) {
                                        return updateData[0];
                                    }
                                    return item;
                                });
                                return data;
                            });
                        }
                    });
                }
            });
    }

    handlePublishProductClick(event) {
        var id = parseInt(event.currentTarget.id);
        var updateData = this.state.data.filter(item => item.Id === id);
        updateData[0].IsPublished = 1;

        swal({
            title: "Please confirm",
            text: "\"" + updateData[0].ProductName + "\" is a winner and should be published live to the website?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((result) => {
                if (result) {

                    return productPublished(updateData[0]).then(res => {
                        if (res.ok) {
                            this.setState(prevState => {
                                const data = prevState.data.map((item) => {
                                    if (item.Id === id) {
                                        return updateData[0];
                                    }
                                    return item;
                                });
                                return data;
                            });
                        }
                    });
                }
            });
    }

    hideFilter() {
        this.setState({ showFilter: false })
    }

    handleDeleteProductClick(event) {
        var id = parseInt(event.currentTarget.id);
        swal({
            title: "Please confirm",
            text: "Are you sure that you want to delete this record? ID: " + id,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((result) => {
                if (result) {
                    deleteProduct(id).then(() => {
                        this.fetchData(this.state);
                    });
                }
            });
    }

    renderTableByCampaign() {
        this.setState({
            openCampaign: !this.state.openCampaign,
            page: 0
        }, () => this.fetchData(this.state));
    }

    render() {
        const now = new Date();
        const time = moment(now).format('YYYYMMDDHHmmss');

        const { data, pages, loading } = this.state;

        const dataPagination = data.map(item => {
            return ({
                Id: item.Id,
                LargeImage: item.LargeImage ? `${STORAGE_URL}product/${item.LargeImage}?${time}` : '',
                ProductName: item.ProductName,
                CampaignName: item.Campaign ? item.Campaign.CampaignName : '',
                CompanyName: item.Manufacturer ? item.Manufacturer.CompanyName : '',
                CompanyId: item.Manufacturer ? item.Manufacturer.Id : 0,
                SubmissionStatus: item.SubmissionStatus ? 'Completed' : 'Incomplete',
                PaymentStatus: item.PaymentStatus !== null ? 'Paid' : 'Unpaid',
                TestersLimit: item.ProductConfiguration ? item.ProductConfiguration.ProductQuantity : 0,
                SurveyLink: item.ProductConfiguration ? item.ProductConfiguration.SurveyMonkeyLink : '',
                ClientReport: item.ProductConfiguration ? item.ProductConfiguration.ClientReport : '',
                EvaluationDeadline: item.ProductConfiguration ? moment(item.ProductConfiguration.EvaluationDeadline).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'),
                CommunityMemberProduct: item.CommunityMemberProduct,
                ProductConfiguration: item.ProductConfiguration,
                QtyOfTestersAssigned: item.CommunityMemberProduct.filter(x => x.Accepted).length,
                TargetAgeGroupFrom: item.AgeTargetStarts ? item.AgeTargetStarts.Name : '',
                TargetAgeGroupFromId: item.AgeTargetStarts ? item.AgeTargetStarts.Id : 0,
                TargetAgeGroupTo: item.AgeTargetEnds ? item.AgeTargetEnds.Name : '',
                TargetAgeGroupToId: item.AgeTargetEnds ? item.AgeTargetEnds.Id : 0,
                TargetAgeGroupParentChildrenId: item.AgeTargetEnds ? item.AgeTargetEnds.CommunityMemberAgeTarget : 0,
                GenderTargetName: item.GenderTarget ? item.GenderTarget.GenderTargetName : '',
                GenderTargetId: item.GenderTargetId,
                PickUp: item.DropShipId === 1 ? null : 1,
                StatusReceived: item.StatusReceived ? 'Received' : 'Not Received',
                IsWinner: item.IsWinner,
                IsPublished: item.IsPublished,
            })
        })

        const columns = [{
            id: 'id',
            Header: 'ID',
            accessor: 'Id',
            width: 60,
        }, {
            id: 'LargeImage',
            Header: 'IMAGE',
            accessor: 'LargeImage',
            Cell: row => (
                <img src={row.original.LargeImage}
                    style={{ height: '80px' }} alt="Not available" />
            ),
            width: 90,
            filterable: false,
        }, {
            id: 'ProductName',
            Header: 'PRODUCT NAME',
            accessor: 'ProductName',
            Cell: row => (
                <div>
                    <span className="text-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            this.props.history.push(
                                {
                                    pathname: MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_EDIT_CALL_PATH + row.original.Id,
                                    state: { levelAccess: "Administrator" }
                                })}
                        title="Edit this product">{row.value}
                    </span>
                </div >
            ),
            width: 150,
        }, {
            id: 'CampaignName',
            Header: 'CAMPAIGN',
            accessor: 'CampaignName',
            width: 100,
        }, {
            id: 'CompanyName',
            Header: 'COMPANY NAME',
            accessor: 'CompanyName',
            Cell: row => (
                <div>
                    <a className="text-primary"
                        style={{ cursor: 'pointer' }}
                        href={MyConstantsFile.APP_ADMIN_CLIENT_EDIT_CALL_PATH + row.original.CompanyId}
                        rel="noopener noreferrer"
                        target="_blank"
                        title="Edit this client">
                        {row.value}
                    </a>
                </div >
            ),
            width: 100,
        }, {
            id: 'EvaluationDeadline',
            Header: 'DUE DATE',
            accessor: 'EvaluationDeadline',
            Cell: row => (
                <TableEditable
                    value={row.value}
                    index={row.index}
                    changingLabelName={row.original.ProductName}
                    type="date"
                    style={{ padding: '5px', width: '50px' }}
                    onHandlerSave={this.saveDate} />
            ),
            width: 90,
            filterable: false,
        }, {
            id: 'SurveyLink',
            Header: 'SURVEY LINK',
            accessor: 'SurveyLink',
            Cell: row => (
                <TableEditable
                    value={row.value}
                    index={row.index}
                    changingLabelName={row.original.ProductName}
                    type="text"
                    style={{ padding: '5px', width: '170px' }}
                    onHandlerSave={this.saveSurveyLink} />
            ),
            width: 90,
            filterable: false,
        }, {
            id: 'ClientReport',
            Header: 'CLIENT REPORT',
            accessor: 'ClientReport',
            Cell: row => (
                <TableEditable
                    value={row.value}
                    index={row.index}
                    changingLabelName={row.original.ProductName}
                    type="text"
                    style={{ padding: '5px', width: '170px' }}
                    onHandlerSave={this.saveClientReport} />
            ),
            width: 90,
            filterable: false,
        }, {
            id: 'SubmissionStatus',
            Header: 'STATUS',
            accessor: 'SubmissionStatus',
            Cell: row => (
                <span className={row.value === 'Completed' ? 'bold tracking-status bg-success' : 'bold tracking-status bg-danger'}>{row.value}</span>
            ),
            width: 90,
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "null"}
                >
                    <option value="null">Show All</option>
                    <option value="true">Complete</option>
                    <option value="false">Incomplete</option>
                </select>,
            filterable: false,
        }, {
            id: 'PaymentStatus',
            Header: 'PAYMENT',
            accessor: 'PaymentStatus',
            Cell: row => (
                <span className={row.value === 'Paid' ? 'bold tracking-status bg-success' : 'bold tracking-status bg-danger'}>{row.value}</span>
            ),
            width: 70,
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "null"}
                >
                    <option value="null">Show All</option>
                    <option value="true">Paid</option>
                    <option value="false">Unpaid</option>
                </select>,
            filterable: false,
        }, {
            id: 'TestersLimit',
            Header: 'TESTERS LIMIT',
            accessor: 'TestersLimit',
            Cell: row => (
                <TableEditable
                    value={row.value}
                    index={row.index}
                    changingLabelName={row.original.ProductName}
                    type="number"
                    style={{ padding: '5px', width: '60px' }}
                    onHandlerSave={this.saveTesterLimit} />
            ),
            width: 70,
            filterable: false,
        }, {
            id: 'QtyOfTestersAssigned',
            Header: 'TESTERS ASSIGNED',
            accessor: 'QtyOfTestersAssigned',
            Cell: row => (
                <div>
                    <span className={
                        (row.value) === row.original.ProductConfiguration.ProductQuantity ?
                            "text-success" : "text-warning"
                    }
                        style={{ cursor: 'pointer' }}
                        title="See invited members list"
                        onClick={() => this.setState({
                            showFilter: true,
                            filterData: row.original,
                        }, () => {
                            window.scroll(0, 0);
                            this.child.current.handleCurrentCommunityMember();
                        })}>
                        {row.value}
                        (
            					{row.original.CommunityMemberProduct.length}
                        )
            		</span>
                </div >
            ),
            width: 70,
            filterable: false,
        }, {
            id: 'StatusReceived',
            Header: 'RECEIVED',
            accessor: 'StatusReceived',
            Cell: row => (
                <span id={row.original.Id} onClick={row.value === 'Received' ? '' : this.handleReceivedProductClick}
                    title={row.value === 'Received' ? '' : 'Mark as Received'} className="mouseDelete">
                    <i className={row.value === 'Received' ? 'fa fa-check cursor-default text-success' : 'fas fa-times text-danger'}></i></span>
            ),
            width: 50,
            filterable: false,
        }, {
            id: 'IsWinner',
            Header: 'WINNER',
            accessor: 'IsWinner',
            Cell: row => (
                <span id={row.original.Id} onClick={row.original.IsWinner === 1 ? '' : this.handleWinnerProductClick}
                    title={row.original.IsWinner === 1 ? 'Winner' : ''} className="mouseDelete">
                    <i className={row.original.IsWinner === 1 ? "text-success fa fa-trophy cursor-default" : "text-warning fa fa-trophy"}></i></span>
            ),
            width: 50,
            filterable: false,
        }, {
            id: 'IsPublished',
            Header: 'PUBLISH ON WEB SITE',
            accessor: 'IsPublished',
            Cell: row => (
                <span id={row.original.Id} onClick={row.original.IsPublished ? '' : this.handlePublishProductClick}
                    title={row.original.IsPublished ? 'Published' : ''} className="mouseDelete">
                    <i className={row.original.IsPublished ? "text-success fa fa-rocket cursor-default" : "text-warning fa fa-rocket"}></i></span>
            ),
            width: 50,
            filterable: false,
        }, {
            id: 'Testers',
            Header: 'ACTIONS',
            width: 80,
            Cell: row => (
                <div>
                    <p className="text-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.setState({
                            showFilter: true,
                            filterData: row.original,
                        }, () => {
                            this.child.current.clearTab();
                            window.scroll(0, 0);
                        })}>Add Tester
                        </p>
                    < br />
                    <a id={row.original.Id} href={row.original.ProductConfiguration.SurveyMonkeyLink}
                        target="_blank" title="View Survey" className="mouseDelete"><i className={row.original.ProductConfiguration.SurveyMonkeyLink ? "fas fa-file-alt" : "noneDisplay"}></i></a>
                    < br />
                    <a id={row.original.Id} href={row.original.ProductConfiguration.ClientReport}
                        target="_blank" title="View Report" className="mouseDelete"><i className={row.original.ProductConfiguration.ClientReport ? "fas fa-chart-pie" : "noneDisplay"}></i></a>
                    < br />
                    <span id={row.original.Id} onClick={this.handleDeleteProductClick} title="Remove" className="mouseDelete"><i className="fa fa-trash"></i></span>
                </div >
            ),
            filterable: false,
        }]

        return (
            <div>
                <ul className="breadcrumb">
                    <li><p>Product</p></li>
                    <li><span href="#" className="active">Manage</span> </li>
                </ul>
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="grid simple horizontal purple">
                            {this.state.showFilter && <div className="blockDisplay grid-title">
                                <FilterHelper
                                    ref={this.child}
                                    filterData={this.state.filterData}
                                    onHideFilter={this.hideFilter}
                                    history={this.props.history}
                                    refreshData={() => { this.fetchData(this.state) }}
                                />
                            </div>}

                            <div className="grid-title">
                                <h3>Product</h3>
                            </div>
                            <div className="grid-body">

                                <div className={this.state.displayTable + " row"}>

                                    <div className="col-12">
                                        <div className="mb-3">
                                            <button type="button" class="btn btn-outline-info" onClick={this.renderTableByCampaign} >
                                                {this.state.openCampaign ? "See All Campaign" : "See Open Campaign"}</button>
                                        </div>
                                    </div>

                                    <ReactTable
                                        data={dataPagination}
                                        columns={columns}
                                        pages={pages}
                                        loading={loading}
                                        manual
                                        minRows={1}
                                        filterable
                                        defaultPageSize={10}
                                        className="-striped -highlight col-12"
                                        // Controlled props
                                        sorted={this.state.sorted}
                                        page={this.state.page}
                                        pageSize={this.state.pageSize}
                                        expanded={this.state.expanded}
                                        resized={this.state.resized}
                                        filtered={this.state.filtered}
                                        // Callbacks
                                        onSortedChange={sorted => this.setState({ sorted }, () => { this.fetchData(this.state) })}
                                        onPageChange={page => this.setState({ page }, () => { this.fetchData(this.state) })}
                                        onPageSizeChange={(pageSize, page) =>
                                            this.setState({ page, pageSize }, () => { this.fetchData(this.state) })}
                                        onExpandedChange={expanded => this.setState({ expanded })}
                                        onResizedChange={resized => this.setState({ resized })}
                                        onFilteredChange={filtered => {
                                            this.setState({ filtered }, () => {
                                                if (filtered.length === 0 || (filtered.length > 0 && filtered[0].value.length > 2)) {
                                                    this.state.page = 0
                                                    this.fetchData(this.state)
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default ProductListAdmin;