import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import swal from 'sweetalert'
import ReactTable from "react-table";
import 'react-table/react-table.css'
import matchSorter from 'match-sorter'

import { getAllRewardProduct, deleteRewardProduct } from '../../../service/RewardProduct';

import NumberFormat from 'react-number-format';

import * as MyConstantsFile from '../../../util/MyConstantsFile'

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class RewardProductList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: []
		}
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		getAllRewardProduct()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ list: json });
			})
			.catch(console.log);

	}

	handleClick(event) {
		var id = parseInt(event.currentTarget.id);
		swal({
			title: "Please confirm",
			text: "Are you sure that you want to delete this record? ID: " + id,
			// icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((result) => {
				if (result) {
					deleteRewardProduct(id).then(() => {
						for (var i = 0; i < this.state.list.length; i++) {
							if (this.state.list[i].Id == id) {
								this.state.list.splice(i, 1);
								break;
							}
						}
						this.props.history.push(MyConstantsFile.APP_ADMIN_REWARD_PRODUCT_PATH)
					});
				}
			});
	}

	render() {
		const now = new Date();
		const time = moment(now).format('YYYYMMDDHHmmss');

		const data = this.state.list;

		const columns = [{
			id: 'id',
			Header: 'ID',
			accessor: 'Id',
			width: 50,
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["id"] }),
			filterAll: true,
		}, {
			Header: 'IMAGE',
			accessor: 'MainPhoto',
			Cell: row => (
				<img src={(row.value !== null && row.value !== '' ? `${STORAGE_URL}rewards/${row.value}?${time}` : '')}
					style={{ height: '80px' }} alt="Image not available" />
			),
			width: 120,
		}
			, {
			id: 'ManufacturerId',
			Header: 'Client Name',
			accessor: 'ManufacturerId',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["ManufacturerId"] }),
			filterAll: true,
		}, {
			id: 'RewardName',
			Header: 'Reward Name',
			accessor: 'RewardName',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["RewardName"] }),
			filterAll: true,
		}, {
			id: 'RewardDescription',
			Header: 'Reward Description',
			accessor: 'RewardDescription',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["RewardDescription"] }),
			filterAll: true,
		}, {
			id: 'Point',
			Header: 'Point',
			accessor: d => d.Point,
			Cell: row => (
				<div>
					<NumberFormat value={row.original.Point} displayType={'text'} thousandSeparator={true} />
				</div>
			),
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["Point"] }),
			filterAll: true,
		}, {
			id: 'Quantity',
			Header: 'Quantity',
			accessor: d => d.Quantity,
			Cell: row => (
				<div>
					<NumberFormat value={row.original.Quantity} displayType={'text'} thousandSeparator={true} />
				</div>
			),
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ["Quantity"] }),
			filterAll: true,
		}, {
			Header: 'ACTION',
			Cell: row => (
				<div>
					<Link to={MyConstantsFile.APP_ADMIN_REWARD_PRODUCT_EDIT_CALL_PATH + row.original.Id} title="Edit"><i className="fas fa-pencil-alt"></i></Link>
					<br />
					<a id={row.original.Id} onClick={this.handleClick} title="Remove" className="mouseDelete"><i className="fa fa-trash"></i></a>
				</div>
			),
			width: 100,
		}]

		return (
			<div>
				<ul className="breadcrumb">
					<li><p>Rewards</p></li>
					<li><a href="#" className="active">Manage</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<div className="grid simple horizontal purple">
							<div className="grid-title"><div className="tools">

							</div>
								<h3>Rewards</h3>
							</div>
							<div className="grid-body">
								<ReactTable
									data={data}
									columns={columns}
									minRows={1}
									filterable
									defaultFilterMethod={(filter, row) =>
										String(row[filter.id]) === filter.value}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RewardProductList;