import React, { Component } from 'react';

import ResponsiveMenu from 'react-responsive-navbar';

class Header extends Component {
    // constructor(props) {
    // 	super(props);
    // }

    render() {
        return (
            <div className="style-header bg-white ">
                <header className="style-site-header">
                   <div  /* className="style-container"*/> 
                        <div className="style-header-component-inner">
                            {/* <nav className="style-site-main-nav style-clearfix" data-container="#masthead .header-main">
                                <ul id="menu-main" className="style-main-menu style-mega-menu">
                                    <li className="style-menu-item lazyra-mm-item lazyra-mm-popup-narrow style-mm-item-level-0"><a href="https://ptpa.com/how-it-works/"><span class="mm-text">How it Works</span></a></li>
                                    <li className="style-menu-item lazyra-mm-item lazyra-mm-popup-narrow style-mm-item-level-0"><a href="https://ptpa.com/winners/"><span class="mm-text">Recent Winners</span></a></li>
                                    <li className="style-menu-item lazyra-mm-item lazyra-mm-popup-narrow style-mm-item-level-0"><a href="https://ptpa.com/categories/"><span class="mm-text">Products</span></a></li>
                                    <li className="style-menu-item lazyra-mm-item lazyra-mm-popup-narrow style-mm-item-level-0"><a href="https://ptpa.com/parent-rewards-program/"><span class="mm-text">Rewards</span></a></li>
                                    <li className="style-menu-item lazyra-mm-item lazyra-mm-popup-narrow style-mm-item-level-0"><a href="https://www.ptpa.com/"><span class="mm-text"><img src="/assets/img/Parent-Tested-Parent-Approved.com-Logo.png" /></span></a></li>
                                    <li className="style-menu-item lazyra-mm-item lazyra-mm-popup-narrow style-mm-item-level-0"><a target="_blank" href="https://www.ptpamedia.com/services/parent-tested-parent-approved-seal-of-approval/"><span class="mm-text"><i class="mm-icon fa fa-briefcase"></i>For Brands</span></a></li>
                                    <li className="style-menu-item lazyra-mm-item lazyra-mm-popup-narrow style-mm-item-level-0"><a target="_blank" href="https://web.ptpabreeze.com/newmember/"><span class="mm-text">Join  Now</span><span class="style-tip" style={{ color: '#ffffff', background: '#dd3333' }}><span class="style-tip-arrow" style={{ color: '#dd3333' }}></span>It's Free!</span></a></li>
                                    <li className="style-menu-item lazyra-mm-item lazyra-mm-popup-narrow style-mm-item-level-0"><a target="_blank" href="http://breeze.ptpamedia.com/login-page?pagefor=user"><span class="mm-text"><i class="mm-icon fa fa-lock"></i>Log In</span></a></li>
                                    <li className="style-menu-item lazyra-mm-item lazyra-mm-popup-narrow style-mm-item-level-0"><a href="https://ptpa.com/#"><span class="mm-text"><i class="mm-icon fa fa-search"></i>Search</span></a></li>
                                </ul>
                            </nav> */}

                            <div className="style-site-main-nav style-clearfix">
                                <div className="small-screen-logo text-center mb-4">
                                    <a href="https://www.ptpa.com/"><span class="mm-text"><img src="/assets/img/Parent-Tested-Parent-Approved.com-Logo.png" /></span></a>
                                </div>
                                <ResponsiveMenu
                                    menuOpenButton={<div className="fa fa-bars" />}
                                    menuCloseButton={<div className="fa fa-bars" />}
                                    changeMenuOn="1225px"
                                    largeMenuClassName="style-main-menu style-mega-menu"
                                    smallMenuClassName="style-mega-menu text-right mr-3 small-menu"
                                    menu={
                                        <ul className="p-3" style={{ fontFamily: 'Poppins', fontSize: '12px' }}>
                                            <li className="p-3"><a href="https://ptpa.com/how-it-works/"><span class="mm-text" style={{ fontFamily: 'Poppins' }}>How it Works</span></a></li>
                                            <li className="p-3"><a href="https://ptpa.com/winners/"><span class="mm-text" style={{ fontFamily: 'Poppins' }}>Recent Winners</span></a></li>
                                            <li className="p-3"><a href="https://ptpa.com/giveaways/"><span class="mm-text" style={{ fontFamily: 'Poppins' }}>OMG Giveaways</span><span class="style-tip" style={{ color: '#ffffff', background: '#dd3333', fontFamily: 'Poppins' }}><span class="style-tip-arrow big-screen-logo" style={{ color: '#dd3333' }}></span>WIN!</span></a></li>
                                            <li className="p-3"><a href="https://product.ptpa.com/categories/"><span class="mm-text" style={{ fontFamily: 'Poppins' }}>Products</span></a></li>
                                            <li className='big-screen-logo'><a href="https://ptpa.com/"><span class="mm-text" style={{ fontFamily: 'Poppins' }}><img src="/assets/img/Parent-Tested-Parent-Approved.com-Logo.png" /></span></a></li>
                                            <li className="p-3"><a href="http://ptpa.com/parent-rewards-program-ptpa-club/"><span class="mm-text" style={{ fontFamily: 'Poppins' }}>Rewards</span><span class="style-tip" style={{ color: '#ffffff', background: '#dd3333', fontFamily: 'Poppins' }}><span class="style-tip-arrow big-screen-logo" style={{ color: '#dd3333' }}></span>PTPA.CLUB</span></a></li>
                                            <li className="p-3"><a target="_blank" href="https://www.ptpamedia.com/services/parent-tested-parent-approved-seal-of-approval/"><span class="mm-text" style={{ fontFamily: 'Poppins' }}><i class="mm-icon fa fa-briefcase"></i>For Brands</span></a></li>
                                            {/* <li className="p-3"><a target="_blank" href="https://ptpabreeze.com/dashboard/communitymember/newmember"><span class="mm-text">Join  Now</span><span class="style-tip" style={{ color: '#ffffff', background: '#dd3333' }}><span class="style-tip-arrow big-screen-logo" style={{ color: '#dd3333' }}></span>It's Free!</span></a></li> */}
                                            <li className="p-3"><a href="https://dashboard.ptpa.com/dashboard/overview"><span class="mm-text" style={{ fontFamily: 'Poppins' }}><i class="mm-icon fa fa-lock"></i>My Account</span></a></li>
                                            {/* <li><a href="https://ptpa.com/#"><span class="mm-text"><i class="mm-icon fa fa-search"></i>Search</span></a></li> */}
                                        </ul>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}

export default Header;