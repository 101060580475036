import React, {Component} from 'react';

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			username: '',
			password: ''
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	handleChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}

	handleClick(e) {
		console.log(fetch);
		//this.props.history.push('/login');
	}
	
	render() {
		return (
			<div>
				<div>
					<label htmlFor="username">Nome:</label>
					<input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
				</div>
				<div>
					<label htmlFor="username">Usuário:</label>
					<input type="text" name="username" value={this.state.user} onChange={this.handleChange} />
				</div>
				<div>
					<label htmlFor="password">Senha:</label>
					<input type="password" name="password" value={this.state.password} onChange={this.handleChange} />
				</div>
				<div>
					<button type="submit" onClick={this.handleClick}>Logar</button>
				</div>
			</div>
		);
	}
}

export default Register;