import React, { Component } from 'react';

import { Tabs, TabList, Tab, PanelList, Panel } from 'react-tabtab';
import * as customStyle from 'react-tabtab/lib/themes/material-design';

import swal from 'sweetalert'

import { getReminderPeople, getReminderPeopleSend, getReminderPeopleOverdue, getReminderPeopleOverdueSend } from '../../../service/CommunityMemberProduct';

import EmailTableHelper from '../SendEmail/EmailTableHelper';

const tabsNamePickUp = "listPickup";
const tabsNameDeadline7Days = "listDeadline7Days";
const tabsNameDeadline3Days = "listDeadline3Days";
const tabsNameDeadline0Days = "listDeadline0Days";
const tabsNameOverdue = "listOverdue";
const daysAcceptedPickup = 3;
const daysAcceptedSurvey = 0;
const daysEvaluationPickup = 0;
const daysEvaluation7Days = 7;
const daysEvaluation3Days = 3;
const daysEvaluation0Days = 0;

class EmailHelper extends Component {

	constructor(props) {
		super(props);

		const tabs = [];

		this.state = {
			allData: [],
			listPickup: [],
			listDeadline7Days: [],
			listDeadline3Days: [],
			listDeadline2Days: [],
			listOverdue: [],
			tabs: [],
			activeIndex: 0,
			createTab: false,
		}

		this.handleTabChange = this.handleTabChange.bind(this);
		this.handleAddTesterTab = this.handleAddTesterTab.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleSendEmail = this.handleSendEmail.bind(this);
	}

	componentDidMount() {
		var data = {};
		data.accepted = true;
		data.pickUp = 1;
		data.received = false;
		data.survey = false;
		data.daysAccepted = daysAcceptedPickup;
		data.daysEvaluationDeadline = daysEvaluationPickup;

		getReminderPeople(data) //pickup
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				json.title = tabsNamePickUp;
				this.state.allData.push(json);
				this.setState({ listPickup: json });
			})
			.catch(console.log);

		data.pickUp = 0;
		data.received = true;
		data.daysAccepted = daysAcceptedSurvey;
		data.daysEvaluationDeadline = daysEvaluation7Days;

		getReminderPeople(data) //7 days
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				json.title = tabsNameDeadline7Days;
				this.state.allData.push(json);
				this.setState({ listDeadline7Days: json });
			})
			.catch(console.log);

		data.daysEvaluationDeadline = daysEvaluation3Days;

		getReminderPeople(data) //3 days
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				json.title = tabsNameDeadline3Days;
				this.state.allData.push(json);
				this.setState({ listDeadline3Days: json });
			})
			.catch(console.log);

		data.daysEvaluationDeadline = daysEvaluation0Days;

		getReminderPeople(data) //today
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				json.title = tabsNameDeadline0Days;
				this.state.allData.push(json);
				this.setState({ listDeadline2Days: json });
			})
			.catch(console.log);

		getReminderPeopleOverdue()
			.then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				json.title = tabsNameOverdue;
				this.state.allData.push(json);
				this.setState({ listOverdue: json });
			})
			.catch(console.log);
	}

	handleAddTesterTab() {

		var newTabs = [];
		for (var i = 0; i < this.state.allData.length; i++) {
			newTabs = {
				title: this.state.allData[i].title,
				content:
					<EmailTableHelper
						onRef={ref => (this.child = ref)}
						history={this.props.history}
						listEmail={this.state.allData[i]}
						onSendEmail={this.handleSendEmail}
					/>
			};

			this.state.tabs.push(newTabs);
		}

		this.setState({ tabs: this.state.tabs, activeIndex: 0 });
	}

	handleTabChange(index) {
		this.setState({ activeIndex: index });
	}

	handleEdit({ type, index }) {
		let { tabs, activeIndex } = this.state;
		if (type === 'delete') {
			tabs.splice(index, 1);
		}
		if (index - 1 >= 0) {
			activeIndex = index - 1;
		} else {
			activeIndex = 0;
		}
		this.setState({ tabs, activeIndex });
	}

	sendConfirmationAlert() {
		swal({
			title: "Emails sent!",
			icon: "success",
		});
		this.child.enableButton();
	}

	handleSendEmail(tabTitle) {

		swal({
			title: "Please confirm",
			text: "Are you sure that you want to send the emails?",
			// icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((result) => {
				if (result) {
					var data = {};
					data.accepted = true;
					data.pickUp = 1;
					data.received = false;
					data.survey = false;
					data.daysAccepted = daysAcceptedPickup;
					data.daysEvaluationDeadline = daysEvaluationPickup;

					switch (tabTitle) {
						case tabsNamePickUp:
							getReminderPeopleSend(data)
							.then(res => res.json().then(json => ({ res, json })))
							.then(({ res, json }) => {
									if (res.ok) {
										this.sendConfirmationAlert();
									}
								})
								.catch(console.log);
							break;
						case tabsNameDeadline7Days:
							data.pickUp = 0;
							data.received = true;
							data.daysAccepted = daysAcceptedSurvey;
							data.daysEvaluationDeadline = daysEvaluation7Days;
							getReminderPeopleSend(data)
							.then(res => res.json().then(json => ({ res, json })))
							.then(({ res, json }) => {
									if (res.ok) {
										this.sendConfirmationAlert();
									}
								})
								.catch(console.log);
							break;
						case tabsNameDeadline3Days:
							data.pickUp = 0;
							data.received = true;
							data.daysAccepted = daysAcceptedSurvey;
							data.daysEvaluationDeadline = daysEvaluation3Days;
							getReminderPeopleSend(data)
							.then(res => res.json().then(json => ({ res, json })))
							.then(({ res, json }) => {
									if (res.ok) {
										this.sendConfirmationAlert();
									}
								})
								.catch(console.log);
							break;
						case tabsNameDeadline0Days:
							data.pickUp = 0;
							data.received = true;
							data.daysAccepted = daysAcceptedSurvey;
							data.daysEvaluationDeadline = daysEvaluation0Days;
							getReminderPeopleSend(data)
							.then(res => res.json().then(json => ({ res, json })))
							.then(({ res, json }) => {
									if (res.ok) {
										this.sendConfirmationAlert();
									}
								})
								.catch(console.log);
							break;
						case tabsNameOverdue:
							getReminderPeopleOverdueSend()
							.then(res => res.json().then(json => ({ res, json })))
							.then(({ res, json }) => {
									if (res.ok) {
										this.sendConfirmationAlert();
									}
								})
								.catch(console.log);
							break;
						default:
							break;
					}
				} else
					this.child.enableButton();
			});
	}

	render() {
		const {
			values,
			handleChange,
			handleBlur,
			handleSubmit,
			errors,
		} = this.props;

		if (this.state.allData.length == 5 && this.state.createTab == false) {
			this.state.createTab = true;
			this.handleAddTesterTab()
		}

		const { tabs, activeIndex } = this.state;

		const tabTemplate = [];
		const panelTemplate = [];

		tabs.forEach((tab, i) => {
			const closable = tabs.length > 1;
			tabTemplate.push(<Tab key={i} closable={closable}>{tab.title}</Tab>);
			panelTemplate.push(<Panel key={i}>{tab.content}</Panel>);
		})

		return (
			<div>
				<ul className="breadcrumb">
					<li><p>Email</p></li>
					<li><a href="#" className="active">Manage</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<div className="grid simple horizontal purple">
							<div className="grid-title">
								<h3>Email</h3>
							</div>
							<div className="grid-body">

								<Tabs
									customStyle={customStyle}
									activeIndex={activeIndex}
									onTabEdit={this.handleEdit}
									onTabChange={this.handleTabChange}
								>
									<TabList>
										{tabTemplate}
									</TabList>
									<PanelList>
										{panelTemplate}
									</PanelList>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default EmailHelper;