import React, { Component } from 'react';
import { withFormik, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import Select from 'react-select';
import 'react-select/dist/react-select.css';
import Carousel from 'nuka-carousel';
import MaskedInput from 'react-text-mask'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Stepper from 'react-stepper-horizontal';
import ResponsiveMenu from 'react-responsive-navbar';
import swal from 'sweetalert'
// import StepZilla from 'react-stepzilla';

import Auth from '../../../util/Auth';
import { getAllCommunityMemberGenderTarget } from '../../../service/CommunityMemberGenderTarget';
import { getAllCountry } from '../../../service/Country';
import { getStateByCountry } from '../../../service/State';
import { getAllMaritalStatus } from '../../../service/MaritalStatus';
import { getAllEducationGroup } from '../../../service/EducationGroup';
import { getAllWorkStatusGroup } from '../../../service/WorkStatusGroup';
import { getAllIncomeGroup } from '../../../service/IncomeGroup';
import { getAllHeardAbout } from '../../../service/HeardAbout';
import { getAllAllergy } from '../../../service/Allergy';
import { getAllNotificationPreference } from '../../../service/NotificationPreference';
import { getAllEthnicity } from '../../../service/Ethnicity';
import { getAllIndicateActivity } from '../../../service/IndicateActivity';


import { getCommunityMemberToken, saveCommunityMember } from '../../../service/CommunityMember';

// import Step1 from './Step1';

const STORAGE_URL = 'https://ptpabreeze.blob.core.windows.net/';

class MyForm extends Component {
	constructor(props) {
		super(props);

		window.scrollTo(0, 0)

		this.state = {
			listGenderTarget: [],
			listEthnicity: [],
			listCountry: [],
			listState: [],
			listMarital: [],
			listEducation: [],
			listWork: [],
			listIncome: [],
			listEthnicity: [],
			listHeardAboutUs: [],
			listPreferences: [],
			listIndicateActivity: [],
			listAllergies: [],
			DOB: null,
			childrenDOB: [],
			CountryId: null,
			StateId: null,
			childrenGenderId: null,
			selectedPickUp: null,
			selectedExpecting: null,
			ExpectingDate: null,
			childrenQty: null,
			selectedPreferences: null,
			selectedIndicateActivity: null,
			selectedAllergies: null,
			selectedChildrenAllergies: null,
			AllergyCheckbox: "No",
			SubscribeCheckbox: null,
			ChildrenCheckbox: "No",
			ChildrenAllergyCheckbox: [],
			loadFromModel: false,
			step: 0,
			crop: {
				x: 10,
				y: 10,
				width: 10,
				height: 10,
				aspect: 1,
			},
			style: window.screen.width < 500 ? {
				width: '100%',
				display: ' inline-block'
			} :
				{},
			styleSlider: window.screen.width < 500 ?
				{
					maxHeight: '900px',
					backgroundColor: '#b4b6da',
				}
				:
				{
					maxHeight: '600px',
					backgroundColor: '#b4b6da',
				},
		}

		this.handleChangeBirthday = this.handleChangeBirthday.bind(this);
		this.handleChangePickUp = this.handleChangePickUp.bind(this);
		this.handleChangeExpecting = this.handleChangeExpecting.bind(this);
		this.handleChangeExpectingDate = this.handleChangeExpectingDate.bind(this);
		this.handleChangeChildrenCheckbox = this.handleChangeChildrenCheckbox.bind(this);
		this.handleChangeAllergiesCheckbox = this.handleChangeAllergiesCheckbox.bind(this);
		this.handleChangeSubscribeCheckbox = this.handleChangeSubscribeCheckbox.bind(this);
		this.handleChangePreferences = this.handleChangePreferences.bind(this);
		this.handleChangeIndicateActivity = this.handleChangeIndicateActivity.bind(this);
		this.handleChangeAllergiesItems = this.handleChangeAllergiesItems.bind(this);
		this.handleChangeChildrenAllergiesCheckbox = this.handleChangeChildrenAllergiesCheckbox.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {//getting and populating all information from the databese in the Select element
		if (Auth.isUserAuthenticated()) {
			getAllCountry()
				.then(res => res.json()
					.then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.listCountry = json;
					window.scrollTo(0, 0)
					// this.setState({ listCountry: json });
				})
				.catch(console.log);
			getAllCommunityMemberGenderTarget()
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.listGenderTarget = json;
					// this.setState({ listGenderTarget: json });
				})
				.catch(console.log);
			getAllEthnicity()
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.listEthnicity = json;
				})
				.catch(console.log);
			getAllMaritalStatus()
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.listMarital = json;
					// this.setState({ listMarital: json });
				})
				.catch(console.log);
			getAllEducationGroup()
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.listEducation = json;
					// this.setState({ listEducation: json });
				})
				.catch(console.log);

			getAllWorkStatusGroup()
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.listWork = json;
					// this.setState({ listWork: json });
				})
				.catch(console.log);
			getAllIncomeGroup()
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.listIncome = json;
					// this.setState({ listIncome: json });
				})
				.catch(console.log);

			getAllEthnicity()
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.listEthnicity = json;
					// this.setState({ listEthnicity: json });
				})
				.catch(console.log);

			getAllNotificationPreference()
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.listPreferences = json;
					// this.setState({ listPreferences: json });
				})
				.catch(console.log);
			getAllIndicateActivity()
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.listIndicateActivity = json;
				})
				.catch(console.log);
			getAllHeardAbout()
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.listHeardAboutUs = json;
					// this.setState({ listHeardAboutUs: json });
				})
				.catch(console.log);
			getAllAllergy()
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ listAllergies: json });
				})
				.catch(console.log);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

	handleChangeGenderTarget = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.CommunityMemberGenderTargetId = selectedOption.value;
			this.setState({
				GenderTargetId: selectedOption.value,
			});
		} else {
			this.props.values.CommunityMemberGenderTargetId = 0;
			this.setState({
				GenderTargetId: 0,
			});
		}
	}

	handleChangeEthnicity = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.EthnicityId = selectedOption.value;
			this.setState({
				EthnicityId: selectedOption.value,
			});
		} else {
			this.props.values.EthnicityId = null;
			this.setState({
				EthnicityId: 0,
			});
		}
	}

	handleChangeCountry = (selectedOption) => {
		this.props.values.StateRequired = false
		if (selectedOption != null) {
			this.props.values.CountryId = selectedOption.value;
			this.setState({
				CountryId: selectedOption.value,
			});

			if (selectedOption.value === 1 || selectedOption.value === 2)
				this.props.values.StateRequired = true
		} else {
			this.props.values.CountryId = 0;
			this.setState({
				CountryId: 0,
			});
		}
		this.getState(this.props.values.CountryId);
	}

	getState(countryId) {
		this.handleChangeState(null);

		getStateByCountry(countryId)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listState: json });
			})
			.catch(console.log);
	}

	handleChangeState = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.StateId = selectedOption.value;
			this.setState({
				StateId: selectedOption.value,
			});
		} else {
			this.props.values.StateId = 0;
			this.setState({
				StateId: 0,
			});
		}
	}

	handleChangeMaritalStatus = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.MaritalStatusId = selectedOption.value;
			this.setState({
				MaritalStatusId: selectedOption.value,
			});
		} else {
			this.props.values.MaritalStatusId = null;
			this.setState({
				MaritalStatusId: 0,
			});
		}
	}

	handleChangeEducationGroup = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.EducationGroupId = selectedOption.value;
			this.setState({
				EducationGroupId: selectedOption.value,
			});
		} else {
			this.props.values.EducationGroupId = null;
			this.setState({
				EducationGroupId: 0,
			});
		}
	}

	handleChangeWorkGroup = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.WorkStatusGroupId = selectedOption.value;
			this.setState({
				WorkStatusGroupId: selectedOption.value,
			});
		} else {
			this.props.values.WorkStatusGroupId = null;
			this.setState({
				WorkStatusGroupId: 0,
			});
		}
	}

	handleChangeIncomeGroup = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.IncomeGroupId = selectedOption.value;
			this.setState({
				IncomeGroupId: selectedOption.value,
			});
		} else {
			this.props.values.IncomeGroupId = null;
			this.setState({
				IncomeGroupId: 0,
			});
		}
	}

	handleChangeEthnicity = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.EthnicityId = selectedOption.value;
			this.setState({
				EthnicityId: selectedOption.value,
			});
		} else {
			this.props.values.EthnicityId = null;
			this.setState({
				EthnicityId: 0,
			});
		}
	}

	handleChangeHeardAboutUs = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.HeardAboutId = selectedOption.value;
			this.setState({
				HeardAboutId: selectedOption.value,
			});
		} else {
			this.props.values.HeardAboutId = null;
			this.setState({
				HeardAboutId: 0,
			});
		}
	}

	handleChangeBirthday(date) {
		if (date != null)
			this.props.values.DOB = moment(date).format('YYYY-MM-DD');
		else
			this.props.values.DOB = null;
		this.state.DOB = moment(date);
		this.setState({
			DOB: date
		});
	}

	//set value and state for user DOB
	setBirthdayValue(date) {
		if (date != null) {
			this.props.values.DOB = moment(date).format('MM/DD/YYYY');
			this.state.DOB = moment(date);
		}
		return this.state.DOB;
	}

	handleChangeExpectingDate(date) {
		if (date != null)
			this.props.values.ExpectingKidDueDate = moment(date).format('YYYY-MM-DD');
		else
			this.props.values.ExpectingKidDueDate = null;
		this.setState({
			ExpectingDate: date
		});
	}

	//set value and state for user expecting
	setExpectingDateValue(date) {
		if (date != null) {
			this.props.values.ExpectingKidDueDate = moment(date).format('MM/DD/YYYY');
			this.state.ExpectingDate = moment(date);
		}
		return this.state.ExpectingDate;
	}

	//update checkbox click
	handleChangePickUp(event) {
		this.state.selectedPickUp = event.target.value;

		event.target.value === "Yes" ? this.props.values.PickUp = true : this.props.values.PickUp = false;

		//I call this just to render the Carousel in order to change the checkbox need to fix for the right way
		getStateByCountry(this.props.values.CountryId)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listState: json });
			})
			.catch(console.log);
	}

	//update checkbox click
	handleChangeExpecting(event) {
		this.state.selectedExpecting = event.target.value;

		event.target.value === "Yes" ? this.props.values.ExpectingKid = true : this.props.values.ExpectingKid = false;

		if (!this.props.values.ExpectingKid) {
			this.state.selectedExpecting = "No";
			this.handleChangeExpectingDate(null);
		}

		//I call this just to render the Carousel in order to change the checkbox need to fix for the right way
		getStateByCountry(this.props.values.CountryId)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listState: json });
			})
			.catch(console.log);
	}

	//update checkbox click
	handleChangePreferences(event) {
		var allPreferences = this.state.selectedPreferences;
		var index = allPreferences.indexOf(parseInt(event.target.id));
		if (index > -1) {
			allPreferences.splice(index, 1);
			this.state.selectedPreferences = allPreferences;
		} else {
			this.state.selectedPreferences.push(parseInt(event.target.id));
		}

		this.props.values.CommunityMemberNotificationPreference = [];
		for (var i = 0; i < this.state.selectedPreferences.length; i++) {
			this.props.values.CommunityMemberNotificationPreference.push({
				NotificationPreferenceId: this.state.selectedPreferences[i]
			})
		}

		this.setState({
			selectedPreferences: this.state.selectedPreferences,
		});

		//I call this just to render the Carousel in order to change the checkbox need to fix for the right way
		getStateByCountry(this.props.values.CountryId)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listState: json });
			})
			.catch(console.log);
	}

	//update checkbox click
	handleChangeIndicateActivity(event) {
		var allIndicateActivity = this.state.selectedIndicateActivity;
		var index = allIndicateActivity.indexOf(parseInt(event.target.id));
		if (index > -1) {
			allIndicateActivity.splice(index, 1);
			this.state.selectedIndicateActivity = allIndicateActivity;
		} else {
			this.state.selectedIndicateActivity.push(parseInt(event.target.id));
		}

		this.props.values.CommunityMemberIndicateActivity = [];
		for (var i = 0; i < this.state.selectedIndicateActivity.length; i++) {
			this.props.values.CommunityMemberIndicateActivity.push({
				IndicateActivityId: this.state.selectedIndicateActivity[i]
			})
		}

		this.setState({
			selectedIndicateActivity: this.state.selectedIndicateActivity,
		});

		//I call this just to render the Carousel in order to change the checkbox need to fix for the right way
		getStateByCountry(this.props.values.CountryId)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listState: json });
			})
			.catch(console.log);
	}

	handleChangeAllergiesItems(event) {
		var allAllergiesItems = this.state.selectedAllergies;
		var index = allAllergiesItems.indexOf(parseInt(event.target.id));
		if (index > -1) {
			allAllergiesItems.splice(index, 1);
			this.state.selectedAllergies = allAllergiesItems;
		} else {
			this.state.selectedAllergies.push(parseInt(event.target.id));
		}

		this.props.values.CommunityMemberAllergy = [];
		for (var i = 0; i < this.state.selectedAllergies.length; i++) {
			this.props.values.CommunityMemberAllergy.push({
				AllergyId: this.state.selectedAllergies[i]
			})
		}

		this.setState({
			selectedAllergies: this.state.selectedAllergies,
		});

		//I call this just to render the Carousel in order to change the checkbox need to fix for the right way
		getStateByCountry(this.props.values.CountryId)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listState: json });
			})
			.catch(console.log);
	}

	handleChangeChildrenAllergiesItems(event, allergyId) {
		var allChildrenAllergiesItems = this.state.selectedChildrenAllergies;
		// var indexChildren = allChildrenAllergiesItems[parseInt(event.target.id)];
		if (allChildrenAllergiesItems[parseInt(event.target.id)] != null) {
			var indexChildrenAllergy = allChildrenAllergiesItems[parseInt(event.target.id)].indexOf(allergyId);
			if (indexChildrenAllergy > -1) {
				allChildrenAllergiesItems[parseInt(event.target.id)].splice(indexChildrenAllergy, 1);
				this.state.selectedChildrenAllergies = allChildrenAllergiesItems;
			} else {
				this.state.selectedChildrenAllergies[parseInt(event.target.id)].push(allergyId);
			}
		} else {
			var arrayAllergy = []
			for (var i = 0; i < parseInt(event.target.id); i++) {
				if (allChildrenAllergiesItems[i] == null) {
					allChildrenAllergiesItems[i] = [];
				}
			}
			arrayAllergy.push(allergyId);
			allChildrenAllergiesItems.push(parseInt(event.target.id));
			allChildrenAllergiesItems[parseInt(event.target.id)] = arrayAllergy;
			this.state.selectedChildrenAllergies = allChildrenAllergiesItems;
		}


		if (this.state.selectedChildrenAllergies[parseInt(event.target.id)].length == 0) {
			this.props.values.CommunityMemberChildren[parseInt(event.target.id)].CommunityMemberChildrenAllergy = [];
		} else {
			for (var i = 0; this.state.selectedChildrenAllergies != null && i < this.state.selectedChildrenAllergies.length; i++) {
				this.props.values.CommunityMemberChildren[i].CommunityMemberChildrenAllergy = [];
				for (var j = 0; this.state.selectedChildrenAllergies[i][j] != null && j < this.state.selectedChildrenAllergies[i].length; j++) {
					this.props.values.CommunityMemberChildren[i].CommunityMemberChildrenAllergy[j] = {};
					this.props.values.CommunityMemberChildren[i].CommunityMemberChildrenAllergy[j].AllergyId = this.state.selectedChildrenAllergies[i][j];
				}
			}
		}

		//I call this just to render the Carousel in order to change the checkbox need to fix for the right way
		getStateByCountry(this.props.values.CountryId)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listState: json });
			})
			.catch(console.log);
	}

	//update checkbox click
	handleChangeChildrenCheckbox(event) {
		event.target.value === "Yes" ? this.state.ChildrenCheckbox = "Yes" : this.state.ChildrenCheckbox = "No";

		//reset children data when select no for children
		if (this.state.ChildrenCheckbox === "No") {
			this.props.values.ChildrenQtyValue = 0;
			this.props.values.CommunityMemberChildren = [];
			this.setState({
				childrenQty: [],
				childrenDOB: [],
				selectedChildrenAllergies: [],
				ChildrenAllergyCheckbox: [],
			});
		}

		//I call this just to render the Carousel in order to change the checkbox need to fix for the right way
		getStateByCountry(this.props.values.CountryId)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listState: json });
			})
			.catch(console.log);
	}

	handleChangeAllergiesCheckbox(event) {
		event.target.value === "Yes" ? this.state.AllergyCheckbox = "Yes" : this.state.AllergyCheckbox = "No";

		//reset children data when select no for children
		if (this.state.AllergyCheckbox === "No") {
			this.props.values.CommunityMemberAllergy = [];
			this.setState({
				selectedAllergies: [],
			});
		}

		//I call this just to render the Carousel in order to change the checkbox need to fix for the right way
		getStateByCountry(this.props.values.CountryId)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listState: json });
			})
			.catch(console.log);
	}

	handleChangeSubscribeCheckbox(event) {
		event.target.value === "Yes" ? this.state.SubscribeCheckbox = "Yes" : this.state.SubscribeCheckbox = "No";

		this.props.values.Subscribe = event.target.value === 'Yes' ? true : false

		if (!this.props.values.Subscribe)
			this.props.values.CommunityMemberNotificationPreference = [];

		//I call this just to render the Carousel in order to change the checkbox need to fix for the right way
		getStateByCountry(this.props.values.CountryId)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listState: json });
			})
			.catch(console.log);
	}

	handleChangeChildrenAllergiesCheckbox(event) {
		event.target.value === "Yes" ? this.state.ChildrenAllergyCheckbox[parseInt(event.target.id)] = 'Yes' :
			this.state.ChildrenAllergyCheckbox[parseInt(event.target.id)] = 'No';

		// reset children data when select no for children
		if (this.state.ChildrenAllergyCheckbox[parseInt(event.target.id)] === "No" &&
			this.props.values.CommunityMemberChildren[parseInt(event.target.id)] != null) {
			this.props.values.CommunityMemberChildren[parseInt(event.target.id)].CommunityMemberChildrenAllergy = null;
			this.state.selectedChildrenAllergies[parseInt(event.target.id)] = [];
		}

		//I call this just to render the Carousel in order to change the checkbox need to fix for the right way
		getStateByCountry(this.props.values.CountryId)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listState: json });
			})
			.catch(console.log);
	}


	handleChangeChildrenQty = (selectedOption) => {
		var temp = [];
		if (selectedOption != null) {
			if (this.props.values.ChildrenQtyValue > selectedOption.value) {
				this.props.values.ChildrenQtyValue = 0;
				this.props.values.CommunityMemberChildren = [];
				this.setState({
					childrenQty: [],
					childrenDOB: [],
					selectedChildrenAllergies: [],
					ChildrenAllergyCheckbox: [],
				});
			}

			for (var i = 0; i < selectedOption.value; i++) {
				temp[i] = i;
			}
			this.props.values.ChildrenQtyValue = selectedOption.value;
			this.setState({
				childrenQty: temp,
			});
		}
	}

	handleChangeChildrenBirthday(date, id) {
		var listChildren = this.state.childrenDOB;

		listChildren[id] = date.target.value;

		if (this.props.values.CommunityMemberChildren.length > id) {
			this.props.values.CommunityMemberChildren[id].DOB = date.target.value;
		}
		else {
			this.props.values.CommunityMemberChildren.push({ DOB: "", GenderTargetId: null, CommunityMemberChildrenAllergy: [] })
		}
		this.setState({
			childrenDOB: listChildren,
		});
	}

	handleChangeChildrenGenderTarget(selectedOption, id) {
		if (selectedOption != null) {
			if (this.props.values.CommunityMemberChildren.length >= id + 1) {
				this.props.values.CommunityMemberChildren[id].GenderTargetId = selectedOption.value;
			} else {
				this.props.values.CommunityMemberChildren.push({ DOB: null, GenderTargetId: selectedOption.value, CommunityMemberChildrenAllergy: [] })
			}
			this.setState({
				childrenGenderId: selectedOption.value,
			});
		} else {
			this.props.values.CommunityMemberChildren[id].GenderTargetId = 0;
			this.setState({
				childrenGenderId: 0,
			});
		}
	}

	setNumberOfChildren(ChildrenQty) {
		if (ChildrenQty == null && this.props.values.CommunityMemberChildren != null)
			this.props.values.ChildrenQtyValue = this.props.values.CommunityMemberChildren.length;
		return this.props.values.ChildrenQtyValue;
	}

	handleClick() {
		this.props.save(this.props.values);
		Auth.deauthenticateCoupon();
		this.props.history.push(`/Dashboard/CommunityMember/Login`);
	}

	render() {

		const {
			values,
			touched,
			errors,
			isSubmitting,
			imageSelected,
			onModalShow,
			imagePreview,
			onImageChange,
			onImageLoaded,
			crop,
			onCropComplete,
			onCropSave,
			handleChange,
			handleBlur,
			handleSubmit,
			reset,
		} = this.props;

		const time = moment(now).format('YYYYMMDDHHmmss');
		const created = values.Created !== '' ? moment(values.Created).format('MM/DD/YYYY HH:mm') : '';
		const updated = values.Updated !== '' ? moment(values.Updated).format('MM/DD/YYYY HH:mm') : '';
		const image = imageSelected !== '' ? imageSelected : (values.ProfileImage !== null && values.ProfileImage !== '' ?
			`${STORAGE_URL}member/${values.ProfileImage}?${time}`
			: this.props.values.CommunityMemberGenderTargetId == 1 ? '/assets/img/FemaleProfile.png'
				: this.props.values.CommunityMemberGenderTargetId == 2 ? '/assets/img/MaleProfile.png'
					: '/assets/img/OtherProfile.png'
		);

		if (this.state.loadFromModel == false && this.props.model.Id != 0) {
			this.state.loadFromModel = true;
			this.state.CountryId = this.props.model.CountryId;
			this.props.model.PickUp == 1 ? this.state.selectedPickUp = 'Yes' : this.state.selectedPickUp = 'No';
			this.props.model.ExpectingKid == 1 ? this.state.selectedExpecting = 'Yes' : this.state.selectedExpecting = 'No';

			this.state.selectedPreferences = [];
			for (var i = 0; i < this.props.model.CommunityMemberNotificationPreference.length; i++) {
				this.state.selectedPreferences.push(this.props.model.CommunityMemberNotificationPreference[i].NotificationPreferenceId);
			}

			this.state.selectedIndicateActivity = [];
			for (var i = 0; i < this.props.model.CommunityMemberIndicateActivity.length; i++) {
				this.state.selectedIndicateActivity.push(this.props.model.CommunityMemberIndicateActivity[i].IndicateActivityId);
			}

			this.state.selectedAllergies = [];
			for (var i = 0; i < this.props.model.CommunityMemberAllergy.length; i++) {
				this.state.selectedAllergies.push(this.props.model.CommunityMemberAllergy[i].AllergyId);
			}

			this.state.selectedChildrenAllergies = [];
			for (var i = 0; i < this.props.model.CommunityMemberChildren.length; i++) {
				this.state.selectedChildrenAllergies[i] = [];
				for (var j = 0; j < this.props.model.CommunityMemberChildren[i].CommunityMemberChildrenAllergy.length; j++) {
					if (this.props.model.CommunityMemberChildren[i].CommunityMemberChildrenAllergy != null) {
						this.state.selectedChildrenAllergies[i][j] = this.props.model.CommunityMemberChildren[i].CommunityMemberChildrenAllergy[j].AllergyId;
					}
				}
			}
			this.props.model.CommunityMemberAllergy.length > 0 ? this.state.AllergyCheckbox = "Yes" : this.state.AllergyCheckbox = "No";
			this.props.model.CommunityMemberChildren.length > 0 ? this.state.ChildrenCheckbox = "Yes" : this.state.ChildrenCheckbox = "No";
			this.props.model.Subscribe ? this.state.SubscribeCheckbox = "Yes" : this.state.SubscribeCheckbox = "No";

			for (var i = 0; i < this.props.model.CommunityMemberChildren.length; i++) {
				this.props.model.CommunityMemberChildren[i].CommunityMemberChildrenAllergy.length > 0 ?
					this.state.ChildrenAllergyCheckbox[i] = "Yes" : this.state.ChildrenAllergyCheckbox[i] = "No";
			}


			var tempQty = [];
			var tempChildrenDOB = [];
			for (var i = 0; i < this.props.model.CommunityMemberChildren.length; i++) {
				tempQty[i] = i;
				tempChildrenDOB[i] = moment(this.props.model.CommunityMemberChildren[i].DOB).format('MM/DD/YYYY');
			}
			this.state.childrenQty = tempQty;
			this.state.childrenDOB = tempChildrenDOB;

			this.getState(this.props.model.CountryId);
		}

		if (this.state.StateId == null && this.props.model.StateId > 0) {
			this.setState({ StateId: this.props.model.StateId })
		}

		if (this.props.values.CommunityMemberGenderTargetId == null)
			this.props.values.CommunityMemberGenderTargetId = 0;

		if (this.props.values.CountryId == null)
			this.props.values.CountryId = 0;

		if (this.props.values.StateId == null)
			this.props.values.StateId = 0;

		const listGenderTargetValue = [];
		const itemsGenderTarget = this.state.listGenderTarget.map((item) =>
			listGenderTargetValue.push({ value: item.Id, label: item.CommunityMemberGenderName })
		);

		const listEthnicityValue = [];
		const itemsEthnicity = this.state.listEthnicity.map((item) =>
			listEthnicityValue.push({ value: item.Id, label: item.EthnicityLabel })
		);

		const listCountryValue = [];
		const itemsCountry = this.state.listCountry.map((item) =>
			listCountryValue.push({ value: item.Id, label: item.CountryName })
		);

		const listStateValue = [];
		const itemsState = this.state.listState.map((item) =>
			listStateValue.push({ value: item.Id, label: item.StateName })
		);

		const listMaritalValue = [];
		const itemsMarital = this.state.listMarital.map((item) =>
			listMaritalValue.push({ value: item.Id, label: item.CivilStatus })
		);

		const listEducationValue = [];
		const itemsEducation = this.state.listEducation.map((item) =>
			listEducationValue.push({ value: item.Id, label: item.EducationGroupName })
		);

		const listWorkValue = [];
		const itemsWork = this.state.listWork.map((item) =>
			listWorkValue.push({ value: item.Id, label: item.WorkStatusGroupName })
		);

		const listIncomeValue = [];
		const itemsIncome = this.state.listIncome.map((item) =>
			listIncomeValue.push({ value: item.Id, label: item.IncomeGroupName })
		);

		const listHeardAboutUsValue = [];
		const itemsHeardAboutUs = this.state.listHeardAboutUs.map((item) =>
			listHeardAboutUsValue.push({ value: item.Id, label: item.HeardAboutName })
		);

		const itemsPreferences = this.state.listPreferences.map((item) => (
			<div className="col-12">
				<div className="row align-items-center m-0 p-0">
					<input type="checkbox" name="Preferences" value='Yes' id={item.Id}
						onChange={this.handleChangePreferences}
						checked={this.state.selectedPreferences != null ? this.state.selectedPreferences.indexOf(item.Id) > -1 : false} />
					<label className="col text-left">{item.NotificationPreferenceLabel}</label>
				</div>
			</div>
		));

		const itemsIndicateActivity = this.state.listIndicateActivity.map((item) => (
			<div className="col-12">
				<div className="row align-items-center m-0 p-0">
					<input type="checkbox" name="Preferences" value='Yes' id={item.Id}
						onChange={this.handleChangeIndicateActivity}
						checked={this.state.selectedIndicateActivity != null ? this.state.selectedIndicateActivity.indexOf(item.Id) > -1 : false} />
					<label className="col text-left">{item.ActivityLabel}</label>
				</div>
			</div>
		));

		const itemsAllergies = this.state.listAllergies.map((item) => (
			<div className="col-6">
				<div className="row align-items-center m-0 p-0">
					<input type="checkbox" name="itemsAllergies" value='Yes' id={item.Id}
						onChange={this.handleChangeAllergiesItems}
						checked={this.state.selectedAllergies != null ? this.state.selectedAllergies.indexOf(item.Id) > -1 : false} />
					<label className="col text-left">{item.Component}</label>
				</div>
			</div>
		));

		var itemsChildrenAllergies = [];
		if (this.state.childrenQty != null) {
			for (var i = 0; i < this.state.childrenQty.length; i++) {
				const childrenAllergies = this.state.listAllergies.map((item) => (
					<div className="col-6">
						<div className="row align-items-center m-0 p-0">
							<input type="checkbox" name="itemsChildrenAllergies" id={i}
								onChange={(e) => this.handleChangeChildrenAllergiesItems(e, item.Id)}
								checked={this.state.selectedChildrenAllergies[i] != null ?
									// this.state.selectedChildrenAllergies.indexOf(i) > -1 ?
									this.state.selectedChildrenAllergies[i].indexOf(item.Id) > -1
									: false
									// : false
								} />
							<label className="col text-left">{item.Component}</label>
						</div>
					</div>
				));
				itemsChildrenAllergies.push(childrenAllergies);
			}
		}

		if (this.state.CountryId != 1 || this.state.StateId != 9) {
			this.state.selectedPickUp = '';
			this.props.values.PickUp = null;
		}

		var items;
		if (this.state.loadFromModel == true && this.state.childrenQty != null && this.state.childrenQty.length > 0) {
			items = this.state.childrenQty.map(item => (
				<div className='style-form-group' style={this.state.style}>
					<div className="row justify-content-center m-0 p-0">
						<input type="text" className="style-form-control-layout title-layout" value={`Child #${item + 1} Birthday (MM/DD/YYYY)`}
							readOnly disabled style={this.state.style} />
						<div className="style-form-control-layout">
							{/* <DatePicker
								className="style-form-control-layout"
								selected={this.state.childrenDOB[item]}
								onChange={(e) => this.handleChangeChildrenBirthday(e, item)}
								showMonthDropdown showYearDropdown scrollableYearDropdown yearDropdownItemNumber={40} maxDate={moment()} y /> */}

							{/* <DatePicker
											value={this.setBirthdayValue(values.DOB)}
											id="DOB" className="style-form-control-layout"
											showMonthDropdown showYearDropdown scrollableYearDropdown yearDropdownItemNumber={80} maxDate={moment()} /> */}

							<MaskedInput mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} guide={false} className="form-control"
								value={typeof values.CommunityMemberChildren[item] != 'undefined' ? values.CommunityMemberChildren[item].DOB : ''}
								onChange={(e) => this.handleChangeChildrenBirthday(e, item)} onBlur={handleBlur} />
						</div>
					</div>

					<div className='style-form-group' style={this.state.style}>
						<div className="row justify-content-center m-0 p-0">
							<input type="text" className="style-form-control-layout title-layout" value={`Child #${item + 1} Gender`} readOnly disabled />
							<Select className="style-form-control-layout fixing-select-width" name="form-field-Gender" type="text" id="ChildrenGender"
								value={values.CommunityMemberChildren != null ?
									values.CommunityMemberChildren != [] ?
										((values.CommunityMemberChildren[item] != null) ?
											((values.CommunityMemberChildren[item].GenderTargetId != null) ?
												values.CommunityMemberChildren[item].GenderTargetId : null)
											: null)
										: null
									: null}
								onChange={(e) => this.handleChangeChildrenGenderTarget(e, item)}
								options={listGenderTargetValue} />
						</div>
					</div>

					<div className='style-form-group' style={this.state.style}>
						<div className="row justify-content-center m-0 p-0">
							<input type="text" className="style-form-control-layout title-layout mr-3" value={`Child #${item + 1} Allergy`} readOnly disabled />

							<div className="row m-0 p-0 style-form-control-layout align-items-center" style={{ margin: '0px 5px' }}>
								<input type="checkbox" name="ChildrenAllergy" value='Yes' id={item}
									onChange={this.handleChangeChildrenAllergiesCheckbox}
									checked={this.state.ChildrenAllergyCheckbox[item] === "Yes"} />
								<label className="col text-left">Yes</label>

								<input type="checkbox" name="ChildrenAllergy" value='No' id={item}
									checked={this.state.ChildrenAllergyCheckbox[item] === "No"}
									onChange={this.handleChangeChildrenAllergiesCheckbox} />
								<label className="col text-left" >No</label>
							</div>
						</div>
					</div>

					{this.state.ChildrenAllergyCheckbox[item] === "Yes" && <div className='style-form-group' style={this.state.style}>
						<div className="row justify-content-center m-0 p-0">
							<div className="col-sm-0 col-lg-6 text-right">
							</div>

							<div className="col">
								<div className="row align-items-center m-0 p-0">
									{itemsChildrenAllergies[item]}
								</div>
							</div>
						</div>
					</div>}
				</div>
			));
		}

		// const steps =
		// 	[
		// 		{ name: 'The basics', component: <Step1 /> },
		// 		{ name: 'Teste', component: <Step1 /> },
		// 		// { name: 'Step 2', component: <Step2 /> },
		// 		// { name: 'Step 3', component: <Step3 /> },
		// 		// { name: 'Step 4', component: <Step4 /> },
		// 		// { name: 'Step 5', component: <Step5 /> }
		// 	]

		return (
			<div className="style-container text-center">

				<div className={(this.props.model.PostalCode == null || this.props.model.PostalCode == '') ? "noneDisplay" : "style-header bg-white"}>
					<div className="style-site-header">
						<div className="style-container">
							<div className="style-header-component-inner border-top">
								<div className="style-site-main-nav style-clearfix">
									<ResponsiveMenu
										menuOpenButton={<div className="fa fa-bars" />}
										menuCloseButton={<div className="fa fa-bars" />}
										changeMenuOn="200px"
										largeMenuClassName="style-main-menu style-mega-menu"
										smallMenuClassName="style-mega-menu text-right"
										menu={
											<ul className="p-0">
												<li><a style={{ cursor: 'pointer' }}
													onClick={() => {
														this.props.save(this.props.values);
														this.props.history.push(`/Dashboard/Overview`);
													}}
												>
													<span class="mm-text"><i class="mm-icon far fa-user-circle" aria-hidden="true"></i>Save and return to Dashboard</span></a></li>
												<li><a onClick={() => {
													this.props.save(this.props.values);
													window.location.href = "https://ptpa.com/giveaways/";
												}} style={{ cursor: 'pointer' }}><span class="mm-text"><i class="mm-icon fas fa-medal"></i>Save and go to CONTESTS</span></a></li>
												<li><a onClick={this.handleClick} style={{ cursor: 'pointer' }}><span className="mm-text"><i className="mm-icon fa fa-lock"></i>Save and Log out</span>  </a></li>
											</ul>
										}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<h1><span>
					You are steps away from becoming<br />a member and earning rewards!
				</span></h1>

				{/* <div className='step-progress'>
					<StepZilla steps={steps}
						showNavigation={true}
						showSteps={false}
						dontValidate={true}
						prevBtnOnLastStep={false} />
				</div> */}

				{window.screen.width > 500 && <div>
					<Stepper steps={[
						{ title: 'The basics' },
						{ title: 'The address' },
						{ title: 'Getting personal' },
						{ title: 'Kids?' },
						{ title: 'Keeping in touch' },
						{ title: 'Profile pic' },
						{ title: "You're done" }
					]}
						activeStep={this.state.step}
						titleFontSize={12} />
				</div>}

				<form onSubmit={handleSubmit}>

					{/* this is the photo selection from file */}
					<div className="modal fade bd-example-modal-lg" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-lg" style={{ marginTop: '100px', width: '830px' }}>
							<div className="modal-content">
								<div className="modal-header">
									<h4 className="modal-title">Upload your photo</h4>
								</div>
								<div className="modal-body">
									<div className="form-group">
										<input type="file" id="file-picker" className="form-control" accept="image/*" onChange={onImageChange} />
									</div>
									<div className="form-group col">
										<ReactCrop
											crop={this.state.crop}
											src={imagePreview}
											onImageLoaded={onImageLoaded}
											onComplete={onCropComplete}
											onChange={(crop) => this.setState({ crop })}
										/>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
									<button type="button" disabled={!imagePreview || imagePreview === ''} className="btn btn-primary" data-dismiss="modal" onClick={onCropSave}>Save</button>
								</div>
							</div>
						</div>
					</div>

					<div class="modal fade" id="myModal" role="dialog">
						<div class="modal-dialog">

							<div class="modal-content">
								<div class="modal-body">
									<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2879.1921316983116!2d-79.4984136840529!3d43.8103739791163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2e920df40953%3A0x2813b00bc97187b4!2sPTPA+Media+Inc.!5e0!3m2!1sen!2sca!4v1534170494571" width="800" height="600" frameborder="0" style={{ border: 0 }} allowfullscreen></iframe>
								</div>
							</div>

						</div>
					</div>

					<Carousel
						heightMode="current"
						dragging={false}
						swiping={false}
						style={this.state.styleSlider}
						renderCenterLeftControls={({ previousSlide }) => (
							<div className={this.state.step === 0 ? 'noneDisplay' : 'blockDisplay form-actions'}>
								<div className="row justify-content-center style-fa m-0 p-0" style={{ right: '40px' }}>
									<a className="col-12" onClick={() => {
										if (this.state.step === 4 || this.state.step === 5) {
											this.setState({
												step: this.state.step - 1,
												styleSlider: {
													maxHeight: '1000px',
													backgroundColor: '#b4b6da',
												}
											});
											previousSlide();
										} else {
											this.setState({
												step: this.state.step - 1,
												styleSlider: window.screen.width < 500 ?
													{
														height: '1400px',
														backgroundColor: '#b4b6da',
													}
													:
													{
														maxHeight: '1000px',
														backgroundColor: '#b4b6da',
													},
											});
											previousSlide();
										}
										// previousSlide(); this.setState({ step: this.state.step - 1 }) 
									}}>
										<i className="fa fa-angle-left style-fa-bigger"></i></a>
									<span className="title col-12">Prev</span>
								</div>
							</div>
						)}

						renderCenterRightControls={({ nextSlide }) => (
							<div className={this.state.step == 6 ? 'noneDisplay' : 'blockDisplay form-actions'}>
								<div className="row justify-content-center style-fa m-0 p-0" style={{ left: '40px' }}>
									<button className="col-12" style={{ borderColor: "transparent", backgroundColor: "transparent", all: "none" }}
										onClick={() => {
											if (this.state.step === 0) {
												if ((values.FirstName !== null && values.FirstName !== '') &&
													(values.LastName !== null && values.LastName !== '') &&
													(values.DOB !== null && values.DOB !== '') &&
													(values.CommunityMemberGenderTargetId !== null && values.CommunityMemberGenderTargetId > 0)) {
													this.setState({
														step: this.state.step + 1,
														styleSlider: window.screen.width < 500 ?
															{
																maxHeight: '1100px',
																backgroundColor: '#b4b6da',
															}
															:
															{
																maxHeight: '600px',
																backgroundColor: '#b4b6da',
															},
													}, () => nextSlide());
												}
											} if (this.state.step === 1) {
												if (this.props.values.City != null && this.props.values.City !== '' &&
													this.props.values.PostalCode != null && this.props.values.PostalCode !== '' &&
													this.props.values.CountryId != null && this.props.values.CountryId !== 0 &&
													(this.props.values.StateId != null && this.props.values.StateId !== 0 || this.props.values.CountryId > 2) &&
													this.props.values.Street != null && this.props.values.Street !== '') {
													this.setState({
														step: this.state.step + 1,
														styleSlider: window.screen.width < 500 ?
															{
																height: '800px !important',
																backgroundColor: '#b4b6da',
															}
															:
															{
																maxHeight: '600px',
																backgroundColor: '#b4b6da',
															},
													}, () => nextSlide());
													// nextSlide();
												}
											} if (this.state.step > 1) {
												this.setState({
													step: this.state.step + 1,
													styleSlider: window.screen.width < 500 ?
														{
															height: '1600px',
															backgroundColor: '#b4b6da',
														}
														:
														{
															maxHeight: '1000px',
															backgroundColor: '#b4b6da',
														},
												}, () => nextSlide());
												// nextSlide();
											} 
										}}><i className="fa fa-angle-right style-fa-bigger"></i></button>
									<span className="title col-12">Next</span>
								</div>
							</div>
						)}
						renderBottomCenterControls={null}
					>
						<div className="style-form-container">
							<div className="mb-5 p-2" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
								<img className="img-fluid" src="/assets/img/CompleteProfileBanner.png" style={{ width: '50px' }} />
								<span style={{ fontFamily: 'Montserrat' }}><strong style={{ fontFamily: 'Montserrat' }}>The Basics:</strong> Let's get to know each other!</span>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.FirstName && errors.FirstName ? 'style-form-group has-error' : 'style-form-group'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"First Name *"} readOnly disabled style={this.state.style} />
									<input type="text" id="FirstName" className="style-form-control-layout" value={values.FirstName}
										onChange={handleChange} onBlur={handleBlur} style={this.state.style} />
									<br />
									{errors.FirstName && touched.FirstName && <span className="text-danger col-12">{errors.FirstName}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.LastName && errors.LastName ? 'style-form-group has-error' : 'style-form-group'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Last Name *"} readOnly disabled style={this.state.style} />
									<input type="text" id="LastName" className="style-form-control-layout" value={values.LastName}
										onChange={handleChange} onBlur={handleBlur} style={this.state.style} />
									<br />
									{errors.LastName && touched.LastName && <span className="text-danger col-12">{errors.LastName}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.Email && errors.Email ? 'style-form-group has-error' : 'style-form-group'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Email *"} readOnly disabled style={this.state.style} />
									<input readOnly type="text" id="Email" className="style-form-control-layout" value={values.Email}
										onChange={handleChange} onBlur={handleBlur} style={this.state.style} />
									<br />
									{errors.Email && touched.Email && <span className="text-danger col-12">{errors.Email}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.DOB && errors.DOB ? 'style-form-group has-error' : 'style-form-group'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Birthday (MM/DD/YYYY)*"} readOnly disabled style={this.state.style} />
									<MaskedInput mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} guide={false} id="DOB" className="style-form-control-layout"
										value={values.DOB} onChange={handleChange} onBlur={handleBlur} style={this.state.style} />
									<br />
									{errors.DOB && touched.DOB && <span className="text-danger col-12">{errors.DOB}</span>}
								</div>
							</div>

							{/* <div className={touched.DOB && errors.DOB ? 'style-form-group has-error' : 'style-form-group'} style={this.state.style}>
								<div className="row justify-content-center m-0 p-0">
									<input type="text" className="style-form-control-layout title-layout p-0 m-0" value={"Birthday (MM/DD/YYYY)*"} readOnly disabled  style={this.state.style}/>
									<div className="style-form-control-layout"> */}
							{/* <DatePicker
											value={this.setBirthdayValue(values.DOB)}
											selected={this.state.DOB} onChange={this.handleChangeBirthday}
											id="DOB" className="style-form-control-layout"
											showMonthDropdown showYearDropdown scrollableYearDropdown yearDropdownItemNumber={80} maxDate={moment()} /> */}
							{/* <MaskedInput mask={[/[01]/, /\d/, '/', /[0123]/, /\d/, '/', /[012]/, /\d/, /\d/, /\d/]} guide={false} id="DOB" className="form-control"
											value={moment(values.DOB).format('MM/DD/YYYY')} onChange={handleChange} onBlur={handleBlur} />
										{errors.DOB && touched.DOB && <span className="text-danger">{errors.DOB}</span>}
									</div>
								</div>
							</div> */}

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.CommunityMemberGenderTargetId && errors.CommunityMemberGenderTargetId ? 'style-form-group has-error row justify-content-center p-0 m-0' : 'style-form-group row justify-content-center p-0 m-0'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Select Gender*"} readOnly disabled style={this.state.style} />
									<Select className="style-form-control-layout fixing-select-width" name="form-field-Gender" type="text" id="CommunityMemberGenderTargetId" value={values.CommunityMemberGenderTargetId}
										onChange={this.handleChangeGenderTarget} options={listGenderTargetValue} style={this.state.style} />
									<br />
									{errors.CommunityMemberGenderTargetId && touched.CommunityMemberGenderTargetId && <span className="text-danger col-12">{errors.CommunityMemberGenderTargetId}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.EthnicityId && errors.EthnicityId ? 'style-form-group has-error row justify-content-center p-0 m-0  mt-2' : 'style-form-group row justify-content-center p-0 m-0  mt-2'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Select Ethnicity"} readOnly disabled style={this.state.style} />
									<Select className="style-form-control-layout fixing-select-width" name="form-field-Gender" type="text" id="EthnicityId" value={values.EthnicityId}
										onChange={this.handleChangeEthnicity} options={listEthnicityValue} style={this.state.style} />
									<br />
									{errors.EthnicityId && touched.EthnicityId && <span className="text-danger col-12">{errors.EthnicityId}</span>}
								</div>
							</div>

							<h6 className="text-left mb-5">We are automatically saving your information for you as you fill out this form.</h6>

							{/* close the first component part 1 of the carousel */} </div>

						<div className="style-form-container">


							<div className="mb-5 p-2" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
								<img className="img-fluid" src="/assets/img/CompleteProfileBanner.png" style={{ width: '50px' }} />
								<span style={{ fontFamily: 'Montserrat' }}><strong style={{ fontFamily: 'Montserrat' }}>The Basics:</strong> Let's get to know each other!</span>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.Street && errors.Street ? 'style-form-group has-error' : 'style-form-group'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Address Line 1*"} readOnly disabled style={this.state.style} />
									<input type="text" id="Street" className="style-form-control-layout" value={values.Street}
										onChange={handleChange} onBlur={handleBlur} style={this.state.style} />
									<br />
									{errors.Street && touched.Street && <span className="text-danger col-12">{errors.Street}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.Apartment && errors.Apartment ? 'style-form-group has-error' : 'style-form-group'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Address Line 2"} readOnly disabled style={this.state.style} />
									<input type="text" id="Apartment" className="style-form-control-layout" value={values.Apartment}
										onChange={handleChange} onBlur={handleBlur} style={this.state.style} />
									<br />
									{errors.Apartment && touched.Apartment && <span className="text-danger col-12">{errors.Apartment}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.City && errors.City ? 'style-form-group has-error' : 'style-form-group'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"City*"} readOnly disabled style={this.state.style} />
									<input type="text" id="City" className="style-form-control-layout" value={values.City}
										onChange={handleChange} onBlur={handleBlur} style={this.state.style} />
									<br />
									{errors.City && touched.City && <span className="text-danger col-12">{errors.City}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.PostalCode && errors.PostalCode ? 'style-form-group has-error' : 'style-form-group'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"ZIP/Postal Code*"} readOnly disabled style={this.state.style} />
									<input type="text" id="PostalCode" className="style-form-control-layout" value={values.PostalCode}
										onChange={handleChange} onBlur={handleBlur} style={this.state.style} />
									<br />
									{errors.PostalCode && touched.PostalCode && <span className="text-danger col-12">{errors.PostalCode}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.CountryId && errors.CountryId ? 'style-form-group has-error row row justify-content-center p-0 m-0' : 'style-form-group row row justify-content-center p-0 m-0'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Country*"} readOnly disabled style={this.state.style} />
									<Select className="style-form-control-layout fixing-select-width" name="form-field-Country" type="text" id="CountryId" value={values.CountryId}
										onChange={this.handleChangeCountry} options={listCountryValue} style={this.state.style} />
									<br />
									{errors.CountryId && touched.CountryId && <span className="text-danger col-12">{errors.CountryId}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.StateId && errors.StateId ? 'style-form-group has-error row row justify-content-center p-0 m-0 mt-2' : 'style-form-group row row justify-content-center p-0 m-0 mt-2'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"State/Province*"} readOnly disabled style={this.state.style} />
									<Select className="style-form-control-layout fixing-select-width" name="form-field-State" type="text" id="StateId" value={values.StateId}
										onChange={this.handleChangeState} options={listStateValue} style={this.state.style} />
									<br />
									{errors.StateId && touched.StateId && <span className="text-danger col-12">{errors.StateId}</span>}
								</div>
							</div>

							{this.state.CountryId == 1 && this.state.StateId == 9 && <div className='row justify-content-center m-0 p-0' style={this.state.style}>
								<div className="style-form-group row p-0 mt-2" style={this.state.style}>
									<div className="style-form-control-layout title-layout mr-3" rows="2"
										readOnly disabled style={this.state.style}>
										Are you able to pick your products up directly from our &nbsp;
											<button type="location" class="location" data-toggle="modal" data-target="#myModal"> location</button>
									</div>
									<div className="row m-0 p-0 style-form-control-layout align-items-center" style={this.state.style}>
										<input type="checkbox" name="PickUp" value='Yes'
											onChange={this.handleChangePickUp}
											checked={values.PickUp === true} />
										<label className="col text-left">Yes</label>

										<input type="checkbox" name="PickUp" value="No"
											checked={values.PickUp === false}
											onChange={this.handleChangePickUp} />
										<label className="col text-left" >No</label>
									</div>
								</div>
							</div>}

							{/* <div className={(this.state.CountryId == 1 && this.state.StateId == 9) ? 'style-form-group' : 'noneDisplay'} style={this.state.style}>
								<div className="row justify-content-center m-0 p-0">
									<div className="style-form-control-layout title-layout text-left p-0 m-0" rows="2"
										readOnly disabled  style={this.state.style}>Are you able to pick your products up directly from our &nbsp;
										<button type="location" class="location" data-toggle="modal" data-target="#myModal" style={this.state.style}> location</button>
									</div>
									<div className="row m-0 p-0 style-form-control-layout align-items-center" style={{ margin: '0px 5px' }}>
										<input type="checkbox" name="PickUp" value='Yes'
											onChange={this.handleChangePickUp}
											checked={values.PickUp === true} />
										<label className="col text-left">Yes</label>

										<input type="checkbox" name="PickUp" value="No"
											checked={values.PickUp === false}
											onChange={this.handleChangePickUp} />
										<label className="col text-left" >No</label>
									</div>
								</div>
							</div> */}

							<h6 className="text-left mb-5">We are automatically saving your information for you as you fill out this form.</h6>

							{/* close the first component part 2 of the carousel */} </div>

						<div className="style-form-container">

							<div className="mb-5 p-2" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
								<img className="img-fluid" src="/assets/img/CompleteProfileBanner.png" style={{ width: '50px' }} />
								<span style={{ fontFamily: 'Montserrat' }}><strong style={{ fontFamily: 'Montserrat' }}>Getting Personal:</strong> Are we besties yet?</span>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.MaritalStatusId && errors.MaritalStatusId ? 'style-form-group has-error row row justify-content-center p-0 m-0' : 'style-form-group row row justify-content-center p-0 m-0'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Marital Status"} readOnly disabled style={this.state.style} />
									<Select className="style-form-control-layout fixing-select-width" name="form-field-MaritalStatus" type="text" id="MaritalStatusId" value={values.MaritalStatusId}
										onChange={this.handleChangeMaritalStatus} options={listMaritalValue} style={this.state.style} />
									<br />
									{errors.MaritalStatusId && touched.MaritalStatusId && <span className="text-danger col-12">{errors.MaritalStatusId}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.EducationGroupId && errors.EducationGroupId ? 'style-form-group has-error row row justify-content-center p-0 m-0' : 'style-form-group row row justify-content-center p-0 m-0'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Highest level of education earned?"} readOnly disabled style={this.state.style} />
									<Select className="style-form-control-layout fixing-select-width" name="form-field-EducationGroup" type="text" id="EducationGroupId" value={values.EducationGroupId}
										onChange={this.handleChangeEducationGroup} options={listEducationValue} style={this.state.style} />
									<br />
									{errors.EducationGroupId && touched.EducationGroupId && <span className="text-danger col-12">{errors.EducationGroupId}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.WorkStatusGroupId && errors.WorkStatusGroupId ? 'style-form-group has-error row row justify-content-center p-0 m-0' : 'style-form-group row row justify-content-center p-0 m-0'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Work Status"} readOnly disabled style={this.state.style} />
									<Select className="style-form-control-layout fixing-select-width" name="form-field-WorkStatusGroup" type="text" id="WorkStatusGroupId" value={values.WorkStatusGroupId}
										onChange={this.handleChangeWorkGroup} options={listWorkValue} style={this.state.style} />
									<br />
									{errors.WorkStatusGroupId && touched.WorkStatusGroupId && <span className="text-danger col-12">{errors.WorkStatusGroupId}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.IncomeGroupId && errors.IncomeGroupId ? 'style-form-group has-error row row justify-content-center p-0 m-0' : 'style-form-group row row justify-content-center p-0 m-0'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Total household income?"} readOnly disabled style={this.state.style} />
									<Select className="style-form-control-layout fixing-select-width" name="form-field-IncomeGroup" type="text" id="IncomeGroupId" value={values.IncomeGroupId}
										onChange={this.handleChangeIncomeGroup} options={listIncomeValue} style={this.state.style} />
									<br />
									{errors.IncomeGroupId && touched.IncomeGroupId && <span className="text-danger col-12">{errors.IncomeGroupId}</span>}
								</div>
							</div>

							<div className="row justify-content-center m-0 p-0" style={this.state.style}>
								<div className="style-form-group row p-0 mt-2" style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout mr-3" value={"Do you have any allergies?"} readOnly disabled style={this.state.style} />
									<div className="row m-0 p-0 style-form-control-layout align-items-center" style={this.state.style}>
										<input type="checkbox" name="Allergies" value='Yes'
											onChange={this.handleChangeAllergiesCheckbox}
											checked={this.state.AllergyCheckbox === "Yes"} />
										<label className="col text-left">Yes</label>

										<input type="checkbox" name="Allergies" value="No"
											checked={this.state.AllergyCheckbox === "No"}
											onChange={this.handleChangeAllergiesCheckbox} />
										<label className="col text-left" >No</label>
									</div>
								</div>
							</div>

							{this.state.AllergyCheckbox === "Yes" && <div className='style-form-group' style={this.state.style}>
								<div className="row justify-content-center m-0 p-0">
									<div className="col-sm-0 col-lg-6 text-right">
									</div>

									<div className="col">
										<div className="row align-items-center m-0 p-0">
											{itemsAllergies}
										</div>
									</div>
								</div>
							</div>}

							<div className="row justify-content-center m-0 p-0" style={this.state.style}>
								<div className="style-form-group row p-0 mt-2" style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout mr-3" value={"Are you expecting?"} readOnly disabled style={this.state.style} />
									<div className="row m-0 p-0 style-form-control-layout align-items-center" style={this.state.style}>
										<input type="checkbox" name="Expecting" value='Yes'
											onChange={this.handleChangeExpecting}
											checked={values.ExpectingKid === true} />
										<label className="col text-left">Yes</label>

										<input type="checkbox" name="Expecting" value="No"
											checked={values.ExpectingKid === false}
											onChange={this.handleChangeExpecting} />
										<label className="col text-left" >No</label>
									</div>
								</div>
							</div>

							{this.state.selectedExpecting === "Yes" && <div className="row justify-content-center m-0 p-0">
								<div className={touched.ExpectingKidDueDate && errors.ExpectingKidDueDate ? 'style-form-group has-error' : 'style-form-group'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"Due Date (MM/DD/YYYY)*"} readOnly disabled style={this.state.style} />
									<MaskedInput mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} guide={false} id="ExpectingKidDueDate" className="style-form-control-layout"
										value={values.ExpectingKidDueDate} onChange={handleChange} onBlur={handleBlur} style={this.state.style} />
									<br />
									{errors.ExpectingKidDueDate && touched.ExpectingKidDueDate && <span className="text-danger col-12">{errors.ExpectingKidDueDate}</span>}
								</div>
							</div>}

							{/* <div className={this.state.selectedExpecting === "Yes" ? 'style-form-group' : 'noneDisplay'} style={this.state.style}>
								<div className="row justify-content-center m-0 p-0">
									<input type="text" className="style-form-control-layout title-layout" value={"Due Date"} readOnly disabled />
									<div className="style-form-control-layout"> */}
							{/* <DatePicker
											value={this.setExpectingDateValue(values.ExpectingKidDueDate)}
											selected={this.state.ExpectingDate} onChange={this.handleChangeExpectingDate}
											id="ExpectingKidDueDate" className="style-form-control-layout"
											showMonthDropdown showYearDropdown scrollableYearDropdown minDate={moment()}
											placeholderText="(MM/DD/YYYY)"
										/> */}
							{/* <MaskedInput mask={[/[01]/, /\d/, '/', /[0123]/, /\d/, '/', /[012]/, /\d/, /\d/, /\d/]} guide={false} id="ExpectingKidDueDate" className="form-control"
											value={moment(values.ExpectingKidDueDate).format('MM/DD/YYYY')} onChange={handleChange} onBlur={handleBlur} />
									</div>
									{errors.ExpectingKidDueDate && touched.ExpectingKidDueDate && <span className="text-danger">{errors.ExpectingKidDueDate}</span>}
								</div>
							</div> */}

							<h6 className="text-left mb-5">We are automatically saving your information for you as you fill out this form.</h6>

							{/* close the second component of the carousel */} </div>

						<div className="style-form-container">

							<div className="mb-5 p-2" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
								<img className="img-fluid" src="/assets/img/CompleteProfileBanner.png" style={{ width: '50px' }} />
								<span style={{ fontFamily: 'Montserrat' }}><strong style={{ fontFamily: 'Montserrat' }}>Your Kids:</strong> Let's talk about your little ones!</span>
							</div>

							<div className="row justify-content-center m-0 p-0" style={this.state.style}>
								<div className="style-form-group row p-0 mt-2" style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout mr-3" value={"Do you have children?"} readOnly disabled style={this.state.style} />
									<div className="row m-0 p-0 style-form-control-layout align-items-center" style={this.state.style}>
										<input type="checkbox" name="Children" value='Yes'
											onChange={this.handleChangeChildrenCheckbox}
											checked={this.state.ChildrenCheckbox === "Yes"} />
										<label className="col text-left">Yes</label>

										<input type="checkbox" name="Children" value="No"
											checked={this.state.ChildrenCheckbox === "No"}
											onChange={this.handleChangeChildrenCheckbox} />
										<label className="col text-left" >No</label>
									</div>
								</div>
							</div>

							{this.state.ChildrenCheckbox === "Yes" && <div className='row justify-content-center m-0 p-0'>
								<div className="style-form-group row p-0 m-0" style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"How many children?"} readOnly disabled style={this.state.style} />
									<Select className="style-form-control-layout fixing-select-width" name="form-field-IncomeGroup" type="text" id="ChildrenQtyValue" value={this.setNumberOfChildren(values.ChildrenQtyValue)}
										onChange={this.handleChangeChildrenQty} options={[
											{ value: 1, label: '1' },
											{ value: 2, label: '2' },
											{ value: 3, label: '3' },
											{ value: 4, label: '4' },
											{ value: 5, label: '5' },
										]} style={this.state.style} />
								</div>
							</div>}

							{items}

							<h6 className="text-left mb-5">We are automatically saving your information for you as you fill out this form.</h6>

							{/* close the third component of the carousel */} </div>

						<div className="style-form-container">

							<div className="mb-5 p-2" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
								<img className="img-fluid" src="/assets/img/CompleteProfileBanner.png" style={{ width: '50px' }} />
								<span style={{ fontFamily: 'Montserrat' }}><strong style={{ fontFamily: 'Montserrat' }}>Keeping in Touch:</strong> How may we span you? Just kidding!</span>
							</div>

							<div className="row justify-content-center m-0 p-0" style={this.state.style}>
								<div className="style-form-group row p-0 mt-2" style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout mr-3" value={"Do we have your permission to email you?"} readOnly disabled style={this.state.style} />
									<div className="row m-0 p-0 style-form-control-layout align-items-center" style={this.state.style}>
										<input type="checkbox" name="Subscribe" value='Yes'
											onChange={this.handleChangeSubscribeCheckbox}
											checked={this.state.SubscribeCheckbox === "Yes"} />
										<label className="col text-left">Yes</label>

										<input type="checkbox" name="Subscribe" value="No"
											checked={this.state.SubscribeCheckbox === "No"}
											onChange={this.handleChangeSubscribeCheckbox} />
										<label className="col text-left" >No</label>
									</div>
								</div>
							</div>

							{this.state.SubscribeCheckbox === "Yes" && <div className='style-form-group' style={this.state.style}>
								<div className="row justify-content-center m-0 p-0">
									<input type="text" className="style-form-control-layout title-layout" value={"Email me about"} readOnly disabled style={this.state.style} />

									<div className="row m-0 p-0 style-form-control-layout align-items-center" style={this.state.style}>
										{itemsPreferences}
									</div>
								</div>
							</div>}

							<div className="row justify-content-center m-0 p-0" style={this.state.style}>
								<div className="style-form-group row p-0 mt-2" style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout mr-3" value={"Indicate activities/interests which you and/or household members enjoy on a regular basis:"} readOnly disabled style={this.state.style} />

									<div className="row m-0 p-0 style-form-control-layout align-items-center" style={this.state.style}>
										{itemsIndicateActivity}
									</div>
								</div>
							</div>

							{/* {values.Subscribe &&
								<div className='style-form-group' style={this.state.style}>
									<div className="row justify-content-center m-0 p-0">
										<div className="col text-right p-0 m-0">
											<input type="text" className="style-form-control-layout title-layout" value={"Email me about"} readOnly disabled />
										</div>

										<div className="col p-0 m-0">
											<div className="style-form-control-layout align-items-center">
												{itemsPreferences}
											</div>
										</div>
									</div>
								</div>
							} */}

							<h3 className="text-left mb-5 mt-5">
								<span style={{ fontFamily: 'Montserrat' }}>
									And one last thing... (You're almost done!)
								</span>
							</h3>

							<div className="row justify-content-center m-0 p-0">
								<div className={touched.HeardAboutId && errors.HeardAboutId ? 'style-form-group has-error row row justify-content-center p-0 m-0' : 'style-form-group row row justify-content-center p-0 m-0'} style={this.state.style}>
									<input type="text" className="style-form-control-layout title-layout" value={"How did hear about us?"} readOnly disabled style={this.state.style} />
									<Select className="style-form-control-layout fixing-select-width" name="form-field-HeardAbout" type="text" id="HeardAboutId" value={values.HeardAboutId}
										onChange={this.handleChangeHeardAboutUs} options={listHeardAboutUsValue} style={this.state.style} />
									<br />
									{errors.HeardAboutId && touched.HeardAboutId && <span className="text-danger col-12">{errors.HeardAboutId}</span>}
								</div>
							</div>

							<h6 className="text-left mb-5">We are automatically saving your information for you as you fill out this form.</h6>

							{/* close the forth component of the carousel */} </div>

						{/* block for photo */}

						<div className="style-form-container">

							<div className="mb-5 p-2" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
								<img className="img-fluid" src="/assets/img/CompleteProfileBanner.png" style={{ width: '50px' }} />
								<span style={{ fontFamily: 'Montserrat' }}><strong style={{ fontFamily: 'Montserrat' }}>Say cheese!:</strong> Well not quite, upload a pic or choose one of ours.</span>
							</div>

							<div className='style-form-group' style={this.state.style}>
								<div className="row justify-content-center m-0 p-0">
									<div className="col">
										<img src={image} style={{ width: '200px', borderRadius: '50%' }} /><br />
										<button type="button"
											className="btn btn-small btn-primary"
											data-toggle="modal"
											data-backdrop="false"
											data-target=".bd-example-modal-lg"
											onClick={onModalShow}>Upload a photo</button>
										<div className="modal fade bd-example-modal-lg" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
											<div className="modal-dialog modal-lg" style={{ marginTop: '100px' }}>

											</div>
										</div>
									</div>
								</div>
							</div>

							<h6 className="text-left mb-5">We are automatically saving your information for you as you fill out this form.</h6>

							{/* close the fifty component of the carousel */}
						</div>

						<div className="style-form-container">
							<h1 className="style-last-slide-msg mb-5">
								<span>
									We are now officially besties!
								</span>
							</h1>

							<h2 className="mb-5">
								<span>
									Oh, and you’re a member of the amazing
								</span>
							</h2>

							<h2 className="mb-5">
								<span>
									PTPA community, that’s pretty cool too!
								</span>
							</h2>

							<div>
								<button className="explore-dashboard"
									onClick={() => this.props.history.push(`/Dashboard/Overview`)}><span className="text-dark">Explore Your Dashboard</span></button>
							</div>

							{/* close the six component of the carousel */}
						</div>

					</Carousel>

					{/* <div className="form-actions">
						<div className="pull-right">
							<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
						</div>
					</div> */}
				</form>
			</div>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		FirstName: Yup.string()
			.required('FirstName is required!'),
		LastName: Yup.string()
			.required('LastName is required!'),
		DOB: Yup.string()
			.required('Birthday is required!')
			.nullable(true),
		CommunityMemberGenderTargetId: Yup.number()
			.moreThan(0, 'Gender is required!')
			.nullable(true),
		City: Yup.string()
			.required('City is required!')
			.nullable(true),
		PostalCode: Yup.string()
			.required('PostalCode is required!')
			.nullable(true),
		CountryId: Yup.number()
			.moreThan(0, 'Country is required!')
			.nullable(true),
		StateRequired: Yup.boolean(),
		StateId: Yup.number()
			.when('StateRequired', {
				is: true,
				then: Yup.number().moreThan(0, 'State is required!').nullable(true),
				otherwise: Yup.number().min(0)
			}),
		Street: Yup.string()
			.required('Address is required!')
			.nullable(true),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.save(values).catch((error) => {
			setSubmitting(false);
		});
	},
	//displayName: 'BasicForm', // helps with React DevTools
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = {
	Id: 0, FirstName: '', LastName: '', Email: '', Street: '', Apartment: '', PostalCode: '', CommunityMemberGenderTargetId: null, City: '',
	CountryId: null, StateId: null, DOB: '', MaritalStatusId: null, EducationGroupId: null, WorkStatusGroupId: null,
	IncomeGroupId: null, ExpectingKidDueDate: '', ExpectingKid: null, PickUp: null, ChildrenQtyValue: null, HeardAboutId: null,
	CommunityMemberAllergy: null, CommunityMemberChildren: [], Subscribe: null, ProfileImage: '', EthnicityId: null
};

function getCroppedImg(image, pixelCrop, fileName) {
	const canvas = document.createElement('canvas');

	if (!pixelCrop)
		pixelCrop = image;

	var MAX_WIDTH = 300;
	var MAX_HEIGHT = 300;
	var width = pixelCrop.width;
	var height = pixelCrop.height;

	if (width > height) {
		if (width > MAX_WIDTH) {
			height *= MAX_WIDTH / width;
			width = MAX_WIDTH;
		}
	} else {
		if (height > MAX_HEIGHT) {
			width *= MAX_HEIGHT / height;
			height = MAX_HEIGHT;
		}
	}

	canvas.width = width;
	canvas.height = height;
	const ctx = canvas.getContext('2d');

	ctx.drawImage(
		image,
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height,
		0,
		0,
		width,
		height
	);

	// As Base64 string
	const base64Image = canvas.toDataURL('image/jpeg');

	return base64Image;
}

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema),
			imagePreview: '',
			imageSelected: '',
			crop: {
				x: 10,
				y: 10,
				width: 10,
				height: 10,
				aspect: 1,
			},
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleImageLoaded = this.handleImageLoaded.bind(this)
		this.handleImageChange = this.handleImageChange.bind(this);
		this.handleCropChange = this.handleCropChange.bind(this);
		this.handleCropComplete = this.handleCropComplete.bind(this);
		this.handleModalShow = this.handleModalShow.bind(this);
		this.handleCropSave = this.handleCropSave.bind(this);
	}

	componentDidMount() {
		if (Auth.isUserAuthenticated())
			getCommunityMemberToken()
				.then(res => res.json()
					.then(json => ({ res, json })))
				.then(({ res, json }) => {
					if (json.Status) {
						var temp = json;
						temp.DOB = json.DOB? moment(json.DOB).format('MM/DD/YYYY').toString() : '';
						temp.ExpectingKidDueDate = json.ExpectingKidDueDate? moment(json.ExpectingKidDueDate).format('MM/DD/YYYY').toString() : '';
						temp.StateRequired = json.CountryId === 1 || json.CountryId === 2

						this.setState({ model: temp });
					}
				})
				.catch(() =>
					swal({
						title: "Sorry, this is a member only page",
						text: "Please login to access this page",
						icon: '/assets/img/SadFaceResize.png',
						// buttons: true,
						dangerMode: true,
						closeOnClickOutside: false,
					}).then((result) => {
						if (result) {
							//TODO: change when go to production
							var indexOfSlash = window.location.href.indexOf('.com');
							var redirectTo = window.location.href.substring(indexOfSlash + 4);
							// var indexOfSlash = window.location.href.indexOf(':3000'); // for test ind development
							// var redirectTo = window.location.href.substring(indexOfSlash + 5);
							window.location.href = ('/Dashboard/CommunityMember/Login/?redirect=' + redirectTo);
						}
					})
				);
	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {

		var data = values;
		data.DOB = moment(values.DOB).format('MM/DD/YYYY');
		data.ExpectingKidDueDate = values.ExpectingKidDueDate !== null && values.ExpectingKidDueDate !== ''? moment(values.ExpectingKidDueDate).format('MM/DD/YYYY') : null;

		if (values.StateId === 0)
			data.StateId = null

		for (var i = 0; i < values.CommunityMemberChildren.length; i++) {
			data.CommunityMemberChildren[i].DOB = moment(values.CommunityMemberChildren[i].DOB).format('MM/DD/YYYY');
		}

		var imageSelected = this.state.imageSelected;

		if (imageSelected !== '') {
			data = Object.assign(data, { Image: imageSelected.replace('data:image/jpeg;base64,', '') });
		}

		return saveCommunityMember(data).then(res => {
			if (res.ok) {
			}
		});
	}

	handleImageLoaded(image) {
		this.setState({ image });
	}

	handleImageChange(e) {
		const imageType = /^image\//;
		const file = e.target.files.item(0);

		if (!file || !imageType.test(file.type)) {
			return;
		}

		const reader = new FileReader();

		reader.onload = (data) => {
			let dataUrl = data.target.result;
			this.setState({ imagePreview: dataUrl });
		};

		reader.readAsDataURL(file);
	}

	handleCropChange(crop) {
		this.setState({ crop });
	}

	handleCropComplete(crop, pixelCrop) {
		this.setState({ pixelCrop });
	}

	handleModalShow() {
		this.setState({
			crop: {
				x: 0,
				y: 0,
				aspect: 1
			},
			imagePreview: ''
		});
	}

	handleCropSave() {
		var croped = getCroppedImg(this.state.image, this.state.pixelCrop);
		this.setState({ imageSelected: croped });
	}

	render() {
		return (
			<div>
				<div className="tab-content">
					<div className="tab-pane active" id="tab1EditUser">
						<MyEnhancedForm
							model={this.state.model}
							history={this.props.history}
							save={this.handleSave}
							reset={this.handleReset}
							onModalShow={this.handleModalShow}
							onImageLoaded={this.handleImageLoaded}
							imageSelected={this.state.imageSelected}
							imagePreview={this.state.imagePreview}
							onImageChange={this.handleImageChange}
							crop={this.state.crop}
							onCropChange={this.handleCropChange}
							onCropComplete={this.handleCropComplete}
							onCropSave={this.handleCropSave} />
					</div>
					{/* </div>
					</div> */}
				</div>
			</div>
		);
	}
}

export default Form;