import API_URL from '../config';
import { getFetchOpts } from './Common';

const endpoint = `${API_URL}/ReviewWebSite`;

export const getAllReviewWebSite = () => {
	return fetch(endpoint, Object.assign({
		method: 'GET',
		//body: JSON.stringify()
	}, getFetchOpts()));
};

export const getReviewWebSite = id => {
	let url = `${endpoint}/${id}`;

	return fetch(url, Object.assign({
		method: 'GET'
	}, getFetchOpts()));
};

export const saveReviewWebSite = (reviewWebSite) => {
	let url = endpoint;
	let method = 'POST';
	
	if(reviewWebSite.Id && reviewWebSite.Id > 0)
	{
		url += `/${reviewWebSite.Id}`
		method = 'PUT';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(reviewWebSite)
	}, getFetchOpts()));
};

export const deleteReviewWebSite = (id) => {
	let url = endpoint;
	let method = 'POST';
	
	if(id && id > 0)
	{
		url += `/${id}`
		method = 'DELETE';
	}
	
	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(id)
	}, getFetchOpts()));
};

export const getListIdProductPaged = (tableModel) => {
	let url = `${endpoint}/ListIdProduct/`;
	let method = 'POST';

	return fetch(url, Object.assign({
		method,
		body: JSON.stringify(tableModel)
	}, getFetchOpts()));
};