import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';

import { uploadSurvey } from '../../../service/ProductSurveyQuestion';

class FormUploadSurvey extends Component {
	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}
	render() {
		const {
			values,
			isSubmitting,
			handleSubmit,
		} = this.props;
		
		return (
			<form onSubmit={handleSubmit}>
				<div className="form-group">
					<input type="file" id="File" className="form-control" />
				</div>

				<div className="form-group">
					<input className="form-control" ref={(ref) => { this.fileName = ref; }} type="text" placeholder="Optional name for the file" />
				</div>
				<div>
					<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
				</div>

			</form>
		)
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),

	handleSubmit: (values, { setSubmitting, props }) => {
		props.save(values).catch((error) => {
			setSubmitting(false);
		});
	},
});

const MyEnhancedForm = formikEnhancer(FormUploadSurvey);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = { Id: 0, Name: '' };

class UploadSurvey extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.match.params.id,
			model: Object.assign(schema, baseSchema)
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.getBase64 = this.getBase64.bind(this);
	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	getBase64(file, obj) {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			obj.File = reader.result;
		};
		reader.onerror = function (error) {
		};
	 }

	handleSave(values) {
		var files = document.querySelector('[type=file]').files;
		if (files.length > 0) {
			this.getBase64(files[0], values);
		}


		return uploadSurvey(values).then(res => {
			if (res.ok) {
				//this.props.history.push('/age-target');
			}
		});
	}

	render() {
		return (
			<div>
				<ul className="breadcrumb">
					<li><p>Product Survey</p></li>
					<li><Link to="/age-target">Manage</Link></li>
					<li><a href="#" className="active">{(window.location.href.indexOf("edit") > -1 ? "Edit" : "Add")}</a> </li>
				</ul>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" role="tabList">
							<li className="active"><a href="#tab1EditUser" role="tab" data-toggle="tab">{(window.location.href.indexOf("edit") > -1 ? "Edit Product Survey" : "Add Product Survey")}</a></li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="tab1EditUser">
								<MyEnhancedForm model={this.state.model} save={this.handleSave} reset={this.handleReset} getBase64={this.getBase64} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default UploadSurvey;