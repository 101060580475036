import React, { Component } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { getProspectByCouponCode } from '../../../service/Prospect';
import { saveProspectManufacturer } from '../../../service/ProspectManufacturer';
import { saveManufacturer } from '../../../service/Manufacturer';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import Auth from '../../../util/Auth';
import { getManufacturerToken } from '../../../service/Manufacturer';

import { getAllCountry } from '../../../service/Country';
import { getStateByCountry } from '../../../service/State';

import Stepper from 'react-stepper-horizontal';
import MaskedInput from 'react-text-mask'

import * as MyConstantsFile from '../../../util/MyConstantsFile'

class MyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listState: [],
			StateId: null,
			listCountry: [],
			CountryId: null,
			Id: 0,
			loadFromModel: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.model !== nextProps.model)
			this.props.resetForm();
	}

	handleChangeCountry = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.CountryId = selectedOption.value;
			this.setState({
				CountryId: selectedOption.value,
			});
		} else {
			this.props.values.CountryId = null;
			this.setState({
				CountryId: null,
			});
		}
		this.getState(this.props.values.CountryId);
	}

	handleChangeState = (selectedOption) => {
		if (selectedOption != null) {
			this.props.values.StateId = selectedOption.value;
			this.setState({
				StateId: selectedOption.value,
			});
		} else {
			this.props.values.StateId = null;
			this.setState({
				StateId: null,
			});
		}
	}

	getState(countryId) {
		if (this.state.StateId != null)
			this.handleChangeState(null);

		getStateByCountry(countryId)
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listState: json });
			})
			.catch(console.log);
	}

	componentDidMount() {
		getAllCountry()
			.then(res => res.json()
				.then(json => ({ res, json })))
			.then(({ res, json }) => {
				this.setState({ listCountry: json });
			})
			.catch(console.log);
	}

	render() {
		const {
			values,
			touched,
			errors,
			isSubmitting,
			handleBlur,
			handleChange,
			handleSubmit,
		} = this.props;
		const created = values.Created !== '' ? moment(values.Created).format('MM/DD/YYYY HH:mm') : '';
		const updated = values.Updated !== '' ? moment(values.Updated).format('MM/DD/YYYY HH:mm') : '';

		const listCountryValue = [];
		const itemsCountry = this.state.listCountry.map((item) =>
			listCountryValue.push({ value: item.Id, label: item.CountryName })
		);

		const listStateValue = [];
		const itemsState = this.state.listState.map((item) =>
			listStateValue.push({ value: item.Id, label: item.StateName })
		);

		if (this.state.loadFromModel == false && this.props.values.State != null) {
			this.state.loadFromModel = true;
			this.props.values.CountryId = this.props.values.State.CountryId;
			this.state.CountryId = this.props.values.State.CountryId;

			this.props.values.editMode = true;

			this.getState(this.props.values.State.CountryId);
		}

		if (this.state.StateId == null && this.props.values.StateId > 0) {
			this.setState({ StateId: this.props.model.StateId })
		}

		const steps = [{ title: 'Complete Profile' }, { title: 'Payment Information' }, { title: 'Review and Submit Payment' }];

		return (
			<form onSubmit={handleSubmit}>

				<div className="mb-4">
					<Stepper steps={steps} activeStep={0} />
				</div>

				<div>
					<h5 className="complete-information">Welcome {this.props.model.FirstName}! <br /> <br />
						Thank you for choosing to submit your product with PTPA. Please begin the order process by filling out your billing information.
					</h5>
				</div>

				<div className={touched.CompanyName && errors.CompanyName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="CompanyName">Company Name *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="CompanyName" className="form-control" value={values.CompanyName} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.CompanyName && touched.CompanyName && <span className="text-danger">{errors.CompanyName}</span>}
				</div>

				<div className={touched.FirstName && errors.FirstName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="FirstName">First Name *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="FirstName" className="form-control" value={values.FirstName}
							onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.FirstName && touched.FirstName && <span className="text-danger">{errors.FirstName}</span>}
				</div>

				<div className={touched.LastName && errors.LastName ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="LastName">Last Name *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="LastName" className="form-control" value={values.LastName}
							onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.LastName && touched.LastName && <span className="text-danger">{errors.LastName}</span>}
				</div>

				<div className={touched.Email && errors.Email ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Email">Email *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="Email" className="form-control" value={values.Email}
							onChange={handleChange} onBlur={handleBlur} readOnly />
					</div>
					{errors.Email && touched.Email && <span className="text-danger">{errors.Email}</span>}
				</div>

				<div className={touched.PhoneNumber && errors.PhoneNumber ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Email">Phone Number *</label>
					<div className="input-width-icon right">
						<MaskedInput mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} id="PhoneNumber"
							className="form-control" guide={false} value={values.PhoneNumber} onChange={handleChange} onBlur={handleBlur}
							placeholder="(XXX) XXX-XXXX" />
					</div>
					{errors.PhoneNumber && touched.PhoneNumber && <span className="text-danger">{errors.PhoneNumber}</span>}
				</div>

				<div className={touched.Password && errors.Password ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Password">Create Password *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="password" id="Password" className="form-control" value={values.Password} onChange={handleChange} onBlur={handleBlur} />
					</div>
					(Password must contain at least 8 characters and must contain at least one capital letter and one numeric character.)<br />
					{errors.Password && touched.Password && <span className="text-danger">{errors.Password}</span>}
				</div>

				<div className={touched.ConfirmPassword && errors.ConfirmPassword ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="ConfirmPassword">Confirm your password *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="password" id="ConfirmPassword" className="form-control" value={values.ConfirmPassword} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.ConfirmPassword && touched.ConfirmPassword && <span className="text-danger">{errors.ConfirmPassword}</span>}
				</div>

				<div className={touched.CountryId && errors.CountryId ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="CountryId">Country *</label>
					<div className="input-width-icon right">
						<i></i>
						<Select name="form-field-Country" type="text" id="CountryId" value={values.CountryId} onChange={this.handleChangeCountry} options={listCountryValue} />
					</div>
					{errors.CountryId && touched.CountryId && <span className="text-danger">{errors.CountryId}</span>}
				</div>

				<div className={touched.Street && errors.Street ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Street">Address Line 1 *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="Street" className="form-control" value={values.Street} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Street && touched.Street && <span className="text-danger">{errors.Street}</span>}
				</div>

				<div className={touched.Apartment && errors.Apartment ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="Apartment">Address Line 2</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="Apartment" className="form-control" value={values.Apartment} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.Apartment && touched.Apartment && <span className="text-danger">{errors.Apartment}</span>}
				</div>

				<div className={touched.City && errors.City ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="City">City *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="City" className="form-control" value={values.City} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.City && touched.City && <span className="text-danger">{errors.City}</span>}
				</div>

				<div className={touched.StateId && errors.StateId ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="StateId">State/Province *</label>
					<div className="input-width-icon right">
						<i></i>
						<Select name="form-field-State" type="text" id="StateId" value={values.StateId} onChange={this.handleChangeState} options={listStateValue} />
					</div>
					{errors.StateId && touched.StateId && <span className="text-danger">{errors.StateId}</span>}
				</div>

				<div className={touched.ZipCode && errors.ZipCode ? 'form-group has-error' : 'form-group'}>
					<label className="form-label" htmlFor="ZipCode">Postal/Zip Code *</label>
					<div className="input-width-icon right">
						<i></i>
						<input type="text" id="ZipCode" className="form-control" value={values.ZipCode} onChange={handleChange} onBlur={handleBlur} />
					</div>
					{errors.ZipCode && touched.ZipCode && <span className="text-danger">{errors.ZipCode}</span>}
				</div>

				<div className="form-actions">
					<div className="pull-right">
						<button className={isSubmitting ? 'btn btn-danger btn-cons disabled' : 'btn btn-danger btn-cons'}>{!isSubmitting ? 'Save' : 'Saving'}</button>
					</div>
				</div>
			</form>
		);
	}
}

const formikEnhancer = withFormik({
	mapPropsToValues: props => ({ ...props.model }),
	validationSchema: Yup.object().shape({
		CompanyName: Yup.string()
			.required('Company Name is required!'),
		City: Yup.string()
			.required('City is required!'),
		Street: Yup.string()
			.required('Address is required!'),
		ZipCode: Yup.string()
			.required('Postal/Zip Code is required!'),
		StateId: Yup.number()
			.required('State is required!')
			.nullable(true),
		CountryId: Yup.number()
			.required('Country is required!')
			.nullable(true),
		Email: Yup.string()
			.required('Email is required!')
			.email('Invalid email address'),
		PhoneNumber: Yup.string()
			.required('Phone Number is required!')
			.nullable(true),
		Password: Yup.string()
			.min(8, 'Password has to be longer than 8 characters!')
			.required('Password is required!')
			.matches(/[a-z]/, 'Lowercase character required!')
			.matches(/[A-Z]/, 'Uppercase character required!'),
		ConfirmPassword: Yup.string()
			.oneOf([Yup.ref('Password'), null], "Passwords don't match")
			.required('Password is required!'),
	}),
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSave(values).catch((error) => {
			setSubmitting(false);
		});
	},
});

const MyEnhancedForm = formikEnhancer(MyForm);

const now = new Date();

const baseSchema = { Created: now, Updated: now };
const schema = {
	Id: 0, Email: '', PhoneNumber: '', Password: '', ConfirmPassword: '', FirstName: '', LastName: '', City: '',
	Street: '', Apartment: '', ZipCode: '', CompanyName: '', CountryId: null, StateId: null,
};

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			couponCode: Auth.getCoupon(),
			model: Object.assign(schema, baseSchema)
		}

		this.handleReset = this.handleReset.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentDidMount() {
		let couponCode = this.state.couponCode;

		if (couponCode) {
			getProspectByCouponCode(couponCode)
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.state.model = json;
					this.state.model.Password = "";
					this.state.model.ConfirmPassword = "";
					this.state.model.ZipCode = "";
					this.state.model.City = "";
					this.state.model.Street = "";
					this.state.model.PhoneNumber = "";
					this.state.model.StateId = null;
					this.state.model.CountryId = null;
					this.setState({
						model: this.state.model,
					});
					this.state.Id = json.Id;
				});
		}
		else {
			getManufacturerToken()
				.then(res => res.json().then(json => ({ res, json })))
				.then(({ res, json }) => {
					this.setState({ model: json });
					this.state.Id = json.Id;
				});
		}

	}

	handleReset() {
		this.setState({
			model: Object.assign(schema, baseSchema)
		});
	}

	handleSave(values) {

		var manufacturerObj = {};
		var prospectManufacturerObj = {};
		var prospectObj = {};

		manufacturerObj = {
			CompanyName: values.CompanyName,
			FirstName: values.FirstName,
			LastName: values.LastName,
			Email: values.Email,
			PhoneNumber: values.PhoneNumber,
			CountryId: values.CountryId,
			StateId: values.StateId,
			City: values.City,
			Street: values.Street,
			Apartment: values.Apartment,
			ZipCode: values.ZipCode,
			Register: { Email: values.Email, Password: values.Password, UserName: values.Email }
		}

		prospectObj = {
			Coupon: { CouponCode: Auth.getCoupon() },
		}

		prospectManufacturerObj = {
			Manufacturer: manufacturerObj,
			ProspectId: this.state.Id,
			Prospect: prospectObj
		}

		return saveProspectManufacturer(prospectManufacturerObj).then(res => res.json().then(json => ({ res, json })))
			.then(({ res, json }) => {
				if (res.ok) {
					if (json.token != null) {
						Auth.authenticateUser(json.token);
						Auth.setTypeUser("manufacturer");

						if (values.editMode)
							this.props.history.push(`/`);
						else if (values.Invoice) {
							window.location.href = MyConstantsFile.APP_ADMIN_PRODUCT_CLIENT_ADD_PATH;
						} else {
							window.location.href = MyConstantsFile.APP_ADMIN_PAYMENTINFORMATION_PATH;
						}
					}
				}
			}
			);

	}

	render() {
		return (
			<div>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" role="tabList">
							<li className="active"><a href="#tab1EditUser" role="tab" data-toggle="tab">Complete your information</a></li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="tab1EditUser">
								<MyEnhancedForm model={this.state.model} onSave={this.handleSave} reset={this.handleReset} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Form;
