export const APP_ADMIN_LOGIN_PATH = "/AdminPanel/Login"
export const APP_ADMIN_REGISTER_PATH = "/AdminPanel/Register"
export const APP_ADMIN_PATH = "/AdminPanel"
export const APP_WEBSITE_PATH = ""

//PAGES NAME
export const PAGE_NAME_ADMINISTRATOR = "Administrator"
export const PAGE_NAME_COMPANYTYPES = "Company Types"
export const PAGE_NAME_COUNTRY = "Country"
export const PAGE_NAME_COUPON = "Coupon"
export const PAGE_NAME_GENDERTARGET = "Gender Target"
export const PAGE_NAME_CAMPAIGN = "Campaign"
export const PAGE_NAME_CATEGORY = "Category"
export const PAGE_NAME_HEARDABOUT = "Heard About"
export const PAGE_NAME_LEAD = "Lead"
export const PAGE_NAME_EVALUATOR = "Evaluator"
export const PAGE_NAME_EMAIL = "Email"
export const PAGE_NAME_CLIENT = "Client"
export const PAGE_NAME_PACKAGE = "Package"
export const PAGE_NAME_PACKAGEITEM = "Package Item"
export const PAGE_NAME_PAYMENTINFORMATION= "Payment Information"
export const PAGE_NAME_PAYMENTSUMMARY= "Payment Summary"
export const PAGE_NAME_PRODUCT= "Product"
export const PAGE_NAME_PROSPECT= "Prospect"
export const PAGE_NAME_STATE= "State"
export const PAGE_NAME_ALLERGY= "Allergy"
export const PAGE_NAME_COMMUNITYMEMBER= "Community Member"
export const PAGE_NAME_REWARD_PRODUCT= "Reward Product"
export const PAGE_NAME_SUBSCRIBER= "Subscriber"
export const PAGE_NAME_REWARD= "Reward"
export const PAGE_NAME_WEBSITE_PRODUCT= "Product"
export const PAGE_NAME_WEBSITE_PRODUCT_CATEGORY= "Products"
export const PAGE_NAME_WEBSITE_CATEGORIES= "Categories"
export const PAGE_NAME_POINTFEATURE= "Point Feature"
export const PAGE_NAME_EDUCATIONGROUP = "Education Group"
export const PAGE_NAME_ETHNICITY = "Ethnicity"
export const PAGE_NAME_INCOMEGROUP = "Income Group"
export const PAGE_NAME_MARITALSTATUS = "Marital Status"
export const PAGE_NAME_WORKSTATUSGROUP = "Work Status Group"
export const PAGE_NAME_INDICATEACTIVITY = "Indicate Activity"
export const PAGE_NAME_NOTIFICATIONPREFERENCE = "Notification Preference"
export const PAGE_NAME_TAX = "Tax"
export const PAGE_NAME_AGETARGET = "Age Target"
export const PAGE_NAME_CALLTOACTION = "CTA"


//URL of page
export const PAGE_URL_ADMINISTRATOR = "Administrator"
export const PAGE_URL_COMPANYTYPES = "CompanyTypes"
export const PAGE_URL_COUNTRY = "Country"
export const PAGE_URL_COUPON = "Coupon"
export const PAGE_URL_GENDERTARGET = "GenderTarget"
export const PAGE_URL_CAMPAIGN = "Campaign"
export const PAGE_URL_CATEGORY = "Category"
export const PAGE_URL_HEARDABOUT = "HeardAbout"
export const PAGE_URL_LEAD = "Lead"
export const PAGE_URL_EVALUATOR = "Evaluator"
export const PAGE_URL_EMAIL = "Email"
export const PAGE_URL_CLIENT = "Client"
export const PAGE_URL_PACKAGE = "Package"
export const PAGE_URL_PACKAGEITEM = "PackageItem"
export const PAGE_URL_PAYMENTINFORMATION= "PaymentInformation"
export const PAGE_URL_PAYMENTSUMMARY= "PaymentSummary"
export const PAGE_URL_PRODUCT= "Product"
export const PAGE_URL_PROSPECT= "Prospect"
export const PAGE_URL_STATE= "State"
export const PAGE_URL_ALLERGY= "Allergy"
export const PAGE_URL_COMMUNITYMEMBER= "CommunityMember"
export const PAGE_URL_REWARD_PRODUCT= "RewardProduct"
export const PAGE_URL_SUBSCRIBER= "Subscriber"
export const PAGE_URL_REWARD= "Reward"
export const PAGE_URL_WEBSITE_PRODUCT= "Product"
export const PAGE_URL_WEBSITE_PRODUCT_CATEGORY= "Products"
export const PAGE_URL_WEBSITE_CATEGORIES= "Categories"
export const PAGE_URL_POINTFEATURE= "PointFeature"
export const PAGE_URL_EDUCATIONGROUP = "EducationGroup"
export const PAGE_URL_ETHNICITY = "Ethnicity"
export const PAGE_URL_INCOMEGROUP = "IncomeGroup"
export const PAGE_URL_MARITALSTATUS = "MaritalStatus"
export const PAGE_URL_WORKSTATUSGROUP = "WorkStatusGroup"
export const PAGE_URL_INDICATEACTIVITY = "IndicateActivity"
export const PAGE_URL_NOTIFICATIONPREFERENCE = "NotificationPreference"
export const PAGE_URL_TAX = "TAX"
export const PAGE_URL_AGETARGET = "AgeTarget"
export const PAGE_URL_CALLTOACTION = "CallToAction"






//Path inside AdminPanel
export const APP_ADMIN_ADMINISTRATOR_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_ADMINISTRATOR}`
export const APP_ADMIN_ADMINISTRATOR_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_ADMINISTRATOR}/Add`
export const APP_ADMIN_ADMINISTRATOR_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_ADMINISTRATOR}/Edit/:id`
export const APP_ADMIN_ADMINISTRATOR_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_ADMINISTRATOR}/Edit/`

export const APP_ADMIN_COMPANYTYPES_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COMPANYTYPES}`
export const APP_ADMIN_COMPANYTYPES_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COMPANYTYPES}/Add`
export const APP_ADMIN_COMPANYTYPES_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COMPANYTYPES}/Edit/:id`
export const APP_ADMIN_COMPANYTYPES_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COMPANYTYPES}/Edit/`

export const APP_ADMIN_COUNTRY_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COUNTRY}`
export const APP_ADMIN_COUNTRY_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COUNTRY}/Add`
export const APP_ADMIN_COUNTRY_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COUNTRY}/Edit/:id`
export const APP_ADMIN_COUNTRY_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COUNTRY}/Edit/`

export const APP_ADMIN_COUPON_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COUPON}`
export const APP_ADMIN_COUPON_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COUPON}/Add`
export const APP_ADMIN_COUPON_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COUPON}/Edit/:id`
export const APP_ADMIN_COUPON_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COUPON}/Edit/`

export const APP_ADMIN_GENDERTARGET_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_GENDERTARGET}`
export const APP_ADMIN_GENDERTARGET_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_GENDERTARGET}/Add`
export const APP_ADMIN_GENDERTARGET_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_GENDERTARGET}/Edit/:id`
export const APP_ADMIN_GENDERTARGET_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_GENDERTARGET}/Edit/`

export const APP_ADMIN_CAMPAIGN_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CAMPAIGN}`
export const APP_ADMIN_CAMPAIGN_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CAMPAIGN}/Add`
export const APP_ADMIN_CAMPAIGN_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CAMPAIGN}/Edit/:id`
export const APP_ADMIN_CAMPAIGN_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CAMPAIGN}/Edit/`

export const APP_ADMIN_CATEGORY_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CATEGORY}`
export const APP_ADMIN_CATEGORY_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CATEGORY}/Add`
export const APP_ADMIN_CATEGORY_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CATEGORY}/Edit/:id`
export const APP_ADMIN_CATEGORY_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CATEGORY}/Edit/`

export const APP_ADMIN_HEARDABOUT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_HEARDABOUT}`
export const APP_ADMIN_HEARDABOUT_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_HEARDABOUT}/Add`
export const APP_ADMIN_HEARDABOUT_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_HEARDABOUT}/Edit/:id`
export const APP_ADMIN_HEARDABOUT_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_HEARDABOUT}/Edit/`

export const APP_ADMIN_LEAD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_LEAD}`
export const APP_ADMIN_LEAD_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_LEAD}/Add`
export const APP_ADMIN_LEAD_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_LEAD}/Edit/:id`
export const APP_ADMIN_LEAD_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_LEAD}/Edit/`

export const APP_ADMIN_EVALUATOR_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_EVALUATOR}`
export const APP_ADMIN_EVALUATOR_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_EVALUATOR}/Add`
export const APP_ADMIN_EVALUATOR_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_EVALUATOR}/Edit/:id`
export const APP_ADMIN_EVALUATOR_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_EVALUATOR}/Edit/`

export const APP_ADMIN_EMAIL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_EMAIL}`
export const APP_ADMIN_EMAIL_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_EMAIL}/Add`
export const APP_ADMIN_EMAIL_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_EMAIL}/Edit/:id`
export const APP_ADMIN_EMAIL_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_EMAIL}/Edit/`

export const APP_ADMIN_CLIENT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CLIENT}`
export const APP_ADMIN_CLIENT_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CLIENT}/Add`
export const APP_ADMIN_CLIENT_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CLIENT}/Edit/:id`
export const APP_ADMIN_CLIENT_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CLIENT}/Edit/`
export const APP_ADMIN_CLIENT_COMPLETE_INFORMATION_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CLIENT}/CompleteInformation/`
export const APP_ADMIN_CLIENT_EDIT_PROFILE_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CLIENT}/EditProfile/`
export const APP_ADMIN_CLIENT_EDIT_PASSWORD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CLIENT}/EditPassword/`

export const APP_ADMIN_PACKAGE_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PACKAGE}`
export const APP_ADMIN_PACKAGE_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PACKAGE}/Add`
export const APP_ADMIN_PACKAGE_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PACKAGE}/Edit/:id`
export const APP_ADMIN_PACKAGE_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PACKAGE}/Edit/`

export const APP_ADMIN_PACKAGEITEM_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PACKAGEITEM}`
export const APP_ADMIN_PACKAGEITEM_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PACKAGEITEM}/Add`
export const APP_ADMIN_PACKAGEITEM_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PACKAGEITEM}/Edit/:id`
export const APP_ADMIN_PACKAGEITEM_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PACKAGEITEM}/Edit/`

export const APP_ADMIN_PAYMENTINFORMATION_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PAYMENTINFORMATION}/Add/Card`

export const APP_ADMIN_PAYMENTSUMMARY_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PAYMENTSUMMARY}`

export const APP_ADMIN_PRODUCT_ADMIN_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PRODUCT}/Admin`

export const APP_ADMIN_PRODUCT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PRODUCT}/`
export const APP_ADMIN_PRODUCT_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PRODUCT}/Edit/`

export const APP_ADMIN_PRODUCT_CLIENT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PRODUCT}/Client`
export const APP_ADMIN_PRODUCT_CLIENT_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PRODUCT}/Client/Add`
export const APP_ADMIN_PRODUCT_CLIENT_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PRODUCT}/Client/Edit/:id`
export const APP_ADMIN_PRODUCT_CLIENT_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PRODUCT}/Client/Edit/`

export const APP_ADMIN_PRODUCT_UPLOAD_SURVEY_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PRODUCT}/UploadSurvey`

export const APP_ADMIN_COMMUNITYMEMBER_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_COMMUNITYMEMBER}`

export const APP_ADMIN_PROSPECT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PROSPECT}`
export const APP_ADMIN_PROSPECT_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PROSPECT}/Add`
export const APP_ADMIN_PROSPECT_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PROSPECT}/Edit/:id`
export const APP_ADMIN_PROSPECT_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_PROSPECT}/Edit/`

export const APP_ADMIN_STATE_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_STATE}`
export const APP_ADMIN_STATE_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_STATE}/Add`
export const APP_ADMIN_STATE_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_STATE}/Edit/:id`
export const APP_ADMIN_STATE_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_STATE}/Edit/`

export const APP_ADMIN_ALLERGY_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_ALLERGY}`
export const APP_ADMIN_ALLERGY_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_ALLERGY}/Add`
export const APP_ADMIN_ALLERGY_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_ALLERGY}/Edit/:id`
export const APP_ADMIN_ALLERGY_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_ALLERGY}/Edit/`

export const APP_ADMIN_REWARD_PRODUCT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_REWARD_PRODUCT}`
export const APP_ADMIN_REWARD_PRODUCT_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_REWARD_PRODUCT}/Add`
export const APP_ADMIN_REWARD_PRODUCT_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_REWARD_PRODUCT}/Edit/:id`
export const APP_ADMIN_REWARD_PRODUCT_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_REWARD_PRODUCT}/Edit/`

export const APP_ADMIN_SUBSCRIBER_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_SUBSCRIBER}`
export const APP_ADMIN_SUBSCRIBER_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_SUBSCRIBER}/Add`
export const APP_ADMIN_SUBSCRIBER_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_SUBSCRIBER}/Edit/:id`
export const APP_ADMIN_SUBSCRIBER_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_SUBSCRIBER}/Edit/`

export const APP_ADMIN_POINTFEATURE_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_POINTFEATURE}`
export const APP_ADMIN_POINTFEATURE_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_POINTFEATURE}/Add`
export const APP_ADMIN_POINTFEATURE_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_POINTFEATURE}/Edit/:id`
export const APP_ADMIN_POINTFEATURE_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_POINTFEATURE}/Edit/`

export const APP_ADMIN_REWARD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_REWARD}`
export const APP_ADMIN_REWARD_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_REWARD}/Add`
export const APP_ADMIN_REWARD_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_REWARD}/Edit/:id`
export const APP_ADMIN_REWARD_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_REWARD}/Edit/`

export const APP_ADMIN_EDUCATIONGROUP_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_EDUCATIONGROUP}`
export const APP_ADMIN_EDUCATIONGROUP_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_EDUCATIONGROUP}/Add`
export const APP_ADMIN_EDUCATIONGROUP_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_EDUCATIONGROUP}/Edit/:id`
export const APP_ADMIN_EDUCATIONGROUP_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_EDUCATIONGROUP}/Edit/`

export const APP_ADMIN_ETHNICITY_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_ETHNICITY}`
export const APP_ADMIN_ETHNICITY_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_ETHNICITY}/Add`
export const APP_ADMIN_ETHNICITY_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_ETHNICITY}/Edit/:id`
export const APP_ADMIN_ETHNICITY_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_ETHNICITY}/Edit/`

export const APP_ADMIN_INCOMEGROUP_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_INCOMEGROUP}`
export const APP_ADMIN_INCOMEGROUP_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_INCOMEGROUP}/Add`
export const APP_ADMIN_INCOMEGROUP_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_INCOMEGROUP}/Edit/:id`
export const APP_ADMIN_INCOMEGROUP_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_INCOMEGROUP}/Edit/`

export const APP_ADMIN_MARITALSTATUS_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_MARITALSTATUS}`
export const APP_ADMIN_MARITALSTATUS_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_MARITALSTATUS}/Add`
export const APP_ADMIN_MARITALSTATUS_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_MARITALSTATUS}/Edit/:id`
export const APP_ADMIN_MARITALSTATUS_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_MARITALSTATUS}/Edit/`

export const APP_ADMIN_WORKSTATUSGROUP_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_WORKSTATUSGROUP}`
export const APP_ADMIN_WORKSTATUSGROUP_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_WORKSTATUSGROUP}/Add`
export const APP_ADMIN_WORKSTATUSGROUP_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_WORKSTATUSGROUP}/Edit/:id`
export const APP_ADMIN_WORKSTATUSGROUP_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_WORKSTATUSGROUP}/Edit/`

export const APP_ADMIN_INDICATEACTIVITY_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_INDICATEACTIVITY}`
export const APP_ADMIN_INDICATEACTIVITY_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_INDICATEACTIVITY}/Add`
export const APP_ADMIN_INDICATEACTIVITY_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_INDICATEACTIVITY}/Edit/:id`
export const APP_ADMIN_INDICATEACTIVITY_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_INDICATEACTIVITY}/Edit/`

export const APP_ADMIN_NOTIFICATIONPREFERENCE_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_NOTIFICATIONPREFERENCE}`
export const APP_ADMIN_NOTIFICATIONPREFERENCE_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_NOTIFICATIONPREFERENCE}/Add`
export const APP_ADMIN_NOTIFICATIONPREFERENCE_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_NOTIFICATIONPREFERENCE}/Edit/:id`
export const APP_ADMIN_NOTIFICATIONPREFERENCE_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_NOTIFICATIONPREFERENCE}/Edit/`

export const APP_ADMIN_TAX_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_TAX}`
export const APP_ADMIN_TAX_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_TAX}/Add`
export const APP_ADMIN_TAX_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_TAX}/Edit/:id`
export const APP_ADMIN_TAX_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_TAX}/Edit/`

export const APP_ADMIN_AGETARGET_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_AGETARGET}`
export const APP_ADMIN_AGETARGET_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_AGETARGET}/Add`
export const APP_ADMIN_AGETARGET_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_AGETARGET}/Edit/:id`
export const APP_ADMIN_AGETARGET_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_AGETARGET}/Edit/`

export const APP_ADMIN_CALLTOACTION_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CALLTOACTION}`
export const APP_ADMIN_CALLTOACTION_ADD_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CALLTOACTION}/Add`
export const APP_ADMIN_CALLTOACTION_EDIT_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CALLTOACTION}/Edit/:id`
export const APP_ADMIN_CALLTOACTION_EDIT_CALL_PATH = `${APP_ADMIN_PATH}/${PAGE_URL_CALLTOACTION}/Edit/`





//Path inside Website
export const APP_WEBSITE_PRODUCT_PATH = `${APP_WEBSITE_PATH}/${PAGE_URL_WEBSITE_PRODUCT}/:friendlyURL`
export const APP_WEBSITE_PRODUCT_CALL_PATH = `${APP_WEBSITE_PATH}/${PAGE_URL_WEBSITE_PRODUCT}/`
export const APP_WEBSITE_PRODUCT_CATEGORY_CALL_PATH = `${APP_WEBSITE_PATH}/${PAGE_URL_WEBSITE_PRODUCT_CATEGORY}/`

export const APP_WEBSITE_CATEGORIES_PATH = `${APP_WEBSITE_PATH}/${PAGE_URL_WEBSITE_CATEGORIES}/`